export class ReferenciaComercial {
    fondoinversion_id: Number;
    persona_id:Number;
    nombre_comercial:string;
    telefono:string;
    compra_promedio:string;
    ejecutivo_cuenta:any;
    direccion:string;
    estado:string;
    fecha_registro:any;
    constructor(rc:ReferenciaComercial = null){
        if(rc != null){
            this.fondoinversion_id=rc.fondoinversion_id;
            this.persona_id=rc.persona_id;
            this.nombre_comercial=rc.nombre_comercial;
            this.telefono=rc.telefono;
            this.compra_promedio=rc.compra_promedio;
            this.ejecutivo_cuenta=rc.ejecutivo_cuenta;
            this.direccion=rc.direccion;
            this.estado=rc.estado;
            this.fecha_registro=rc.fecha_registro;
        }
    }
}