import { Component, OnInit, ViewChild, Input, Output, EventEmitter, IterableDiffer, IterableDiffers } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AccionistaEmpresa } from 'src/app/model/accionistaEmpresa.model';
import { Data } from 'src/app/model/z.model';
import { Pep } from 'src/app/model/persona/pep.model';
import { VinculacionAdministradora } from 'src/app/model/vinculacionAdministradora.model';
import { Observacion } from 'src/app/model/observaciones.model';

@Component({
  selector: 'app-accionista-empresa',
  templateUrl: './accionista-empresa.component.html',
  styleUrls: ['./accionista-empresa.component.css']
})
export class AccionistaEmpresaComponent implements OnInit {
  isLoading:boolean=true;
  isLoading2:boolean=true;
  banText:boolean = false;
  banTextVinculacion:boolean = false;
  banTextPeps:boolean = false;
  @Input() banCheck: boolean;
  @Input() ae: AccionistaEmpresa[];
  @Input() p:Pep[];
  @Input() va: VinculacionAdministradora[] = [];
  @ViewChild('pag1', {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['identificacion','nombre','porcentaje'];
  @ViewChild('pag2', {static: true}) paginator2: MatPaginator;
  displayedColumns2: string[] = ['funcion','cargo','jerarquia'];
  dataSource;
  dataSource2;
  displayedColumns3: string[] = ['administradora','descripcion'];
  @ViewChild('pag3', {static: true}) paginator3: MatPaginator;
  dataSource3;
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsChangePeps:EventEmitter<string> = new EventEmitter();
  @Output() obsChangeVinculacion:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  @Output() obsCheckPeps:EventEmitter<boolean> = new EventEmitter();
  @Output() obsCheckVinculacion:EventEmitter<boolean> = new EventEmitter();
  displayedColumns4: string[] = ['mensaje','estado'];
  @Input() obsList:Observacion[]=[];
  dataSource4;
  iterableDiffer: IterableDiffer<any>;
  constructor(private iterableDiffers: IterableDiffers,) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }
  ngOnChanges(){
    if(this.ae.length>0){
      this.isLoading=false;
    }
    this.dataSource = new MatTableDataSource<AccionistaEmpresa>(this.transformData(this.ae));
    this.dataSource.paginator = this.paginator;
    if(this.p.length>0){
      this.isLoading2=false;
    }
    this.dataSource2 = new MatTableDataSource<Pep>(this.p);
    this.dataSource2.paginator = this.paginator2;
    this.dataSource3 = new MatTableDataSource<VinculacionAdministradora>(this.va);
    this.dataSource3.paginator = this.paginator3;
  }
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.obsList);
    if (changes) {
      var obs = this.obsList.filter(t=>(t.paso==4));
      this.dataSource4 = new MatTableDataSource<Observacion>(obs);
    }
  }
  ngOnInit() {
  }
  public transformData(data:any):any[]{
    var dt:{
      identificacion:string,
      nombre:string,
      porcentaje:string
    }[]=[];
    for (let t in data) {
      dt.push({
        identificacion:Data._trim(data[t].identificacion_empresa),
        nombre:Data._trim(data[t].nombre_empresa),
        porcentaje:data[t].porcentaje
      });
    }
    return dt;
  }
  changeStatusObs(e){
    this.banText=e.checked;
    this.obsCheck.emit(this.banText);
  }
  changeStatusObsPeps(e){
    this.banTextPeps=e.checked;
    this.obsCheckPeps.emit(this.banTextPeps);
  }
  changeStatusObsVinculacion(e){
    this.banTextVinculacion=e.checked;
    this.obsCheckVinculacion.emit(this.banTextVinculacion);
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
  obtValorPeps(v){
    this.obsChangePeps.emit(v);
  }
  obtValorVinculacion(v){
    this.obsChangeVinculacion.emit(v);
  }
}
