import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { GeneralService } from 'src/app/service/general.service';
import { Report1 } from 'src/app/model/reports/report1.model';
import { Report2 } from 'src/app/model/reports/report2.model';
import { Data } from 'src/app/model/z.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'app-chart-inversiones',
  templateUrl: './chart-inversiones.component.html',
  styleUrls: ['./chart-inversiones.component.css']
})
export class ChartInversionesComponent implements OnInit {
  listReport1:Report1[]=[];
  listReport2:Report2[]=[];
  fondos:any[]=[];
  formFondo:FormGroup;
  totalCredito:number = 0;
  totalDebito:number = 0;
  totalCreditoFondos:number = 0;
  totalDebitoFondos:number = 0;
  inversionistas:number = 0;
  totalInversionistas:number = 0;

  radioModel = 'anual';
  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'Inversiones' },
    { data: [], label: 'Rescates' }
  ];
  meses:string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
  public lineChartLabels: Label[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [{}]
    },
    plugins: {
      datalabels: {
        display:false
      }
    },
    annotation: {
      annotations: [
        // {
        //   type: 'line',
        //   mode: 'vertical',
        //   scaleID: 'x-axis-0',
        //   value: 'March',
        //   borderColor: 'orange',
        //   borderWidth: 2,
        //   label: {
        //     enabled: true,
        //     fontColor: 'orange',
        //     content: 'LineAnno'
        //   }
        // }
      ]
    }
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(62, 82, 143,0.2)',
      borderColor: 'rgba(62, 82, 143,1)',
      pointBackgroundColor: '#1a77a8b7',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    {
      backgroundColor: 'rgba(3, 168, 112,0.2)',
      borderColor: 'rgba(3, 168, 112,1)',
      pointBackgroundColor: '#1a255279',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;


  constructor(private _builder: FormBuilder,private generalService: GeneralService) {
    this.formFondo = this._builder.group({
      fondo:['']
    });
  }

  ngOnInit() {
    this.getAllReport();
    this.onChanges();
  }
  get tipoFondos(){
    return Data._fondosInversion;
  }
  getAllReport(){
    let calls:any[]=[];
    calls.push(this.generalService.useService(Report1.getAll()));
    calls.push(this.generalService.useService(Report2.getAll()));
    forkJoin(calls).subscribe(
      (data:any[])=>{
        if(data[0].datos){
          this.totalCreditoFondos = 0;
          this.totalDebitoFondos = 0;
          for (let t in data[0].datos) {
            this.totalCreditoFondos = this.totalCreditoFondos + Number(data[0].datos[t].CR);
            this.totalDebitoFondos = this.totalDebitoFondos + Number(data[0].datos[t].DB);
            if(this.fondos.length==0){
              var arry:any[] = [data[0].datos[t]];
              this.fondos.push(arry);
            }else{
              var bool = false;
              for(let x of this.fondos){
                for(let x1 of x){
                  if(data[0].datos[t].fondoinversion_id == x1.fondoinversion_id){
                    x.push(data[0].datos[t]);
                    bool = true;
                    break;
                  }
                } 
              }
              if(bool==false){
                var arry:any[] = [data[0].datos[t]];
                this.fondos.push(arry);
              }
            }
          }
        }

        if(data[1].datos!=null){
          this.totalInversionistas = 0;
          for (let t in data[1].datos) {
            this.totalInversionistas = this.totalInversionistas + Number(data[1].datos[t].inversionistas);
            this.listReport2.push(new Report2(data[1].datos[t] as Report2));
          }
        }
        if(this.fondos.length!=0)
          this.formFondo.patchValue({fondo:this.fondos[0][0].fondoinversion_id});
        else
          this.formFondo.patchValue({fondo:this.tipoFondos[0].fondoinversion_id});
      }
    );
  }
  onChanges(): void {
    this.formFondo.valueChanges.subscribe(val => {
      this.btn_mensual(val.fondo);
    });
  }
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  btn_mensual(idFondo){
    this.inversionistas= 0;
    this.listReport2.forEach(t=>{
      if(t.fondoinversion_id==idFondo){
        this.inversionistas = t.inversionistas;
      }
    })
    this.totalCredito = 0;
    this.totalDebito = 0;
    this.lineChartLabels = [];
    this.lineChartData = [
      { data: [], label: 'Inversiones' },
      { data: [], label: 'Rescates' }
    ];
    var encontro:boolean = false;
    for(let x of this.fondos){
      if(x[0].fondoinversion_id==idFondo){
        encontro = true;
        for(let x1 of x){
          this.totalCredito = this.totalCredito + Number(x1.CR);
          this.totalDebito = this.totalDebito + Number(x1.DB);
          this.lineChartLabels.push(this.meses[x1.mes-1]);
          for (let i = 0; i < this.lineChartData.length; i++) {
            if(i==0){
              this.lineChartData[i].data[this.lineChartLabels.length-1] = x1.CR;
            }else{
              this.lineChartData[i].data[this.lineChartLabels.length-1] = x1.DB;
            }
          }
        }
        break;
      }
    }
    if(encontro==false){
      this.lineChartLabels = [];
      this.lineChartData = [
        { data: [], label: 'No existen datos' }
      ];
    }
    this.chart.update();
  }
}
