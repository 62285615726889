import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { GeneralService } from 'src/app/service/general.service';
import { Report1 } from 'src/app/model/reports/report1.model';
import { Report2 } from 'src/app/model/reports/report2.model';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'app-chart-fondo',
  templateUrl: './chart-fondo.component.html',
  styleUrls: ['./chart-fondo.component.css']
})
export class ChartFondoComponent implements OnInit {
  listReport1:Report1[]=[];
  listReport2:Report2[]=[];
  radioModel = 'anual';
  fondoSelected = "Alfa";
  totalCredito:number = 0;
  totalDebito:number = 0;
  totalInversionistas:number = 0;
  solicitantes:number = 0;
  public barChartOptions: ChartOptions = {
    scales: {
      xAxes: [{}],
      yAxes: [{id: 'y-axis-1', type: 'linear', position: 'left', ticks: {min:0}}]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          return datasetLabel + ': $' + tooltipItem.yLabel;
        },
        footer: (tooltipItem, data) => {
          const datasetLabel = data.datasets[tooltipItem[0].datasetIndex].label || '';
          var foot = [];
          this.listReport2.forEach(t=>{
            if(t.nombrefondo==datasetLabel){
              foot = ['Nº Inversionistas: '+ t.inversionistas,'Nº Solicitantes: '+t.solicitantes,'Porc. Inversionistas: '+parseFloat((t.indice_inversionistas*100).toString()).toFixed(2)+'%'];    
            }
          })
          return foot;
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    
    plugins: {
      datalabels: {
        color:'#fff'
      }
    }
  };
  public barChartLabels: Label[] = ['Anual'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(62, 82, 143,1)',
      borderColor: 'rgba(62, 82, 143,1)',
      pointBackgroundColor: '#1a77a8b7',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)',
    },
    {
      backgroundColor: 'rgba(3, 168, 112,1)',
      borderColor: 'rgba(3, 168, 112,1)',
      pointBackgroundColor: '#1a255279',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      backgroundColor: 'red',
      borderColor: 'rgba(3, 168, 112,1)',
      pointBackgroundColor: '#1a255279',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      backgroundColor: 'green',
      borderColor: 'rgba(3, 168, 112,1)',
      pointBackgroundColor: '#1a255279',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      backgroundColor: 'yellow',
      borderColor: 'rgba(3, 168, 112,1)',
      pointBackgroundColor: '#1a255279',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      backgroundColor: 'purple',
      borderColor: 'rgba(3, 168, 112,1)',
      pointBackgroundColor: '#1a255279',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public barChartData: ChartDataSets[] = [{data:[0],label:'No existen datos'}];

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }
  constructor(private generalService: GeneralService) { }
  ngOnInit() {
    this.getAllReport();
  }
  getAllReport(){
    let calls:any[]=[];
    calls.push(this.generalService.useService(Report1.getAll()));
    calls.push(this.generalService.useService(Report2.getAll()));
    forkJoin(calls).subscribe(
      (resp:any[])=>{
        console.log(resp);
        this.totalCredito = 0;
        this.totalDebito = 0;
        for (let t in resp[0].datos) {
          this.totalCredito = this.totalCredito + Number(resp[0].datos[t].CR);
          this.totalDebito = this.totalDebito + Number(resp[0].datos[t].DB);
          this.listReport1.push(new Report1(resp[0].datos[t] as Report1));
        }

        this.totalInversionistas = 0;
        for (let t in resp[1].datos) {
          this.totalInversionistas = this.totalInversionistas + resp[1].datos[t].inversionistas;
          this.solicitantes = this.solicitantes + resp[1].datos[t].solicitantes;
          this.listReport2.push(new Report2(resp[1].datos[t] as Report2));
        }
        if (this.listReport1.length != 0)
          this.btn_anual();
      }
    );
  }
  btn_mensual(){
    this.barChartLabels = ['Mensual']
    this.barChartData =[];
    var currentYear = (new Date()).getFullYear();
    var currentMount = (new Date()).getMonth();
    var data:any[]=[];
    this.listReport1.forEach(t=>{
      var buscar:boolean = false;
      if(t.anio==currentYear && t.mes == currentMount){
        for(let x of data){
          if(t.fondoinversion_id == x.fondoinversion_id){
            buscar = true;
            x.value = x.value + (t.CR - t.DB);
          }
        }
        if(buscar == false){
          data.push({fondoinversion_id: t.fondoinversion_id,nombrefondo:t.nombrefondo,value:(t.CR-t.DB)});
        }
      }
    })
    data.forEach(t=>{
      this.barChartData.push({data:[t.value],label:t.nombrefondo});
    });
  }
  btn_anual(){
    this.barChartLabels = ['Anual']
    this.barChartData =[];
    var currentYear = (new Date()).getFullYear();
    var data:any[]=[];
    this.listReport1.forEach(t=>{
      var buscar:boolean = false;
      if(t.anio==currentYear){
        for(let x of data){
          if(t.fondoinversion_id == x.fondoinversion_id){
            buscar = true;
            x.value = x.value + (t.CR - t.DB);
          }
        }
        if(buscar == false){
          data.push({fondoinversion_id: t.fondoinversion_id,nombrefondo:t.nombrefondo,value:(t.CR-t.DB)});
        }
      }
    });
    data.forEach(t=>{
      this.barChartData.push({data:[Number(Number(t.value).toFixed(2))],label:t.nombrefondo});
    });
  }
}