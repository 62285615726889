import { Component, OnInit } from '@angular/core';
import { ReportFuncionario } from 'src/app/model/reports/reportFuncionario.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportFuncionarioRetiro } from 'src/app/model/reports/reportFuncionarioRetiro.model';

@Component({
  selector: 'app-table-produccion',
  templateUrl: './table-produccion.component.html',
  styleUrls: ['./table-produccion.component.css']
})
export class TableProduccionComponent implements OnInit {
  displayedColumns: string[] = ['nombre','pendientes','atendidos','total','aprobados','observados','cancelados'];
  dataSource;
  displayedColumnsRet: string[] = ['nombre','atendidos','noAtendido','montoAtendido','montoNoAtendido'];
  dataSourceRet;
  listFuncionarios:ReportFuncionario[] = [];
  listFuncionariosRetiro:ReportFuncionarioRetiro[] = [];
  constructor(private generalService:GeneralService) { }

  ngOnInit() {
    this.getAllFuncionario();
    this.getAllFuncionarioRetiro();
  }
  getAllFuncionario(){
    this.generalService.useService(ReportFuncionario.getAll()).subscribe(
      (data:any) =>{
        if(data.datos){
          console.log(data.datos);
          this.listFuncionarios = Object.values(data.datos).map((t:any)=>{
            return new ReportFuncionario(t);
          });
        }
        this.dataSource =  new MatTableDataSource<ReportFuncionario>(this.listFuncionarios)
      },
      (err:HttpErrorResponse)=>{
        console.error("Ocurrio un error al consultar la producción de funcionarios por solicitudes de inversión.");
      }
    );
  }
  getAllFuncionarioRetiro(){
    this.listFuncionariosRetiro = [];
    this.generalService.useService(ReportFuncionarioRetiro.getAll()).subscribe(
      (data:any) =>{
        console.log(data);
        for (let t in data.datos) {
          if(this.listFuncionariosRetiro.length==0){
            this.listFuncionariosRetiro.push(new ReportFuncionarioRetiro(data.datos[t] as ReportFuncionarioRetiro));
          }else{
            for(let x of this.listFuncionariosRetiro){
              if(x.nombre == data.datos[t].nombre){
                x.calculeProd(data.datos[t] as ReportFuncionarioRetiro);
              } 
            }
          }
        }
        this.dataSourceRet =  new MatTableDataSource<ReportFuncionarioRetiro>(this.listFuncionariosRetiro);
      },
      (err:HttpErrorResponse)=>{
        console.error("Ocurrio un error al consultar la producción de funcionarios para solicitudes de retiro. Consulte con su proveedor.");
      }
    );
  }
}