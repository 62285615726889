import { Ciudad } from './ciudad.model';

export class Provincia {
    descripcion: string;
    ciudades:Ciudad[] = [];
    constructor(pr:Provincia = null){
        if(pr!=null){
            this.descripcion = pr.descripcion;
            for(let f in pr.ciudades){
                this.ciudades.push(new Ciudad(pr.ciudades[f]));
            }
        }
    }
}