import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-crear-grupos',
  templateUrl: './crear-grupos.component.html',
  styleUrls: ['./crear-grupos.component.css']
})
export class CrearGruposComponent implements OnInit {
  paramBusquedaGroup:FormGroup;
  constructor(private _builder: FormBuilder) { }

  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      NombreGrupo: [''],
      Descripcion:['']
    });
  }
  find(form){
    console.log(form);
  }
}
