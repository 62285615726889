import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Data } from 'src/app/model/z.model';

import { MatPaginator } from '@angular/material/paginator';
import { SolicitudTransferencia } from 'src/app/model/solicitudes/solicitudTransferencia.model';
import { MatTableDataSource } from '@angular/material/table';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatSort } from '@angular/material/sort';
import { HttpErrorResponse } from '@angular/common/http';
import { SimuladorBanco } from 'src/app/model/simuladorBanco.model';
import { ExportServicesService } from 'src/app/service/export-services.service';
import * as jsPDF  from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-transferencia',
  templateUrl: './transferencia.component.html',
  styleUrls: ['./transferencia.component.css']
})
export class TransferenciaComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource;
  isLoading = true;
  isLoading2:boolean;
  displayedColumns: string[] = ['transaccion_id','identificacion','nombre','nombrefondo','estado_nombre','monto','fecha_registro'];
  listSolicitudTransferencia: SolicitudTransferencia[]=[];
  paramBusquedaGroup:FormGroup;
  tipoFondos: FondoInversion[] = [];
  tipoEstados: DetalleCatalogo[] = [];
  constructor(private generalService: GeneralService,private _builder: FormBuilder, private excelService:ExportServicesService) {}

  ngOnInit() {
    this.tipoEstados = Data._fListCatalogo("ESTADO SOLICITUD");
    this.tipoFondos = Data._fondosInversion;
    this.paramBusquedaGroup = this._builder.group({
      Documento: [''],
      FondoInversion:[''],
      rangoFecha:[''],
      Estado:['']
    });
    this.getAllSolRetiro();
    this.onChanges();
  }
  run(){
    this.isLoading2 = true;
    this.generalService.useService(SimuladorBanco.startSimulador()).subscribe(
      (data)=>{
        this.getAllSolRetiro();
        this.isLoading2 = false;
      },
      (err)=>{console.error("Ocurrio un error en el simulador")}
    );
  }
  getAllSolRetiro(){
    this.paramBusquedaGroup.disable();
    this.listSolicitudTransferencia = [];
    this.dataSource = new MatTableDataSource<SolicitudTransferencia>(this.listSolicitudTransferencia);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(SolicitudTransferencia.getAll()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listSolicitudTransferencia.push(new SolicitudTransferencia(data.datos[t] as SolicitudTransferencia));
        }
        this.dataSource = new MatTableDataSource<SolicitudTransferencia>(this.listSolicitudTransferencia);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
        this.paramBusquedaGroup.enable();
      },
      (err : HttpErrorResponse)=>{
        console.error("Ocurrio un error al listar solicitudes de retiro. Consulte con su Proveedor");
      }
    );
  }
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.find(this.paramBusquedaGroup.value);
    });
  }
  find(form:any){
    var temp = this.listSolicitudTransferencia;
    if(form.Documento!=""){
      temp = temp.filter(function(el) {
        return el.identificacion.indexOf(form.Documento) > -1});
    }
    if(form.FondoInversion!="")
      temp=temp.filter(d => (d.fondoinversion_id == form.FondoInversion));
    if(form.rangoFecha != null){
      if((form.rangoFecha[0] != null && form.rangoFecha[1] != null)){
        form.rangoFecha[0].setHours(0, 0, 0);
        form.rangoFecha[1].setHours(23, 59, 59);
        temp=temp.filter(d => (d.fecha_registro.getTime() <= form.rangoFecha[1].getTime() && d.fecha_registro.getTime() >= form.rangoFecha[0].getTime()));
      }
    }
    if(form.Estado!="")
      temp=temp.filter(d => (d.estado_nombre == form.Estado));
    this.configTable(temp);
  }
  configTable(data){
    this.dataSource = new MatTableDataSource<SolicitudTransferencia>(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
