import { Data } from './z.model';

export class Beneficiario {
    persona_id:Number;
    tipoidentificacion_id:Number;
    identificacion: string;
    nombres:string;
    apellidos:string;
    porcentaje:any;
    genero:Number;
    tipoparentesco_id:Number;
    estado: string;
    constructor(b:Beneficiario = null){
        if(b!=null){
            this.persona_id = b.persona_id;
            this.tipoidentificacion_id = b.tipoidentificacion_id;
            this.identificacion=atob(b.identificacion);
            this.nombres=Data._atob(b.nombres);
            this.apellidos=Data._atob(b.apellidos);
            this.porcentaje = b.porcentaje;
            this.tipoparentesco_id = b.tipoparentesco_id;
            this.genero = b.genero;
            this.estado = b.estado;
        }
    }
}
