import { formatDate } from '@angular/common';
import { Data } from './z.model';

export class Beneficio {
    beneficio_id:number;
    titulo:string;
    descripcion:string;
    monto:string;
    inicio_beneficio:Date;
    fin_beneficio:Date;
    estado:string;
    tipo_beneficio:number;
    url:string;
    asignados:any[]=[];
    constructor(b:Beneficio = null){
        if(b != null){
            this.beneficio_id = b.beneficio_id;
            this.titulo=b.titulo;
            this.descripcion=b.descripcion;
            this.monto=b.monto;
            this.fin_beneficio=new Date(b.fin_beneficio['date']);
            this.inicio_beneficio=new Date(b.inicio_beneficio['date']);
            this.estado=b.estado;
            this.tipo_beneficio=b.tipo_beneficio;
            this.url=b.url;
            for(let i in b.asignados){
                this.asignados.push(b.asignados[i]);
            }
        }
    }
    //TODO Aun no me lo han dado a conocer.
    public static getAll() {
        return Data._getBody("C5",168,sessionStorage.getItem("token_fks"));
    }
    public static assign(idFondo,idBenef){
        var tmp = Data._getBody("I3",173,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = idFondo;
        tmp["data"]["beneficio_id"] = idBenef;
        return tmp;
    }
    public static post(b:Beneficio) {
        var finicio = b.inicio_beneficio?formatDate(b.inicio_beneficio, 'yyyy-MM-dd', 'en'):null;
        var ffin = b.fin_beneficio?formatDate(b.fin_beneficio, 'yyyy-MM-dd', 'en'):null;
        var tmp = Data._getBody("I",173,sessionStorage.getItem("token_fks"));
        tmp["data"]["titulo"] = b.titulo;
        tmp["data"]["descripcion"] = b.descripcion;
        tmp["data"]["monto"] = b.monto;
        tmp["data"]["fecha_inicio"] = finicio;
        tmp["data"]["fecha_fin"] = ffin;
        tmp["data"]["estado"] = "A";
        tmp["data"]["tipo_beneficio"] = b.tipo_beneficio;
        tmp["data"]["url"] = b.url;
        return tmp;
    }
}