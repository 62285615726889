import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { TipoUsuario } from 'src/app/model/tipoUsuario.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from 'src/app/service/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { GrupoUsuario } from 'src/app/model/grupoUsuario.model';
import { RelacionTipoGrupoUsuario } from 'src/app/model/relacionTipoGrupoUsuario.model';
import { Data } from 'src/app/model/z.model';
import { MessageComponent } from 'src/app/util/messageError.component';

@Component({
  selector: 'app-mant-tipo-usuario',
  templateUrl: './mant-tipo-usuario.component.html',
  styleUrls: ['./mant-tipo-usuario.component.css']
})
export class MantTipoUsuarioComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['tipoUsuario','grupoUsuario','acciones'];
  tipoSelected: RelacionTipoGrupoUsuario;
  paramBusquedaGroup:FormGroup;
  addForm:FormGroup;
  modalRef: BsModalRef;
  isCollapsed = true;
  isSaving:boolean;
  isLoading:boolean;
  isDeleting:boolean;
  fech:Date= new Date();
  tipoGrupo: GrupoUsuario[]=[];
  tipoUsuario: TipoUsuario[]=[];
  addText: string;
  listRelacion: RelacionTipoGrupoUsuario[] = [];
  constructor(private message: MessageComponent,private generalService:GeneralService,private modalService: BsModalService,private _builder: FormBuilder) {
    this.paramBusquedaGroup = this._builder.group({
      tipousuario_grupo_id: [null],
      grupo_id: ['',[Validators.required]],
      tipousuario_id:['',[Validators.required]]
    });
    this.addForm = this._builder.group({
      nombre: ['',[Validators.required]]
    });
  }
  clear(){
    this.tipoSelected = new RelacionTipoGrupoUsuario();
    this.isCollapsed = true;
    this.paramBusquedaGroup.reset({
      tipousuario_grupo_id: '',
      grupo_id: '',
      tipousuario_id:''
    });
    this.addForm.reset({nombre:''});
  }
  viewRelacion(f:RelacionTipoGrupoUsuario,ban:boolean){
    this.isCollapsed = false;
    this.tipoSelected = f;
    console.log(this.tipoSelected);
    this.paramBusquedaGroup.patchValue(this.tipoSelected);
    console.log(this.paramBusquedaGroup.value);
    if(ban)
      this.paramBusquedaGroup.disable();
    else
      this.paramBusquedaGroup.enable();
  }
  ngOnInit() {
    this.getAllTipoUsuario();
    this.getAllGrupoUsuario();
    this.getAllTipoGrupoUsuario();
  }
  save(form){
    this.isSaving=true;
    this.generalService.useService(RelacionTipoGrupoUsuario.post(form)).subscribe(
      (data : any)=>{
        this.isSaving=false;
        if(Data.isOk(data)){
          this.clear();
          this.getAllTipoGrupoUsuario();
        }else{
          this.message.messageError("No se realizó la operación.");
        }
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  delTipo(){
    this.isDeleting=true;
    this.generalService.useService( RelacionTipoGrupoUsuario.delete(this.tipoSelected.tipousuario_grupo_id)).subscribe(
      (data : any)=>{
        console.log(data);
        if(Data.isOk(data)){
          this.clear();
          this.getAllTipoGrupoUsuario();
          this.modalRef.hide();
        }else{
          this.message.messageError("No se realizó la operación");
        }
        this.isDeleting=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  openModal(template: TemplateRef<any>,f:RelacionTipoGrupoUsuario) {
    this.tipoSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }
  getAllTipoGrupoUsuario() {
    this.isLoading = true;
    this.listRelacion = [];
    this.dataSource = new MatTableDataSource<TipoUsuario>(this.listRelacion);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(RelacionTipoGrupoUsuario.getAll()).subscribe(
      (data : any)=>{ 
        for (let t in data.datos) {
          this.listRelacion.push(new RelacionTipoGrupoUsuario(data.datos[t] as RelacionTipoGrupoUsuario));
        }
        this.dataSource = new MatTableDataSource<TipoUsuario>(this.listRelacion);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  getAllGrupoUsuario() {
    this.tipoGrupo = [];
    this.generalService.useService(GrupoUsuario.getAll()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.tipoGrupo.push(new GrupoUsuario(data.datos[t] as GrupoUsuario));
        }
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  getAllTipoUsuario(){
    this.tipoUsuario = [];
    this.generalService.useService(TipoUsuario.getAll()).subscribe(
      (data : any)=>{
        for(let t in data.datos){
          this.tipoUsuario.push(new TipoUsuario({tipousuario_id:Number(t),tipousuario:data.datos[t].tipousuario}))
        }
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  saveAdd(e){
    this.isSaving=true;
    var tmp;
    if(this.addText == "Grupo"){
      tmp = GrupoUsuario.post({descripcion:e.nombre,grupo_id:null});
    }else{
      tmp = TipoUsuario.post({tipousuario:e.nombre,tipousuario_id:null});
    }
    this.generalService.useService(tmp).subscribe(
      (data : any)=>{
        this.isSaving=false;
        if(this.addText == "Grupo"){
          this.getAllGrupoUsuario();
        }else{
          this.getAllTipoUsuario();
        }
        this.clear();
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}