import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
defineLocale('es', esLocale);

@Component({
  selector: 'app-consulta-movimientos',
  templateUrl: './consulta-movimientos.component.html',
  styleUrls: ['./consulta-movimientos.component.css']
})
export class ConsultaMovimientosComponent implements OnInit {
  tipoIdentificaciones: DetalleCatalogo[] = [];

  paramBusquedaGroup:FormGroup;

  constructor(private _builder: FormBuilder,private localeService: BsLocaleService) {
    this.localeService.use('es');
  }

  ngOnInit() {
    this.tipoIdentificaciones = Data._fListCatalogo("TIPO DE IDENTIFICACION PERSONA");
    this.paramBusquedaGroup = this._builder.group({
      tipoIdentificacion: [''],
      Documento:[''],
      fechaD:[''],
      fechaH:['']
    });
  }
  find(form){
    console.log(form);
  }
}
