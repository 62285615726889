import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatPaginator } from '@angular/material/paginator';
import { Data } from 'src/app/model/z.model';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { Usuario } from 'src/app/model/usuario.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { GeneralService } from 'src/app/service/general.service';
import { Persona } from 'src/app/model/persona/persona.model';
import { TipoUsuario } from 'src/app/model/tipoUsuario.model';
import { Administradora } from 'src/app/model/administradora.model';
import { MessageComponent } from 'src/app/util/messageError.component';

@Component({
  selector: 'app-mant-usuario',
  templateUrl: './mant-usuario.component.html',
  styleUrls: ['./mant-usuario.component.css']
})
export class MantUsuarioComponent implements OnInit {
  hidden = false;
  isLoading : boolean;
  isDelete: boolean;
  isChangePass:boolean;
  @ViewChild(MatHorizontalStepper, {static: true}) stepper: MatHorizontalStepper;
  @ViewChild("pag1", {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['identificacion','nombre','usuario','cargo','acciones'];
  listUsuario:Usuario[]=[];
  isLinear = true;
  isCollapsed = true;
  usuarioSelected:Usuario = new Usuario();
  datosPersonalesForm:FormGroup;
  formFilter:FormGroup;
  credencialesForm:FormGroup;
  changePassForm:FormGroup;
  modalRef: BsModalRef;
  id_usuario: number;
  cboTpoUsuario: any[];
  cboAdministradora: Administradora[];
  constructor(private message: MessageComponent,private modalService: BsModalService,private _builder: FormBuilder,private localeService: BsLocaleService,
              private generalService:GeneralService) {
    this.localeService.use('es');
    this.datosPersonalesForm = this._builder.group({
      persona_id:[null],
      nombres: ['',Validators.required],
      apellidos:['',Validators.required],
      tipoidentificacion_id:['',Validators.required],
      identificacion:['',Validators.required],
      fecha_expedicion:['',Validators.required],
      codigo_dactilar:['',Validators.required],
      nacionalidad_id:['',Validators.required],
      fecha_nacimiento:['',Validators.required],
      genero:['',Validators.required],
      estadocivil_id:['',Validators.required]
    });
    this.credencialesForm = this._builder.group({
      tpo:['',[Validators.required]],
      email:['',[Validators.required,Validators.email]],
      financiera:['',Validators.required]
    });
    this.changePassForm = this._builder.group({
      password:['',[Validators.required]]
    });
    this.formFilter = this._builder.group({
      tipo:[''],
      identificacion:[''],
      administradora:['']
    });
    this.datosPersonalesForm.controls['identificacion'].disable();
  }
  ngOnInit() {
    this.getAllTipoUsuario();
    this.getAllAdministradora();
    this.onChanges();
  }
  get tipoIdentificacion(){ return Data._fListCatalogo("TIPO DE IDENTIFICACION PERSONA"); }
  get genero(){ return Data._fListCatalogo("SEXO"); }
  get nacionalidad(){ return Data._fListCatalogo("NACIONALIDAD"); }
  get estadoCivil(){ return Data._fListCatalogo("ESTADO CIVIL"); }
  get tipoGrupo(){ return Data._fListCatalogo("TIPO GRUPO DE TRABAJO"); }
  onChanges(): void {
    this.datosPersonalesForm.controls['tipoidentificacion_id'].valueChanges.subscribe(val => {
      if(val=='')
        this.datosPersonalesForm.controls['identificacion'].disable();
      else
        this.datosPersonalesForm.controls['identificacion'].enable();
    });
    this.formFilter.valueChanges.subscribe(val => {
      const tableFilters = [];
      tableFilters.push({
        id: 'identificacion',
        value: val.identificacion
      },{
        id:'tipo',
        value: val.tipo
      },{
        id:'invadministradora_fondo_id',
        value:val.administradora
      });
      console.log(tableFilters);
      this.dataSource.filter = JSON.stringify(tableFilters);
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    });
  }
  getPersonaByCedula(val:string){
    this.generalService.useService(Persona.getByCedula(val)).subscribe(
      (data:any)=>{
        if(data.datos[0].status!="ERROR PELIGROSO"){
          var d = data.datos[0].datospersonales.fecha_nacimiento.split("-");
          data.datos[0].datospersonales.fecha_nacimiento = new Date(Number(d[0]),Number(d[1])-1,Number(d[2]));
          data.datos[0].datospersonales.identificacion = val;
          this.datosPersonalesForm.patchValue(data.datos[0].datospersonales);
        }
      },(err:HttpErrorResponse)=>{
        console.error(err);
      }
    );
  }
  buscarCedula(val){
    if(this.datosPersonalesForm.controls['tipoidentificacion_id'].value=='14' && val.length==10)
        this.getPersonaByCedula(val);
  }
  getAllTipoUsuario(){
    this.cboTpoUsuario = [];
    this.generalService.useService(TipoUsuario.getAll()).subscribe(
      (data : any)=>{
        for(let t in data.datos){
          this.cboTpoUsuario.push(new TipoUsuario({tipousuario_id:Number(t),tipousuario:data.datos[t].tipousuario}));
        }
        this.getAllUsuario();
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  getAllAdministradora(){
    this.cboAdministradora = [];
    this.generalService.useService(Administradora.getAll()).subscribe(
      (data : any)=>{
        for(let t in data.datos){
          console.log(data.datos[t]);
          this.cboAdministradora.push(new Administradora(data.datos[t]));
        }
      },
      (err : HttpErrorResponse)=>{
        let x = JSON.parse(Data._limpiarJson(err.error.text,"administradora{"))
        for (let t in x.datos) {
          this.cboAdministradora.push(new Administradora(x.datos[t] as Administradora));
        }
      }
    );
  }
  getAllUsuario(){
    this.isLoading = true;
    this.listUsuario = [];
    this.dataSource = new MatTableDataSource<Usuario>(this.listUsuario);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(Usuario.getAll()).subscribe(
      (data : any)=>{
        this.listUsuario = Object.values(data.datos).map((t:Usuario)=>{
          t.tipo_nombre = this.cboTpoUsuario.find(element => element.tipousuario_id == t.tipo).tipousuario;
          return new Usuario(t);
        });
        console.log(this.listUsuario);
        this.dataSource = new MatTableDataSource<Usuario>(this.listUsuario);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = 
        (data: any, filtersJson: string) => {
          console.log(data, filtersJson);
          const matchFilter = [];
          const filters = JSON.parse(filtersJson);
          filters.forEach(filter => {
            let val;
            if(filter.id === 'identificacion'){
              val = data['datospersonales']['identificacion'];  
            }else{
              val = data[filter.id] === null ? '' : (typeof data[filter.id] == 'number'?data[filter.id].toString():data[filter.id]);
            }
            matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
          });
          return matchFilter.every(Boolean);
        };
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err.error);
      }
    );
  }
  cambiarPass(form){
    this.isChangePass = true;
    form.id = this.usuarioSelected.usuario_id;
    form.estado = this.usuarioSelected.estado;
    form.tipo = this.usuarioSelected.tipo;
    this.generalService.useService(Usuario.changePass(form)).subscribe(
      (data : any)=>{
        this.usuarioSelected = new Usuario();
        this.modalRef.hide();
        this.isChangePass = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err.error);
      }
    );
  }
  saveDatosPersonales(id_usuario){
    let form = this.datosPersonalesForm.value;
    this.isLoading = true;
    this.generalService.useService(Persona.post(form,id_usuario)).subscribe(
      (data : any)=>{
        console.log(data);
        if(Data.isOk(data)){
          this.clear();
          this.getAllUsuario();
        }
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  makeid(length:number):string{
    var result='';
    var characters='ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  saveCredenciales(){
    let form = this.credencialesForm.value;
    this.isLoading = !this.isLoading;
    form.contrasenia = this.makeid(5);
    this.generalService.useService(Usuario.post(form,form.financiera)).subscribe(
      (data : any)=>{
        console.log(data);
        if(Data.isOk(data)){
          this.saveDatosPersonales(Number(data.datos[0].identificador));
        }
        this.isLoading = !this.isLoading;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  openModal(template: TemplateRef<any>,f:Usuario,size:string) {
    this.usuarioSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-'+size);
  }
  clear(){
    this.isCollapsed = true;
    this.datosPersonalesForm.reset({
      persona_id:null,
      nombres: '',
      apellidos:'',
      tipoidentificacion_id:'',
      identificacion:'',
      fecha_expedicion:'',
      codigo_dactilar:'',
      nacionalidad_id:'',
      fecha_nacimiento:'',
      genero:'',
      estadocivil_id:''
    });
    this.credencialesForm.reset({
      tpo:'',
      email:'',
      financiera:''
    });
    this.changePassForm.reset({
      password:''
    });
    this.credencialesForm.enable();
  }
  viewUsuario(f:Usuario,ban:boolean){
    console.log(f);
    this.isCollapsed = false;
    this.datosPersonalesForm.patchValue(f.datospersonales);
    this.credencialesForm.patchValue({financiera:f.invadministradora_fondo_id, email: f.email, tpo:f.tipo});
    if(ban)
      this.datosPersonalesForm.disable();
    else
      this.datosPersonalesForm.enable();
    this.credencialesForm.disable();
  }
  delUsuario(){
    this.isDelete = true;
    this.generalService.useService(Usuario.delete(this.usuarioSelected.usuario_id)).subscribe(
      (data : any)=>{
        console.log(data);
        if(Data.isOk(data)){
          this.getAllUsuario();
          this.usuarioSelected = null;
        }else{
          this.message.messageError("El usuario no pudo ser eliminado, intente de nuevo, caso contrario comuniquese con su proveedor.");
        }
        this.isDelete = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
    this.modalRef.hide();
  }
}