import { Data } from '../z.model';

export class SolicitudTransferencia {
    administradorafondos_id: number;
    concepto: string;
    estado: string;
    estado_nombre:string;
    estado_color:string;
    fecha_registro: Date = new Date();
    fondoinversion_id: number
    identificacion:string;
    transaccion_id:string;
    monto: string;
    nombres:string;
    apellidos:string;
    nombrefondo: string;
    penalidad: any;
    persona_id: number;
    personafondoinversion_id: number;
    porcentajepenalidad: string;
    solicitud_transferencia_id: number;
    solictudretirofondo_id:number;
    tiporetiro_id: number;

    financiera_id:number;
    financiera_nombre:string;
    tipocuenta_id:number;
    tipocuenta_nombre:string;
    numero:string;
    email:string;
    telefono: string;
    tipoidentificacion_id:number;
    tipoidentificacion_nombre:string;
    referencia:string;
    constructor(st:SolicitudTransferencia = null){
        if(st !=null){
            this.email = Data._atob(st.email);
            this.telefono = Data._atob(st.telefono);
            this.tipoidentificacion_id = st.tipoidentificacion_id;
            this.tipoidentificacion_nombre = Data._fCatalogo(st.tipoidentificacion_id,"TIPO DE IDENTIFICACION PERSONA");
            this.numero = Data._atob(st.numero);
            this.financiera_id = st.financiera_id;
            this.financiera_nombre = Data._findFinanciera(st.financiera_id).Nombre;
            this.tipocuenta_nombre = Data._fCatalogo(st.tipocuenta_id,"TIPO DE CUENTA");
            this.tipocuenta_id = st.tipocuenta_id;
            this.referencia = st.referencia;

            this.transaccion_id = st.transaccion_id?Data._trim(st.transaccion_id):"NINGUNO";
            this.solicitud_transferencia_id = st.solicitud_transferencia_id;
            this.nombrefondo = st.nombrefondo;
            this.fondoinversion_id = st.fondoinversion_id;
            this.administradorafondos_id = st.administradorafondos_id;
            this.solictudretirofondo_id = st.solictudretirofondo_id;
            this.personafondoinversion_id = st.personafondoinversion_id;
            this.nombres = atob(st.nombres);
            this.apellidos = atob(st.apellidos);
            this.identificacion = atob(st.identificacion);
            this.persona_id = st.persona_id;
            this.tiporetiro_id = st.tiporetiro_id;
            this.monto = st.monto;
            this.estado = st.estado;
            if(st.estado == 'A'){
                this.estado_nombre = "APROBADO";
                this.estado_color = "primary";
            }else if(st.estado == 'P'){
                this.estado_nombre = "PENDIENTE";
                this.estado_color = "warn";
            }else if(st.estado == 'C'){
                this.estado_nombre = "CANCELADO";
                this.estado_color = "accent";
            }else if(st.estado == 'R'){
                this.estado_nombre = "REVISIÓN ESPECIAL";
                this.estado_color = "link";
            }else if(st.estado == 'O'){
                this.estado_nombre = "OBSERVADO";
                this.estado_color = "primary";
            }
            this.fecha_registro = new Date(st.fecha_registro['date']);
            this.concepto = st.concepto;
            this.porcentajepenalidad = st.porcentajepenalidad;
            this.penalidad = st.penalidad;
        }
    }
    /*Este retorna retiros*/
    public static getAllRetiro() {
        var tmp = Data._getBody("C4",166,sessionStorage.getItem("token_fks"));
        console.log(JSON.stringify(tmp));
        return tmp;
    }
    public static getAll() {
        var tmp = Data._getBody("C9",166,sessionStorage.getItem("token_fks"));
        return tmp;
    }
    public static retornoTransferencia(obj:any){
        let tmp = Data._getBody("rretiro",103,sessionStorage.getItem("token_fks"));
        tmp["data"]["solicitud_retiro_id"] = obj.solicitud_retiro_id;
        tmp["data"]["solicitud_transferencia_id"] = obj.solicitud_transferencia_id;
        tmp["data"]["mensaje"] = obj.mensaje;
        tmp["data"]["comprobante"] = obj.comprobante;
        tmp["data"]["referencia"] = obj.referencia;
        console.log(JSON.stringify(tmp));
        return tmp;
    }
}