import { Component, TemplateRef, OnInit } from '@angular/core';
import { Catalogo } from 'src/app/model/catalogo.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { GeneralService } from 'src/app/service/general.service';
@Component({
  selector: 'app-mant-catalogo',
  templateUrl: './mant-catalogo.component.html',
  styleUrls: ['./mant-catalogo.component.css']
})
export class MantCatalogoComponent implements OnInit {
  isLoading:boolean;
  isLoading2:boolean=false;
  isCollapsed = true;
  modalRef: BsModalRef;
  panelOpenState = false;
  paramBusquedaGroup:FormGroup;
  detalleCatalogoForm:FormGroup;
  listCatalogos: Catalogo[] = [];
  listCatTemp:Catalogo[] = [];
  catalogoSelected:Catalogo;
  constructor(private generalService:GeneralService, private _builder: FormBuilder,private modalService: BsModalService) { }
  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      catalogo_id:[null],
      nombre: ['',Validators.required],
      descripcion:['',Validators.required]
    });
    this.detalleCatalogoForm = this._builder.group({
      descripcion: [''],
      etiqueta:[''],
      orden:[''],
      valor:[''],
      catalogo_id:[null]
    });
    this.getAllCatalogo()
  }
  getAllCatalogo(){
    this.isLoading = false;
    this.listCatalogos = [];
    this.generalService.useService(Catalogo.getAll()).subscribe(
      (data : any)=>{
        console.log(data);
        this.fillCatalogo(data.datos);
      },
      (err : HttpErrorResponse)=>{
        console.log(err.error);
      }
    );
  }
  fillCatalogo(datos){
    for (let t in datos) {
      let temp:Catalogo = new Catalogo(datos[t] as Catalogo);
      temp.catalogo_id = Number(t);
      this.listCatalogos.push(temp);
    }
    this.listCatTemp = this.listCatalogos;
    this.isLoading = true;
  }
  showEdit(c:Catalogo){
    this.paramBusquedaGroup.patchValue(c);
    this.isCollapsed = false;
  }
  save(form){
    this.isLoading2 = !this.isLoading2;
    this.generalService.useService(Catalogo.post(form)).subscribe(
      (data : any)=>{
        this.isLoading2 = !this.isLoading2;
        this.isCollapsed = true;
        this.getAllCatalogo();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  delCatalogo(){
    this.isLoading2 = !this.isLoading2;
    this.generalService.useService(Catalogo.delete(this.catalogoSelected.catalogo_id)).subscribe(
      (data : any)=>{
        this.isLoading2 = !this.isLoading2;
        this.getAllCatalogo();
        this.catalogoSelected = null;
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        console.log(err)
        
      }
    );
  }
  addDetCatalogo(form){
    form.detallecatalogo_id = null;
    this.generalService.useService(DetalleCatalogo.post(form)).subscribe(
      (data : any)=>{
        console.log(data)
      },
      (err : HttpErrorResponse)=>{
        console.log(err)
      }
    );
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-lg');
  }
  findInCatalogo(e){
    this.listCatalogos = this.listCatTemp;
    this.listCatalogos = this.listCatalogos.filter((t) => t.nombre.indexOf(e) > -1);
  }
}