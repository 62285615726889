import { Injectable } from "@angular/core";  
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { AppLoadService } from '../service/app-load.service';
import { Data } from '../model/z.model';
import { GeneralService } from '../service/general.service';
import { BloqueMenu } from '../model/bloqueMenu.model';

@Injectable()  
export class BloqueResolve implements Resolve<any>{
  constructor(private postService: GeneralService) {}  
  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.postService.useService(BloqueMenu.getAll()).toPromise();
  }
}