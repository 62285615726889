import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { Data } from 'src/app/model/z.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { Rendimiento } from 'src/app/model/rendimiento.model';
import { DatePipe } from '@angular/common';
import { Inversionista } from 'src/app/model/inversionista.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as jsPDF  from 'jspdf';
import 'jspdf-autotable';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-rep-costos-asociados',
  templateUrl: './rep-costos-asociados.component.html',
  styleUrls: ['./rep-costos-asociados.component.css']
})
export class RepCostosAsociadosComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  urlSafe: SafeResourceUrl;
  dataSource;
  displayedColumns: string[] = ['id','nombres','nombrefondo','provincia','ciudad','fecha_inicio_inversion','codigo_referido','monto_inversion','estado'];
  formFilter:FormGroup;
  tipoFondos: FondoInversion[] = [];
  isCollapsed = true;
  hidden = false;
  isLoading : boolean = false;
  isLoading2:boolean = true;
  minDateYear:Date = new Date(2020,1,1);
  maxDateYear:Date = new Date();
  minDate:Date = new Date(this.maxDateYear.getFullYear()-1,12,31);
  maxDate:Date = new Date(this.maxDateYear.getFullYear(),11,30);
  totalDebito:number = 0;
  totalCredito:number= 0;
  listInversionista : Inversionista[] = [];
  modalRef: BsModalRef;
  constructor(private router : Router,public sanitizer: DomSanitizer,private modalService: BsModalService,private datePipe: DatePipe,private generalService:GeneralService,private _builder: FormBuilder) { }
  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.formFilter = this._builder.group({
      identificacion:[''],
      fondoInversion:[''],
      rangoFecha:[''],
      monto_inicio:[''],
      monto_fin:[''],
      estado:['']
    });
    this.getAllInversionistas(this.maxDateYear.getFullYear());
    this.onChanges();
  }
  getAllInversionistas(anio){
    this.listInversionista = [];
    this.dataSource = new MatTableDataSource<Inversionista>(this.listInversionista);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.generalService.useService(Rendimiento.getAll()).subscribe(
      (data : any)=>{
        this.totalCredito = 0;
        this.totalDebito = 0;
        for (let t in data.datos) {
          if(this.listInversionista.length == 0){
            this.listInversionista.push(new Inversionista(data.datos[t]));
          }else{
            var enco = false;
            for(let x of this.listInversionista){
              if(x.persona_id == data.datos[t].persona_id){
                enco = true;
                x.addRend(data.datos[t]);
              }
            };
            if(enco==false){
              this.listInversionista.push(new Inversionista(data.datos[t]));
            }
          }
        }
        this.configTable(this.listInversionista);
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    )
  }
  onChanges(): void {
    this.formFilter.valueChanges.subscribe(val => {
      this.find(val);
    });
  }
  find(form:any){
    var temp = this.listInversionista;
    if(form.identificacion!=""){
        temp = temp.filter(function(el) {
          return el.identificacion.indexOf(form.identificacion) > -1});
    }
    this.configTable(temp); 
  }
  configTable(data){
    this.totalCredito = 0;
    this.totalDebito = 0;
    for(let x of data){
      this.totalCredito = this.totalCredito + Number(x.CR);
      this.totalDebito = this.totalDebito + Number(x.DB);
    }
    this.dataSource = new MatTableDataSource<Inversionista>(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getInversionistaDetalle(id){
    this.router.navigate(['/home/reporteria/inversionistas',id]);
  }
  openModalPdf(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-xl');
    this.generarPDF();
  }
  generarPDF(){
    const doc = new jsPDF('p', 'pt','a4')
    doc.setDrawColor(0);
    doc.setFillColor(37,43,71);
    doc.rect(0, 0, 595, 120, 'F');
    doc.addImage(environment.logoToBase64, 'PNG', 10, 10, 250, 80);
    doc.setFontSize(18);
    doc.setTextColor(255,255,255);
    doc.text(270,35,"")
    doc.setFontSize(18);
    doc.text(440,55,"REPORTE DE ")
    doc.setFontSize(18);
    doc.text(405,85,"INVERSIONISTAS")
    doc.autoTable({
      startY: 140,
      columnStyles: { 0: { fillColor: [37, 43, 71],textColor:[255,255,255] },2: { fillColor: [37, 43, 71],textColor:[255,255,255] } },
      cellWidth: 'wrap',
      body: [
        ['FECHA:', new Date().toLocaleString()]
      ],
    });
    doc.setFontSize(10);
    let data = [];
    this.dataSource.filteredData.forEach(obj => {
      let arr = [];
      this.displayedColumns.forEach(col => {
        if(col=="CR" || col=="DB")
          arr.push(Number.parseFloat(obj[col]).toFixed(2));
        else
          arr.push(obj[col]);
      });
      data.push(arr);
    });  
    let finalY = doc.previousAutoTable.finalY;    
    doc.autoTable({
      head: [['IDENTIFICACIÓN','NOMBRES','INVERSIONES','RESCATES']],
      body: data,
      foot: [
        [{ content: 'Total', colSpan: 2, styles: { halign: 'left' } },{ content: this.totalCredito.toFixed(2), styles: { halign: 'right' } },{ content: this.totalDebito.toFixed(2), styles: { halign: 'right' } }],
      ],
      columnStyles: { 2: {halign: 'right'}, 3: {halign: 'right'}},
      headStyles:{fillColor: [37, 43, 71],textColor:[255,255,255]},
      footStyles:{fillColor: [37, 43, 71],textColor:[255,255,255]},
      startY: finalY
    });
    doc.setProperties({
      title: 'Reporte de inversionistas '+ new Date(),
      subject: 'Información de inversionistas, inversiones, rescates y su estado actual.',		
      author: 'Invierte',
      keywords: 'Reporte, inversiones',
      creator: 'Ing. Gonzalez Davids'
    });
    var string = doc.output('datauristring');
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(string);
  }
  printa(){
    let doc = new jsPDF(); 
    
    doc.save('table.pdf')
  }
}