import { Data } from './z.model';

export class FondoInversion {
    fondoinversion_id:number;
    tipoinversionista_id:number;
    tipoinversionista_nombre:string = "AUDAZ";
    tipofondo_id:number;
    tipofondo_nombre:string;
    administradorafondos_id:number;
    nombrefondo:string;
    eslogan:string;
    tasa_activa: string;
    calificacion:string;
    permanencia:number;
    aporte_inicial:string;
    incrementoobligatorio:string;
    incremento:string;
    rendimiento_anual:string;
    rendimiento_promedio:string;
    politica:string;
    horario:string;
    pdffondo:string;
    perfil_id:number;
    estado:string;
    codigo_ext:string;
    id_periodicidad:number;
    anything:any[] = [];
    url_img:string = "/assets/img/icono_rounded.png";
    isSaving:boolean;
    constructor(f:FondoInversion=null){
        if(f!=null){
            this.codigo_ext = f.codigo_ext;
            this.fondoinversion_id=f.fondoinversion_id;
            this.tipoinversionista_id=f.tipoinversionista_id;
            this.tipoinversionista_nombre = Data._fCatalogo(f.tipoinversionista_id,"TIPO DE INVERSIONISTA");
            this.tipofondo_id=f.tipofondo_id;
            this.tipofondo_nombre = Data._fCatalogo(f.tipofondo_id,"TIPO DE FONDO");
            this.administradorafondos_id=f.administradorafondos_id;
            this.nombrefondo=Data._trim(f.nombrefondo);
            this.eslogan=Data._trim(f.eslogan);
            this.tasa_activa=Data._trim(f.tasa_activa);
            this.calificacion=Data._trim(f.calificacion);
            this.permanencia=f.permanencia;
            this.aporte_inicial=Data._trim(f.aporte_inicial);
            this.incrementoobligatorio=Data._trim(f.incrementoobligatorio);
            this.incremento=Data._trim(f.incremento);
            this.rendimiento_anual=Data._trim(f.rendimiento_anual);
            this.rendimiento_promedio=Data._trim(f.rendimiento_promedio);
            this.politica=Data._trim(f.politica);
            this.horario=Data._trim(f.horario);
            this.pdffondo=Data._trim(f.pdffondo);
            this.perfil_id=f.perfil_id;
            this.estado=Data._trim(f.estado);
            this.id_periodicidad= f.id_periodicidad
            this.url_img = f.url_img;
        }
    }
    public static getAllByAdministradora(id){
        var tmp = Data._getBody("C",18,sessionStorage.getItem("token_fks"));
        tmp["data"]["administradorafondos_id"]=id;
        return tmp;
    }
    public static getAll(){
        var tmp = Data._getBody("C9",34,sessionStorage.getItem("token_fks"));
        return tmp;
    }
    public static post(f:FondoInversion){
        var tmp = Data._getBody("I",18,sessionStorage.getItem("token_fks"));
        if(f.fondoinversion_id != null){
          tmp = Data._getBody("U",18,sessionStorage.getItem("token_fks"));
          tmp["data"]["fondoinversion_id"] = f.fondoinversion_id;  
        }
        tmp["data"]["codigo_ext"] = f.codigo_ext;
        tmp["data"]["tipoinversionista_id"] = 0;
        tmp["data"]["tipofondo_id"] = f.tipofondo_id;
        tmp["data"]["administradorafondos_id"] = f.administradorafondos_id;
        tmp["data"]["nombrefondo"] = f.nombrefondo;
        tmp["data"]["eslogan"] = f.eslogan;
        tmp["data"]["tasa_activa"] = f.tasa_activa;
        tmp["data"]["calificacion"] = f.calificacion;
        tmp["data"]["permanencia"] = f.permanencia;
        tmp["data"]["aporte_inicial"] = f.aporte_inicial;
        tmp["data"]["incrementoobligatorio"] = String(f.incrementoobligatorio)=="false"?0:1;
        tmp["data"]["incremento"] = f.incremento;
        tmp["data"]["rendimiento_anual"] = f.rendimiento_anual;
        tmp["data"]["rendimiento_promedio"] = f.rendimiento_promedio;
        tmp["data"]["politica"] = f.politica;
        tmp["data"]["horario"] = f.horario;
        tmp["data"]["pdffondo"] = f.pdffondo;
        tmp["data"]["url_img"] = f.url_img;
        tmp["data"]["id_periodicidad"] = f.id_periodicidad;
        tmp["data"]["perfil_id"] = 0;
        tmp["data"]["estado"] = "A";
        return tmp;
    }
}