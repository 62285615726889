import { Component, OnInit } from '@angular/core';
import { AppLoadService } from './service/app-load.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { Data } from './model/z.model';
import { SwPush } from '@angular/service-worker';
import { GeneralService } from './service/general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('logoAnimation', [
      state('show', style({
        opacity: 1
      })),
      state('hide',   style({
        opacity: 0
      })),
      transition('show => hide', animate('6000ms ease-out')),
      transition('hide => show', animate('1000ms ease-in'))
    ])
  ]
})
export class AppComponent implements OnInit {
  //readonly VAPID_PUBLIC_KEY = "BEhl1dlOq1nIDjJilW58ykBicxJUSdmjUZ2G-yI0raxLCO7avGxhXyY6LQRV14__82I0SmLNJeivCSnO0hnrg9A";
  ocultar:boolean = true;
  show = false;
  get showPrueba(){
    if(Data.loading.op=="EP")
      return this.show = false;
    else if(Data.loading.op=="OK")
      return this.show = true;
    else if(Data.loading.op=="ERR"){
      return this.show = true;

    }
  }
  constructor(private swPush: SwPush,
    private generalService: GeneralService,private router : Router){}
  get stateName() {
    return Data.loading.op=="OK" ? 'show' : 'hide'
  }
  ngOnInit() {
    //this.subscribeToNotifications();
  }
  // subscribeToNotifications() {

  //   this.swPush.requestSubscription({
  //       serverPublicKey: this.VAPID_PUBLIC_KEY
  //   })
  //   .then(sub => console.log(sub))
  //   .catch(err => console.error("Could not subscribe to notifications", err));
  // }
}