import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Data } from '../model/z.model';
import { FondoInversion } from '../model/fondoInversion.model';

@Injectable({
  providedIn: 'root'
})
export class FondoInversionService {
  url2=environment.apiHost2;
  object;
  constructor(private http:HttpClient) { }
  async getAllFondoInversionByIdAdministradora(id:Number): Promise<any> {
    var tmp = Data._getBody("C",18,sessionStorage.getItem("token_fks"));
    tmp["data"]["administradorafondos_id"]=id;
    var reqHeader = new HttpHeaders();
    try {
      const res:any = await this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader }).toPromise();
      var any = res.datos;
      Data._fondosInversion = [];
      for(let f in any){
        Data._fondosInversion.push(new FondoInversion(any[f]));
      }
      return Data._fondosInversion;
    } catch(err) {
      console.error("Error al cargar datos prioritarios...");
      return {op:"ERR",mensaje:"Error al cargar datos prioritarios..."};
    }
  }
  getAllFondoInversion() {
    var tmp = Data._getBody("C1",15,sessionStorage.getItem("token_fks"));
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
  postFondoInversion(f:FondoInversion){
    var tmp = Data._getBody("I",43,sessionStorage.getItem("token_fks"));
    if(f.fondoinversion_id != null){
      tmp = Data._getBody("U",43,sessionStorage.getItem("token_fks"));
      tmp["data"]["fondoinversion_id"] = f.fondoinversion_id;  
    }
    tmp["data"]["tipoinversionista_id"] = f.tipoinversionista_id;
    tmp["data"]["tipofondo_id"] = f.tipofondo_id;
    tmp["data"]["administradorafondos_id"] = 10;
    tmp["data"]["nombrefondo"] = f.nombrefondo;
    tmp["data"]["eslogan"] = f.eslogan;
    tmp["data"]["tasa_activa"] = f.tasa_activa;
    tmp["data"]["calificacion"] = f.calificacion;
    tmp["data"]["permanencia"] = f.permanencia;
    tmp["data"]["aporte_inicial"] = f.aporte_inicial;
    tmp["data"]["incrementoobligatorio"] = f.incrementoobligatorio;
    tmp["data"]["incremento"] = f.incremento;
    tmp["data"]["rendimiento_anual"] = f.rendimiento_anual;
    tmp["data"]["rendimiento_promedio"] = f.rendimiento_promedio;
    tmp["data"]["politica"] = f.politica;
    tmp["data"]["horario"] = f.horario;
    tmp["data"]["pdffondo"] = f.pdffondo;
    tmp["data"]["perfil_id"] = f.perfil_id;
    tmp["data"]["estado"] = "A";
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
  deleteFondoInversion(id:Number){
    var tmp = Data._getBody("D",18,sessionStorage.getItem("token_fks"));
    tmp["data"]["fondoinversion_id"] = id;
    console.log(JSON.stringify(tmp));
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
}