import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Rendimiento } from 'src/app/model/rendimiento.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Emisor } from 'src/app/model/emisor.model';

@Component({
  selector: 'app-retorno-banco',
  templateUrl: './retorno-banco.component.html',
  styleUrls: ['./retorno-banco.component.css']
})
export class RetornoBancoComponent implements OnInit {
  notSelectFondo:boolean;
  @ViewChild("pag1", {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['participante','calificacion','porcentaje'];
  listEmisor: Emisor[]=[];
  formFilter:FormGroup;
  tipoFondos: FondoInversion[] = Data._fondosInversion;
  paramBusquedaGroup:FormGroup;
  isCollapsed = true;
  hidden = false;
  isLoading : boolean = false;
  isLoading2: boolean;
  idFondoInversion;
  @Input()
  set fondoInversion(fondoInversion: string) {
    console.log(fondoInversion);
    if(fondoInversion=="")
      this.notSelectFondo=true;
    else{
      this.idFondoInversion=fondoInversion;
      this.notSelectFondo=false;
      this.getAllEmisores(fondoInversion);
    }
  }
  constructor(private generalService:GeneralService,private _builder: FormBuilder) { }

  ngOnInit() {
    this.formFilter = this._builder.group({
      Documento: [''],
      FondoInversion:[''],
      rangoFecha:[''],
      Estado:['']
    });
    this.paramBusquedaGroup = this._builder.group({
      codigo:[''],
      fondoInversion: [''],
      identificacion:[''],
      rangoFecha:[''],
      montoInicial:[''],
      montoFinal:[''],
      aporte_inicial:[''],
      aporte_incremental:[''],
      rescate_parcial:[''],
      rescate_total:['']
    });
  }
  find(form){
    console.log(form);
  }
  save(form){
    console.log(form);
  }
  getAllEmisores(id){
    this.isLoading2 = true;
    this.listEmisor = [];
    this.dataSource = new MatTableDataSource<Emisor>(this.listEmisor);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(Emisor.getAll(id)).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listEmisor.push(new Emisor(data.datos[t] as Emisor));
        }
        this.dataSource = new MatTableDataSource<Emisor>(this.listEmisor);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  csvRecords:Emisor[]=[];
  isCvs = false;
  dataSource2;
  isCollapsedTable = true;
  @ViewChild("pag2", {static: true}) paginator2: MatPaginator;
  @ViewChild('fileImportInput', {static: true}) fileImportInput: any;
  fileChangeListener($event: any): void {
    this.isLoading = true;
    var files = $event.srcElement.files;
    if (this.isCSVFile(files[0])) {
      var input = $event.target;
      var reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = (data) => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray,headersRow.length);
        this.isCvs = true;
        this.dataSource2 = new MatTableDataSource<any>(this.csvRecords);
        this.dataSource2.paginator = this.paginator2;
        this.hidden = true;
        this.isCollapsedTable = !this.isCollapsedTable;
        this.fileReset();
      }
      reader.onerror = function() {
          alert('Unable to read ' + input.files[0]);
      };
    } else {
      alert('Please import valid .csv file.');
      this.fileReset();
    }
    this.isLoading = false;
  }
  fileReset() {
    this.fileImportInput.nativeElement.value = "";
  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = csvRecordsArr[0].split(",");
    if(headers.length <= 1){
      headers = csvRecordsArr[0].split(";");
    }
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }
  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    var dataArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let data = csvRecordsArray[i].split(",");
      if(data.length <= 1){
        data = csvRecordsArray[i].split(";");
      }
      if (data.length == headerLength) {
        var d:Emisor = new Emisor();
        d.participante = data[0];
        d.calificacion = data[1];
        d.porcentaje = data[2];
        dataArr.push(d);
      }
    }
    return dataArr;
  }
  clearCVS(){
    this.csvRecords = [];
    this.isCvs = false;
    this.hidden = false;
    this.isCollapsedTable = true;
  }
  isLoading3:boolean;
  saveCVS(){
    this.isLoading3=true;
    this.generalService.useService(Emisor.post(this.csvRecords,this.idFondoInversion)).subscribe(
      (data : any)=>{
        console.log(data);
        this.isLoading3 = false;
        this.clearCVS();
        this.getAllEmisores(this.idFondoInversion);
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}