import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { BsLocaleService, BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { FondoInversion } from 'src/app/model/fondoInversion.model';

@Component({
  selector: 'app-consulta-estado-cuenta',
  templateUrl: './consulta-estado-cuenta.component.html',
  styleUrls: ['./consulta-estado-cuenta.component.css']
})
export class ConsultaEstadoCuentaComponent implements OnInit {

  //CATALOGOS
  tipoFondos: FondoInversion[] = Data._fondosInversion;
  tipoIdentificaciones: DetalleCatalogo[] = [];
  bsConfig: Partial<BsDatepickerConfig>;
  minMode: BsDatepickerViewMode = 'month';

  paramBusquedaGroup:FormGroup;

  constructor(private localeService: BsLocaleService,private _builder: FormBuilder) {
    this.localeService.use('es');
    this.bsConfig = Object.assign({}, {
      isAnimated: true,
      containerClass:'theme-dark-blue',
      minMode : this.minMode,
      dateInputFormat: 'MMMM'
    });
  }
  
  fillComboBox(){
    this.tipoIdentificaciones = Data._fListCatalogo("TIPO DE IDENTIFICACION PERSONA");
  }

  ngOnInit() {
    this.fillComboBox();
    this.paramBusquedaGroup = this._builder.group({
      tipoIdentificacion: [''],
      mes: [''],
      Documento:[''],
      FondoInversion:['']
    });
  }
  find(form){
    console.log(form);
  }
}
