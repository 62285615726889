import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { GeneralService } from 'src/app/service/general.service';
import { Beneficio } from 'src/app/model/beneficio.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { ImageSnippet } from 'src/app/model/imageSnippet.model';
import { SelectionModel } from '@angular/cdk/collections';
declare var jQuery: any;
@Component({
  selector: 'app-nuevo-beneficio',
  templateUrl: './nuevo-beneficio.component.html',
  styleUrls: ['./nuevo-beneficio.component.css']
})
export class NuevoBeneficioComponent implements OnInit {
  isLoading:boolean;
  isBtnLoading:boolean;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource;
  displayedColumns: string[] = ['titulo','descripcion','monto','rangoFechas','acciones'];
  listBeneficio: Beneficio[]=[];
  formBeneficio:FormGroup;
  modalRef: BsModalRef;
  tipoFondos: FondoInversion[] = [];
  tipoBeneficios: DetalleCatalogo[] = [];
  selectedFile: ImageSnippet = new ImageSnippet(null,null);
  placeholderFecha:string="yyyy/mm/dd - yyyy/mm/dd";
  dataSourceFondos;
  displayedColumnsFondos: string[] = ['select','fondo'];
  asignados:any[]=[];
  idBenefSelected;
  beneficioSelected:Beneficio;
  addonText:boolean = true;
  selection = new SelectionModel<FondoInversion>(true, []);
  constructor(private generalService: GeneralService,private modalService: BsModalService,private _builder: FormBuilder) { }
  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.dataSourceFondos = new MatTableDataSource<FondoInversion>(this.tipoFondos);
    this.formBeneficio = this._builder.group({
      titulo: [''],
      descripcion:[''],
      tipo_beneficio:[''],
      rangoFecha:[''],
      monto:[''],
      url:['']
    });
    this.formBeneficio.controls['rangoFecha'].enable();
    this.getAllBeneficio();
    this.onchanges();
  }
  onchanges() {
    this.formBeneficio.controls['tipo_beneficio'].valueChanges.subscribe(val => {
      console.log(val);
      let num = Number(val);
      if(num == 1){
        //Por contrato
        this.placeholderFecha="Indefinido";
        this.formBeneficio.controls['rangoFecha'].disable();
        this.formBeneficio.controls['monto'].disable();
      }else if (num == 2){
        //Dos por uno
        this.placeholderFecha="yyyy/mm/dd - yyyy/mm/dd";
        this.formBeneficio.controls['rangoFecha'].enable();
        this.formBeneficio.controls['monto'].enable();
      }else if (num == 2){
        //Por descuento
        this.placeholderFecha="yyyy/mm/dd - yyyy/mm/dd";
        this.formBeneficio.controls['rangoFecha'].enable();
        this.formBeneficio.controls['monto'].enable();
      }
    });
  }
  openModal(template: TemplateRef<any>,asignados:any[],idBenef) {
    this.asignados = asignados;
    console.log(this.asignados);
    this.idBenefSelected = idBenef;
    for(let y of asignados){
     for(let x of this.tipoFondos){  
        if(x.fondoinversion_id == y.fondoinversion_id){
          this.selection.select(x);
        }
      }
    }
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }
  changeAddon(e){
    if(e.value == "1")
      this.addonText = true;
    else
      this.addonText = false;
  }
  find(form){
    this.isBtnLoading=true;
    console.log(form);  
    if(this.selectedFile.file != null){
      this.generalService.uploadImage(this.selectedFile.file,"beneficio").subscribe(
        (res:any) => {
          form.url = res.datos[0].url
          if(form.rangoFecha){
            form.inicio_beneficio = form.rangoFecha[0];
            form.fin_beneficio = form.rangoFecha[1];
          }
          this.generalService.useService(Beneficio.post(form)).subscribe((data:any)=>{
            this.isBtnLoading=false;
            this.getAllBeneficio();
            console.log(data);
          },(err:HttpErrorResponse)=>{
            console.log(err);
          });
        },
        (err) => {
          console.log(err);
      })
    }
  }
  disasbleIf(row:FondoInversion){
    for(let y of this.asignados){
      if(y.fondoinversion_id==row.fondoinversion_id){
        return true;
      }
    }
    return false;
  }
  btnLoading:boolean;
  saveAsignacion(){
    var num = this.selection.selected.length;
    this.selection.selected.forEach((t,idx)=>{
      if(!this.disasbleIf(t)){
        this.btnLoading = true;
        this.generalService.useService(Beneficio.assign(t.fondoinversion_id,this.idBenefSelected)).subscribe(
          (data : any)=>{
            this.formBeneficio.reset();
            this.btnLoading = false;
            this.getAllBeneficio();
            this.cerrarModal();
          },
          (err : HttpErrorResponse)=>{
            console.log(err);
          }
        );     
      }
    })
  }
  activeInput(e){
    if(!e.checked){
      this.placeholderFecha="Indefinido";
      this.formBeneficio.controls['rangoFecha'].disable();
      this.formBeneficio.controls['rangoFecha'].setValue("");
    }else{
      if(this.formBeneficio.controls['tipo_beneficio'].value != '1'){
        this.placeholderFecha="yyyy/mm/dd - yyyy/mm/dd";
        this.formBeneficio.controls['rangoFecha'].enable();
      }
    }
  }
  cerrarModal(){
    this.modalRef.hide();
    this.asignados=[];
    this.selection.clear();
    this.idBenefSelected="";
  }
  openModalDel(template: TemplateRef<any>,f:Beneficio){
    this.beneficioSelected  = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }
  getAllBeneficio(){
    this.listBeneficio = [];
    this.dataSource = new MatTableDataSource<Beneficio>(this.listBeneficio);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.isLoading = true;
    this.generalService.useService(Beneficio.getAll()).subscribe(
      (data : any)=>{
        console.log(data);
        for (let t in data.datos) {
          this.listBeneficio.push(new Beneficio(data.datos[t] as Beneficio));
        }
        console.log(this.listBeneficio);
        this.dataSource = new MatTableDataSource<Beneficio>(this.listBeneficio);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  } 
  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.selectedFile.pending = true;
    });

    reader.readAsDataURL(file);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: FondoInversion): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    //return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}