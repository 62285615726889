import { Pais } from './pais.model';
import { environment } from 'src/environments/environment';
import { DetalleCatalogo } from './detalleCatalogo.model';
import { Financiera } from './financiera.model';
import { FondoInversion } from './fondoInversion.model';
import { NotificacionUsuario } from './notificacionUsuario.model';
import { DomSanitizer } from '@angular/platform-browser';
declare var require: any;
var platform = require('platform');

export class Data {
    static _notificaciones:NotificacionUsuario[]=[];
    static _numNotificaciones:number;
    public static loading:any = {mensaje:null,op:"EP"};
    static _player:any;
    static _financieras: Financiera[] = [];
    static _fondosInversion: FondoInversion[] = [];
    static _paises : Pais[] = [];
    static _data = {}; //CATALOGO
    static _paisDivision:any = null;
    static _countObservaciones:number = 12;
    //INFORMACION PARA LAS PETICIONES
    static _id_qrt:string = "";
    static _equipo:string = platform.os.family + ' ' + platform.os.version + ' ' + platform.os.architecture+'bits';
    static _idEquipo:string = "";
    static _idKernel:string = platform.description;
    //REPORTES
    static _report1:any;
    static _report2:any;

    public static _getBody(m:string,i:number,t:string):any{
        var _body = {
            "aplicacion":environment.id,
            "id_qrt":i,
            "data":{
                "metodo": m,
                "token": t,
                "equipo": this._equipo,
                "idequipo": this._idEquipo,
                "idkernel": this._idKernel
            }
        };
        return _body;
    }
    public static _findFinanciera(id:number): Financiera{
        for (let t of this._financieras) {
            if(t.financiera_id == id){
                return t;
            }
        }
        return null;
    }
    public static boolStatus(result){
        if(result.codigo != 100)
          return false;
        else
          return true;
    }
    public static _atob(str) {
        try{
            return decodeURIComponent(atob(str).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }catch{
          try{
            return atob(str || '');
          }catch{
            return str;
          }
          
        }
      }
    public static _fCatalogo(id:Number,c:string):string{
        console.log(this._data[c]);
        if(id != null){
            var tcat = this._data[c];
            for (let t in tcat) {
                if(Number(t) == id){
                    return this._trim(tcat[t].descripcion);
                }
            }
            return 'NINGUNO';
        }else{
            return 'NINGUNO';
        }
    }
    public static _fCatalogoObj(id:Number,c:string):string{
        if(id != null){
            var tcat = this._data[c];
            for (let t in tcat) {
                if(Number(t) == id){
                    return tcat[t];
                }
            }
            return null;
        }else{
            return null;
        }
    }

    public static _fListCatalogo(c:string):DetalleCatalogo[]{
        var el = this._data[c];
        var tmp:DetalleCatalogo[] = [];
        for (let t in el) {
            let temp:DetalleCatalogo = new DetalleCatalogo(el[t] as DetalleCatalogo);
            temp.detallecatalogo_id = Number(t);
            tmp.push(temp);
        }
        return tmp;
    }
    public static _findPais(id:Number):string{
        for (let i in Object.keys(Data._paises)) {
            if(Data._paises[i].id_pais == id){
                return Data._paises[i].descripcion;
            }
        }
        return "NINGUNO";
    }
    public static _findDivisionPais(id:Number):Pais{
        for (let i in Object.keys(Data._paises)) {
            if(Data._paises[i].id_pais == id){
                return Data._paises[i];
            }
        }
        return null;
    }
    public static _trim(val:String):string{
        if(val==null){
            return '';
        }else{
            return val.trim();
        }
    }
    public static _transformURL(url){
        var sanitizer: DomSanitizer
        return sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    public static _limpiarJson(json,textBasura){
        return json.replace(textBasura,'{');
    }
    public static isOk(data):boolean{
        if(data){
            if(data.datos){
                if(data.datos[0]){
                    if(data.datos[0].codigo == 100 || data.datos[0].status=="100"){
                        return true;
                    }
                }else{
                    if(data.datos.gate1){
                        return true;
                    }
                }
            }
        }
        return false;
    }
}