import { Data } from '../z.model';

export class Pep {
    nombre_pep: string;
    persona_id:number;
    funcion:number;
    cargo:number;
    gerarquia:string;
    relacion_con_peps:number;
    tipo_relacion_con_peps:number;
    estado:string;

    funcion_nombre:string;
    cargo_nombre:string;
    relacion_nombre:string;

    constructor(p:Pep = null){
        if(p != null){
            this.nombre_pep = p.nombre_pep;
            this.persona_id = p.persona_id;
            this.funcion = p.funcion;
            this.funcion_nombre = Data._fCatalogo(p.funcion,"CARGO PEP");
            this.cargo = p.cargo;
            this.cargo_nombre = Data._fCatalogo(p.cargo,this.funcion_nombre);
            this.gerarquia = p.gerarquia;
            this.relacion_con_peps = p.relacion_con_peps;
            this.relacion_nombre = Data._fCatalogo(p.relacion_con_peps,"RELACION PEP");
            this.tipo_relacion_con_peps = p.tipo_relacion_con_peps;
            this.estado = p.estado;
        }
    }
}