export class EstadoFinanciero {
    totalactivos:number = 0;
    totalpasivos:number;
    patrimonio:string;
    constructor(ie:EstadoFinanciero=null){
        if(ie != null){
            this.totalactivos = ie.totalactivos;
            this.totalpasivos = ie.totalpasivos;
            this.patrimonio = ie.patrimonio;
        }
    }
}