import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-importar-documentos',
  templateUrl: './importar-documentos.component.html',
  styleUrls: ['./importar-documentos.component.css']
})
export class ImportarDocumentosComponent implements OnInit {
  paramBusquedaGroup:FormGroup;

  constructor(private _builder: FormBuilder) { }
  
  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      ftype: [''],
      financiera:[''],
      FechaD:[''],
      FechaH:['']
    });
  }
  find(form){
    console.log(form);
  }
}
