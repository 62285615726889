import { Data } from '../z.model';

export class SolicitudRetiro {
    solictudretirofondo_id:number;
    personafondoinversion_id: number;
    fondoinversion_id:number;
    nombre:string;
    nombrefondo:string;
    identificacion:string;
    administradora_cuenta_id: number;
    persona_id: number;
    personacuenta_id: number;
    tiporetiro_id: number;
    transaccion_id: string;
    monto: string;
    estado: string;
    estado_nombre:string;
    estado_color:string;
    fecha_registro: Date = new Date();
    concepto: string;
    porcentajepenalidad: number;
    penalidad: number;

    isLoading:boolean = false;
    constructor(sr:SolicitudRetiro = null){
        if(sr!=null){
            this.solictudretirofondo_id = sr.solictudretirofondo_id;
            this.personafondoinversion_id = sr.personafondoinversion_id;
            this.fondoinversion_id = sr.fondoinversion_id;
            this.nombre = atob(sr.nombre);
            this.nombrefondo = sr.nombrefondo;
            this.identificacion = atob(sr.identificacion);
            this.administradora_cuenta_id = sr.administradora_cuenta_id;
            this.persona_id = sr.persona_id;
            this.personacuenta_id = sr.personacuenta_id;
            this.tiporetiro_id = sr.tiporetiro_id;
            this.transaccion_id = sr.transaccion_id?sr.transaccion_id:"NINGUNO";
            this.monto = sr.monto;
            this.estado = sr.estado;
            if(sr.estado == 'A'){
                this.estado_nombre = "APROBADO";
                this.estado_color = "primary";
            }else if(sr.estado == 'P'){
                this.estado_nombre = "PENDIENTE";
                this.estado_color = "warn";
            }else if(sr.estado == 'C'){
                this.estado_nombre = "CANCELADO";
                this.estado_color = "accent";
            }else if(sr.estado == 'R'){
                this.estado_nombre = "REVISIÓN ESPECIAL";
                this.estado_color = "link";
            }else if(sr.estado == 'O'){
                this.estado_nombre = "OBSERVADO";
                this.estado_color = "primary";
            }
            this.fecha_registro = new Date(sr.fecha_registro['date']);
            this.concepto = sr.concepto;
            this.porcentajepenalidad = Number(sr.porcentajepenalidad);
            this.penalidad = Number(sr.penalidad);
        }
    }
    public static getAll() {
        var tmp = Data._getBody("C2",166,sessionStorage.getItem("token_fks"));
        return tmp;
    }
    public static getById(id:number) {
        var tmp = Data._getBody("C8",166,sessionStorage.getItem("token_fks"));
        tmp["data"]["solicitudretirofondo_id"] = id;
        return tmp;
    }
    public static put(id,estado) {
        var tmp = Data._getBody("U3",100,sessionStorage.getItem("token_fks"));
        tmp["data"]["solictudretirofondo_id"] = id;
        tmp["data"]["estado"] = estado;
        return tmp;
    }
}