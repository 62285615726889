import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { GeneralService } from 'src/app/service/general.service';
import { Usuario } from 'src/app/model/usuario.model';
import { Data } from 'src/app/model/z.model';
import { ModalsComponent } from 'src/app/site/modals/modals.component';
import { AlertService } from 'src/app/util/alerts/alert.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm:FormGroup;
  typePass:string = "password";
  iconTypePass:string = "visibility_off";
  public player:any;
  isLoading : boolean = false;
  
  @ViewChild('childModal',{static:true}) childModal :ModalsComponent;
  constructor(private alertService: AlertService,private _builder: FormBuilder,private generalService : GeneralService,private router : Router) {
    this.loginForm = this._builder.group({
      usuario: ['tecnicoinvierte002@yopmail.com',Validators.required],
      pass:['',Validators.required]
    });
  }
  sub;
  ngOnInit() {
    this.init();
    const otherCont = interval(1000);
    this.sub = otherCont.subscribe(()=>this.changeSize());
  }
  OnSubmit(){
    this.isLoading = !this.isLoading;
    this.generalService.useServiceWithBackend(Usuario.login(this.loginForm.value.usuario,this.loginForm.value.pass)).subscribe(
      (data : any)=>{
        if(Data.isOk(data)){
          this.login(data);
        }else{
          this.alertService.responseService(data);
          this.isLoading = false;
        }
      },
      (err : HttpErrorResponse)=>{
        this.alertService.error("Error inesperado. Consulte con su proveedor.");
        this.isLoading = false;
    });
  }
  login(data){
    data = data.datos;
    if (data.gate1 != undefined) {
      var z:any[] = data.gate1.split(":");
      var t = z[0];
      sessionStorage.setItem('inf8',btoa(Data._trim(data.atributos.Logo)));
      sessionStorage.setItem('token_fks',data.gate1);
      sessionStorage.setItem('inf1',btoa(data["Fecha"].date));
      sessionStorage.setItem('inf2',btoa(z[1]));
      sessionStorage.setItem('inf3',data.atributos.nombres);
      sessionStorage.setItem('inf4',data.atributos.apellidos);
      sessionStorage.setItem('inf5',btoa(z[2]));
      sessionStorage.setItem('inf6',btoa(Data._trim(data.atributos.tipousuario)));
      sessionStorage.setItem('inf7',btoa(data.atributos.razon_social));
      this.router.navigate(['/home']);
    }else
      sessionStorage.clear();
  }
  init(){
    if (window['YT']) {
      this.startVideo();
      return;
    }
    var tag = document.createElement('script');
    tag.src = "assets/js/youtube_api.min.js";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }
  changeTypePass(){
    if(this.typePass == "password"){
      this.typePass = "text";
      this.iconTypePass = "visibility";
    }else{
      this.typePass = "password";
      this.iconTypePass = "visibility_off";
    }
  }
  startVideo(){
    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    if(Number(width)<Number(height))
      width = 1670*height/960;
    else
      height = 960*width/1670;
    this.player = new window['YT'].Player('player', {
      videoId: 'aHk7SM89nYI',
      width: width,
      height: height,
      playerVars : {
        'modestbranding':1,
        'rel' : 0,
        'iv_load_policy':3,
        'showinfo' : 0,
        'showsearch' : 0, 
        'controls' : 0,
        'fs':0
      },
      events: {
        'onReady': this.onPlayerReady,
        'onStateChange': this.onStateChange
      }
    });
  }
  changeSize(){
    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    var porcW = width*100/1670;
    var porcH = height*100/960;
    if(porcW<porcH)
      width = 1670*height/960;
    else
      height = 960*width/1670;
    if(this.player != undefined)
      this.player.setSize(width,height);
  }
  onPlayerReady(event){
    event.target.mute();
    event.target.playVideo();
  }
  onStateChange(event){
    if (event.data === 1)
      document.getElementById("contenedorvideo").style.opacity = "1";
    if (event.data === window['YT'].PlayerState.ENDED) {
      document.getElementById("contenedorvideo").style.opacity = "0";
      event.target.playVideo();
    }
  }
  ngOnDestroy() {
    window['onYouTubeIframeAPIReady'] = null;
    if (this.player)
      this.player.destroy();
    this.sub.unsubscribe();
  }
}