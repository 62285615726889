import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Data } from 'src/app/model/z.model';

import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SolicitudDebito } from 'src/app/model/solicitudes/solicitudDebito.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { ExportServicesService } from 'src/app/service/export-services.service';
defineLocale('es', esLocale);
import * as jsPDF  from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-debito',
  templateUrl: './debito.component.html',
  styleUrls: ['./debito.component.css']
})
export class DebitoComponent implements OnInit {
  isLoading:boolean;
  isLoading2:boolean;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource;
  displayedColumns: string[] = ['transaccion_id','identificacion','nombre','nombrefondo','monto_debitar','fecha_registro','estado'];
  listSolicitudDebito: SolicitudDebito[]=[];
  paramBusquedaGroup:FormGroup;
  formSimulador:FormGroup;
  tipoFondos: FondoInversion[] = [];
  tipoEstados: DetalleCatalogo[] = [];
  tipoSolicitud:DetalleCatalogo[] = [];

  constructor(private generalService: GeneralService,private _builder: FormBuilder,
              private excelService:ExportServicesService) {
  }
  ngOnInit() {
    this.tipoEstados = Data._fListCatalogo("ESTADO SOLICITUD");
    this.tipoSolicitud = Data._fListCatalogo("TIPO SOLICITUDES DEBITO");
    this.tipoFondos = Data._fondosInversion;
    this.paramBusquedaGroup = this._builder.group({
      Documento: [''],
      tipoSolicitud: [''],
      FondoInversion:[''],
      rangoFecha:[''],
      Estado:['']
    });
    this.getAllSolDebito();
    this.onChanges();
  }
  getAllSolDebito(){
    this.isLoading = true;
    this.paramBusquedaGroup.disable();
    this.listSolicitudDebito = [];
    this.dataSource = new MatTableDataSource<SolicitudDebito>(this.listSolicitudDebito);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(SolicitudDebito.getAll()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listSolicitudDebito.push(new SolicitudDebito(data.datos[t] as SolicitudDebito));
        }
        console.log(this.listSolicitudDebito);
        this.dataSource = new MatTableDataSource<SolicitudDebito>(this.listSolicitudDebito);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
        this.paramBusquedaGroup.enable();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.find(this.paramBusquedaGroup.value);
    });
  }
  find(form:any){
    var temp = this.listSolicitudDebito;
    if(form.Documento!=""){
      temp = temp.filter(function(el) {
        return el.identificacion.indexOf(form.Documento) > -1});
    }
    if(form.FondoInversion!="")
      temp=temp.filter(d => (d.fondoinversion_id == form.FondoInversion));
    if(form.rangoFecha != null){
      if((form.rangoFecha[0] != null && form.rangoFecha[1] != null)){
        form.rangoFecha[0].setHours(0, 0, 0);
        form.rangoFecha[1].setHours(23, 59, 59);
        temp=temp.filter(d => (d.fecha_registro.getTime() <= form.rangoFecha[1].getTime() && d.fecha_registro.getTime() >= form.rangoFecha[0].getTime()));
      }
    }
    if(form.Estado!="")
      temp=temp.filter(d => (d.estado_nombre == form.Estado));
    if(form.tipoSolicitud!=""){
      console.log(form);
      temp=temp.filter(d => (d.tipo_solicitud_id == form.tipoSolicitud));
    }
    this.configTable(temp);
  }
  configTable(data){
    this.dataSource = new MatTableDataSource<SolicitudDebito>(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}