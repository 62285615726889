import { DatosPersonales } from './datospersonales.model';
import { Domicilio } from './domicilio.model';
import { DatosLaborables } from '../datosLaborables.model';
import { DatosContacto } from '../datoscontacto.model';
import { DatosCuenta } from './datosCuenta.model';
import { NivelInstruccion } from '../nivelInstruccion.model';
import { ReferenciaComercial } from '../referenciaComercial.model';
import { ReferenciaBancaria } from '../referenciaBancaria.model';
import { DatosConyugue } from '../datosConyugue.model';
import { Beneficiario } from '../beneficiario.model';
import { ReferenciaPersonal } from '../referenciaPersonal.model';
import { AccionistaEmpresa } from '../accionistaEmpresa.model';
import { SituacionFinanciera } from '../situacionFinanciera.model';
import { LugarTrabajo } from '../lugarTrabajo.model';
import { Imagen } from '../imagen.model';
import { Pep } from './pep.model';
import { Data } from '../z.model';
import { InfoEconomicaFinanciera } from '../infoEconomicaFinanciera.model';
import { EstadoFinanciero } from '../estadoFinanciero.model';
import { formatDate } from '@angular/common';

export class Persona {
    datospersonales:DatosPersonales  = new DatosPersonales();
    datoslaborales:DatosLaborables = new DatosLaborables();
    lugardetrabajo:LugarTrabajo = new LugarTrabajo();
    datosdecontacto:DatosContacto = new DatosContacto();
    datosdecuentas:DatosCuenta[] = [];
    nivelinstruccion:NivelInstruccion = new NivelInstruccion();
    domicilio:Domicilio = new Domicilio();
    referenciasbancarias:ReferenciaBancaria[] = [];
    referenciascomerciales:ReferenciaComercial[] = [];
    referenciaspersonales:ReferenciaPersonal[] = [];
    informacionconyuge:DatosConyugue = new DatosConyugue();
    beneficiario:Beneficiario[] = [];
    accionistaempresa:AccionistaEmpresa[] = [];
    situacionfinanciera:SituacionFinanciera[] = [];
    situacionfinanciera_activos:SituacionFinanciera[] = [];
    situacionfinanciera_pasivos:SituacionFinanciera[] = [];
    situacionfinanciera_egresos:SituacionFinanciera[] = [];
    situacionfinanciera_ingresos:SituacionFinanciera[] = [];
    infoeconomicafinanciera:InfoEconomicaFinanciera = new InfoEconomicaFinanciera();
    estadofinanciero:EstadoFinanciero = new EstadoFinanciero();
    flujo:number=0;
    patrimonio:number=0;
    imagenesydocumentos:Imagen[] = [];
    imgEstadoCuenta:Imagen = new Imagen();
    imgServicioBasico:Imagen = new Imagen();
    peps:Pep[] = [];
    constructor(p:Persona = null){
        if(p != null){
            this.datospersonales = new DatosPersonales(p.datospersonales);
            this.nivelinstruccion = new NivelInstruccion();
            this.informacionconyuge = new DatosConyugue(p.informacionconyuge);
            this.domicilio = new Domicilio(p.domicilio);
            this.datoslaborales = new DatosLaborables(p.datoslaborales);
            this.lugardetrabajo = new LugarTrabajo(p.lugardetrabajo);
            this.datosdecontacto = new DatosContacto(p.datosdecontacto);
            for (const [key, value] of Object.entries(p.datosdecuentas)) {
                value.datoscuenta_id = Number(key);
                this.datosdecuentas.push(new DatosCuenta(value));
            }
            for(let i in p.referenciasbancarias){
                this.referenciasbancarias.push(new ReferenciaBancaria(p.referenciasbancarias[i]));
            }
            for(let j in p.referenciascomerciales){
                this.referenciascomerciales.push(new ReferenciaComercial(p.referenciascomerciales[j]));
            }
            for(let k in p.imagenesydocumentos){
                if(Data._trim(p.imagenesydocumentos[k].nombre) == "ESTADO CUENTA")
                    this.imgEstadoCuenta = new Imagen(p.imagenesydocumentos[k]);
                else if(Data._trim(p.imagenesydocumentos[k].nombre) == "SERVICIO BASICO")
                    this.imgServicioBasico = new Imagen(p.imagenesydocumentos[k]);
                else
                    this.imagenesydocumentos.push(new Imagen(p.imagenesydocumentos[k]));
            }
            for(let k in p.beneficiario){
                this.beneficiario.push(new Beneficiario(p.beneficiario[k]));
            }
            for(let l in p.peps){
                this.peps.push(new Pep(p.peps[l]));
            }
            for(let l in p.accionistaempresa){
                this.accionistaempresa.push(new AccionistaEmpresa(p.accionistaempresa[l]));
            }
            for (let t in p.situacionfinanciera) {
                if(p.situacionfinanciera[t].tipo_situacion_financiera_id>833 && p.situacionfinanciera[t].tipo_situacion_financiera_id<844){
                  //activos
                  this.situacionfinanciera_activos.push(new SituacionFinanciera(p.situacionfinanciera[t]));
                  this.patrimonio = this.patrimonio + Number(p.situacionfinanciera[t].valor);
                }else if(p.situacionfinanciera[t].tipo_situacion_financiera_id>843 && p.situacionfinanciera[t].tipo_situacion_financiera_id<848){
                  //pasivos
                  this.situacionfinanciera_pasivos.push(new SituacionFinanciera(p.situacionfinanciera[t]));
                  this.patrimonio = this.patrimonio - Number(p.situacionfinanciera[t].valor);
                }else if(p.situacionfinanciera[t].tipo_situacion_financiera_id>847 && p.situacionfinanciera[t].tipo_situacion_financiera_id<851){
                  //ingresos mensulales
                  this.situacionfinanciera_ingresos.push(new SituacionFinanciera(p.situacionfinanciera[t]));
                  this.flujo = this.flujo + Number(p.situacionfinanciera[t].valor);
                }else if(p.situacionfinanciera[t].tipo_situacion_financiera_id>850 && p.situacionfinanciera[t].tipo_situacion_financiera_id<857){
                  //engresos mensulales
                  this.situacionfinanciera_egresos.push(new SituacionFinanciera(p.situacionfinanciera[t]));
                  this.flujo = this.flujo - Number(p.situacionfinanciera[t].valor);
                }
            }
            this.referenciaspersonales = p.referenciaspersonales;
            

            this.situacionfinanciera = p.situacionfinanciera;
            for(let k in p.infoeconomicafinanciera){
                this.infoeconomicafinanciera = new InfoEconomicaFinanciera(p.infoeconomicafinanciera[k]);
            }
            for(let k in p.estadofinanciero){
                this.estadofinanciero = new EstadoFinanciero(p.estadofinanciero[k]);
            }
            this.flujo = Number(this.infoeconomicafinanciera.patrimonio);
        }
    }
    public static getById(id:Number) {
        var tmp = Data._getBody("C2",3,sessionStorage.getItem('token_fks'));
        tmp["data"]["persona_id"]=id;
        return tmp;
    }
    public static getByCedula(ced:String){
        var tmp = Data._getBody("C",3,sessionStorage.getItem('token_fks'));
        tmp["data"]["subservicio"]='registro_civil';
        tmp["data"]["id"]=ced;
        delete tmp.data.token;
        return tmp;
    }
    public static updateEstado(id:number, estado:string){
        var tmp = Data._getBody("U3",138,sessionStorage.getItem('token_fks'));
        tmp["data"]["persona_id"] = id;
        tmp["data"]["estado"] = estado;
        return tmp;
    }
    public static post(dp:DatosPersonales,userident){
        var tmp = Data._getBody("I5",3,sessionStorage.getItem("token_fks"));
        tmp["data"]["userident"]=userident;
        tmp["data"]["fecha_registro"]=formatDate(new Date(), 'yyyy-MM-dd', 'en');
        if(dp.persona_id != null){
          tmp = Data._getBody("U2",130,sessionStorage.getItem("token_fks"));
          tmp["data"]["persona_id"] = dp.persona_id;  
        }
        tmp["data"]["tipo_persona_id"]=12;
        tmp["data"]["tipoidentificacion_id"]=Number(dp.tipoidentificacion_id);
        tmp["data"]["identificacion"]=btoa(dp.identificacion);
        tmp["data"]["fecha_expedicion"]=formatDate(dp.fecha_expedicion, 'yyyy-MM-dd', 'en');
        tmp["data"]["nombres"]=btoa(dp.nombres);
        tmp["data"]["apellidos"]=btoa(dp.apellidos);
        tmp["data"]["razon_social"]="NINGUNO";
        tmp["data"]["fecha_nacimiento"]=formatDate(dp.fecha_nacimiento, 'yyyy-MM-dd', 'en');
        tmp["data"]["codigo_dactilar"]=btoa(dp.codigo_dactilar);
        tmp["data"]["nacionalidad_id"]=Number(dp.nacionalidad_id);
        tmp["data"]["estadocivil_id"]=Number(dp.estadocivil_id);
        tmp["data"]["genero"]=dp.genero;
        tmp["data"]["ocupacion_id"]="0";
        tmp["data"]["accionista_en_empresa"]="0";
        tmp["data"]["persona_expuesta_politicamente"]="0";
        return tmp;
    }
}