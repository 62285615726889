import { Data } from './z.model';

export class Financiera {
    public financiera_id: Number;
    public tipofinanciera_id: Number;
    public tipoFinanciera_nombre:string;
    public Nombre: string;
    public codigoentidad:string;
    public pais_id: Number;
    public direccionmatriz: String;
    public telefono: string ;
    public estado:string;
    constructor(f:Financiera = null){
        if(f!=null){
            this.financiera_id = f.financiera_id;
            this.tipofinanciera_id = f.tipofinanciera_id;
            this.tipoFinanciera_nombre = Data._fCatalogo(f.tipofinanciera_id,"TIPO FINANCIERA");
            this.Nombre= Data._trim(f.Nombre);
            this.codigoentidad = Data._trim(f.codigoentidad);
            this.pais_id = f.pais_id;
            this.direccionmatriz = Data._trim(f.direccionmatriz);
            this.telefono = Data._trim(f.telefono);
            this.estado = f.estado;
        }
    }

    public static getAll(){
        return Data._getBody("C",43,sessionStorage.getItem("token_fks"));
    }
}