import { Component, OnInit, Injectable, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TipoUsuario } from 'src/app/model/tipoUsuario.model';
import { Usuario } from 'src/app/model/usuario.model';
import { BloqueMenu } from 'src/app/model/bloqueMenu.model';
import { PermisoUsuarioItem } from 'src/app/model/permisoUsuarioItem.model';
import { Administradora } from 'src/app/model/administradora.model';
import { Data } from 'src/app/model/z.model';
import { forkJoin } from 'rxjs';
import { AlertService } from 'src/app/util/alerts/alert.service';

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  id: number;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  isActive:boolean = false;
  id:number;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-mant-asignar-items',
  templateUrl: './mant-asignar-items.component.html',
  styleUrls: ['./mant-asignar-items.component.css']
})
export class MantAsignarItemsComponent implements OnInit {
  paramBusquedaGroup:FormGroup;
  tipoUsuario: TipoUsuario[]=[];
  cboUsuario:any[] = [];
  todosItems:TodoItemNode[]=[]
  modalRef: BsModalRef;
  isLoading:boolean=false;
  todosMenu:any[];
  listMenu:any;
  cboAdministradora: Administradora[];
  constructor(private modalService: BsModalService,private route: ActivatedRoute,private _builder: FormBuilder, private generalService:GeneralService,
              private alertService: AlertService) {
    this.paramBusquedaGroup = this._builder.group({
      adm:[''],
      tipoUsu: ['',[Validators.required]],
      usuarios: ['',[Validators.required]]
    });
    this.paramBusquedaGroup.controls['usuarios'].disable();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }
  ngOnInit() {
    this.init();
    this.dataSource.data = this.todosItems;
    this.onChanges();
  }
  init(){
    let calls:any[]=[];
    calls.push(this.generalService.useService(TipoUsuario.getAll()));
    calls.push(this.generalService.useService(BloqueMenu.getMenuOfUsuario()));
    calls.push(this.generalService.useService(Administradora.getAll()));
    forkJoin(calls).subscribe(
      (res:any[])=>{
        for(let t in res[0].datos){
          this.tipoUsuario.push(new TipoUsuario({tipousuario_id:Number(t),tipousuario:res[0].datos[t].tipousuario}))
        }
        this.listMenu = res[1].datos;
        this.cboAdministradora = Object.values(res[2].datos).map((t:any)=>{
          return new Administradora(t);
        });
      }
    );
  }
  onChanges(): void {
    this.paramBusquedaGroup.get('tipoUsu').valueChanges.subscribe(val => {
      if(val != ''){
        let calls: any[] = [];
        calls.push(this.generalService.useService(Usuario.getAllByTipoUsuario(Number(val),this.paramBusquedaGroup.controls['adm'].value)));
        calls.push(this.generalService.useService(BloqueMenu.getMenuByTipoUsuario(Number(val))));
        forkJoin(calls).subscribe(
          (resp : any)=>{
            this.cboUsuario = [];
            console.log(resp[0]);
            if(resp[0].datos){
              this.cboUsuario = Object.values(resp[0].datos).map((t:any)=>{
                t.nombres = Data._atob(t.nombres) + ' ' + Data._atob(t.apellidos)
                t.usuario = Data._atob(t.usuario);
                return t;
              })
              this.paramBusquedaGroup.controls['usuarios'].enable();
            }else{
              this.paramBusquedaGroup.controls['usuarios'].disable();
              
              this.alertService.warn('No existen usuarios.');
            }
            this.todosItems = [];
            if(resp[1].datos){
              this.todosItems = Object.values(resp[1].datos).map((x:any)=>{
                let child:TodoItemNode[];
                if(x.items){
                  child = Object.values(x.items).map((y:any)=>{
                    return {
                      item:y.item,
                      children:null,
                      id:y.item_id
                    }
                  });
                }
                return {
                  children:child,
                  item:x.nombre_bloque,
                  id:x.bloque_id
                };
              })
            }
            this.dataSource.data = this.todosItems;
          },
          (err : HttpErrorResponse)=>{
            console.log(err);
          }
        );
      }
    });
    this.paramBusquedaGroup.get('usuarios').valueChanges.subscribe(val => {
      console.log(this.listMenu);
      if(val!=''){
        for(let a in this.listMenu){
          if(this.listMenu[a].usuario_id==val){
            var lp = this.listMenu[a].permisos;
            for(let b in lp){
              for(let c in lp[b].items){
                for(let d of this.dataSource._flattenedData.getValue()){
                  if(d.level == 1 && d.id == lp[b].items[c].item_id){
                    this.todoLeafItemSelectionToggle(d,true);
                  }
                }
              }
            }
          }
        }
      }
    });
  }
  cambio(e){
    console.log(e);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-sm');
  }
  getMenuOfUsuarios(){
    this.isLoading = true;
    this.generalService.useService(BloqueMenu.getMenuOfUsuario()).subscribe(
      (data : any)=>{
        this.listMenu = data.datos;
        this.isLoading=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err.error);
      }
    );
  }
  save(form){
    this.isLoading=true;
    var ids = this.dataSource._flattenedData.getValue().filter(t=>(t.isActive==true)).map(t=>{
      return t.id;
    }).join(","); 
    if(ids.length>0){
      this.generalService.useService(PermisoUsuarioItem.post(ids,form.usuarios)).subscribe(
        (data : any)=>{
          this.isLoading=false;
          this.cboUsuario = [];
          this.todosItems = [];
          this.paramBusquedaGroup.reset({tipoUsu:'',usuarios:'',adm:''});
          this.getMenuOfUsuarios();
        },
        (err : HttpErrorResponse)=>{
          console.log(err.error);
        }
      );
    }
  }


  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
        ? existingNode
        : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode,e): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    descendants.forEach(t=>{t.isActive=e});
    // Force update for the parent
    descendants.every(child =>{
      this.checklistSelection.isSelected(child)
      }
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode,e): void {
    this.dataSource._flattenedData.getValue().forEach(t=>{if(t.level == 1 && t.id == node.id)t.isActive=e})
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}