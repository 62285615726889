import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { Data } from 'src/app/model/z.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportBene } from 'src/app/model/reports/reportBene.model';

@Component({
  selector: 'app-rep-beneficios',
  templateUrl: './rep-beneficios.component.html',
  styleUrls: ['./rep-beneficios.component.css']
})
export class RepBeneficiosComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['nombres','fondoInversion','fecha_inicio_inversion','cod_beneficio','beneficio','tipo_beneficio','monto','estado'];
  listBene: ReportBene[]=[];
  formFilter:FormGroup;
  tipoFondos: FondoInversion[] = [];
  isCollapsed = true;
  hidden = false;
  isLoading : boolean = false;
  isLoading2:boolean = true;
  minDateYear:Date = new Date(2020,1,1);
  maxDateYear:Date = new Date();
  minDate:Date = new Date(this.maxDateYear.getFullYear()-1,12,31);
  maxDate:Date = new Date(this.maxDateYear.getFullYear(),11,30);
  totalMonto = 0;
  totalPersonas = 0;
  constructor(private generalService:GeneralService,private _builder: FormBuilder) { }

  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.formFilter = this._builder.group({
      identificacion:[''],
      fondoInversion:[''],
      provincia:[''],
      ciudad:[''],
      rangoFecha:[''],
      tipo_beneficio:[''],
      monto_inicio:[''],
      monto_fin:[''],
      estado:['']
    });
    this.getAllRendimientos(this.maxDateYear.getFullYear(),0);
    this.onChanges();
  }
  getAllRendimientos(anio,mes){
    this.listBene = [];
    this.dataSource = new MatTableDataSource<ReportBene>(this.listBene);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(ReportBene.getAll(anio)).subscribe(
      (data : any)=>{
        console.log(data);
        this.totalMonto = 0;
        this.totalPersonas = 0;
        this.formFilter.patchValue({FondoInversion:"",mes:""});
        for (let t in data.datos) {
          this.totalMonto = this.totalMonto + Number(data.datos[t].monto);
          this.totalPersonas = this.totalPersonas + Number(data.datos[t].personas_asignadas);
          this.listBene.push(new ReportBene(data.datos[t] as ReportBene));
        }
        this.dataSource = new MatTableDataSource<ReportBene>(this.listBene);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    )
  }
  onChanges(): void {
    this.formFilter.valueChanges.subscribe(val => {
      console.log(val);
      this.find(val);
    });
    this.formFilter.controls['anio'].valueChanges.subscribe(val => {
      // console.log(val);
      // if(val!=null){
      //   if(val!="")
      //     this.getAllRendimientos(val.getFullYear(),0);
      // }
    });
  }
  find(form:any){
    var temp = this.listBene;
    if(form.FondoInversion!="")
      temp=temp.filter(d => (d.fondoinversion_id == form.FondoInversion));
    if(form.mes!=null){
      if(form.mes!="")
        temp=temp.filter(d => (d.m_ben == form.mes.getMonth()+1));
    }
    if(form.anio!=null){
      if(form.anio!="")
        temp=temp.filter(d => (d.a_ben == form.anio.getFullYear()));
    }
    this.configTable(temp); 
  }
  configTable(data){
    this.totalMonto = 0;
    this.totalPersonas = 0;
    for(let x of data){
      this.totalMonto = this.totalMonto + Number(x.monto);
      this.totalPersonas = this.totalPersonas + Number(x.personas_asignadas);
    }
    this.dataSource = new MatTableDataSource<ReportBene>(data);
    this.dataSource.paginator = this.paginator;
  }
}