import { Data } from './z.model';

export class PreguntaSecreta {
    id_pregunta:number;
    descripcion:string;
    estado:string;
    constructor(ps:PreguntaSecreta=null){
        if(ps!=null){
            this.id_pregunta = ps.id_pregunta;
            this.descripcion = ps.descripcion;
            this.estado = ps.estado;
        }
    }
    public static getAll(){
        return Data._getBody("C",37,sessionStorage.getItem("token_fks"));
    }
    public static post(f:PreguntaSecreta){
        var tmp = Data._getBody("I",38,sessionStorage.getItem("token_fks"));
        if(f.id_pregunta != null){
          tmp = Data._getBody("U",39,sessionStorage.getItem("token_fks"));
          tmp["data"]["id_pregunta"] = f.id_pregunta;  
        }
        tmp["data"]["descripcion"] = f.descripcion;
        tmp["data"]["estado"] = "A";
        return tmp;
    }
    
    public static delete(id:number){
        var tmp = Data._getBody("D",4,sessionStorage.getItem("token_fks"));
        tmp["data"]["id_pregunta"] = id;
        return tmp;
    }
}