import { Component, OnInit, Output, EventEmitter, Input, Pipe, PipeTransform, IterableDiffers, IterableDiffer} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Contrato } from 'src/app/model/contrato.model';
@Component({
  selector: 'app-ret-documentos-habilitantes',
  templateUrl: './ret-documentos-habilitantes.component.html',
  styleUrls: ['./ret-documentos-habilitantes.component.css']
})
export class RetDocumentosHabilitantesComponent implements OnInit, PipeTransform  {
  doccontrato;
  docterminos;
  docdebito;
  administradora:string;
  _contratos:Contrato[]=[];
  @Input() contratos:Contrato[]=[];
  hover:boolean = true;
  banText:boolean = false;
  banTextSB:boolean = false;
  @Input() banCheck:boolean;
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  iterableDiffer: IterableDiffer<any>;
  constructor(private iterableDiffers: IterableDiffers,private sanitizer: DomSanitizer) { 
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }
  ngOnInit() {
    this.administradora = atob(sessionStorage.getItem('inf7'));
  }
  changeStatusObs(e){
    this.obsCheck.emit(e);
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.contratos);
    if (changes) {
      this.contratos.forEach(t=>{
        t.url = this.transform(t.url);
      })
    }
  }
}
