import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { interval } from 'rxjs/internal/observable/interval';
import { GeneralService } from 'src/app/service/general.service';
import { Catalogo } from 'src/app/model/catalogo.model';
import { Data } from 'src/app/model/z.model';


@Component({
  selector: 'app-dispositivo',
  templateUrl: './dispositivo.component.html',
  styleUrls: ['./dispositivo.component.css'],
  animations: [
    trigger('logoAnimation', [
      state('showLogo', style({
        opacity: 1
      })),
      state('hideLogo',   style({
        opacity: 0
      })),
      transition('showLogo => hideLogo', animate('6000ms ease-out')),
      transition('hideLogo => showLogo', animate('1000ms ease-in'))
    ])
  ]
})
export class DispositivoComponent implements OnInit {
  width = document.body.clientWidth;
  height = document.body.clientHeight;
  ocultar:boolean = true;
  show = false;
  sub;
  stateName = 'hideLogo';
  resp:boolean;
  public player:any;
  constructor(private route: ActivatedRoute, private generalService:GeneralService) {
    
  }

  ngOnInit() {
    if(this.route.snapshot.data.userposts){
      Data.loading = {op:"OK",mensaje:null};
      Data._idEquipo = this.route.snapshot.data.userposts.IP
    }
    this.show = false;
    this.init();
    const otherCont = interval(1000);
    this.sub = otherCont.subscribe(() =>{
      this.changeSize();
    });
    this.route.queryParams.subscribe(params => {
      this.generalService.useServiceWithBackend(Catalogo.validarDispositivo(params.id)).subscribe(
        (data:any)=>{
          this.resp = false;
          this.show = true;
          this.stateName = 'showLogo';
          if(Data.isOk(data)){
            this.resp = true;
          }
        }
      );
    });
  }
  init(){
    if (window['YT']) {
      this.startVideo();
      return;
    }
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }
  startVideo(){
    if(Number(this.width)<Number(this.height)){
      this.width = 1670*this.height/960;
    }else{
      this.height = 960*this.width/1670;
    }
    this.player = new window['YT'].Player('player', {
      videoId: 'HyYpY69Zk6Y',
      width: this.width,
      height: this.height,
      playerVars : {
        'modestbranding':1,
        'rel' : 0,
        'iv_load_policy':3,
        'showinfo' : 0,
        'showsearch' : 0, 
        'controls' : 0,
        'fs':0
      },
      events: {
        'onReady': this.onPlayerReady,
        'onStateChange': this.onStateChange
      }
    });
  }
  onPlayerReady(event){
    event.target.mute();
    event.target.playVideo();
  }
  onStateChange(event){
    if (event.data === 1) {
      document.getElementById("contenedorvideo").style.opacity = "1";
    }
    if (event.data === window['YT'].PlayerState.ENDED) {
      document.getElementById("contenedorvideo").style.opacity = "0";
      event.target.playVideo(); 
    }
  }
  ngOnDestroy() {
    window['onYouTubeIframeAPIReady'] = null;
    if (this.player) {
      this.player.destroy();
    }
    this.sub.unsubscribe();
  }
  changeSize(){
    var porcW = this.width*100/1670;
    var porcH = this.height*100/960;
    if(porcW<porcH){
      this.width = 1670*this.height/960;
    }else{
      this.height = 960*this.width/1670;
    }
    if(this.player != undefined){
      this.player.setSize(this.width,this.height);
    } 
  }
}