import { FondoInversion } from './fondoInversion.model';
import { Data } from './z.model';
import { SolicitudInversion } from './solicitudes/solicitudInversion.model';
import { Contrato } from './contrato.model';

export class FondoAprobado {
    solicitud_inversion_id: number;
    fondoinversion_id: number;
    aporte: string;
    incrementoopcional: string;
    fecha_registro: Date;
    nombrefondo: string;
    pdffondo: string;
    nombre_comercial:string;
    razon_social: string;
    sitioweb:string;
    telefonos:string;
    capital_acumulado: number;
    capital_invertido: number;
    dias_permanece: number;
    horario: string;
    permanencia: number;
    rendimiento: number;
    rendimiento_anual: string;
    rendimiento_promedio: string;
    solicitud:SolicitudInversion;
    contratos:Contrato[]=[];
    precontratos:Contrato[]=[];
    constructor(si:FondoAprobado=null){
        if(si!=null){
            this.solicitud_inversion_id = si.solicitud_inversion_id;
            this.fondoinversion_id = si.fondoinversion_id;
            this.aporte = si.aporte;
            this.incrementoopcional = si.incrementoopcional;
            this.fecha_registro = new Date(si.fecha_registro['date']);
            this.nombrefondo = si.nombrefondo;
            this.pdffondo = si.pdffondo;
            this.nombre_comercial = si.nombre_comercial;
            this.razon_social = si.razon_social;
            this.sitioweb = si.sitioweb;
            this.telefonos = Data._atob(si.telefonos);
            this.capital_acumulado = si.capital_acumulado;
            this.capital_invertido = si.capital_invertido;
            this.dias_permanece = si.dias_permanece;
            this.horario = si.horario;
            this.permanencia = si.permanencia;
            this.rendimiento = si.rendimiento;
            this.rendimiento_anual = si.rendimiento_anual;
            this.rendimiento_promedio = si.rendimiento_promedio;
        }
    }
    public static getAll() {
        return Data._getBody("C5",34,sessionStorage.getItem("token_fks"));
    }
    public static getAllById(id:string) {
        var tmp = Data._getBody("C6",34,sessionStorage.getItem("token_fks"));
        tmp["data"]["id"] = btoa(id);
        return tmp;
    }
}