import { Data } from './z.model';

export class Administradora {
    administradora_fondo_id: Number;
    ruc: string;
    razon_social:string;
    nombre_comercial: string;
    pais_id: number;
    direccion: string;
    email: string;
    telefonos: string;
    sitioweb: string;
    estado:string;
    url:string;
    constructor(a:Administradora=null){
        if(a!=null){
            this.administradora_fondo_id = a.administradora_fondo_id;
            this.ruc = atob(a.ruc);
            this.razon_social = atob(a.razon_social);
            this.nombre_comercial = atob(a.nombre_comercial);
            this.pais_id = a.pais_id;
            this.direccion = Data._atob(a.direccion);
            this.email = atob(a.email);
            this.telefonos = atob(a.telefonos);
            this.sitioweb = atob(a.sitioweb);
            this.estado = a.estado;
            this.url = a.url;
        }
    }
    public static getAll(){
        return Data._getBody("C",35,sessionStorage.getItem("token_fks"));
    }
    public static getInvierte(){
        return Data._getBody("C7",35,sessionStorage.getItem("token_fks"));
    }
    public static post(f:Administradora){
        var tmp = Data._getBody("I",35,sessionStorage.getItem("token_fks"));
        if(f.administradora_fondo_id != null){
          tmp = Data._getBody("U",35,sessionStorage.getItem("token_fks"));
          tmp["data"]["administradora_fondo_id"] = f.administradora_fondo_id;  
        }
        tmp["data"]["ruc"] = btoa(f.ruc);
        tmp["data"]["razon_social"] = btoa(f.razon_social);
        tmp["data"]["nombre_comercial"] = btoa(f.nombre_comercial);
        tmp["data"]["pais_id"] = f.pais_id;
        tmp["data"]["direccion"] = btoa(f.direccion);
        tmp["data"]["email"] = btoa(f.email);
        tmp["data"]["telefonos"] = btoa(f.telefonos);
        tmp["data"]["sitioweb"] = btoa(f.sitioweb);
        tmp["data"]["tipo"] = 0;
        tmp["data"]["url"] = f.url;
        return tmp;
    }
    public static delete(id:Number){
        var tmp = Data._getBody("D",35,sessionStorage.getItem("token_fks"));
        tmp["data"]["administradora_fondo_id"] = id;
        return tmp;
    }
}