import { Component, OnInit, TemplateRef, Input, Output, EventEmitter, ViewChild, IterableDiffers, IterableDiffer} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Imagen } from 'src/app/model/imagen.model';
import { MatPaginator } from '@angular/material/paginator';
import { Observacion } from 'src/app/model/observaciones.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ret-documentos',
  templateUrl: './ret-documentos.component.html',
  styleUrls: ['./ret-documentos.component.css']
})
export class RetDocumentosComponent implements OnInit {
  @Input() i:Imagen[] = [];
  @Input() isb:Imagen = new Imagen();
  @Input() banCheck:boolean;
  modalRef: BsModalRef;
  hover:boolean = true;
  imgSelected:Imagen = new Imagen();
  banText:boolean = false;
  banTextSB:boolean = false;
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsChangeServiciosBasicos:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();

  displayedColumns2: string[] = ['mensaje','estado'];
  @ViewChild('tab2', {static: true}) paginator2: MatPaginator;
  @Input() obsList:Observacion[]=[];
  dataSource2;
  dataSource1;
  iterableDiffer: IterableDiffer<any>;
  constructor(private iterableDiffers: IterableDiffers,private modalService: BsModalService) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }
  ngOnInit() {
  }
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.obsList);
    if (changes) {
      var obs = this.obsList.filter(t=>(t.paso==7));
      this.dataSource1 = new MatTableDataSource<Observacion>(obs);
      var obs = this.obsList.filter(t=>(t.paso==8));
      this.dataSource2 = new MatTableDataSource<Observacion>(obs);
    }
  }
  openModal(template: TemplateRef<any>,img:Imagen) {
    this.imgSelected = img;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('size-modal');
  }
  changeStatusObs(e){
    this.banText=e.checked;
    this.obsCheck.emit(this.banText);
  }
  changeStatusObsSB(e){
    this.banTextSB=e.checked;
    this.obsCheck.emit(this.banText);
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
  obtValorServiciosBasicos(v){
    this.obsChangeServiciosBasicos.emit(v);
  }
}
