import { Component, OnInit, Input, Output, EventEmitter, ViewChild, IterableDiffers, IterableDiffer } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatosContacto } from 'src/app/model/datoscontacto.model';
import { Data } from 'src/app/model/z.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { Domicilio } from 'src/app/model/persona/domicilio.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { Observacion } from 'src/app/model/observaciones.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-datos-contacto',
  templateUrl: './datos-contacto.component.html',
  styleUrls: ['./datos-contacto.component.css']
})
export class DatosContactoComponent implements OnInit {
  @Input() dc: DatosContacto;
  @Input() d: Domicilio;
  @Input() banCheck:boolean;
  paramBusquedaGroup:FormGroup;
  paramBusquedaGroup2:FormGroup;
  cboOperadora: DetalleCatalogo[] = [];
  banText:boolean = false;
  descProv:string = "";
  descCiudad:string = "";
  cboVivienda: DetalleCatalogo[] = [];
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();

  displayedColumns2: string[] = ['mensaje','estado'];
  @ViewChild('tab2', {static: true}) paginator2: MatPaginator;
  @Input() obsList:Observacion[]=[];
  dataSource2;

  iterableDiffer: IterableDiffer<any>;
  constructor(private iterableDiffers: IterableDiffers,private _builder: FormBuilder, private dataService:DataService) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.paramBusquedaGroup = this._builder.group({
      correoelectronico: [''],
      operadoratelefonica:[''],
      telefonoConvencional:[''],
      telefonocelular:['']
    });
    this.paramBusquedaGroup.disable();
  }
  ngOnChanges(){
    if(this.d != null ){
      this.dataService.getDivisionPais(this.d.pais_id).subscribe(
        (data : any)=>{
          var pro = data.datos;
          var ciu = null;
          for (let i in pro) {
            if(Number(i) == this.d.provincia_id){
              ciu = pro[i];
              this.descProv = ciu.descripcion;
              for (let j in ciu.ciudades) {
                if(Number(j) == this.d.ciudad_id){
                  this.descCiudad = ciu.ciudades[j].descripcion;
                }
              }
            }
          }
        },
        (err : HttpErrorResponse)=>{
        }
      );
    }
    this.paramBusquedaGroup2 = this._builder.group({
      direccion: [''],
      pais:[Data._findPais(this.d.pais_id)],
      provincia:[this.descProv],
      ciudad:[this.descCiudad],
      sector:[''],
      codigopostal:["NINGUNO"],
      vivienda:[''],
      tiempoResidencia:['']
    });
    this.paramBusquedaGroup2.disable();
  }
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.obsList);
    if (changes) {
      var obs = this.obsList.filter(t=>(t.paso==3));
      this.dataSource2 = new MatTableDataSource<Observacion>(obs);
    }
  }
  ngOnInit() {
    this.cboOperadora = Data._fListCatalogo("TIPO OPERADORA");
    this.cboVivienda = Data._fListCatalogo("TIPO VIVIENDA");
  }
  changeStatusObs(e){
    this.banText=e.checked;
    this.obsCheck.emit(this.banText);
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
}
