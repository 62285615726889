import { Data } from '../z.model';

export class ReportBene {
    personas_asignadas:number;
    fondoinversion_id:number;
    nombrefondo:string;
    beneficio_id:number;
    a_ben:number;
    m_ben:number;
    s_ben:number;
    tipo_beneficio:number;
    titulo:string
    monto:number
    constructor(b:ReportBene = null){
        if(b != null){
            this.personas_asignadas=b.personas_asignadas;
            this.fondoinversion_id=b.fondoinversion_id;
            this.beneficio_id=b.beneficio_id;
            this.nombrefondo=b.nombrefondo;
            this.a_ben = b.a_ben;
            this.m_ben = b.m_ben;
            this.s_ben = b.s_ben;
            this.tipo_beneficio=b.tipo_beneficio;
            this.titulo=b.titulo;
            this.monto = b.monto;
        }
    }
    public static getAll(anio:number) {
        var tmp = Data._getBody("C7",168,sessionStorage.getItem("token_fks"));
        tmp["data"]["anio"] = anio;
        return tmp;
    }
}