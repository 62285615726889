import { Data } from './z.model';

export class NormaFondo {
    normas_id:number;
    fondoinversion_id:number;
    permanencia:number;
    incrementoobligatorio:boolean;
    incrementomanual:boolean;
    min_io:number;
    max_io:number;
    min_im:number;
    max_im:number;
    permite_retiro_parcial:boolean;
    permite_capital_rendimiento:boolean;
    perm_rend_retiro:boolean;
    perm_capital_retiro:boolean;
    porc_rend_retiro:number;
    porc_capital_retiro:number;
    aplica_penalidad:boolean;
    porc_sobre_rend:number;
    porc_sobre_capital_retiro:number;
    aplica_autorenovacion:boolean;
    dias_autorenovacion:number;
    aplica_nuevo_contrato:boolean;
    dias_antes_rescate:number;
    notificar_cliente:boolean;
    constructor(n:NormaFondo=null){
        if(n!=null){
            this.normas_id = n.normas_id;
            this.fondoinversion_id=n.fondoinversion_id;
            this.permanencia=n.permanencia;
            this.incrementoobligatorio=n.incrementoobligatorio;
            this.incrementomanual=n.incrementomanual;
            this.min_io=n.min_io;
            this.max_io=n.max_io;
            this.min_im=n.min_im;
            this.max_im=n.max_im;
            this.permite_retiro_parcial=n.permite_retiro_parcial;
            this.permite_capital_rendimiento=n.permite_capital_rendimiento;
            this.perm_rend_retiro=n.perm_rend_retiro;
            this.perm_capital_retiro=n.perm_capital_retiro;
            this.porc_rend_retiro=n.porc_rend_retiro;
            this.porc_capital_retiro=n.porc_capital_retiro;
            this.aplica_penalidad=n.aplica_penalidad;
            this.porc_sobre_rend=n.porc_sobre_rend;
            this.porc_sobre_capital_retiro=n.porc_sobre_capital_retiro;
            this.aplica_autorenovacion=n.aplica_autorenovacion;
            this.dias_autorenovacion=n.dias_autorenovacion;
            this.aplica_nuevo_contrato=n.aplica_nuevo_contrato;
            this.dias_antes_rescate=n.dias_antes_rescate;
            this.notificar_cliente=n.notificar_cliente;
        }
    }
    public static getAll(idFondo){
        var tmp = Data._getBody("C",159,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = idFondo;
        return tmp;
    }
    public static post(f:NormaFondo){
        var tmp = Data._getBody("I",163,sessionStorage.getItem("token_fks"));
        if(f.normas_id != null){
          tmp = Data._getBody("U",164,sessionStorage.getItem("token_fks"));
          tmp["data"]["normas_id"] = f.normas_id;  
        }
        tmp["data"]["fondoinversion_id"] = f.fondoinversion_id;
        tmp["data"]["permanencia"] = f.permanencia;
        tmp["data"]["incrementoobligatorio"] = f.incrementoobligatorio?1:0;
        tmp["data"]["incrementomanual"] = f.incrementomanual?1:0;
        tmp["data"]["min_io"] = f.min_io;
        tmp["data"]["max_io"] = f.max_io;
        tmp["data"]["min_im"] = f.min_im;
        tmp["data"]["max_im"] = f.max_im;
        tmp["data"]["permite_retiro_parcial"] = f.permite_retiro_parcial?1:0;
        tmp["data"]["permite_capital_rendimiento"] = f.permite_capital_rendimiento?1:0;
        tmp["data"]["perm_rend_retiro"] = f.perm_rend_retiro?1:0;
        tmp["data"]["perm_capital_retiro"] = f.perm_capital_retiro?1:0;
        tmp["data"]["porc_rend_retiro"] = f.porc_rend_retiro;
        tmp["data"]["porc_capital_retiro"] = f.porc_capital_retiro;
        tmp["data"]["aplica_penalidad"] = f.aplica_penalidad?1:0;
        tmp["data"]["porc_sobre_rend"] = f.porc_sobre_rend;
        tmp["data"]["porc_sobre_capital_retiro"] = f.porc_sobre_capital_retiro;
        tmp["data"]["aplica_autorenovacion"] = f.aplica_autorenovacion?1:0;
        tmp["data"]["dias_autorenovacion"] = f.dias_autorenovacion;
        tmp["data"]["aplica_nuevo_contrato"] = f.aplica_nuevo_contrato?1:0;
        tmp["data"]["dias_antes_rescate"] = f.dias_antes_rescate;
        tmp["data"]["notificar_cliente"] = f.notificar_cliente?1:0;
        return tmp;
    }
    public static delete(id:Number){
        var tmp = Data._getBody("D",162,sessionStorage.getItem("token_fks"));
        tmp["data"]["normas_id"] = id;
        return tmp;
    }
}