import { Data } from './z.model';
import { DomSanitizer } from '@angular/platform-browser';
export class Contrato {
    solicitud_inversion_id:number;
    nombrefondo: string;
    url: any;
    personafondoinversion_id:number;
    identificacion:string;
    nombres: string;
    apellidos: string;
    estado: number;
    tipo: number;
    tipo_doc: number;
    tipo_doc_nombre:string;
    constructor(b:Contrato = null){
        if(b!=null){
            this.solicitud_inversion_id = b.solicitud_inversion_id;
            this.nombrefondo = b.nombrefondo;
            this.url = b.url;
            this.personafondoinversion_id = b.personafondoinversion_id;
            this.identificacion = b.identificacion;
            this.nombres = b.nombres;
            this.apellidos = b.apellidos;
            this.estado = b.estado;
            this.tipo = b.tipo;
            if(b.tipo_doc==1){
                this.tipo_doc_nombre = "CONTRATO";
            }else if(b.tipo_doc==2){
                this.tipo_doc_nombre = "TERMINOS Y CONDICIONES";
            }else if(b.tipo_doc==3){
                this.tipo_doc_nombre = "ORDEN DE DEBITO";
            }else if(b.tipo_doc==4){
                this.tipo_doc_nombre = "ORDEN DE RETIRO";
            }
        }
    }
    public static getAll(ci:string) {
        var tmp = Data._getBody("C5",58,sessionStorage.getItem("token_fks"));
        tmp["data"]["ci"] = btoa(ci);
        return tmp;
    }
}