import { Data } from './z.model';

export class DatosConyugue {
    conyugue_id:number;
    persona_id:number;
    tipoidentificacion_id:Number;
    identificacion: string;
    fecha_expedicion:Date;
    nombres:string;
    apellidos:string;
    fecha_nacimiento:Date;
    codigo_dactilar: string;
    nacionalidad_id:number;
    estadocivil_id:number;
    genero:string;
    ocupacion_id:number;
    operadora_id: number;
    celular:string;
    sitio: string;
    relacionlaboral_id:number;
    tiponegocio_id:number;
    area:string;
    niveleducacion_id:number;
    cargo:string;
    empresatrabajo:string;
    Ingresomensual:string;
    fecha_inicioactividades:Date;
    estado:string;
    constructor(dc:DatosConyugue = null){
        if(dc != null){
            this.conyugue_id = dc.conyugue_id;
            this.persona_id = dc.persona_id;
            this.tipoidentificacion_id = dc.tipoidentificacion_id;
            this.identificacion = atob(dc.identificacion);
            this.fecha_expedicion = dc.fecha_expedicion; 
            this.nombres = Data._atob(dc.nombres);
            this.apellidos = Data._atob(dc.apellidos);
            this.fecha_nacimiento = dc.fecha_nacimiento;
            this.codigo_dactilar = dc.codigo_dactilar;
            this.nacionalidad_id = dc.nacionalidad_id;
            this.estadocivil_id = dc.estadocivil_id;
            this.genero = dc.genero;
            this.ocupacion_id = dc.ocupacion_id;
            this.operadora_id = dc.operadora_id;
            this.celular = dc.celular;
            this.sitio = dc.sitio;
            this.relacionlaboral_id = dc.relacionlaboral_id;
            this.tiponegocio_id = dc.tiponegocio_id
            this.area = dc.area;
            this.niveleducacion_id = dc.niveleducacion_id;
            this.cargo = dc.cargo;
            this.empresatrabajo = dc.empresatrabajo;
            this.Ingresomensual = dc.Ingresomensual;
            this.fecha_inicioactividades = dc.fecha_inicioactividades;
            this.estado =dc.estado;
        }
    }
}
