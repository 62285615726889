import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { GeneralService } from 'src/app/service/general.service';
import { CuentaRegistrada } from 'src/app/model/cuentaRegistrada.model';
import { AlertService } from 'src/app/util/alerts/alert.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'app-cuenta-bancaria',
  templateUrl: './cuenta-bancaria.component.html',
  styleUrls: ['./cuenta-bancaria.component.css']
})
export class CuentaBancariaComponent implements OnInit {
  isLoading:boolean;
  isCollapsed:boolean = true;
  formRegistroCuenta:FormGroup;
  formAsignaCuentas:FormGroup;
  displayedColumns: string[] = ['financiera','tipocuenta','numero_cuenta'];
  listCuenta: CuentaRegistrada[];
  dataSource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private alertService:AlertService,private _builder: FormBuilder, private gs : GeneralService) {
    this.formRegistroCuenta = this._builder.group({
      tipocuenta: ['',[Validators.required]],
      financiera:['',[Validators.required]],
      numero:['',[Validators.required]],
      descripcion:['',[Validators.required]]
    });
    this.formAsignaCuentas = new FormGroup({
      formArrayName: this._builder.array([])
    })

    this.buildForm();
  }
  buildForm() {
    const controlArray = this.formAsignaCuentas.get('formArrayName') as FormArray;
    Data._fondosInversion.forEach(s=>{
      controlArray.push(
        this._builder.group({
          fondoinversion_cuenta_id: [null],
          fondoinversion_id: [s.fondoinversion_id],
          administradora_cuenta_id: ['']
        })
      )
    })
    console.log(controlArray.controls)
  }

  ngOnInit() {
    this.isCollapsed = true;
    this.getAllCuenta();
  }
  clear(){
    this.isCollapsed = true;
    this.formRegistroCuenta.reset({
      tipocuenta: '',
      financiera:'',
      numero:'',
      descripcion:''
    });
  }
  save(form){
    this.isLoading = true;
    this.gs.useService(CuentaRegistrada.post(form)).subscribe(
      (data:any)=>{
        if(Data.isOk(data)){
          this.clear();
          this.alertService.success("Cuenta registrada.");
          this.getAllCuenta();
        }else{
          this.alertService.error("Error al guardar.");
        }
        this.isLoading = false;
      },
      (err)=>{
        console.log(err);
      }
    );
    console.log(form);
  }
  get financieras(){
    return Data._financieras;
  }
  get tipoCuenta(){
    return Data._fListCatalogo("TIPO DE CUENTA");
  }
  getAllCuenta(){
    this.isLoading = true;
    this.listCuenta = [];
    this.dataSource = new MatTableDataSource<any>(this.listCuenta);
    this.dataSource.paginator = this.paginator;
    let calls:any[] = [];
    calls.push(this.gs.useService(CuentaRegistrada.getAll()));
    calls.push(this.gs.useService(CuentaRegistrada.listarCuentaFondo()));
    forkJoin(calls).subscribe(
      (resp : any)=>{
        if(resp[0].datos){
          this.listCuenta = Object.values(resp[0].datos).map((t:any)=>{
            return new CuentaRegistrada(t);
          })
        }
        this.dataSource = new MatTableDataSource<any>(this.listCuenta);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
        if(resp[1].datos){ 
          let cArray = this.formAsignaCuentas.get('formArrayName') as FormArray;
          for(let cArr of cArray.controls){
            let ban = false;
            for(let c of Object.values(resp[1].datos as any[])){
              if(c.fondoinversion_id == cArr.value.fondoinversion_id){
                ban = true
                cArr.patchValue(c);
              }
            }
            if(!ban){
              cArr.patchValue({
                fondoinversion_cuenta_id: null,
                administradora_cuenta_id: ''
              });
            }
          }
        }
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  get fondos(){
    return Data._fondosInversion;
  }
  saveCuentaFondo(fondo,i){
    fondo.isSaving = true;
    const controlArray = this.formAsignaCuentas.get('formArrayName') as FormArray;
    if(controlArray.controls[i].value.administradora_cuenta_id != ''){
      this.gs.useService(CuentaRegistrada.asignarCuentaFondo(controlArray.controls[i].value)).subscribe(
        (data:any)=>{
          if(Data.isOk(data)){
            this.alertService.success("Cuenta registrada a " + fondo.nombrefondo+".");
          }else{
            this.alertService.error("Error al registrar cuenta en fondo de inversión.");
          }
          fondo.isSaving = false;
          this.getAllCuenta();
        },
        (err:HttpErrorResponse)=>{
          fondo.isSaving = false;
          console.log(err);
        }
      );
    }else{
      this.deleteCuentaFondo(fondo,controlArray.controls[i].value);
    }
  }
  deleteCuentaFondo(fondo,form){
    fondo.isSaving = true;
    if(form.fondoinversion_cuenta_id){
      this.gs.useService(CuentaRegistrada.eliminarCuentaFondo(form.fondoinversion_cuenta_id)).subscribe(
        (data:any)=>{
          console.log(data);
          if(Data.isOk(data)){
            this.alertService.success("Se elimino cuenta asignada al fondo" + fondo.nombrefondo+".");
          }else{
            this.alertService.error("Error al eliminar asignacion.");
          }
          fondo.isSaving = false;
          this.getAllCuenta();
        },
        (err:HttpErrorResponse)=>{
          fondo.isSaving = false;
        }
      );
    }else{
      fondo.isSaving = false;
    }
  }
}
