import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { PermisoServicio } from 'src/app/model/permisoServicio.model';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { Data } from 'src/app/model/z.model';
import { MessageComponent } from 'src/app/util/messageError.component';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'app-mant-permisos-servicios',
  templateUrl: './mant-permisos-servicios.component.html',
  styleUrls: ['./mant-permisos-servicios.component.css']
})
export class MantPermisosServiciosComponent implements OnInit {
  isLoadingFormApp:boolean;
  isSavePermisos:boolean;
  isSavingPermiso:boolean;
  dataSource = new MatTableDataSource<PermisoServicio>();
  displayedColumns: string[] = ['id_app','identificador','nombre','descripcion','tipo','estado'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort , {static: true}) sort: MatSort;
  paramBusquedaGroup:FormGroup;
  listServicios: any[]=[];
  disabled:boolean = true;
  simuled:boolean = false;
  listPermisoServicio: PermisoServicio[];
  isLoading2:boolean = true;
  isCollapsed1:boolean = true;
  isCollapsed2:boolean = true;
  formPermisoNuevo: FormGroup;
  formApp: FormGroup;
  isLoading: boolean;
  cboApp:any[];
  cboQrt:any[];
  changeService:any[] = [];
  constructor(private message: MessageComponent ,private generalService:GeneralService,private _builder: FormBuilder) { 
    this.formApp = this._builder.group({
      nombreapp: ['',Validators.required]
    });
    this.formPermisoNuevo = this._builder.group({
      idapp:['',Validators.required],
      idqrt:['',Validators.required]
    });
    this.paramBusquedaGroup = this._builder.group({
      id_APP: ['',Validators.required],
      tipo: [''],
      descripcion_corta:[''],
      editar: [false,Validators.requiredTrue]
    });
  }
  
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.disabled = !this.paramBusquedaGroup.value.editar;
      if(this.paramBusquedaGroup.value.plataforma == "1"){
        this.simuled = true;
      }else if(this.paramBusquedaGroup.value.plataforma == "2"){
        this.simuled = true;
      }else{
        this.simuled = false;
      }
    });
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      const tableFilters = [];
      tableFilters.push({
        id: 'tipo',
        value: val.tipo
      },{
        id: 'id_APP',
        value: val.id_APP
      },{
        id: 'descripcion_corta',
        value: val.descripcion_corta
      });
      this.dataSource.filter = JSON.stringify(tableFilters);
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    });
  }

  ngOnInit() {
    this.getAllPermisoServicio();
    this.getAllQrt();
    this.onChanges();
    this.clear();
  }
  saveApp(form){
    this.isLoadingFormApp=true;
    this.generalService.useService(PermisoServicio.post(form)).subscribe(
      (data:any)=>{
        if(this.message.messageSave(data)){
          this.clear();
        }
        this.isLoadingFormApp=true;
      },(err)=>{
        console.log(err);
      }
    );
  }
  savePermisos(){
    if(this.changeService.length>0 && !this.disabled){
      this.isSavePermisos = true;
      let calls:any[] = [];
      this.changeService.forEach(t=>{
        calls.push(this.generalService.useService(PermisoServicio.put(t.id,t.estado)));
      })
      forkJoin(calls).subscribe((resp)=>{
        this.changeService = [];
        this.isSavePermisos = false;
        this.paramBusquedaGroup.controls['editar'].setValue(false);
      });
    }else{
      this.message.messageError("No hay cambios existentes o no tiene habilitado la edición.")
    }
    console.log(this.changeService);
  }
  getAllApp(){
    this.generalService.useService(PermisoServicio.getAllApp()).subscribe(
      (data : any)=>{
        this.cboApp = Object.values(data.datos).map((t:any)=>{
          t.nombreapp = t.nombreapp.trim();
          return t;
        });
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  savePermiso(form){
    this.isSavingPermiso = true;
    console.log(form);
    this.generalService.useService(PermisoServicio.postPermiso(form)).subscribe(
      (data:any)=>{
        console.log(data);
        this.isSavingPermiso = false;
        this.clear();
        this.getAllPermisoServicio();
      },
      (err)=>{
        console.log(err);
      }
    );
  }
  getAllQrt(){
    this.generalService.useService(PermisoServicio.getAllQrt()).subscribe(
      (data : any)=>{
        console.log(data);
        this.cboQrt = Object.values(data.datos).map((t:any)=>{
          t.descripcion_corta = t.descripcion_corta.trim();
          return t;
        });
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  getAllPermisoServicio(){
    this.listPermisoServicio = [];
    this.generalService.useService(PermisoServicio.getAll()).subscribe(
      (data : any)=>{
        this.fillPermiso(data.datos);
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  fillPermiso(datos){
    for (let t in datos) {
      this.listPermisoServicio.push(new PermisoServicio(datos[t] as PermisoServicio));
    }
    this.dataSource = new MatTableDataSource<PermisoServicio>(this.listPermisoServicio);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = 
    (data: any, filtersJson: string) => {
      const matchFilter = [];
      const filters = JSON.parse(filtersJson);
      filters.forEach(filter => {
        const val = data[filter.id] === null ? '' : (typeof data[filter.id] == 'number'?data[filter.id].toString():data[filter.id]);
        matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
      });
        return matchFilter.every(Boolean);
    };
  }
  changeStatus(id:number,estado){
    this.dataSource.data.forEach(t=>{
      if(t.identificador == id){
        t.estado = estado.checked;
        return;
      }
      return;
    });
    let ban:boolean = false;
    for(let x of this.changeService){
      if(id == x.id){
        ban = true;
        x.estado = estado.checked;
      }
    }
    if(!ban){
      this.changeService.push({id:id, estado:estado.checked})
    }
  }
  clear(){
    this.isCollapsed1=true;
    this.isCollapsed2=true;
    this.getAllApp();
    this.formApp.reset();
    this.formPermisoNuevo.reset({
      idqrt: '',
      idapp: ''
    });
    this.paramBusquedaGroup.reset({
      id_APP: '',
      tipo: '',
      descripcion_corta:'',
      editar: false
    });
    this.getAllPermisoServicio();
  }
}