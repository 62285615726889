import { Data } from './z.model';
import { formatDate } from '@angular/common';
declare var require: any;
const PHPUnserialize = require('phpUnserialize');

export class NotificacionUsuario {
    notificacionusuario_id:number;
    notificacion_id:number;
    tiponotificacion_id:number;
    tiponotificacion_nombre:string;
    titulo:string;
    imagen:string;
    descripcion:string;
    url:string;
    aplicacosto:boolean;
    fondoinversion_id:number;
    leida:number;
    persona_id:number;
    hora_fecha:any;
    haceTiempo:string;
    argumentos:any;
    constructor(n:NotificacionUsuario=null){
        if(n!=null){
            this.descripcion = Data._trim(n.descripcion);
            if(Data._atob(n.argumentos) != ''){
                this.argumentos = PHPUnserialize(Data._atob(n.argumentos));
            }
            for(let x in this.argumentos){
                if(x == "nombrefondo" && this.argumentos[x]){
                    this.argumentos[x] = this.argumentos[x].trim();
                }
                if(x == "nombre"){
                    this.argumentos[x] = this.argumentos[x].split(" ").map(t=>{return Data._atob(t)}).join(" ");;
                }
                this.descripcion = this.descripcion.replace("{"+x+"}", this.argumentos[x]);
            }
            this.notificacionusuario_id = n.notificacionusuario_id;
            this.notificacion_id = n.notificacion_id;
            this.tiponotificacion_id = n.tiponotificacion_id;
            this.tiponotificacion_nombre = Data._fCatalogo(n.tiponotificacion_id,"TIPO DE NOTIFICACION");
            this.titulo = Data._trim(n.titulo);
            if(this.notificacion_id == 18 && this.argumentos){
                this.url = "/home/solicitudes/inversion/"+this.argumentos.solicitud_inversion_id;
            }else if((this.notificacion_id == 40 || this.notificacion_id == 39) && this.argumentos){
                this.url = "/home/solicitudes/retiro/"+(this.argumentos.solicitudretiro_id);
                //console.log(this.argumentos);
            }
            this.imagen = Data._trim(n.imagen);
            this.aplicacosto = n.aplicacosto;
            this.fondoinversion_id = n.fondoinversion_id;
            this.leida = n.leida;
            this.persona_id = n.persona_id;
            if(n.hora_fecha){
                let now = new Date();
                let fReg = new Date(n.hora_fecha.date);
                this.hora_fecha = fReg;
                let diff = (now.getTime() - fReg.getTime())/1000;
                if(diff<59)
                    this.haceTiempo = 'Hace ' + diff.toFixed(0)+' seg';
                else{
                    let min = diff/60;
                    if(min<59)
                        this.haceTiempo = 'Hace ' + min.toFixed(0)+' min';
                    else{
                        let hor = min/60;
                        if(hor<23)
                            this.haceTiempo = 'Hace ' + hor.toFixed(0)+' h';
                        else{
                            let dias = hor/24;
                            if(dias<30)
                                this.haceTiempo = 'Hace ' + dias.toFixed(0)+' d';
                            else{
                                this.haceTiempo = formatDate(fReg, 'dd/MM/yyyy', 'en-US');
                            }
                        }
                    }
                }
            }
        }
    }
    public static getAll(){
        var tmp = Data._getBody("C2",120,sessionStorage.getItem("token_fks"));
        tmp["data"]["persona_id"] = 0
        return tmp;
    }
    public static getNoLeidas(){
        var tmp = Data._getBody("C3",120,sessionStorage.getItem("token_fks"));
        tmp["data"]["persona_id"] = 0
        return tmp;
    }
    public static updateToLeida(id){
        var tmp = Data._getBody("U2",122,sessionStorage.getItem("token_fks"));
        tmp["data"]["notificacionusuario_id"] = id;
        return tmp;
    }
    public static delete(id){
        var tmp = Data._getBody("D2",123,sessionStorage.getItem("token_fks"));
        tmp["data"]["notificacionusuario_id"] = id;
        return tmp;
    }
}