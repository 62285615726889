import { Data } from './z.model';

export class GrupoUsuario {
    grupo_id:number;
    descripcion:string;
    constructor(b:GrupoUsuario=null){
        if(b!=null){
            this.grupo_id = b.grupo_id;
            this.descripcion = Data._trim(b.descripcion);
        }
    }
    public static getAll(){
        return Data._getBody("C4",148,sessionStorage.getItem("token_fks"));
    }
    public static post(f:GrupoUsuario){
        var tmp = Data._getBody("I3",149,sessionStorage.getItem("token_fks"));
        if(f.grupo_id != null){
          tmp = Data._getBody("U3",150,sessionStorage.getItem("token_fks"));
          tmp["data"]["grupo_id"] = f.grupo_id;
        }
        tmp["data"]["descripcion"] = f.descripcion;
        return tmp;
    }
    // public static post(f:BloqueMenu){
    //     var tmp = Data._getBody("I",109,sessionStorage.getItem("token_fks"));
    //     if(f.sistema_bloques_id != null){
    //       tmp = Data._getBody("U",110,sessionStorage.getItem("token_fks"));
    //       tmp["data"]["sistema_bloques_id"] = f.sistema_bloques_id;
    //     }
    //     tmp["data"]["nombre_bloque"] = f.nombre_bloque;
    //     tmp["data"]["url"] = f.url;
    //     return tmp;
    // }
    public static delete(id:Number){
        var tmp = Data._getBody("D3",151,sessionStorage.getItem("token_fks"));
        tmp["data"]["grupo_id"] = id;
        return tmp;
    }
    // public static getMenu(){
    //     var tmp = Data._getBody("C1",148,sessionStorage.getItem("token_fks"));
    //     console.log(tmp);
    //     return tmp;
    // }
}