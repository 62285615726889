import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Dispositivo } from 'src/app/model/dispositivo.model';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-conf-dispositivos',
  templateUrl: './conf-dispositivos.component.html',
  styleUrls: ['./conf-dispositivos.component.sass']
})
export class ConfDispositivosComponent implements OnInit {
  listDispositivo:Dispositivo[];
  selected:Dispositivo;
  isDeleting:boolean;
  @ViewChild('modalDelete', { static: false }) modalDelete: ModalDirective;
  constructor(private generalService:GeneralService) { }

  ngOnInit() {
    this.getAllDispositivo();
  }
  getAllDispositivo(){
    this.listDispositivo=[];
    this.generalService.useService(Dispositivo.getAll()).subscribe(
      (data:any)=>{
        if(data.datos){
          this.listDispositivo = Object.values(data.datos).map(t=>{
            return new Dispositivo(t);
          });
        }
      }
    );
  }
  delete(){
    this.isDeleting = true;
    this.generalService.useService(Dispositivo.delete(this.selected.id_equipo_persona)).subscribe(
      (data)=>{
        this.isDeleting = false;
        this.selected = null;
        this.modalDelete.hide();
        this.getAllDispositivo();
      }
    );
  }
}
