import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Beneficiario } from 'src/app/model/beneficiario.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Data } from 'src/app/model/z.model';

@Component({
  selector: 'app-beneficiario',
  templateUrl: './beneficiario.component.html',
  styleUrls: ['./beneficiario.component.css']
})
export class BeneficiarioComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['identificacion','tipoIdentificacion','nombreBeneficiario','parentezco','sexo'];
  dataSource;
  banText:boolean = false;
  @Input() be: Beneficiario[] ;
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  constructor() { }
  ngOnChanges(){
      this.dataSource = new MatTableDataSource<Beneficiario>(this.transformData(this.be));
      this.dataSource.paginator = this.paginator;
  }
  ngOnInit() {
  }
  public transformData(data:any):any[]{
    var dt:{
      identificacion:string,
      tipoIdentificacion:string,
      nombre:string,
      parentezco:string,
      sexo:string,
      porcentaje:string
    }[]=[];
    for (let t in data) {
      dt.push({"identificacion":data[t].identificacion,"tipoIdentificacion":Data._fCatalogo(data[t].tipoidentificacion_id,"TIPO DE IDENTIFICACION PERSONA"),"nombre":data[t].nombres+" "+data[t].apellidos,"parentezco":Data._fCatalogo(data[t].tipoparentesco_id,"TIPO PARENTESCO"),"sexo":Data._fCatalogo(data[t].genero,"SEXO"),"porcentaje":data[t].porcentaje});
    }
    return dt;
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
  changeStatusObs(e){
    this.banText=e.checked;
    this.obsCheck.emit(this.banText);
  }
}
