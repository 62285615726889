import { Component, OnInit } from '@angular/core';
import { Data } from 'src/app/model/z.model';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as jsPDF  from 'jspdf';
import 'jspdf-autotable';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { FondoAprobado } from 'src/app/model/fondoAprobado.model';
import { SolicitudInversion } from 'src/app/model/solicitudes/solicitudInversion.model';
import { Persona } from 'src/app/model/persona/persona.model';
import { formatDate } from '@angular/common';
import { Contrato } from 'src/app/model/contrato.model';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-detalle-cons-inversionistas',
  templateUrl: './detalle-cons-inversionistas.component.html',
  styleUrls: ['./detalle-cons-inversionistas.component.css']
})
export class DetalleConsInversionistasComponent implements OnInit {
  displayedColumns: string[] = ['capAcumulado','capInvertido','diasPermanencia','rendimientoGenerado'];
  isLoading: boolean;
  persona:Persona = new Persona();
  constructor(private dataService:DataService,private sanitizer: DomSanitizer,private activedRroute: ActivatedRoute,private generalService:GeneralService) { }
  cedula:string;
  today:Date = new Date();
  listFondoAprobado:FondoAprobado[] = [];
  pdfShow;
  listContrato:Contrato[]=[];
  descProv:string;
  descCiudad:string;
  ngOnInit() {
    this.activedRroute.paramMap.subscribe(params => {
      this.cedula = params.get("id");
      this.getInversionistaDetalle(this.cedula);
    })
  }
  transform(url) {
    this.pdfShow = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    return this.pdfShow;
  }
  getInversionistaDetalle(id){
    this.isLoading = true;
    this.generalService.useService(Contrato.getAll(id)).subscribe(
      (c:any)=>{
        for(let p in c.datos){
          this.listContrato.push(new Contrato(c.datos[p]));
        }
        this.generalService.useService(FondoAprobado.getAllById(id)).subscribe(
          (data:any)=>{
            for (let t in data.datos) {
              this.generalService.useService(SolicitudInversion.getById(data.datos[t].solicitud_inversion_id)).subscribe(
                (x:any)=>{
                  var f:FondoAprobado = new FondoAprobado(data.datos[t]);
                  for(let contr of this.listContrato){
                    if(contr.tipo==0)
                      f.precontratos.push(contr);
                    else
                      f.contratos.push(contr);
                  }
                  f.solicitud = new SolicitudInversion(x.datos[0] as SolicitudInversion);
                  this.generalService.useService(Persona.getById(f.solicitud.persona_id)).subscribe(
                    (y:any)=>{
                      if(y!=null){
                        if(y.datos!=null)
                          f.solicitud.persona = new Persona(y.datos as Persona);
                          this.persona = new Persona(y.datos as Persona);
                          this.getDomicilio();    
                          console.log(this.persona);
                      }
                      this.isLoading = false;
                    },(err:HttpErrorResponse)=>{
                      console.error(err);
                    }
                  );
                  this.listFondoAprobado.push(f);
                },
                (err:HttpErrorResponse)=>{
                  console.log(err);
                } 
              );
            }
            console.log(this.listFondoAprobado);
          },
          (err:HttpErrorResponse)=>{
            console.log(err);
          }
        );
      },(err:HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  getDomicilio(){
    this.dataService.getDivisionPais(this.persona.domicilio.pais_id).subscribe(
      (data : any)=>{
        var pro = data.datos;
        var ciu = null;
        for (let i in pro) {
          if(Number(i) == this.persona.domicilio.provincia_id){
            ciu = pro[i];
            this.descProv = ciu.descripcion;
            for (let j in ciu.ciudades) {
              if(Number(j) == this.persona.domicilio.ciudad_id){
                this.descCiudad = ciu.ciudades[j].descripcion;
              }
            }
          }
        }
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  generatePdfClient(){
    const doc = new jsPDF('p', 'pt','a4');
    doc.setDrawColor(0);
    doc.setFillColor(37,43,71);
    doc.rect(0, 0, 595, 120, 'F');
    doc.addImage(environment.logoToBase64, 'PNG', 10, 10, 250, 80);
    doc.setFontSize(16);
    doc.setTextColor(255,255,255);
    doc.text(280,55,"FORMULARIO CONOZCA A SU CLIENTE");
    doc.text(340,75,"PERSONA NATURAL");
    doc.autoTable({
      startY: 140,
      columnStyles: { 0: { fillColor: [37, 43, 71],textColor:[255,255,255] } },
      cellWidth: 'wrap',
      body: [
        ['FECHA DE CONSULTA:', new Date().toLocaleString()]
      ],
    });
    let finalY = doc.previousAutoTable.finalY;    
    doc.autoTable({
      startY: finalY+10,
      theme:'grid',
      head:[[{ content: 'INFORMACIÓN DEL CLIENTE' ,colSpan:3}]],
      body:[
        [
          { content: 'Nombres y apellidos',styles: {fontStyle:'bold'}},
          { content: 'Cédula/Pasaporte', styles: {fontStyle:'bold'}},
          { content: 'Fecha de nacimiento', styles: {fontStyle:'bold'}}
        ],[
          { content: this.persona.datospersonales.apellidos+' '+this.persona.datospersonales.nombres, styles: { halign: 'center'}},
          { content: this.persona.datospersonales.identificacion,styles:{halign:'center'}},
          { content: formatDate(this.persona.datospersonales.fecha_nacimiento, 'yyyy-MM-dd', 'en'),styles:{halign:'center'}}
        ]
      ],
      headStyles:{halign:'center',fillColor: [37, 43, 71],textColor:[255,255,255]}
    });
    finalY = doc.previousAutoTable.finalY;
    doc.autoTable({
      startY: finalY,
      theme:'grid',
      body:[
        [
          { content: 'Estado civil', styles: {fontStyle:'bold'}},
          { content: 'Dirección del domicilio', styles: {fontStyle:'bold'}},
          { content: 'E-mail', styles: {fontStyle:'bold'}}
        ],[
          { content: Data._fCatalogo(this.persona.datospersonales.estadocivil_id,'ESTADO CIVIL'), styles: { halign: 'center'}},
          { content:this.persona.domicilio.direccion,styles:{halign:'center'}},
          { content:this.persona.datosdecontacto.correo_alternativo,styles:{halign:'center'}}
        ]
      ],
    });
    finalY = doc.previousAutoTable.finalY;
    doc.autoTable({
      theme:'grid',
      startY: finalY,
      body:[
        [
          { content: 'Provincia', styles: {fontStyle:'bold'}},
          { content: 'Ciudad', styles: {fontStyle:'bold'}}, 
          { content: 'Teléfono convencional', styles: {fontStyle:'bold'}},
          { content: 'Teléfono celular', styles: {fontStyle:'bold'}}
        ],[
          { content: this.descProv, styles: { halign: 'center'}},
          { content: this.descCiudad,styles:{halign:'center'}},
          { content: this.persona.datosdecontacto.telefono,styles:{halign:'center'}},
          { content: this.persona.datosdecontacto.celular ,styles:{halign:'center'}}
        ]
      ],
    });
    if(this.persona.informacionconyuge.conyugue_id!=null){
      finalY = doc.previousAutoTable.finalY;
      doc.autoTable({
        theme:'grid',
        startY: finalY+10,
        head:[[{ content: 'INFORMACION DEL CONYUGUE'}]],
        body:[
          [
            { content: 'Nombres y apellidos',styles: {fontStyle:'bold'}}
          ],[
            { content: this.persona.informacionconyuge.apellidos+' '+this.persona.informacionconyuge.nombres, styles: { halign: 'center'}}
          ]
        ],
        headStyles:{halign:'center',fillColor: [37, 43, 71],textColor:[255,255,255]}
      });
    }
    finalY = doc.previousAutoTable.finalY;    
    doc.autoTable({
      theme:'grid',
      startY: finalY+10,
      head:[[{ content: 'SITUACIÓN FINANCIERA' ,colSpan:3}]],
      body:[
        [
          { content: 'Total activos (a)',styles: {fontStyle:'bold'}},
          { content: 'Total pasivos (b)',styles: {fontStyle:'bold'}},
          { content: 'Total patrimonio (a-b)',styles: {fontStyle:'bold'}}
        ],[
          { content: this.persona.estadofinanciero.totalactivos, styles: { halign: 'center'}},
          { content: this.persona.estadofinanciero.totalpasivos, styles: { halign: 'center'}},
          { content: this.persona.estadofinanciero.patrimonio, styles: { halign: 'center'}},
        ]
      ],
      headStyles:{halign:'center',fillColor: [37, 43, 71],textColor:[255,255,255]}
    });
    
    finalY = doc.previousAutoTable.finalY;
    doc.autoTable({
      theme:'grid',
      startY: finalY,
      body:[
        [
          { content: 'Actividad economica principal', styles: {fontStyle:'bold'}},
          { content: 'Ingresos por actividad economica', styles: {fontStyle:'bold'}},
          { content: 'Otros ingresos', styles: {fontStyle:'bold'}}
        ],[
          { content: Data._fCatalogo(this.persona.infoeconomicafinanciera.actividadeconomica_id,'OCUPACION'),styles:{halign:'center'}},
          { content: this.persona.infoeconomicafinanciera.ingresos_actividadeconomica,styles:{halign:'center'}},
          { content: this.persona.infoeconomicafinanciera.otros_ingresos,styles:{halign:'center'}}
        ]
      ],
    });
    finalY = doc.previousAutoTable.finalY;
    doc.autoTable({
      theme:'grid',
      startY: finalY,
      body:[
        [
          { content: 'Origen de los recursos',styles: {fontStyle:'bold'}},
          { content: 'Egresos mensuales',styles: {fontStyle:'bold'}},
          { content: 'Flujo mensual neto',styles: {fontStyle:'bold'}}
        ],[
          { content: Data._trim(this.persona.infoeconomicafinanciera.origen_recursos), styles: { halign: 'center'}},
          { content: this.persona.infoeconomicafinanciera.egresos_mensuales, styles: { halign: 'center'}},
          { content: this.persona.flujo, styles: { halign: 'center'}}
        ]
      ],
    });
    finalY = doc.previousAutoTable.finalY;    
    doc.autoTable({
      theme:'grid',
      startY: finalY+10,
      head:[[{ content: 'CUENTA QUE POSEE EN INSTITUCIONES BANCARIAS' ,colSpan:3}]],
      body:[
        [
          { content: 'Entidad financiera',styles: {fontStyle:'bold'}},
          { content: 'Tipo de cuenta', styles: {fontStyle:'bold'}},
          { content: 'N. de cuenta', styles: {fontStyle:'bold'}}
        ],[
          { content: this.persona.datosdecuentas[0].financiera_nombre, styles: { halign: 'center'}},
          { content: this.persona.datosdecuentas[0].tipocuenta_nombre, styles:{halign:'center'}},
          { content: this.persona.datosdecuentas[0].numero, styles:{halign:'center'}}
        ]
      ],
      headStyles:{halign:'center',fillColor: [37, 43, 71],textColor:[255,255,255]}
    });
    finalY = doc.previousAutoTable.finalY;    
      doc.autoTable({
        theme:'grid',
        startY: finalY+10,
        body:[
          [
            { content: 'PERSONA EXPUESTA POLITICAMENTE',styles: {fontStyle:'bold'}},
            { content: this.persona.peps.length>0?'Si':'No',styles: {halign:'center'}}
          ]
        ],
        columnStyles:{0:{fillColor: [37, 43, 71],textColor:[255,255,255]}}
    });
    if(this.persona.peps.length>0){
      finalY = doc.previousAutoTable.finalY;    
      doc.autoTable({
        startY: finalY,
        theme:'grid',
        body:[
          [
            { content: 'Función',styles: {fontStyle:'bold'}},
            { content: 'Cargo', styles: {fontStyle:'bold'}},
            { content: 'Jerarquía', styles: {fontStyle:'bold'}}
          ],[
            { content: this.persona.peps[0].funcion_nombre, styles: { halign: 'center'}},
            { content: this.persona.peps[0].cargo_nombre, styles:{halign:'center'}},
            { content: this.persona.peps[0].gerarquia, styles:{halign:'center'}}
          ]
        ]
      });
    }
    doc.setProperties({
      title: 'Reporte de inversionistas '+ new Date(),
      subject: 'Información de inversionistas, inversiones, rescates y su estado actual.',
      author: 'Invierte',
      keywords: 'Reporte, inversiones',
      creator: 'Invierte'
    });
    var string = doc.output('datauristring');
    var iframe = '<html><title>Formulario del cliente</title>' +
      '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
      '<body>' +
      '<iframe type="application/pdf" src="' + string + '"></iframe>' +
      '</body></html>';
    let x = window.open("", "myWindow");
    x.document.write(iframe);
    x.document.close();
  }
}