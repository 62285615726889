import { NgModule } from '@angular/core';
//para graficas
import { GaugeChartModule } from 'angular-gauge-chart';
import { ChartsModule } from 'ng2-charts';

//NGX-BOOTSTRAP
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule,BsModalRef } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse/';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover'
import { SortableModule } from 'ngx-bootstrap/sortable'


//MATERIAL-ANGULAR
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import {MatChipsModule} from '@angular/material/chips';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatRadioModule} from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    //Bootstrap
    SortableModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    MatProgressSpinnerModule,
    ProgressbarModule.forRoot(),
    ButtonsModule.forRoot(),
    AlertModule.forRoot(),
    CarouselModule,
    AccordionModule,
    BsDropdownModule,
    ModalModule.forRoot(),
    CollapseModule,
    BsDatepickerModule.forRoot(),
    //Material Angular
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatSortModule,
    MatInputModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatMenuModule,
    MatIconModule,
    MatStepperModule,
    MatTableModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatListModule,
    MatExpansionModule,
    MatTabsModule,
    MatSnackBarModule,
    MatRadioModule,
    MatCardModule,
    //DIAGRAMAS
    GaugeChartModule,
    ChartsModule
  ],
  providers:[BsModalRef],
  exports: [MatButtonModule,MatCheckboxModule,MatTreeModule,SortableModule,PopoverModule, TypeaheadModule,MatProgressSpinnerModule,MatSortModule,MatInputModule,MatCardModule,MatProgressBarModule,ProgressbarModule,ButtonsModule,AlertModule,MatMenuModule,MatBadgeModule,MatIconModule,MatRadioModule,CollapseModule,BrowserAnimationsModule,CarouselModule,ChartsModule, GaugeChartModule,MatSnackBarModule,MatTabsModule,BsDropdownModule,MatExpansionModule,MatListModule,MatSlideToggleModule,MatPaginatorModule,MatTableModule,MatStepperModule,MatChipsModule, ModalModule,BsDatepickerModule]
})
export class AppBootstrapModule {}