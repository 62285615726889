import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InstitucionFinancieraService } from '../service/institucion-financiera.service';
import { FondoInversionService } from '../service/fondo-inversion.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { interval, forkJoin } from 'rxjs';
import { GeneralService } from '../service/general.service';
import { Usuario } from '../model/usuario.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Data } from '../model/z.model';
import { Financiera } from '../model/financiera.model';
import { NotificacionUsuario } from '../model/notificacionUsuario.model';
import { ModalsComponent } from '../site/modals/modals.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{
  countSeg: number = 10;
  seguir:boolean = false;
  sub;
  sub2;
  @ViewChild('finSesion', {static: true}) modalSesion;
  @ViewChild('childModal',{static:true}) childModal :ModalsComponent;
  constructor(private route: ActivatedRoute,private generalService: GeneralService,private modalService: BsModalService,private router : Router, private fondoService:FondoInversionService, private financieraService: InstitucionFinancieraService) { 
  }
  ngOnInit(): void {
    if(this.route.snapshot.data.userposts) 
      Data.loading = this.route.snapshot.data.userposts;
    var t = this.route.snapshot.data.financ;
    for(let f in t.datos){
      Data._financieras.push(new Financiera(t.datos[f]));
    }
    this.initTask();
    this.financieraService.getAllInstitucionFinanciera();
  }
  getNotificaciones(){
    let calls:any[] = [];
    calls.push(this.generalService.useService(NotificacionUsuario.getAll()));
    calls.push(this.generalService.useService(NotificacionUsuario.getNoLeidas()));
    forkJoin(calls).subscribe(
      (resp:any[])=>{
        if(resp[0].datos){
          Data._notificaciones = Object.values(resp[0].datos).map((t:any)=>{
            return new NotificacionUsuario(t);
          }).sort((a,b)=>(b.notificacionusuario_id - a.notificacionusuario_id));  
        }
        Data._numNotificaciones = resp[1].datos[0].n_notificaciones;
      }
    );
  }
  initTask(){
    this.getNotificaciones();
    const contador = interval(30 * 1000);
    this.sub = contador.subscribe((val) =>{
      if((val+1)%4==0){
        this.getNotificaciones();
      }
      this.evaluarFechaToken();
    });
  }                    
  evaluarFechaToken(){
    if(sessionStorage.getItem('inf1')!=null){
      var dt = new Date(atob(sessionStorage.getItem('inf1')));
      var dtNew = new Date(dt.getTime() - (1000*60*1));
      var current = new Date();
      if(current >= dtNew){
        this.sub.unsubscribe();
        this.childModal.show();
        //this.openModal(this.modalSesion);
        const otherCont = interval(1 * 1000);
        this.sub2 = otherCont.subscribe(() =>{
          this.countSeg = this.countSeg - 1;
          if(this.countSeg <= 0)
            this.Logout();
        });
        return true;
      }
      return false;
    }
  }
  Logout() {
    this.childModal.hide();
    this.sub2.unsubscribe();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
  extendLogin(){
    this.generalService.useService(Usuario.extendLogin()).subscribe(
      (data : any)=>{
        if(data.datos[0].status == "OK"){
          this.sub2.unsubscribe();
          var dt = new Date(atob(sessionStorage.getItem('inf1')));
          var dtNew = new Date(dt.getTime() + (30*60*1000));
          sessionStorage.setItem('inf1',btoa(dtNew.toString()));
          this.initTask();
        }else{
          this.Logout();
        }
      },
      (err : HttpErrorResponse)=>{
        console.error("Ocurrio algo inesperado al Extender sesión. Consulte con su Proveedor");
        this.Logout();
      }
    );
    this.childModal.hide();
  }
}