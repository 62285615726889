import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LaddaModule } from 'angular7-ladda';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { InversionComponent } from './site/solicitudes/inversion/inversion.component';
import { RetiroComponent } from './site/solicitudes/retiro/retiro.component';
import { DebitoComponent } from './site/solicitudes/debito/debito.component';
import { TransferenciaComponent } from './site/solicitudes/transferencia/transferencia.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UppercaseDirective } from './validations/uppercase.directive';
import { AlphabetOnlyDirective } from './validations/alphabet-only.directive';
import { NumberDirective } from './validations/numbers-only.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule} from '@angular/material/toolbar';
import { FooterComponent } from './home/footer/footer.component';
import { NavigationComponent } from './home/navigation/navigation.component';
import { TopnavbarComponent } from './home/topnavbar/topnavbar.component';
import { DashboardComponent } from './site/dashboard/dashboard.component';
import { DatosGeneralesComponent } from './site/solicitudes/inversion/inv-detalle/datos-generales/datos-generales.component';
import { LugarTrabajoComponent } from './site/solicitudes/inversion/inv-detalle/lugar-trabajo/lugar-trabajo.component';
import { DatosContactoComponent } from './site/solicitudes/inversion/inv-detalle/datos-contacto/datos-contacto.component';
import { DocumentosComponent } from './site/solicitudes/inversion/inv-detalle/documentos/documentos.component';
import { SituacionFinancieraComponent } from './site/solicitudes/inversion/inv-detalle/situacion-financiera/situacion-financiera.component';
import { AccionistaEmpresaComponent } from './site/solicitudes/inversion/inv-detalle/accionista-empresa/accionista-empresa.component';
import { PepsComponent } from './site/solicitudes/inversion/inv-detalle/peps/peps.component';
import { InvDetalleComponent } from './site/solicitudes/inversion/inv-detalle/inv-detalle.component';
import { CrearGruposComponent } from './site/ajustes/crear-grupos/crear-grupos.component';
import { AppBootstrapModule } from './app-bootstrap.module';
import { ImportarDocumentosComponent } from './site/importar-documentos/importar-documentos.component';
import { MantAsignarRolesComponent } from './site/mantenimiento/mant-asignar-roles/mant-asignar-roles.component';
import { CambiarClaveComponent } from './site/ajustes/cambiar-clave/cambiar-clave.component';
import { RegistroEmisoresComponent } from './site/configuraciones/fondo-inversion/registro-emisores/registro-emisores.component';
import { CuentasFinancierasComponent } from './site/ajustes/cuentas-financieras/cuentas-financieras.component';
import { FondoInversionComponent } from './site/configuraciones/fondo-inversion/fondo-inversion.component';
import { FiDocumentosComponent } from './site/configuraciones/fondo-inversion/fi-documentos/fi-documentos.component';
import { FiFirmaElectronicaComponent } from './site/configuraciones/fondo-inversion/fi-firma-electronica/fi-firma-electronica.component';
import { NuevoBeneficioComponent } from './site/beneficios/nuevo-beneficio/nuevo-beneficio.component';
import { NuevaBonificacionComponent } from './site/bonificaciones/nueva-bonificacion/nueva-bonificacion.component';
import { NuevaNotificacionComponent } from './site/notificaciones/nueva-notificacion/nueva-notificacion.component';
import { MantImpuestoComponent } from './site/mantenimiento/mant-impuesto/mant-impuesto.component';
import { RendimientosSemanalesComponent } from './site/rendimientos/rendimientos-semanales/rendimientos-semanales.component';
import { BeneficiarioComponent } from './site/solicitudes/inversion/inv-detalle/beneficiario/beneficiario.component';
import { InventarioRespuestaComponent } from './site/solicitudes/inversion/inv-detalle/inventario-respuesta/inventario-respuesta.component';
import { RendimientosMensualesComponent } from './site/rendimientos/rendimientos-mensuales/rendimientos-mensuales.component';
import { ConsultaEstadoCuentaComponent } from './site/consultas/consulta-estado-cuenta/consulta-estado-cuenta.component';
import { ConsultaMovimientosComponent } from './site/consultas/consulta-movimientos/consulta-movimientos.component';
import { MantAdministradoraComponent } from './site/mantenimiento/mant-administradora/mant-administradora.component';
import { MantFinancieraComponent } from './site/mantenimiento/mant-financiera/mant-financiera.component';
import { MantCatalogoComponent } from './site/mantenimiento/mant-catalogo/mant-catalogo.component';
import { MantGeolocalizacionComponent } from './site/mantenimiento/mant-geolocalizacion/mant-geolocalizacion.component';
import { MantPoolComponent } from './site/mantenimiento/mant-pool/mant-pool.component';
import { MantDetalleCatalogoComponent } from './site/mantenimiento/mant-catalogo/mant-detalle-catalogo/mant-detalle-catalogo.component';
import { VerificadoComponent } from './util/verificado/verificado.component';
import { ChartFondoComponent } from './site/dashboard/chart-fondo/chart-fondo.component';
import { ChartInversionesComponent } from './site/dashboard/chart-inversiones/chart-inversiones.component';
import { ConsultaBonificacionesComponent } from './site/consultas/consulta-bonificaciones/consulta-bonificaciones.component';
import { ConsultaBeneficiosComponent } from './site/consultas/consulta-beneficios/consulta-beneficios.component';
import { ConsultaSolicitudesDebitoFallidasComponent } from './site/consultas/consulta-solicitudes-debito-fallidas/consulta-solicitudes-debito-fallidas.component';
import { ConsultaSolicitudesTransferenciaFallidasComponent } from './site/consultas/consulta-solicitudes-transferencia-fallidas/consulta-solicitudes-transferencia-fallidas.component';
import { AppLoadService } from './service/app-load.service';
import { InvObservacionesComponent } from './site/solicitudes/inversion/inv-detalle/inv-observaciones/inv-observaciones.component';
import { BreadcrumbsModule} from "ng6-breadcrumbs";
import { MantCostoFinancieroComponent } from './site/mantenimiento/mant-costo-financiero/mant-costo-financiero.component';
import { MantCostoAdministrativoComponent } from './site/mantenimiento/mant-costo-administrativo/mant-costo-administrativo.component';
import { AjCostoAdministrativoComponent } from './site/ajustes/aj-costo-administrativo/aj-costo-administrativo.component';
import { UsuarioAdministradoraComponent } from './site/ajustes/usuario-administradora/usuario-administradora.component';
import { MantFondoInversionComponent } from './site/mantenimiento/mant-fondo-inversion/mant-fondo-inversion.component';
import { WorkingComponent } from './util/working/working.component';
import { MantPreguntasComponent } from './site/mantenimiento/mant-preguntas/mant-preguntas.component';
import { MantPermisosServiciosComponent } from './site/mantenimiento/mant-permisos-servicios/mant-permisos-servicios.component';
import { MantMenuComponent } from './site/mantenimiento/mant-menu/mant-menu.component';
import { MantSubMenuComponent } from './site/mantenimiento/mant-menu/mant-sub-menu/mant-sub-menu.component';
import { PrioryResolve, IpResolve } from './resolve/priory.resolve';
import { MantNotificacionesComponent } from './site/mantenimiento/mant-notificaciones/mant-notificaciones.component';
import { FondosAprobadosComponent } from './site/fondos-aprobados/fondos-aprobados.component';
import { MantTipoUsuarioComponent } from './site/mantenimiento/mant-tipo-usuario/mant-tipo-usuario.component';
import { DispositivoComponent } from './auth/dispositivo/dispositivo.component';
import { BloqueResolve } from './resolve/bloque.resolve';
import { AsignarItemsComponent } from './site/ajustes/asignar-items/asignar-items.component';
import { DocumentosHabilitantesComponent } from './site/solicitudes/inversion/inv-detalle/documentos-habilitantes/documentos-habilitantes.component';
import { InversionistaComponent } from './site/consultas/consulta-inversionista/consulta-inversionista.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FinancieraResolve } from './resolve/financiera.resolve';
import { RepRendimientosComponent } from './site/reporteria/rep-rendimientos/rep-rendimientos.component';
import { AjRendimientosComponent } from './site/ajustes/aj-rendimientos/aj-rendimientos.component';
import { InversionistaFondoComponent } from './site/consultas/consulta-inversionista/inversionista-fondo/inversionista-fondo.component';
import { RepBonificacionesComponent } from './site/reporteria/rep-bonificaciones/rep-bonificaciones.component';
import { RepBeneficiosComponent } from './site/reporteria/rep-beneficios/rep-beneficios.component';
import { RepInversionistasComponent } from './site/reporteria/rep-inversionistas/rep-inversionistas.component';
import { TableProduccionComponent } from './site/dashboard/table-produccion/table-produccion.component';
import { MantUsuarioComponent } from './site/mantenimiento/mant-usuario/mant-usuario.component';
import { MantAsignarItemsComponent } from './site/mantenimiento/mant-asignar-items/mant-asignar-items.component';
import { ConsInversionistasComponent } from './site/consultas/cons-inversionistas/cons-inversionistas.component';
import { DetalleConsInversionistasComponent } from './site/consultas/cons-inversionistas/detalle-rep-inversionistas/detalle-cons-inversionistas.component';
import { FondoInversionResolve } from './resolve/fondoInversion.resolve';
import { retDetalleComponent } from './site/solicitudes/retiro/ret-detalle/ret-detalle.component';
import { RetRescateComponent } from './site/solicitudes/retiro/ret-detalle/ret-rescate/ret-rescate.component';
import { RetDocumentosHabilitantesComponent } from './site/solicitudes/retiro/ret-detalle/ret-documentos-habilitantes/ret-documentos-habilitantes.component';
import { RepReferidoresComponent } from './site/reporteria/rep-referidores/rep-referidores.component';
import { RepSolicitudComponent } from './site/reporteria/rep-solicitud/rep-solicitud.component';
import { RepNotificacionesComponent } from './site/reporteria/rep-notificaciones/rep-notificaciones.component';
import { RepHistorialDocumentosComponent } from './site/reporteria/rep-historial-documentos/rep-historial-documentos.component';
import { RepCostosAsociadosComponent } from './site/reporteria/rep-costos-asociados/rep-costos-asociados.component';
import { NuevoBeneficioInvierteComponent } from './site/beneficios/nuevo-beneficio-invierte/nuevo-beneficio-invierte.component';
import { RetDatosGeneralesComponent } from './site/solicitudes/retiro/ret-detalle/ret-datos-generales/ret-datos-generales.component';
import { RetDatosContactoComponent } from './site/solicitudes/retiro/ret-detalle/ret-datos-contacto/ret-datos-contacto.component';
import { RetDocumentosComponent } from './site/solicitudes/retiro/ret-detalle/ret-documentos/ret-documentos.component';
import { RetSituacionFinancieraComponent } from './site/solicitudes/retiro/ret-detalle/ret-situacion-financiera/ret-situacion-financiera.component';
import { ModalsComponent } from './site/modals/modals.component';
import { HomUnoComponent } from './site/homologacion/hom-uno/hom-uno.component';
import { HomDosComponent } from './site/homologacion/hom-dos/hom-dos.component';
import { DetalleFondoComponent } from './site/configuraciones/fondo-inversion/detalle-fondo/detalle-fondo.component';
import { MessageComponent } from './util/messageError.component';
import { RetornoBancoComponent } from './site/ajustes/retorno-banco/retorno-banco.component';
import { ProcDebitoComponent } from './site/proceso/proc-debito/proc-debito.component';
import { ProcTransferenciaComponent } from './site/proceso/proc-transferencia/proc-transferencia.component';
import { CuentaBancariaComponent } from './site/configuraciones/cuenta-bancaria/cuenta-bancaria.component';
import { DocumentosHabilitantesClienteComponent } from './site/solicitudes/inversion/inv-detalle/documentos-habilitantes-cliente/documentos-habilitantes-cliente.component';
import { CuentaBancariaPersonaComponent } from './site/solicitudes/inversion/inv-detalle/cuenta-bancaria-persona/cuenta-bancaria-persona.component';
import { MantSeguridadComponent } from './site/mantenimiento/mant-seguridad/mant-seguridad.component';
import { ConfDispositivosComponent } from './site/mi-cuenta/cuenta-configuracion/conf-dispositivos/conf-dispositivos.component';
import { CuentaConfiguracionComponent } from './site/mi-cuenta/cuenta-configuracion/cuenta-configuracion.component';
import { RepDetalleFondoComponent } from './site/reporteria/rep-detalle-fondo/rep-detalle-fondo.component';
import { RepSolicitudesIntentoComponent } from './site/reporteria/rep-solicitudes-intento/rep-solicitudes-intento.component';
import { AlertsMsgComponent } from './util/alerts/alerts-msg.component';
import { RepProduccionRetirosComponent } from './site/reporteria/rep-produccion-retiros/rep-produccion-retiros.component';
import { RepProduccionInversionesComponent } from './site/reporteria/rep-produccion-inversiones/rep-produccion-inversiones.component';
import { RepInversionistasDosComponent } from './site/reporteria/rep-inversionistas-dos/rep-inversionistas-dos.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    ModalsComponent,
    RepProduccionRetirosComponent,
    RepProduccionInversionesComponent,
    RepSolicitudesIntentoComponent,
    RepInversionistasDosComponent,
    DispositivoComponent,
    CuentaConfiguracionComponent,
    ConfDispositivosComponent,
    RetSituacionFinancieraComponent,
    ProcTransferenciaComponent,
    ProcDebitoComponent,
    RetornoBancoComponent,
    RetDocumentosComponent,
    RetDatosContactoComponent,
    RetDatosGeneralesComponent,
    NuevoBeneficioInvierteComponent,
    RepSolicitudComponent,
    RepCostosAsociadosComponent,
    RepNotificacionesComponent,
    RepHistorialDocumentosComponent,
    RepDetalleFondoComponent,
    RepReferidoresComponent,
    RetDocumentosHabilitantesComponent,
    retDetalleComponent,
    RetRescateComponent,
    DetalleConsInversionistasComponent,
    ConsInversionistasComponent,
    MantAsignarItemsComponent,
    MantUsuarioComponent,
    RepInversionistasComponent,
    RepBeneficiosComponent,
    RepBonificacionesComponent,
    AjRendimientosComponent,
    RegistroEmisoresComponent,
    UppercaseDirective,
    AlphabetOnlyDirective,
    NumberDirective,
    LoginComponent,
    AuthComponent,
    AppComponent,
    HomeComponent,
    InversionComponent,
    RetiroComponent,
    DebitoComponent,
    TransferenciaComponent,
    FooterComponent,
    NavigationComponent,
    TopnavbarComponent,
    DashboardComponent,
    DatosGeneralesComponent,
    LugarTrabajoComponent,
    InversionistaComponent,
    DatosContactoComponent,
    DocumentosComponent,
    SituacionFinancieraComponent,
    AccionistaEmpresaComponent,
    PepsComponent,
    DocumentosHabilitantesComponent,
    CuentaBancariaPersonaComponent,
    DocumentosHabilitantesClienteComponent,
    InvDetalleComponent,
    CrearGruposComponent,
    ImportarDocumentosComponent,
    MantAsignarRolesComponent,
    CambiarClaveComponent,
    CuentasFinancierasComponent,
    FondoInversionComponent,
    FiDocumentosComponent,
    FiFirmaElectronicaComponent,
    NuevoBeneficioComponent,
    NuevaBonificacionComponent,
    NuevaNotificacionComponent,
    MantImpuestoComponent,
    RendimientosSemanalesComponent,
    BeneficiarioComponent,
    InventarioRespuestaComponent,
    RendimientosMensualesComponent,
    ConsultaEstadoCuentaComponent,
    ConsultaMovimientosComponent,
    MantAdministradoraComponent,
    MantFinancieraComponent,
    MantCatalogoComponent,
    MantGeolocalizacionComponent,
    MantSubMenuComponent,
    MantPoolComponent,
    MantDetalleCatalogoComponent,
    VerificadoComponent,
    ChartFondoComponent,
    ChartInversionesComponent,
    ConsultaBonificacionesComponent,
    ConsultaBeneficiosComponent,
    ConsultaSolicitudesDebitoFallidasComponent,
    ConsultaSolicitudesTransferenciaFallidasComponent,
    InvObservacionesComponent,
    MantCostoFinancieroComponent,
    MantCostoAdministrativoComponent,
    UsuarioAdministradoraComponent,
    MantFondoInversionComponent,
    AjCostoAdministrativoComponent,
    WorkingComponent,
    MantPreguntasComponent,
    MantPermisosServiciosComponent,
    MantMenuComponent,
    MantNotificacionesComponent,
    FondosAprobadosComponent,
    MantTipoUsuarioComponent,
    AsignarItemsComponent,
    RepRendimientosComponent,
    InversionistaFondoComponent,
    TableProduccionComponent,
    HomUnoComponent,
    HomDosComponent,
    DetalleFondoComponent,
    CuentaBancariaComponent,
    MantSeguridadComponent,
    AlertsMsgComponent
  ],
  imports: [
    AppRoutingModule,
    NgSelectModule,
    BreadcrumbsModule,
    LaddaModule, 
    BrowserAnimationsModule,
    AppBootstrapModule,
    BrowserModule,
    MatSidenavModule,
    MatToolbarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // RouterModule.forRoot(routes,{ onSameUrlNavigation: 'reload',useHash: true}),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports:[BrowserAnimationsModule],
  providers: [
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    MessageComponent,
    DatePipe,
    AuthGuard,
    IpResolve,
    AppLoadService,
    BloqueResolve,FondoInversionResolve,FinancieraResolve,
    {
      provide : HTTP_INTERCEPTORS,
      useClass : AuthInterceptor,
      multi : true
    },
    PrioryResolve
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  
}
//Idioma de Paginador
export function getDutchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = 'Items por página:';
  paginatorIntl.nextPageLabel = 'Sig. página';
  paginatorIntl.previousPageLabel = 'Ant. página';
  paginatorIntl.lastPageLabel = 'Ult. página';
  paginatorIntl.firstPageLabel = 'Prim. página';
  paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 de ${length}`; }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
  
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  };
  return paginatorIntl;
}