import { Data } from './z.model';

export class RendimientoSemanal {
    rendimiento_id:number;
    fondoinversion_id:number;
    nombrefondo:string;
    rendimientosemanal:string;
    fecharendimiento:Date;
    semana: number;
    constructor(r:RendimientoSemanal=null){
        if(r!=null){
            this.rendimiento_id = r.rendimiento_id;
            this.fondoinversion_id = r.fondoinversion_id;
            this.nombrefondo = r.nombrefondo;
            this.rendimientosemanal = r.rendimientosemanal;
            this.fecharendimiento = new Date(r.fecharendimiento['date']);
            this.semana = r.semana;
        }
    }
    public static getAll(){
        return Data._getBody("C",156,sessionStorage.getItem("token_fks"));
    }
    public static post(f:RendimientoSemanal){
        var tmp = Data._getBody("I",157,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = f.fondoinversion_id;
        tmp["data"]["rendimientosemanal"] = f.rendimientosemanal;
        return tmp;
    }   
    public static delete(id){
        var tmp = Data._getBody("D",158,sessionStorage.getItem("token_fks"));
        tmp["data"]["rendimiento_id"] = id;
        return tmp;
    }
}