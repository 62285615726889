import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatTableDataSource } from '@angular/material/table';
import { GeneralService } from 'src/app/service/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { RendimientoSemanal } from 'src/app/model/rendimientoSemanal.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { Data } from 'src/app/model/z.model';

@Component({
  selector: 'app-aj-rendimientos',
  templateUrl: './aj-rendimientos.component.html',
  styleUrls: ['./aj-rendimientos.component.css']
})
export class AjRendimientosComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['fondo','rendimiento','semana','fecha','acciones'];
  rendimientoSelected: RendimientoSemanal;
  listRendimiento: RendimientoSemanal[]=[];
  paramBusquedaGroup:FormGroup;
  modalRef: BsModalRef;
  isCollapsed = true;
  isLoading : boolean = false;
  isLoading2 : boolean = true;
  tipoFondos:FondoInversion[] = [];
  fech:Date= new Date();
  constructor(private generalService:GeneralService,private _snackBar: MatSnackBar,private modalService: BsModalService,private _builder: FormBuilder) {
    this.clearData();
  }
  clearData(){
    this.rendimientoSelected = new RendimientoSemanal();
    this.paramBusquedaGroup = this._builder.group({
      fondoinversion_id: ['',Validators.required],
      rendimientosemanal:['',Validators.required]
    });
  }
  delCosto(){
    this.isLoading = !this.isLoading;
    this.generalService.useService(RendimientoSemanal.delete(this.rendimientoSelected.rendimiento_id)).subscribe(
      (data : any)=>{
        console.log(data);
        this.resultRequest(data.datos)
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  viewCosto(f:RendimientoSemanal,ban:boolean){
    this.isCollapsed = false;
    this.rendimientoSelected = f;
    this.paramBusquedaGroup.patchValue(this.rendimientoSelected);
    if(ban)
      this.paramBusquedaGroup.disable();
    else
      this.paramBusquedaGroup.enable();
  }
  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.getAllRendimientos()
  }
  openModal(template: TemplateRef<any>,f:RendimientoSemanal) {
    this.rendimientoSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }

  save(form){
    this.isLoading = !this.isLoading;
    this.generalService.useService(RendimientoSemanal.post(form)).subscribe(
      (data : any)=>{
        console.log(data);
        this.resultRequest(data.datos);
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }

  resultRequest(datos){
    if(datos == null){
      this.openSnackBar("Operacion no realizada","ERROR");
    }else{
      if(datos[0].status == "OK"){
        this.openSnackBar("Operacion realizada con éxito","INFORMACIÓN");
        this.clearData();
        this.getAllRendimientos();
      }
    }
    this.isCollapsed = !this.isCollapsed;
    this.isLoading = !this.isLoading;
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
  
  getAllRendimientos(){
    this.listRendimiento = [];
    this.generalService.useService(RendimientoSemanal.getAll()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listRendimiento.push(new RendimientoSemanal(data.datos[t] as RendimientoSemanal));
        }
        this.dataSource = new MatTableDataSource<RendimientoSemanal>(this.listRendimiento);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}
