import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoUsuario } from 'src/app/model/tipoUsuario.model';
import { GrupoUsuario } from 'src/app/model/grupoUsuario.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator/typings/paginator';
import { Data } from 'src/app/model/z.model';
import { ModalDirective } from 'ngx-bootstrap/modal/modal.directive';

@Component({
  selector: 'app-mant-seguridad',
  templateUrl: './mant-seguridad.component.html',
  styleUrls: ['./mant-seguridad.component.css']
})
export class MantSeguridadComponent implements OnInit {
  listTipoUsuario: TipoUsuario[];
  listGrupoUsuario: GrupoUsuario[];
  formTipo:FormGroup;
  formGrupo:FormGroup;
  isLoadingGrupo:boolean;
  isLoadingTipo:boolean;
  isSaveTipo:boolean;
  isSaveGrupo:boolean;
  isCollapseGrupo:boolean = true;
  isCollapseTipo:boolean = true;
  isDeleting:boolean;
  dataSourceGrupo;
  dataSourceTipo;
  @ViewChild("paginatorTipo", {static: true}) paginatorTipo: MatPaginator;
  @ViewChild("paginatorGrupo", {static: true}) paginatorGrupo: MatPaginator;
  displayedColumns: string[] = ['nombre','acciones'];
  @ViewChild('modalDeleteGrupo', { static: false }) modalDeleteGrupo: ModalDirective;
  @ViewChild('modalDeleteTipo', { static: false }) modalDeleteTipo: ModalDirective;
  constructor(private generalService:GeneralService,private _builder: FormBuilder) {
    this.formTipo = this._builder.group({
      tipousuario_id: [null],
      tipousuario: ['',[Validators.required]]
    });
    this.formGrupo = this._builder.group({
      grupo_id:[null],
      descripcion: ['',[Validators.required]]
    });
  }

  ngOnInit() {
    this.getAllGrupoUsuario();
    this.getallTipoUsuario();
  }
  clear(){
    this.isCollapseGrupo = true;
    this.isCollapseTipo = true;
    this.formGrupo.enable();
    this.formTipo.enable();
    this.formTipo.reset({
      tipousuario_id: null,
      nombre: ''
    });
    this.formGrupo.reset({
      grupo_id:null,
      nombre:''
    });
  }
  getallTipoUsuario(){
    this.isLoadingTipo = true;
    this.listTipoUsuario = [];
    this.dataSourceTipo = new MatTableDataSource(this.listTipoUsuario);
    this.dataSourceTipo.paginator = this.paginatorTipo;
    this.generalService.useService(TipoUsuario.getAll()).subscribe(
      (data : any)=>{
        this.isLoadingTipo = false;
        for(let t in data.datos){
          this.listTipoUsuario.push(new TipoUsuario({tipousuario_id:Number(t),tipousuario:data.datos[t].tipousuario}))
        }
        this.dataSourceTipo = new MatTableDataSource(this.listTipoUsuario);
        this.dataSourceTipo.paginator = this.paginatorTipo;
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  saveTipo(form){
    this.isSaveTipo = true;
    this.generalService.useService(TipoUsuario.post(form)).subscribe(
      (data : any)=>{
        if(Data.isOk(data)){
          this.getallTipoUsuario();
          this.clear();
        }
        this.isSaveTipo=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  saveGrupo(form){
    this.isSaveGrupo=true;
    this.generalService.useService(GrupoUsuario.post(form)).subscribe(
      (data : any)=>{
        if(Data.isOk(data)){
          this.getAllGrupoUsuario();
          this.clear();
        }
        this.isSaveGrupo=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  viewTipo(obj:TipoUsuario,isEdit:boolean){
    this.isCollapseTipo = false;
    this.formTipo.patchValue(obj);
    if(!isEdit){
      this.formTipo.disable();
    }else{
      this.formTipo.enable();
    }
  }
  viewGrupo(obj:GrupoUsuario,isEdit:boolean){
    this.isCollapseGrupo = false;
    this.formGrupo.patchValue(obj);
    if(!isEdit){
      this.formGrupo.disable();
    }else{
      this.formGrupo.enable();
    }
  }
  getAllGrupoUsuario(){
    this.isLoadingGrupo = true;
    this.listGrupoUsuario = [];
    this.dataSourceGrupo = new MatTableDataSource(this.listGrupoUsuario);
    this.dataSourceGrupo.paginator = this.paginatorGrupo;
    this.generalService.useService(GrupoUsuario.getAll()).subscribe(
      (data : any)=>{
        this.isLoadingGrupo = false;
        for (let t in data.datos) {
          this.listGrupoUsuario.push(new GrupoUsuario(data.datos[t] as GrupoUsuario));
        }
        this.dataSourceGrupo = new MatTableDataSource(this.listGrupoUsuario);
        this.dataSourceGrupo.paginator = this.paginatorGrupo;
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  deleteGrupo(){
    this.isDeleting = true;
    this.generalService.useService(GrupoUsuario.delete(this.formGrupo.value.grupo_id)).subscribe(
      (data : any)=>{
        if(Data.isOk(data)){
          this.getAllGrupoUsuario();
          this.modalDeleteGrupo.hide();
        }
        this.isDeleting = false;
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  deleteTipo(){
    this.isDeleting = true;
    this.generalService.useService(TipoUsuario.delete(this.formTipo.value.tipousuario_id)).subscribe(
      (data : any)=>{
        if(Data.isOk(data)){
          this.getallTipoUsuario();
          this.modalDeleteTipo.hide();
        }
        this.isDeleting = false;
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
}
