import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-fondo-inversion',
  templateUrl: './fondo-inversion.component.html',
  styleUrls: ['./fondo-inversion.component.css']
})
export class FondoInversionComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['unidades','valor','fecha','acciones'];
  tipoFondos: FondoInversion[] = [];
  fondoSelected:FondoInversion = new FondoInversion();
  
  formFondo:FormGroup;
  dataSource;
  idFondoSelected:number;
  constructor(private router : Router, private route: ActivatedRoute,private _builder: FormBuilder) {
    this.formFondo = this._builder.group({
      fondoInversion: ['']
    });
  }

  ngOnInit() {
    this.tipoFondos=Data._fondosInversion;
    if(this.route.firstChild){
      this.route.firstChild.params.subscribe(
        (params) => {
          console.log(params);
          this.formFondo.patchValue({fondoInversion:params.id});
          console.log(this.formFondo.value)
        }
      );
    }
    this.onChanges();
  }

  onChanges(): void {
    this.formFondo.valueChanges.subscribe(val => {
      if(val.fondoInversion != "")
        this.router.navigate(['/home/configuraciones/fondoinversion',val.fondoInversion]);
      else{
        this.router.navigate(['/home/configuraciones/fondoinversion']);
      }
    });
  } 
}
