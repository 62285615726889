import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import {catchError, tap, timeout} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService } from "../util/alerts/alert.service";

@Injectable()
export class AuthInterceptor  implements HttpInterceptor {
    constructor(private alertService:AlertService, private router: Router) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (sessionStorage.getItem('token_fks') != null && sessionStorage.getItem('inf1') && sessionStorage.getItem('inf2')) {
            const clonedreq = req.clone({
                // headers: req.headers.set("Authorization", "Bearer " + sessionStorage.getItem('token_fks'))
            });
            return next.handle(clonedreq).pipe(
                // timeout(60000),
                tap((ev: HttpEvent<any>) => {
                    if (ev instanceof HttpResponse) 
                        if(ev.body!=null){
                            if(ev.body.datos != null)
                                if(ev.body.datos[0])
                                    if(ev.body.datos[0].codigo == 105){
                                        this.alertService.error(ev.body.datos[0].mensaje);
                                        sessionStorage.clear();
                                        this.router.navigateByUrl('/login');
                                    }
                        }                            
                }),
                catchError( (error) => {
                    if (error.status === 401)
                        this.router.navigateByUrl('/login');
                    return throwError(error);
                }));
        }else
            this.router.navigateByUrl('/login');
    }
}