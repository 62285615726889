export class DatosContacto {
    personacontacto_id:Number;
    persona_id:Number;
    operadora_id:Number;
    celular:string;
    sitioweb:string;
    correo_alternativo:string;
    estado:string;
    telefono:string;
    constructor(dc:DatosContacto = null){
        if(dc!=null){
            this.personacontacto_id = dc.personacontacto_id;
            this.persona_id = dc.persona_id;
            this.operadora_id = dc.operadora_id;
            this.celular = atob(dc.celular);
            this.sitioweb = atob(dc.sitioweb);
            this.correo_alternativo = atob(dc.correo_alternativo);
            this.estado = dc.estado;
            if(atob(dc.telefono)=="00")
                this.telefono = "NINGUNO";
            else
                this.telefono = atob(dc.telefono);
        }
    }
}