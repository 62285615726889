import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/service/general.service';
import { Usuario } from 'src/app/model/usuario.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cambiar-clave',
  templateUrl: './cambiar-clave.component.html',
  styleUrls: ['./cambiar-clave.component.css']
})
export class CambiarClaveComponent implements OnInit {
  paramBusquedaGroup:FormGroup;
  isLoading:boolean;
  constructor(private router: Router,private _snackBar: MatSnackBar,private _builder: FormBuilder,private generalService:GeneralService) { }

  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      claveAnterior: ['',[Validators.required]],
      claveNueva:['',[Validators.required]],
      confirmarClave:['',[Validators.required]]
    });
  }

  find(form){
    if(form.claveNueva === form.confirmarClave){
      this.isLoading = true;
      this.generalService.useService(Usuario.changePassUs(form.claveAnterior,form.claveNueva)).subscribe(
        (data : any)=>{
          this.isLoading = false;
          if(data.datos.status=="error"){
            this.openSnackBar(data.datos.mensaje,"ERROR");
          }else{
            this.openSnackBar("Se realizó el cambio de contraseña.","BIEN");
            this.logout();
          }
        },
        (err : HttpErrorResponse)=>{
          console.log(err);
        }
      );
    }else{
      this.openSnackBar("Clave actual no coinciden.","ERROR");
    }
  }
  logout(){
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
}
