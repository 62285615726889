import { Data } from './z.model';

export class CuentaRegistrada {
    administradora_cuenta_id:Number;
    financiera_id:number;
    financiera_nombre:string;
    numero_cuenta:string;
    tipocuenta_id:number;
    tipocuenta_nombre:string;
    constructor(sol:CuentaRegistrada){
        this.administradora_cuenta_id=sol.administradora_cuenta_id;
        this.financiera_id=sol.financiera_id;
        this.numero_cuenta=atob(sol.numero_cuenta);
        this.tipocuenta_id=sol.tipocuenta_id;
        this.financiera_nombre = Data._findFinanciera(sol.financiera_id).Nombre;
        this.tipocuenta_nombre = Data._fCatalogo(sol.tipocuenta_id,"TIPO DE CUENTA");
    }
    public static post(c:any){
        var tmp = Data._getBody("I2",35,sessionStorage.getItem("token_fks"));
        tmp["data"]["tipocuenta"] = c.tipocuenta;
        tmp["data"]["financiera"] = c.financiera;
        tmp["data"]["numero"] = c.numero;
        tmp["data"]["descripcion"] = c.descripcion;
        return tmp;
    }
    public static getAll(){
        return Data._getBody("C10",35,sessionStorage.getItem("token_fks"));
    }
    public static listarCuentaFondo(){
        var tmp = Data._getBody("C11",35,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = 0;
        return tmp;
    }
    public static asignarCuentaFondo(f:any){
        var tmp = Data._getBody("I3",35,sessionStorage.getItem("token_fks"));
        if(f.fondoinversion_cuenta_id != null){
          tmp = Data._getBody("U3",35,sessionStorage.getItem("token_fks"));
          tmp["data"]["fondoinversion_cuenta_id"] = f.fondoinversion_cuenta_id;
        }
        tmp["data"]["administradora_cuenta_id"] = f.administradora_cuenta_id;
        tmp["data"]["fondoinversion_id"] = f.fondoinversion_id;
        return tmp;
    }
    public static eliminarCuentaFondo(id:number){
        var tmp = Data._getBody("D3",35,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_cuenta_id"] = id;
        return tmp;
    }
}