import { Injectable } from "@angular/core";  
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { GeneralService } from '../service/general.service';
import { BloqueMenu } from '../model/bloqueMenu.model';
import { TipoUsuario } from '../model/tipoUsuario.model';
import { Financiera } from '../model/financiera.model';
import { Data } from '../model/z.model';

@Injectable()  
export class FinancieraResolve implements Resolve<any>{
  constructor(private postService: GeneralService) {}  
  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.postService.useService(Financiera.getAll()).toPromise();
  }
}