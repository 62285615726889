import { FondoInversion } from '../fondoInversion.model';
import { Persona } from '../persona/persona.model';
import { Data } from '../z.model';

export class SolicitudInversion {
    solicitud_inversion_id:number;
    fondoinversion_id: number;
    persona_id:number;
    nombres:string;
    apellidos:string;
    personacuenta_id:number;
    transaccion_id: string;
    aporte:number;
    incrementoopcional:string;
    incremento:string;
    id_periocidad:string;
    concepto: string;
    estado:string;
    estado_nombre:string;
    estado_color:string;
    fecha_registro:Date = new Date;
    fecha_actualiza:Date = new Date;
    nombrefondo:string;
    identificacion: string;
    fondoInversion:FondoInversion;
    beneficio_fondoinversion_id:Number;
    acumulado:string;
    propositoinversion_id:Number;
    ruc:string;
    razon_social:string;
    nombre_comercial:string;
    contratos:any = {docterminos:"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",docdebito:"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",doccontrato:"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"}
    persona:Persona;
    usuario_acepta:number;
    constructor(si:SolicitudInversion=null){
        if(si!=null){
            this.solicitud_inversion_id = si.solicitud_inversion_id;
            this.fondoinversion_id = si.fondoinversion_id;
            this.nombrefondo = si.nombrefondo;
            this.persona_id = si.persona_id;
            this.nombres = si.nombres;
            this.apellidos = si.apellidos;
            this.personacuenta_id = si.personacuenta_id;
            this.transaccion_id = si.transaccion_id;
            this.aporte = si.aporte;
            this.incrementoopcional = si.incrementoopcional;
            this.incremento = si.incremento;
            this.id_periocidad = si.id_periocidad;
            this.concepto = si.concepto;
            this.identificacion = si.identificacion;
            this.usuario_acepta = si.usuario_acepta;
            this.estado = si.estado;
            if(si.estado == 'A'){
                this.estado_nombre = "APROBADO";
                this.estado_color = "success";
            }else if(si.estado == 'P'){
                this.estado_nombre = "PENDIENTE";
                this.estado_color = "warning";
            }else if(si.estado == 'C'){
                this.estado_nombre = "CANCELADO";
                this.estado_color = "danger";
            }else if(si.estado == 'R'){
                this.estado_nombre = "REVISION ESPECIAL";
                this.estado_color = "primary";
            }else if(si.estado == 'O'){
                this.estado_nombre = "OBSERVADO";
                this.estado_color = "secondary";
            }
            this.fecha_registro = new Date(si.fecha_registro['date']);
            if(si.fecha_actualiza != null){
                this.fecha_actualiza = new Date(si.fecha_actualiza['date']);
            }
            for(let i in si.contratos){
                for(let j in si.contratos[i]){
                    this.contratos[j] = Data._trim(si.contratos[i][j]);
                }
            }
        }
    }
    //Entrega todas las solicitudes aceptadas y no aceptadas.
    public static getAll() {
        return Data._getBody("C2",58,sessionStorage.getItem("token_fks"));
    }
    //Entrega
    public static getAllByAceptadas() {
        return Data._getBody("C6",58,sessionStorage.getItem("token_fks"));
    }
    public static getInversionValida(persona_id) {
        var tmp = Data._getBody("C7",58,sessionStorage.getItem("token_fks"));
        tmp["data"]["persona_id"] = persona_id;
        return tmp;
    }
    public static getById(idSolicitud:number) {
        var tmp = Data._getBody("C3",58,sessionStorage.getItem("token_fks"));
        tmp["data"]["solicitud_inversion_id"] = idSolicitud;
        return tmp;
    }
    public static updateEstadoById(idSolicitud:number,estado:string) {
        var tmp = Data._getBody("U",59,sessionStorage.getItem("token_fks"));
        tmp["data"]["solicitud_inversion_id"] = idSolicitud;
        tmp["data"]["estado"] = estado;
        return tmp;
    }
}