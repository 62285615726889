import { Component, OnInit, ViewChild, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-mant-detalle-catalogo',
  templateUrl: './mant-detalle-catalogo.component.html',
  styleUrls: ['./mant-detalle-catalogo.component.css']
})
export class MantDetalleCatalogoComponent implements OnInit {
  isLoading:boolean = false;
  isLoading2:boolean;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('detalleCatalogo', {static: true}) modalAddDet: any;
  dataSource;
  displayedColumns: string[] = ['id','detalleCatalogo','acciones'];
  _lcat:number;
  @Input() set lcat(lcat:number){
    this.findAllDetCatalogo(lcat);
    this._lcat = lcat;
  }
  @Input() set newDet(ban:boolean){
    if(ban==true){
      this.openModal(this.modalAddDet,false);
      console.log("abrir modal");
    }
  }
  @Output() newDetChange:EventEmitter<boolean> = new EventEmitter<boolean>();
  modalRef: BsModalRef;
  detalleCatalogoForm:FormGroup;
  hiddenButton:boolean;
  detCatalogoSelected:DetalleCatalogo;
  listDetCatalogo:DetalleCatalogo[] = [];
  constructor(private _builder: FormBuilder,private modalService: BsModalService,private generalService:GeneralService) { }

  ngOnInit() {
    this.detalleCatalogoForm = this._builder.group({
      detallecatalogo_id:[null],
      descripcion: ['',Validators.required],
      etiqueta:['',Validators.required],
      orden:['',Validators.required],
      valor:['',Validators.required]
    });
  }
  openModal(template: TemplateRef<any>,hiddenButton:boolean) {
    if(hiddenButton==true)
      this.detalleCatalogoForm.disable();
    else
      this.detalleCatalogoForm.enable();
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }
  updateDetCatalogo(form){
    this.isLoading = !this.isLoading;
    form.catalogo_id = this._lcat;
    this.newDetChange.emit(false);
    this.generalService.useService(DetalleCatalogo.post(form)).subscribe(
      (data : any)=>{
        console.log(data);
        this.findAllDetCatalogo(this._lcat);
        this.modalRef.hide();
        this.isLoading = !this.isLoading;
      },
      (err : HttpErrorResponse)=>{
        console.log(err)
      }
    );
  }
  findAllDetCatalogo(id:number){
    this.isLoading2 = true;
    this.listDetCatalogo = [];
    this.dataSource = new MatTableDataSource<DetalleCatalogo>(this.listDetCatalogo);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(DetalleCatalogo.getAll(id)).subscribe(
      (data : any)=>{
        for (let j in data.datos) {
          let tempDet:DetalleCatalogo = new DetalleCatalogo(data.datos[j] as DetalleCatalogo);
          tempDet.detallecatalogo_id = Number(j);
          this.listDetCatalogo.push(tempDet);
        }
        this.dataSource = new MatTableDataSource<DetalleCatalogo>(this.listDetCatalogo);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  delDetCatalogo(){
    this.isLoading = !this.isLoading;
    this.generalService.useService(DetalleCatalogo.delete(this.detCatalogoSelected.detallecatalogo_id)).subscribe(
      (data : any)=>{
        this.isLoading = !this.isLoading;
        this.findAllDetCatalogo(this._lcat);
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}