import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { BsLocaleService, BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { Rendimiento } from 'src/app/model/rendimiento.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-consulta-inversionista',
  templateUrl: './consulta-inversionista.component.html',
  styleUrls: ['./consulta-inversionista.component.css']
})
export class InversionistaComponent implements OnInit {

  //CATALOGOS
  tipoFondos: FondoInversion[] = [];
  tipoIdentificaciones: DetalleCatalogo[] = [];
  bsConfig: Partial<BsDatepickerConfig>;
  today:Date = new Date();
  dateas:Date;
  paramBusquedaGroup:FormGroup;
  listRendimiento: Rendimiento[]=[];
  isLoading2:boolean = true;
  minDateYear:Date = new Date(2020,1,1);
  maxDateYear:Date = new Date();
  minDate:Date = new Date(this.maxDateYear.getFullYear()-1,12,31);
  maxDate:Date = new Date(this.maxDateYear.getFullYear(),11,30);
  constructor(private datePipe: DatePipe, private generalService:GeneralService,private localeService: BsLocaleService,private _builder: FormBuilder) {
    this.localeService.use('es');
  }
  
  fillComboBox(){
    this.tipoIdentificaciones = Data._fListCatalogo("TIPO DE IDENTIFICACION PERSONA");
  }
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.find(this.paramBusquedaGroup.value);
    });
  }
  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.fillComboBox();
    this.paramBusquedaGroup = this._builder.group({
      semana: [''],
      anio: [''],
      documento:['']
    });
    this.getAllRendimientos(); 
    this.onChanges();
  }
  find(form:any){
    console.log(form);
    var temp = this.listRendimiento;
    if(form.documento!=""){
      temp = temp.filter(function(el) {
        return el.identificacion.indexOf(form.documento) > -1});
    }
    if(form.semana!=null){
      if(form.semana!="")
        temp=temp.filter(d => (d.semana.toString() == this.datePipe.transform(form.semana,"w")));
    }
    if(form.anio!=null){
      if(form.anio!="")
        temp=temp.filter(d => (d.anio == form.anio.getFullYear()));
    }
    this.configTable(temp);
  }
  configTable(data){
    console.log(data);
    for(let y of this.tipoFondos){
      y.anything = [];
    }
    for (let t of data) {
      for(let y of this.tipoFondos){
        if(y.fondoinversion_id == t.fondoinversion_id){
          y.anything.push(t);
          break;
        }
      }
    }
  }
  getAllRendimientos(){
    this.listRendimiento = [];
    for(let y of this.tipoFondos){
      y.anything = [];
    }
    this.generalService.useService(Rendimiento.getAll()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listRendimiento.push(new Rendimiento(data.datos[t] as Rendimiento));
          for(let y of this.tipoFondos){
            var rend = new Rendimiento(data.datos[t] as Rendimiento);
            if(y.fondoinversion_id == rend.fondoinversion_id){
              y.anything.push(rend);
              break;
            }
          }
        }
        console.log(this.listRendimiento);
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}
