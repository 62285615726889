import { Component, OnInit, Input, ViewChild, IterableDiffers, DoCheck, IterableDiffer } from '@angular/core';
import { Rendimiento } from 'src/app/model/rendimiento.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-inversionista-fondo',
  templateUrl: './inversionista-fondo.component.html',
  styleUrls: ['./inversionista-fondo.component.css']
})
export class InversionistaFondoComponent implements OnInit, DoCheck{
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  isLoading2:boolean = true;
  displayedColumns: string[] = ['identificacion','fondoInversion','credito','debito','fecha'];
  iterableDiffer: IterableDiffer<any>;
  @Input() listInversionistas;
  constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit() {
  }
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.listInversionistas);
    if (changes) {
      this.dataSource = new MatTableDataSource<Rendimiento>(this.listInversionistas);
      this.dataSource.paginator = this.paginator;
      this.isLoading2 = false;
    }
}
}
