import { Data } from '../z.model';

export class ReportFuncionario {
    nombre:string;
    produccion:any[] = [];
    atendidos:number;
    aprobados:number;
    cancelados:number;
    observados:number;
    pendientes:number;
    produccionEfectiva:number;
    total:number;
    constructor(b:ReportFuncionario = null){
        if(b != null){
            var atendido = 0;
            var aprobado = 0;
            var observado = 0;
            var cancelado = 0;
            var pend = 0;
            var prod = 0;
            this.nombre=b.nombre;
            for(let i in b.produccion){
                atendido = atendido + b.produccion[i].atendidas_cartera;
                aprobado = aprobado + b.produccion[i].solicitudes_A;
                observado = observado + b.produccion[i].solicitudes_O;
                cancelado = cancelado + b.produccion[i].solicitudes_C;
                pend = pend + b.produccion[i].pendientes;
                prod = prod + b.produccion[i].produccion_efectiva;
                this.produccion.push(b.produccion[i])
            }
            this.atendidos = cancelado + aprobado + observado;
            //this.atendidos = atendido;
            this.aprobados = aprobado;
            this.observados = observado;
            this.cancelados = cancelado;
            this.pendientes = pend;
            this.produccionEfectiva = prod;
            this.total = pend + this.atendidos;
        }
    }
    public static getAll() {
        var tmp = Data._getBody("C11",156,sessionStorage.getItem("token_fks"));
        return tmp;
    }
    public static getAllRetiro(){
        var tmp = Data._getBody ("C12",156,sessionStorage.getItem("token_fks"));
        return tmp;
    }
}