import { Data } from './z.model';

export class PermisoGrupoBloque {
    grupo_bloque_id: number;
    bloque_id: number;
    nombre_bloque: string;
    grupo_id: number;
    descripcion: string;
    constructor(i:PermisoGrupoBloque = null){
        if(i != null){
            this.grupo_bloque_id = i.grupo_bloque_id;
            this.bloque_id = i.bloque_id;
            this.nombre_bloque = i.nombre_bloque;
            this.grupo_id = i.grupo_id;
            this.descripcion = i.descripcion;
        }
    }
    public static getAll(){
        return Data._getBody("C2",148,sessionStorage.getItem("token_fks"));
    }

    public static delete(bloques_id,grupo_id){
        let tmp = Data._getBody("D2",151,sessionStorage.getItem("token_fks"));
        tmp["data"]["bloque_id"] = bloques_id;
        tmp["data"]["grupo_id"] = grupo_id;
        return tmp;
    }
    
    public static post(bloques_id:string, grupo_id:number){
        var tmp = Data._getBody("I2",149,sessionStorage.getItem("token_fks"));
        tmp["data"]["bloque_id"] = bloques_id;
        tmp["data"]["grupo_id"] = grupo_id;
        return tmp;
    }
}