import { IfStmt } from '@angular/compiler';

export class ReferenciaBancaria {
    fondoinversion_id: Number;
    persona_id:Number;
    institucion_financiera:string;
    tarjetacredito_credito:string;
    numero:string;
    saldopromedio_montofecha:string;
    cupo:string;
    fecha_emision:String;
    fecha_vencimiento:String;
    estado:string;
    constructor(rb:ReferenciaBancaria = null){
        if(rb != null){
            this.fondoinversion_id=rb.fondoinversion_id;
            this.persona_id=rb.persona_id;
            this.institucion_financiera=rb.institucion_financiera;
            this.tarjetacredito_credito=rb.tarjetacredito_credito;
            this.numero=atob(rb.numero);
            this.saldopromedio_montofecha=rb.saldopromedio_montofecha;
            if(rb.cupo == ".00")
                this.cupo="NINGUNO";    
            else
                this.cupo=rb.cupo;
            if(rb.fecha_emision.trim()=="")
                this.fecha_emision="NINGUNO";    
            else
                this.fecha_emision=rb.fecha_emision;
            this.fecha_vencimiento=rb.fecha_vencimiento;
            this.estado=rb.estado;
        }
    }
}