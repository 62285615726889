import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Rendimiento } from 'src/app/model/rendimiento.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-rendimientos-semanales',
  templateUrl: './rendimientos-semanales.component.html',
  styleUrls: ['./rendimientos-semanales.component.css']
})
export class RendimientosSemanalesComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['identificacion','fondoInversion','credito','debito','fecha','acciones'];
  listRendimiento: Rendimiento[]=[];
  formFilter:FormGroup;
  tipoFondos: FondoInversion[] = Data._fondosInversion;
  paramBusquedaGroup:FormGroup;
  isCollapsed = true;
  hidden = false;
  isLoading : boolean = false;
  isLoading2:boolean = true;
  constructor(private generalService:GeneralService,private _builder: FormBuilder) { }

  ngOnInit() {
    this.formFilter = this._builder.group({
      Documento: [''],
      FondoInversion:[''],
      rangoFecha:[''],
      Estado:['']
    });
    this.paramBusquedaGroup = this._builder.group({
      codigo:[''],
      fondoInversion: [''],
      identificacion:[''],
      rangoFecha:[''],
      montoInicial:[''],
      montoFinal:[''],
      aporte_inicial:[''],
      aporte_incremental:[''],
      rescate_parcial:[''],
      rescate_total:['']
    });
    this.getAllRendimientos();
  }
  find(form){
    console.log(form);
  }
  save(form){
    console.log(form);
  }
  fileChangeListener(e){
    console.log(e)
  }
  getAllRendimientos(){
    this.listRendimiento = [];
    this.generalService.useService(Rendimiento.getAll()).subscribe(
      (data : any)=>{
        console.log(data);
        for (let t in data.datos) {
          this.listRendimiento.push(new Rendimiento(data.datos[t] as Rendimiento));
        }
        this.dataSource = new MatTableDataSource<Rendimiento>(this.listRendimiento);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}