import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/service/general.service';
import { BloqueMenu } from 'src/app/model/bloqueMenu.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SubMenu } from 'src/app/model/subMenu';
import { Data } from 'src/app/model/z.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  nombres:string;
  tipo:string;
  listMenu:BloqueMenu[]=[];
  modalRef: BsModalRef;
  urlAdministradora:string;
  constructor(private modalService: BsModalService,private router: Router,private generalService: GeneralService) { }
  ngOnInit() {
    this.urlAdministradora = atob(sessionStorage.getItem('inf8'));
    this.getMenu();
    this.nombres = atob(sessionStorage.getItem('inf4')) +' '+atob(sessionStorage.getItem('inf3'));
    this.tipo = atob(sessionStorage.getItem('inf6'));
  }
  logout(){
    sessionStorage.clear();
    this.modalRef.hide();
    this.router.navigate(['/login']);
  }
  getMenu(){
    this.generalService.useService(BloqueMenu.getMenu()).subscribe(
      (data : any)=>{
        if(data && data.datos){
          this.listMenu = Object.values(data.datos).sort((a:any,b:any)=>(a.ordenbloque - b.ordenbloque)).map((t:any)=>{
            if(btoa(t.usuario) == sessionStorage.getItem("inf2") && btoa(t.adm) == sessionStorage.getItem("inf5")){
              let newB = new BloqueMenu();
              newB.setBloqueUsuario(t);
              return newB;
            }
          });;
        }
      },
      (err : HttpErrorResponse)=>{
        console.error("Ocurrio un error inesperado al obtener el menu del usuario.");
      }
    );
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-sm');
  }
  toggle(index: number,url) {
    if(url==null){
      this.listMenu.filter(
        (menu, i) => i !== index && menu.active
      ).forEach(menu => menu.active = !menu.active);
      this.listMenu[index].active = !this.listMenu[index].active;
    }else{
      this.router.navigate([url]);
    }
  }
}