import { Rendimiento } from './rendimiento.model';

export class Inversionista {
    CR:number = 0;
    DB:number = 0;
    identificacion:string; 
    persona_id:string;
    nombres:string;
    listRend:Rendimiento[]=[];
    constructor(b:any=null){
        if(b!=null){
            this.persona_id = b.persona_id;
            this.identificacion = atob(b.identificacion);
            var nombres = b.nombres.split(" ");
            this.nombres = atob(nombres[0])+' '+atob(nombres[1]);
            this.addRend(b);
        }
    }
    addRend(rend:any){
        this.listRend.push(rend);
        this.CR = this.CR + Number(rend.CR);
        this.DB = this.DB + Number(rend.DB);
    }
}