import { Component, OnInit } from '@angular/core';
import { Data } from '../model/z.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    Data._idEquipo = this.route.snapshot.data.ip.IP;
    Data.loading.op="OK";
  }
}