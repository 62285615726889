import { Component, TemplateRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { BloqueMenu } from 'src/app/model/bloqueMenu.model';
import { GeneralService } from 'src/app/service/general.service';
import { SubMenu } from 'src/app/model/subMenu';

@Component({
  selector: 'app-mant-menu',
  templateUrl: './mant-menu.component.html',
  styleUrls: ['./mant-menu.component.css']
})
export class MantMenuComponent implements OnInit {
  isLoadingBtn:boolean=false;
  isLoading:boolean=false;
  isDeletingBloque:boolean;
  isCollapsed = true;
  modalRef: BsModalRef;
  panelOpenState = false;
  paramBusquedaGroup:FormGroup;
  subMenuForm:FormGroup;
  listMenu: BloqueMenu[] = [];
  menuSelected:BloqueMenu;
  constructor(private _builder: FormBuilder,private modalService: BsModalService,private generalService:GeneralService) { }

  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      bloque_id:[null],
      nombre_bloque: ['',Validators.required],
      url:['',Validators.required],
      icon:['',Validators.required],
      orden:['',Validators.required]
    });
    this.subMenuForm = this._builder.group({
      bloques_id: [null, Validators.required],
      item:['', Validators.required],
      url:['',Validators.required],
      orden:['',Validators.required]
    });
    this.getAllMenu()
  }
  getAllMenu(){
    this.isLoading = false;
    this.listMenu = [];
    this.generalService.useService(BloqueMenu.getAll()).subscribe(
      (data : any)=>{
        this.fillMenu(data.datos);
        this.isLoading = true;
      },
      (err : HttpErrorResponse)=>{
        
      }
    );
  }
  fillMenu(datos){
    for (let t in datos) {
      let temp:BloqueMenu = new BloqueMenu(datos[t]);
      this.listMenu.push(temp);
    }
  }
  showEdit(c:BloqueMenu){
    console.log(c)
    this.paramBusquedaGroup.patchValue(c);
    this.isCollapsed = false;
  }
  save(form){
    this.isLoadingBtn = true;
    this.generalService.useService(BloqueMenu.post(form)).subscribe(
      (data : any)=>{
        console.log(data);
        this.getAllMenu();
        this.isLoadingBtn = false;
        this.isCollapsed = true;
        this.paramBusquedaGroup.reset();
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  delMenu(){
    this.isDeletingBloque = true;
    this.generalService.useService(BloqueMenu.delete(this.menuSelected.bloque_id)).subscribe(
      (data : any)=>{
        this.getAllMenu();
        this.menuSelected = null;
        this.modalRef.hide();
        this.isDeletingBloque = false;
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  addSubMenu(form){
    this.isLoadingBtn=true;
    form.bloques_items_id = null;
    for(let a of this.listMenu){
      a.banNuevo = false;
    }
    this.generalService.useService(SubMenu.post(form)).subscribe(
      (data : any)=>{
        for(let a of this.listMenu){
          if(a.bloque_id == form.bloques_id){
            a.banNuevo = true;
          }
        }
        this.isLoadingBtn=false;
        this.modalRef.hide();
        this.subMenuForm.reset(); 
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-lg');
  }
}
