import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Notificacion } from 'src/app/model/notificacion.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { Data } from 'src/app/model/z.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { GeneralService } from 'src/app/service/general.service';
import { ImageSnippet } from 'src/app/model/imageSnippet.model';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-mant-notificaciones',
  templateUrl: './mant-notificaciones.component.html',
  styleUrls: ['./mant-notificaciones.component.css']
})
export class MantNotificacionesComponent implements OnInit {
  isCollapsed = true;
  isLoading2:boolean = true;
  isLoading:boolean=false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['notificacion_id','tipo','titulo','descripcion','aplicacosto','acciones'];
  notificacionSelected: Notificacion;
  listPregunta: Notificacion[]=[];
  paramBusquedaGroup:FormGroup;
  modalRef: BsModalRef;
  
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  fech:Date= new Date();
  cboNotificacion: DetalleCatalogo[] = [];

  
  selectedFile: ImageSnippet = new ImageSnippet(null,null);
  constructor(private generalService:GeneralService,private _snackBar: MatSnackBar,private modalService: BsModalService,private _builder: FormBuilder) {
    this.paramBusquedaGroup = this._builder.group({
      notificacion_id: [null],
      tiponotificacion_id: ['',Validators.required],
      titulo: ['',Validators.required],
      imagen: ['aun no imagen',Validators.required],
      descripcion: ['',Validators.required],
      aplicacosto: [false,Validators.required]
    });
    this.clearData();
  }
  clearData(){
    this.isCollapsed = true;
    this.notificacionSelected = new Notificacion();
    this.paramBusquedaGroup.reset({
      notificacion_id: null,
      tiponotificacion_id: '',
      titulo: '',
      imagen: 'aun no imagen',
      descripcion: '',
      aplicacosto:false
    });
    this.getAllNotificacion();
  }
  viewNotificacion(f:Notificacion,ban:boolean){
    this.notificacionSelected = f;
    this.isCollapsed = false;
    this.selectedFile.src = Data._trim(f.imagen);
    this.paramBusquedaGroup.patchValue(this.notificacionSelected);
    if(ban)
      this.paramBusquedaGroup.disable();
    else
      this.paramBusquedaGroup.enable();
  }
  ngOnInit() {
    this.cboNotificacion = Data._fListCatalogo("TIPO DE NOTIFICACION");
    this.getAllNotificacion();
  }
  save(form){
    this.isLoading=true;
    if(this.selectedFile.file != null){
      this.generalService.uploadImage(this.selectedFile.file,"notify").subscribe(
        (res:any) => {
          form.imagen = res.datos[0].url;
          this.generalService.useService(Notificacion.post(form)).subscribe(
            (data : any)=>{
              console.log(data);
              this.clearData();
              this.isLoading=false;
            },
            (err : HttpErrorResponse)=>{
              console.log(err);
            }
          );
        },
        (err) => {
          console.log(err);
        })
    }else{
      this.generalService.useService(Notificacion.post(form)).subscribe(
        (data : any)=>{
          this.clearData();
          this.getAllNotificacion();
          this.isLoading=false;
          this.isCollapsed=true;
        },
        (err : HttpErrorResponse)=>{
          console.log(err);
        }
      );
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
  getAllNotificacion(){
    this.isLoading2 = true;
    this.listPregunta = [];
    this.dataSource = new MatTableDataSource<Notificacion>(this.listPregunta);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.generalService.useService(Notificacion.getAll()).subscribe(
      (data : any)=>{
        console.log(JSON.stringify(data.datos));
        this.fillPregunta(data.datos);
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  nuevo(){
    this.selectedFile = new ImageSnippet(null,null)
  }
  fillPregunta(datos){
    for (let t in datos) {
      this.listPregunta.push(new Notificacion(datos[t] as Notificacion));
    }
    this.dataSource = new MatTableDataSource<Notificacion>(this.listPregunta);
    this.dataSource.paginator = this.paginator;
    
    this.dataSource.sort = this.sort;
  }
  delNotificacion(){
    this.isLoading=true;
    this.generalService.useService(Notificacion.delete(this.notificacionSelected.notificacion_id)).subscribe(
      (data : any)=>{
        this.getAllNotificacion();
        this.isLoading=false;
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  openModal(template: TemplateRef<any>,f:Notificacion) {
    this.notificacionSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.selectedFile.pending = true;
      this.paramBusquedaGroup.controls['imagen'].setValue('exist');
    });

    reader.readAsDataURL(file);
  }
}
