import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css']
})
export class ModalsComponent implements OnInit {
  @ViewChild('staticModal',{static:true}) public childModal:ModalDirective;
  @Input() title:string;
  @Input() size:string;
  @Input() messaje:string;
  constructor() { }

  ngOnInit() {
  }
  show(){
    this.childModal.show();
  }
  hide(){
    this.childModal.hide();
  }
}
