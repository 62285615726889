import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { Financiera } from 'src/app/model/financiera.model';

@Component({
  selector: 'app-cuentas-financieras',
  templateUrl: './cuentas-financieras.component.html',
  styleUrls: ['./cuentas-financieras.component.css']
})
export class CuentasFinancierasComponent implements OnInit {
  tipoCuentas: DetalleCatalogo[] = [];
  institucionesFinancieras: Financiera[] = [];
  paramBusquedaGroup:FormGroup;
  constructor(private _builder: FormBuilder) { }

  ngOnInit() {
    this.fillComboBox();
    this.paramBusquedaGroup = this._builder.group({
      institucionFinanciera: [''],
      tipoCuenta:[''],
      numeroCuenta:[''],
      titularCuenta:['']
    });
  }
  find(form){
    console.log(form);
  }

  fillComboBox(){
    this.tipoCuentas = Data._fListCatalogo("TIPO DE CUENTA");
    this.institucionesFinancieras = Data._financieras;
  }
}
