import { Component, OnInit, ViewChild, TemplateRef, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Data } from 'src/app/model/z.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Financiera } from 'src/app/model/financiera.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { Pais } from 'src/app/model/pais.model';
import { InstitucionFinancieraService } from 'src/app/service/institucion-financiera.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-mant-financiera',
  templateUrl: './mant-financiera.component.html',
  styleUrls: ['./mant-financiera.component.css']
})
export class MantFinancieraComponent implements OnInit {
  //COMBOS
  paises:Pais[];
  tipoFinanciera: DetalleCatalogo[] = Data._fListCatalogo("TIPO FINANCIERA");
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['tipoFinanciera','nombre','codigoEntidad','direccionMatriz','telefono','acciones'];
  listFinanciera: Financiera[]=[];
  financieraSelected: Financiera;
  paisSelected:Pais;
  paramBusquedaGroup:FormGroup;
  modalRef: BsModalRef;
  isCollapsed = true;
  isLoading:boolean=false;
  isLoading2:boolean = true;
  constructor(private generalService:GeneralService,private _snackBar: MatSnackBar,private modalService: BsModalService, private _builder: FormBuilder, private institucionFinancieraService: InstitucionFinancieraService) {
    this.paramBusquedaGroup = this._builder.group({
      financiera_id: [null],
      tipofinanciera_id:['',Validators.required],
      Nombre:['',Validators.required],
      codigoentidad:['',Validators.required],
      pais_id:['',Validators.required],
      pais_nombre:['',Validators.required],
      direccionmatriz:['',Validators.required],
      telefono:['',Validators.required]
    });
  }
  delFinanciera(){
    this.isLoading=true;
    this.institucionFinancieraService.deleteInstitucionFinanciera(this.financieraSelected.financiera_id).subscribe(
      (data : any)=>{
        this.getAllInstitucionFinanciera();
        this.modalRef.hide();
        this.isLoading=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  getPaises(){
    this.paises =[];
    this.generalService.useService(Pais.getAll()).subscribe(
      (d:any)=>{
        for(let f in d.datos){
          this.paises.push(new Pais(d.datos[f]));
        }
      },(err:HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  viewFinanciera(f:Financiera,ban:boolean){
    this.isCollapsed = false;
    this.financieraSelected = f;
    this.paramBusquedaGroup.patchValue(this.financieraSelected);
    if(ban)
      this.paramBusquedaGroup.disable();
    else{
      this.paramBusquedaGroup.enable();
      this.paramBusquedaGroup.controls['codigoentidad'].disable();
    }
    this.paramBusquedaGroup.controls['pais_nombre'].setValue(this.paises.find(t=>t.id_pais == this.financieraSelected.pais_id).descripcion);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngOnInit() {
    this.getPaises();
    this.getAllInstitucionFinanciera();
  }

  getAllInstitucionFinanciera(){
    this.listFinanciera=[];
    this.isLoading2 = true;
    this.dataSource = new MatTableDataSource<Financiera>(this.listFinanciera);
    this.dataSource.paginator = this.paginator;
    this.institucionFinancieraService.getAllInstitucionFinanciera().then(
      (doc) =>  {
        this.listFinanciera = doc;
        this.dataSource = new MatTableDataSource<Financiera>(this.listFinanciera);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
       }
    );    
  }

  save(form){
    if(form.financiera_id != null){
      form.codigoentidad = this.financieraSelected.codigoentidad;
    }
    this.institucionFinancieraService.postInstitucionFinanciera(form).subscribe(
      (data : any)=>{
        this.getAllInstitucionFinanciera();
        this.isCollapsed = !this.isCollapsed;
        this.isLoading=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }

  openModal(template: TemplateRef<any>,f:Financiera) {
    this.financieraSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
}