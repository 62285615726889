import { Data } from './z.model';

export class Pais {
    id_pais:number;
    descripcion:string;
    division:any;
    constructor(p:Pais = null){
        if(p!=null){
            this.id_pais = p.id_pais;
            this.descripcion = p.descripcion;
        }
    }
    public static getAll(){
        var tmp = Data._getBody("paises",11,"");
        return tmp;
    }
}