import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { DispositivoComponent } from './auth/dispositivo/dispositivo.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { BloqueResolve } from './resolve/bloque.resolve';
import { FinancieraResolve } from './resolve/financiera.resolve';
import { IpResolve, PrioryResolve } from './resolve/priory.resolve';
import { AjCostoAdministrativoComponent } from './site/ajustes/aj-costo-administrativo/aj-costo-administrativo.component';
import { AjRendimientosComponent } from './site/ajustes/aj-rendimientos/aj-rendimientos.component';
import { AsignarItemsComponent } from './site/ajustes/asignar-items/asignar-items.component';
import { CambiarClaveComponent } from './site/ajustes/cambiar-clave/cambiar-clave.component';
import { CrearGruposComponent } from './site/ajustes/crear-grupos/crear-grupos.component';
import { CuentasFinancierasComponent } from './site/ajustes/cuentas-financieras/cuentas-financieras.component';
import { RetornoBancoComponent } from './site/ajustes/retorno-banco/retorno-banco.component';
import { UsuarioAdministradoraComponent } from './site/ajustes/usuario-administradora/usuario-administradora.component';
import { NuevoBeneficioInvierteComponent } from './site/beneficios/nuevo-beneficio-invierte/nuevo-beneficio-invierte.component';
import { NuevoBeneficioComponent } from './site/beneficios/nuevo-beneficio/nuevo-beneficio.component';
import { NuevaBonificacionComponent } from './site/bonificaciones/nueva-bonificacion/nueva-bonificacion.component';
import { CuentaBancariaComponent } from './site/configuraciones/cuenta-bancaria/cuenta-bancaria.component';
import { DetalleFondoComponent } from './site/configuraciones/fondo-inversion/detalle-fondo/detalle-fondo.component';
import { FondoInversionComponent } from './site/configuraciones/fondo-inversion/fondo-inversion.component';
import { ConsInversionistasComponent } from './site/consultas/cons-inversionistas/cons-inversionistas.component';
import { DetalleConsInversionistasComponent } from './site/consultas/cons-inversionistas/detalle-rep-inversionistas/detalle-cons-inversionistas.component';
import { ConsultaBeneficiosComponent } from './site/consultas/consulta-beneficios/consulta-beneficios.component';
import { ConsultaBonificacionesComponent } from './site/consultas/consulta-bonificaciones/consulta-bonificaciones.component';
import { ConsultaEstadoCuentaComponent } from './site/consultas/consulta-estado-cuenta/consulta-estado-cuenta.component';
import { InversionistaComponent } from './site/consultas/consulta-inversionista/consulta-inversionista.component';
import { ConsultaMovimientosComponent } from './site/consultas/consulta-movimientos/consulta-movimientos.component';
import { ConsultaSolicitudesDebitoFallidasComponent } from './site/consultas/consulta-solicitudes-debito-fallidas/consulta-solicitudes-debito-fallidas.component';
import { ConsultaSolicitudesTransferenciaFallidasComponent } from './site/consultas/consulta-solicitudes-transferencia-fallidas/consulta-solicitudes-transferencia-fallidas.component';
import { DashboardComponent } from './site/dashboard/dashboard.component';
import { FondosAprobadosComponent } from './site/fondos-aprobados/fondos-aprobados.component';
import { HomDosComponent } from './site/homologacion/hom-dos/hom-dos.component';
import { HomUnoComponent } from './site/homologacion/hom-uno/hom-uno.component';
import { ImportarDocumentosComponent } from './site/importar-documentos/importar-documentos.component';
import { MantAdministradoraComponent } from './site/mantenimiento/mant-administradora/mant-administradora.component';
import { MantAsignarItemsComponent } from './site/mantenimiento/mant-asignar-items/mant-asignar-items.component';
import { MantAsignarRolesComponent } from './site/mantenimiento/mant-asignar-roles/mant-asignar-roles.component';
import { MantCatalogoComponent } from './site/mantenimiento/mant-catalogo/mant-catalogo.component';
import { MantCostoAdministrativoComponent } from './site/mantenimiento/mant-costo-administrativo/mant-costo-administrativo.component';
import { MantCostoFinancieroComponent } from './site/mantenimiento/mant-costo-financiero/mant-costo-financiero.component';
import { MantFinancieraComponent } from './site/mantenimiento/mant-financiera/mant-financiera.component';
import { MantFondoInversionComponent } from './site/mantenimiento/mant-fondo-inversion/mant-fondo-inversion.component';
import { MantGeolocalizacionComponent } from './site/mantenimiento/mant-geolocalizacion/mant-geolocalizacion.component';
import { MantImpuestoComponent } from './site/mantenimiento/mant-impuesto/mant-impuesto.component';
import { MantMenuComponent } from './site/mantenimiento/mant-menu/mant-menu.component';
import { MantNotificacionesComponent } from './site/mantenimiento/mant-notificaciones/mant-notificaciones.component';
import { MantPermisosServiciosComponent } from './site/mantenimiento/mant-permisos-servicios/mant-permisos-servicios.component';
import { MantPoolComponent } from './site/mantenimiento/mant-pool/mant-pool.component';
import { MantPreguntasComponent } from './site/mantenimiento/mant-preguntas/mant-preguntas.component';
import { MantSeguridadComponent } from './site/mantenimiento/mant-seguridad/mant-seguridad.component';
import { MantTipoUsuarioComponent } from './site/mantenimiento/mant-tipo-usuario/mant-tipo-usuario.component';
import { MantUsuarioComponent } from './site/mantenimiento/mant-usuario/mant-usuario.component';
import { ConfDispositivosComponent } from './site/mi-cuenta/cuenta-configuracion/conf-dispositivos/conf-dispositivos.component';
import { CuentaConfiguracionComponent } from './site/mi-cuenta/cuenta-configuracion/cuenta-configuracion.component';
import { NuevaNotificacionComponent } from './site/notificaciones/nueva-notificacion/nueva-notificacion.component';
import { ProcDebitoComponent } from './site/proceso/proc-debito/proc-debito.component';
import { ProcTransferenciaComponent } from './site/proceso/proc-transferencia/proc-transferencia.component';
import { RendimientosMensualesComponent } from './site/rendimientos/rendimientos-mensuales/rendimientos-mensuales.component';
import { RendimientosSemanalesComponent } from './site/rendimientos/rendimientos-semanales/rendimientos-semanales.component';
import { RepBeneficiosComponent } from './site/reporteria/rep-beneficios/rep-beneficios.component';
import { RepBonificacionesComponent } from './site/reporteria/rep-bonificaciones/rep-bonificaciones.component';
import { RepCostosAsociadosComponent } from './site/reporteria/rep-costos-asociados/rep-costos-asociados.component';
import { RepDetalleFondoComponent } from './site/reporteria/rep-detalle-fondo/rep-detalle-fondo.component';
import { RepHistorialDocumentosComponent } from './site/reporteria/rep-historial-documentos/rep-historial-documentos.component';
import { RepInversionistasDosComponent } from './site/reporteria/rep-inversionistas-dos/rep-inversionistas-dos.component';
import { RepInversionistasComponent } from './site/reporteria/rep-inversionistas/rep-inversionistas.component';
import { RepNotificacionesComponent } from './site/reporteria/rep-notificaciones/rep-notificaciones.component';
import { RepProduccionInversionesComponent } from './site/reporteria/rep-produccion-inversiones/rep-produccion-inversiones.component';
import { RepProduccionRetirosComponent } from './site/reporteria/rep-produccion-retiros/rep-produccion-retiros.component';
import { RepReferidoresComponent } from './site/reporteria/rep-referidores/rep-referidores.component';
import { RepRendimientosComponent } from './site/reporteria/rep-rendimientos/rep-rendimientos.component';
import { RepSolicitudComponent } from './site/reporteria/rep-solicitud/rep-solicitud.component';
import { RepSolicitudesIntentoComponent } from './site/reporteria/rep-solicitudes-intento/rep-solicitudes-intento.component';
import { DebitoComponent } from './site/solicitudes/debito/debito.component';
import { DocumentosComponent } from './site/solicitudes/inversion/inv-detalle/documentos/documentos.component';
import { InvDetalleComponent } from './site/solicitudes/inversion/inv-detalle/inv-detalle.component';
import { InversionComponent } from './site/solicitudes/inversion/inversion.component';
import { retDetalleComponent } from './site/solicitudes/retiro/ret-detalle/ret-detalle.component';
import { RetiroComponent } from './site/solicitudes/retiro/retiro.component';
import { TransferenciaComponent } from './site/solicitudes/transferencia/transferencia.component';
import { VerificadoComponent } from './util/verificado/verificado.component';
import { WorkingComponent } from './util/working/working.component';
import { LoadingComponent } from './util/loading/loading.component';

export const routes: Routes = [
  { path: '', redirectTo:'/login', pathMatch : 'full'},
  { path: 'validardispositivo',component: DispositivoComponent, resolve:{userposts:IpResolve}},
  { path: 'login', component: AuthComponent,resolve:{ ip:IpResolve }, children: [
      { path: '', component: LoginComponent }
    ]
  },
  { path: 'verificado/:pass', component: VerificadoComponent },
  { path: 'working', component: WorkingComponent},
  { path:'home',data:{breadcrumb: 'Home'},component:HomeComponent,canActivate:[AuthGuard],resolve:{ userposts:PrioryResolve,financ:FinancieraResolve} ,children:[
    { path:'', component: DashboardComponent},
      {path:'working', component: WorkingComponent},
      {path:'fondosAprobados', component: FondosAprobadosComponent},
      {path:'ajustes',data:{breadcrumb: 'Ajustes'},children:[
          {path:'asignarItems',data:{breadcrumb: 'Asignar items'},component:AsignarItemsComponent},
          {path:'cambiarClave',data:{breadcrumb: 'Cambiar clave'},component:CambiarClaveComponent},
          {path:'crearGrupos',data:{breadcrumb: 'Crear grupos'},component:CrearGruposComponent},
          {path:'usuariosForAdministradora',data:{breadcrumb: 'Crear usuarios'},component:UsuarioAdministradoraComponent},
          {path:'costoAdministrativo',data:{breadcrumb: 'Costo administrativo'},component:AjCostoAdministrativoComponent},
          {path:'rendimientos',data:{breadcrumb: 'Rendimientos'},component:AjRendimientosComponent},
          {path:'retornoBanco',data:{breadcrumb: 'Retorno banco'},component:RetornoBancoComponent},
          {path:'cuentasFinancieras',data:{breadcrumb: 'Cuentas Financieras'},component:CuentasFinancierasComponent},
        ]
      },
      {path:'beneficios',data:{breadcrumb: 'Beneficios'},children:[
        {path:'nuevo',data:{breadcrumb: 'Nuevo'},component:NuevoBeneficioComponent},
        {path:'nuevoInvierte',data:{breadcrumb: 'Nuevo'},component:NuevoBeneficioInvierteComponent}
      ]},
      {path:'bonificaciones/nuevo',data:{breadcrumb: 'Bonificaciones / Nuevo'},component:NuevaBonificacionComponent},
      {path:'configuraciones', children:[
        {path:'cuentabancaria',data:{breadcrumb: 'Cuenta bancaria'}, component:CuentaBancariaComponent},
        {path:'fondoinversion',component:FondoInversionComponent, data:{breadcrumb: 'Fondos de inversión'},children:[
          {path:':id',data:{breadcrumb: 'detalle'},component:DetalleFondoComponent}
        ]}
      ]},
      {path:'consultas',data:{breadcrumb: 'Consultas'},children:[
          {path:'consinversionista',data:{breadcrumb: 'Inversionistas'},children:[
              {path:'',component:ConsInversionistasComponent},
              {path:':id',data:{breadcrumb: 'Detalle'},component:DetalleConsInversionistasComponent}
            ]
          },
          {path:'inversionista',data:{breadcrumb: 'Inversionistas'},component:InversionistaComponent},
          {path:'movimientos',data:{breadcrumb: 'Movimientos'},component:ConsultaMovimientosComponent},
          {path:'estadoCuenta',data:{breadcrumb: 'Estados de cuenta'},component:ConsultaEstadoCuentaComponent},
          {path:'solicitudesDebitoFallidas',data:{breadcrumb: 'Solicitudes de débito f allidas'},component:ConsultaSolicitudesDebitoFallidasComponent},
          {path:'solicitudesTransferenciaFallidas',data:{breadcrumb: 'Solicitudes de transferencias fallidas'},component:ConsultaSolicitudesTransferenciaFallidasComponent},
          {path:'bonificaciones',data:{breadcrumb: 'Bonificaciones'},component:ConsultaBonificacionesComponent},
          {path:'beneficios',data:{breadcrumb: 'Beneficios'},component:ConsultaBeneficiosComponent}
        ]
      },
      {path:'importardoc',data:{breadcrumb: 'Importar Documentos'},component:ImportarDocumentosComponent},
      {path:'homologacion',component:ImportarDocumentosComponent,children:[
        {path:'homologacionuno',data:{breadcrumb: 'Homologación Uno'},component:HomUnoComponent},
        {path:'homologaciondos',data:{breadcrumb: 'Homologación Dos'},component:HomDosComponent}  
      ]},
      {path:'notificaciones/nuevo',data:{breadcrumb: 'Notificaciones / Nuevo'},component:NuevaNotificacionComponent},
      {path:'rendimientos/semanal',data:{breadcrumb: 'Rendimiento / Semanales'},component:RendimientosSemanalesComponent},
      {path:'rendimientos/mensual',data:{breadcrumb: 'Rendimiento / '},component:RendimientosMensualesComponent},
      {path:'reporteria',data:{breadcrumb: 'Reportes'},children:[
          {path:'movimientos',data:{breadcrumb: 'Movimientos en fondos'},component:RepRendimientosComponent},
          {path:'detallefondo',data:{breadcrumb: 'Det. de fondos'},component:RepDetalleFondoComponent},
          {path:'procesobancario',data:{breadcrumb: 'Proceso bancario'},component:RepSolicitudesIntentoComponent},
          {path:'bonificaciones',data:{breadcrumb: 'Bonificaciones'},component:RepBonificacionesComponent},
          {path:'beneficios',data:{breadcrumb: 'Beneficios'},component:RepBeneficiosComponent},
          {path:'inversionistaspormovimiento',data:{breadcrumb: 'Inversionistas'},component:RepInversionistasComponent},
          {path:'inversionistas',data:{breadcrumb: 'Inversionistas'},component:RepInversionistasDosComponent},
          {path:'referidores',data:{breadcrumb: 'Inversionistas'},component:RepReferidoresComponent},
          {path:'solicitud',data:{breadcrumb: 'Inversionistas'},component:RepSolicitudComponent},
          {path:'prodretiros',data:{breadcrumb: 'Inversionistas'},component:RepProduccionRetirosComponent},
          {path:'prodinversiones',data:{breadcrumb: 'Inversionistas'},component:RepProduccionInversionesComponent},
          {path:'solicitud',data:{breadcrumb: 'Inversionistas'},component:RepSolicitudComponent},
          {path:'notificaciones',data:{breadcrumb: 'Notificaciones'},component:RepNotificacionesComponent},
          {path:'historialDocumentos',data:{breadcrumb: 'Historial documentos'},component:RepHistorialDocumentosComponent},
          {path:'costosAsociados',data:{breadcrumb: 'Costos asociados'},component:RepCostosAsociadosComponent}
        ]
      },
      {path:'proceso',data:{breadcrumb: 'Proceso'},children:[
          {path:'debito',data:{breadcrumb: 'Débito'},component: ProcDebitoComponent},
          {path:'transferencia',data:{breadcrumb: 'Tranferencia'},component:ProcTransferenciaComponent}
        ]
      },
      { path:'miCuenta', children:[
        { path:'setting',data:{breadcrumb: 'Configuración'}, component: CuentaConfiguracionComponent, children:[
          { path:'', component: CambiarClaveComponent},
          { path:'cambiarClave', component: CambiarClaveComponent},
          { path:'dispositivos', component: ConfDispositivosComponent},
        ] },
        { path: 'misDocumentos',data:{breadcrumb: 'Documentos'}, component:DocumentosComponent },
      ]},
      {path:'solicitudes',data:{breadcrumb: 'Solicitudes'},children:[
          {path:'inversion',data:{breadcrumb: 'Inversión'},component:InversionComponent},
          {path:'inversion/:id',data:{breadcrumb: 'Inversión / Detalle'},component:InvDetalleComponent},
          {path:'retiro',data:{breadcrumb: 'Retiro'}, children:[
            {path: '',component:RetiroComponent},
            {path: ':id',data:{breadcrumb: 'Detalle'},component:retDetalleComponent}
          ]},
          {path:'debito',data:{breadcrumb: 'Débito'},component:DebitoComponent},
          {path:'transferencia',data:{breadcrumb: 'Transferencia'},component:TransferenciaComponent},
        ]
      },
      {path:'mantenimiento',data:{breadcrumb: 'Mantenimiento'},children:[
          {path:'administradora',data:{breadcrumb: 'Administradora'},component:MantAdministradoraComponent},
          {path:'asignarRoles',data:{breadcrumb: 'Asignar roles'},component:MantAsignarRolesComponent,resolve: { data: BloqueResolve }},
          {path:'financiera',data:{breadcrumb: 'Financiera'},component:MantFinancieraComponent},
          {path:'fondosInversion',data:{breadcrumb: 'Fondos de inversión'},component:MantFondoInversionComponent},
          {path:'catalogo',data:{breadcrumb: 'Catalogo'},component:MantCatalogoComponent},
          {path:'costoFinanciero',data:{breadcrumb: 'Costo financiero'},component:MantCostoFinancieroComponent},
          {path:'costoAdministrativo',data:{breadcrumb: 'Costo administrativo'},component:MantCostoAdministrativoComponent},
          {path:'geolocalizacion',data:{breadcrumb: 'Geolocalización'},component:MantGeolocalizacionComponent},
          {path:'impuesto',data:{breadcrumb: 'Impuestos'},component:MantImpuestoComponent},
          {path:'menu',data:{breadcrumb: 'Menu'},component:MantMenuComponent},
          {path:'seguridad',data:{breadcrumb: 'Seguridad'},component:MantSeguridadComponent},
          {path:'notificaciones',data:{breadcrumb: 'Notificaciones'},component:MantNotificacionesComponent},
          {path:'permisoItem',data:{breadcrumb: 'Permiso Item'},component:MantAsignarItemsComponent},
          {path:'permisosServicios',data:{breadcrumb: 'Permisos servicios'},component:MantPermisosServiciosComponent},
          {path:'pool',data:{breadcrumb: 'Pool'},component:MantPoolComponent},
          {path:'preguntasSecretas',data:{breadcrumb: 'Preguntas secretas'},component:MantPreguntasComponent},
          {path:'tipoUsuario',data:{breadcrumb: 'Tipo usuario'},component:MantTipoUsuarioComponent},
          {path:'usuario',data:{breadcrumb:'Usuario'},component:MantUsuarioComponent}
        ]
      }
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{useHash: true,
      onSameUrlNavigation: 'reload'
    }),
  ],
  exports: [RouterModule],
  declarations: [LoadingComponent]
})
export class AppRoutingModule { }
