import { Data } from '../z.model';

export class DatosPersonales {
    persona_id:number;
    tipo_persona_id:number;
    tipoidentificacion_id:number;
    tipoidentificacion_nombre:string;
    identificacion: string = "NINGUNO";
    fecha_expedicion:Date = new Date();
    fecha_expedicion_string:string;
    nombres:string = "NINGUNO";
    apellidos:string = "NINGUNO";
    razon_social:string;
    fecha_nacimiento:Date = new Date();
    codigo_dactilar: string = "NINGUNO";
    nacionalidad_id:number;
    estadocivil_id:number;
    genero:string;
    ocupacion_id:number;
    estado: string;
    estado_nombre: string;
    estado_color:string;
    fecha_registro:Date = new Date();
    constructor(dp:DatosPersonales = null){
        if(dp !=null){
            this.persona_id = dp.persona_id;
            this.tipo_persona_id = dp.tipo_persona_id;
            this.tipoidentificacion_id = dp.tipoidentificacion_id;
            this.tipoidentificacion_nombre = Data._fCatalogo(dp.tipoidentificacion_id,"TIPO DE IDENTIFICACION PERSONA");
            this.identificacion = Data._atob(dp.identificacion);
            this.fecha_expedicion = new Date(dp.fecha_expedicion['date']);
            this.nombres = Data._atob(dp.nombres);
            this.apellidos = Data._atob(dp.apellidos);
            this.razon_social = dp.razon_social;
            this.fecha_nacimiento = new Date(dp.fecha_nacimiento['date']);
            this.codigo_dactilar = Data._atob(dp.codigo_dactilar);
            this.nacionalidad_id = dp.nacionalidad_id;
            this.estadocivil_id = dp.estadocivil_id;
            this.genero = dp.genero;
            this.ocupacion_id = dp.ocupacion_id;
            this.estado = dp.estado;
            if(dp.estado == 'A'){
                this.estado_nombre = "APROBADO";
                this.estado_color = "success";
            }else if(dp.estado == 'P'){
                this.estado_nombre = "PENDIENTE";
                this.estado_color = "warning";
            }else if(dp.estado == 'C'){
                this.estado_nombre = "CANCELADO";
                this.estado_color = "danger";
            }else if(dp.estado == 'R'){
                this.estado_nombre = "REVISION ESPECIAL";
                this.estado_color = "primary";
            }else if(dp.estado == 'O'){
                this.estado_nombre = "OBSERVADO";
                this.estado_color = "secondary";
            }
            this.fecha_registro = dp.fecha_registro;
        }
    }
    changeEstado(estado:string){
        this.estado = estado;
            if(estado == 'A'){
                this.estado_nombre = "APROBADO";
                this.estado_color = "success";
            }else if(estado == 'P'){
                this.estado_nombre = "PENDIENTE";
                this.estado_color = "warning";
            }else if(estado == 'C'){
                this.estado_nombre = "CANCELADO";
                this.estado_color = "danger";
            }else if(estado == 'R'){
                this.estado_nombre = "REVISION ESPECIAL";
                this.estado_color = "primary";
            }else if(estado == 'O'){
                this.estado_nombre = "OBSERVADO";
                this.estado_color = "secondary";
            }
    }
}
