import { Data } from './z.model';

export class TipoUsuario {
    tipousuario_id  :number;
    tipousuario:string;
    constructor(ps:TipoUsuario=null){
        if(ps!=null){
            this.tipousuario_id = ps.tipousuario_id;
            this.tipousuario = ps.tipousuario.trim();
        }
    }
    public static getAll(){
        return Data._getBody("C",132,sessionStorage.getItem("token_fks"));
    }
    public static post(f:TipoUsuario){
        var tmp = Data._getBody("I",133,sessionStorage.getItem("token_fks"));
        if(f.tipousuario_id != null){
          tmp = Data._getBody("U",134,sessionStorage.getItem("token_fks"));
          tmp["data"]["tipousuario_id"] = f.tipousuario_id;  
        }
        tmp["data"]["tipousuario"] = f.tipousuario;
        return tmp;
    }
    public static delete(id:number){
        var tmp = Data._getBody("D",135,sessionStorage.getItem("token_fks"));
        tmp["data"]["tipousuario_id"] = id;
        return tmp;
    }
}