import { Data } from './z.model';

export class Notificacion {
    notificacion_id:number;
    tiponotificacion_id:number;
    tiponotificacion_nombre:string;
    titulo:string;
    imagen:string;
    descripcion:string;
    aplicacosto:boolean;
    constructor(n:Notificacion=null){
        if(n!=null){
            this.notificacion_id = n.notificacion_id;
            this.tiponotificacion_id = n.tiponotificacion_id;
            this.tiponotificacion_nombre = Data._fCatalogo(n.tiponotificacion_id,"TIPO DE NOTIFICACION");
            this.titulo = Data._trim(n.titulo);
            this.imagen = Data._trim(n.imagen);
            this.descripcion = Data._trim(n.descripcion);
            this.aplicacosto = n.aplicacosto;
        }
    }
    public static getAll(){
        return Data._getBody("C",120,sessionStorage.getItem("token_fks"));
    }
    public static post(f:Notificacion){
        var tmp = Data._getBody("I",121,sessionStorage.getItem("token_fks"));
        if(f.notificacion_id != null){
          tmp = Data._getBody("U",122,sessionStorage.getItem("token_fks"));
          tmp["data"]["notificacion_id"] = f.notificacion_id;  
        }
        tmp["data"]["tiponotificacion_id"] = f.tiponotificacion_id;
        tmp["data"]["titulo"] = f.titulo;
        tmp["data"]["descripcion"] = f.descripcion;
        tmp["data"]["imagen"] = f.imagen;
        tmp["data"]["aplicacosto"] = f.aplicacosto?1:0;
        tmp["data"]["estado"] = "A";
        return tmp;
    }
    public static delete(id:Number){
        var tmp = Data._getBody("D",123,sessionStorage.getItem("token_fks"));
        tmp["data"]["notificacion_id"] = id;
        console.log(JSON.stringify(tmp));
        return tmp;
    }
}