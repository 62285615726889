import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  url=environment.apiHost2;
  reqHeader = new HttpHeaders();
  constructor(private httpWithBackend:HttpClient,private http:HttpClient,handler: HttpBackend) { 
    this.httpWithBackend = new HttpClient(handler);
  }
  public uploadImage(image: File,name:string){
    let date:Date = new Date();
    const formData = new FormData();
    var blob = image.slice(0, image.size, image.type);
    image = new File([blob], name+'_'+date.getTime()+'.'+image.name.split('.')[1], {type: image.type});
    formData.append('userfile', image); 
    return this.http.post(`${this.url}Imagenes.php`,  formData);
  }
  useServiceWithBackend(tmp:any){
    return this.httpWithBackend.post(`${this.url}JBoxService.php`, JSON.stringify(tmp), { headers: this.reqHeader });
  }
  useService(tmp:any){
    return this.http.post(`${this.url}JBoxService.php`, JSON.stringify(tmp), { headers: this.reqHeader });
  }
  useServiceIp(){
    return this.httpWithBackend.get(`${environment.apiServices}services/ip.php`,{ headers: this.reqHeader });
  }
}
