import { Data } from '../z.model';

export class Report1 {
    administradorafondos_id:number;
    fondoinversion_id:number;
    nombrefondo:string;
    CR:number;
    DB:number;
    mes:number;
    anio:number;
    constructor(b:Report1 = null){
        if(b != null){
            this.administradorafondos_id=b.administradorafondos_id;
            this.fondoinversion_id=b.fondoinversion_id;
            this.nombrefondo=Data._trim(b.nombrefondo);
            this.CR=b.CR;
            this.DB=b.DB;
            this.mes=b.mes;
            this.anio=b.anio;
        }
    }
    //TODO Aun no me lo han dado a conocer.
    public static getAll() {
        return Data._getBody("C8",156,sessionStorage.getItem("token_fks"));
    }
} 