import { Data } from './z.model';

export class SituacionFinanciera {
    fondoinversion_id:any;
    persona_id:number;
    tipo_situacion_financiera_id:number;
    tipo_situacion_financiera_nombre:string;
    descripcion:string;
    valor:string;
    estado:string;
    constructor(sf:SituacionFinanciera=null){
        if(sf != null){
            this.fondoinversion_id = sf.fondoinversion_id;
            this.persona_id=sf.persona_id;
            this.tipo_situacion_financiera_id=sf.tipo_situacion_financiera_id;
            this.tipo_situacion_financiera_nombre=Data._fCatalogo(sf.tipo_situacion_financiera_id,"TIPO SITUACION FINANCIERA")
            this.descripcion=sf.descripcion;
            this.valor=sf.valor;
            this.estado=sf.estado;
        }   
    }
}