import { Data } from '../z.model';

export class ReportFuncionarioRetiro {
    nombre:string;
    atendidos:number;
    noatendidos:number;
    monto_atendidos:number;
    monto_NoAtendidos:number;
    produccion:any[] = [];
    constructor(b:ReportFuncionarioRetiro = null){
        if(b != null){
            this.nombre=b.nombre;
            this.atendidos=b.atendidos;
            this.noatendidos = b.noatendidos;
            this.monto_atendidos = (b.monto_atendidos==null?0:Number(b.monto_atendidos));
            this.monto_NoAtendidos = (b.monto_NoAtendidos==null?0:Number(b.monto_NoAtendidos));
            this.produccion.push(b);
        }
    }
    calculeProd(b:any){
        this.atendidos = this.atendidos + b.atendidos;
        this.noatendidos = this.noatendidos + b.noatendidos;
        this.monto_NoAtendidos = this.monto_NoAtendidos + (b.monto_NoAtendidos==null?0:Number(b.monto_NoAtendidos));
        this.monto_atendidos = this.monto_atendidos + (b.monto_atendidos==null?0:Number(b.monto_atendidos));
        this.produccion.push(b);
    }
    public static getAll(){
        var tmp = Data._getBody ("C12",156,sessionStorage.getItem("token_fks"));
        return tmp;
    }
}