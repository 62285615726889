import { Data } from './z.model';

export class Emisor {
    fondoinversion_id:number;
    participante:string;
    porcentaje:number;
    calificacion:string;
    constructor(ps:Emisor=null){
        if(ps!=null){
            this.fondoinversion_id = ps.fondoinversion_id;
            this.participante = ps.participante;
            this.porcentaje = ps.porcentaje;
            this.calificacion = ps.calificacion;
        }
    }
    public static getAll(id){
        var tmp = Data._getBody("C",174,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = id;
        return tmp;
    }
    public static post(f:Emisor[],id){
        var tmp = Data._getBody("I",175,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = id;
        tmp["data"]["participantes"] = {};
        f.forEach((t,idx)=>{
            tmp["data"]["participantes"][idx]=t;
        });
        return tmp;
    }
}