import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { MatPaginator } from '@angular/material/paginator';
import { Rendimiento } from 'src/app/model/rendimiento.model';
import { MatTableDataSource } from '@angular/material/table';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-rendimientos-mensuales',
  templateUrl: './rendimientos-mensuales.component.html',
  styleUrls: ['./rendimientos-mensuales.component.css']
})
export class RendimientosMensualesComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['tipoFondo','rendimiento','acciones'];
  listRendimiento: Rendimiento[]=[];
  paramBusquedaGroup:FormGroup;
  tipoFondos: FondoInversion[] = Data._fondosInversion;
  isLoading2:boolean = true;
  constructor(private generalService:GeneralService,private _builder: FormBuilder) { }

  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      fondoInversion: [''],
      rendimiento:['']
    });
    // this.listRendimiento.push(new Rendimiento("FONDO DE INVERSION ADMINISTRADO OMEGA",50.2));
    // this.listRendimiento.push(new Rendimiento("FONDO DE INVERSION ADMINISTRADO ALFA",45.00));
    this.dataSource = new MatTableDataSource<Rendimiento>(this.listRendimiento) ;
    this.dataSource.paginator = this.paginator;
  }

  find(form){
    console.log(form);
  }
  getAllRendimientos(){
    this.listRendimiento = [];
    this.generalService.useService(Rendimiento.getAll()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listRendimiento.push(new Rendimiento(data.datos[t] as Rendimiento));
        }
        this.dataSource = new MatTableDataSource<Rendimiento>(this.listRendimiento);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}
