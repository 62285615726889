import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Data } from '../model/z.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CostoFinanciero } from '../model/costoFinanciero.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CostoFinancieroService {
  url2=environment.apiHost2;
  object;
  constructor(private http:HttpClient,private datePipe: DatePipe) { }
  getAllCostoFinanciero(){
    var tmp = Data._getBody("C",66,sessionStorage.getItem("token_fks"));
    tmp["data"]["pais_id"] = 6;
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
  postCostoFinanciero(f:CostoFinanciero){
    var finicio = this.datePipe.transform(f.fecha_inicio, 'yyyy-MM-dd');
    var ffin = this.datePipe.transform(f.fecha_fin, 'yyyy-MM-dd');
    var tmp = Data._getBody("I",67,sessionStorage.getItem("token_fks"));
    if(f.costosfinancieros_id != null){
      tmp = Data._getBody("U",68,sessionStorage.getItem("token_fks"));
      tmp["data"]["costosfinancieros_id"] = f.costosfinancieros_id;  
    }
    tmp["data"]["pais_id"] = 6;
    tmp["data"]["nombre"] = f.nombre;
    tmp["data"]["aplica_porcentaje"] = (f.aplica_porcentaje==true?1:0);
    tmp["data"]["valor"] = f.valor;
    tmp["data"]["monto_desde"] = f.monto_desde;
    tmp["data"]["monto_hasta"] = f.monto_hasta;
    tmp["data"]["fecha_inicio"] = finicio;
    tmp["data"]["fecha_fin"] = ffin;
    tmp["data"]["estado"] = "A";
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
  deleteCostoFinanciero(id:Number){
    var tmp = Data._getBody("D",69,sessionStorage.getItem("token_fks"));
    tmp["data"]["costosfinancieros_id"] = id;
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
}