import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/service/general.service';
import { GrupoUsuario } from 'src/app/model/grupoUsuario.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PermisoGrupoBloque } from 'src/app/model/permisoGrupoBloque.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SortableComponent } from 'ngx-bootstrap/sortable';
import { AlertService } from 'src/app/util/alerts/alert.service';

@Component({
  selector: 'app-mant-asignar-roles',
  templateUrl: './mant-asignar-roles.component.html',
  styleUrls: ['./mant-asignar-roles.component.css']
})
export class MantAsignarRolesComponent implements OnInit {
  isSave:boolean;
  paramBusquedaGroup:FormGroup;
  tipoGrupo: GrupoUsuario[]=[];
  itemObjectsLeft: any[] = [];
  itemObjectsRight: any[] = [];
  data;
  todosPermisos:any[] =[];
  todosBloques:any[]=[]
  paraPasar:any[] = [];
  modalRef: BsModalRef;
  isLoading:boolean=false;
  addText: string;
  addForm: FormGroup;
  @ViewChild("sortTable1", {static: false}) sortable1: SortableComponent;
  @ViewChild("sortTable2", {static: false}) sortable2: SortableComponent;
  
  constructor(private modalService: BsModalService,private route: ActivatedRoute,private _builder: FormBuilder,
              private generalService:GeneralService, private alertService: AlertService) {
    this.paramBusquedaGroup = this._builder.group({
      tipoGrupoUs: ['',[Validators.required]]
    });
    this.addForm = this._builder.group({
      nombre: ['',[Validators.required]]
    });
  }
  ngOnInit() {
    this.data = this.route.snapshot.data.data.datos;
    console.log(this.data);
    for (let t in this.data) {
      this.todosBloques.push({bloque_id:this.data[t].bloque_id,nombre_bloque:this.data[t].nombre_bloque,isChecked:false});
    }
    this.itemObjectsLeft = this.todosBloques;
    this.getAllPermisos();
    this.getAllGrupoUsuario();
    this.onChanges();
  }
  getAllPermisos(){
    this.todosPermisos = [];
    this.isSave = true;
    this.generalService.useService(PermisoGrupoBloque.getAll()).subscribe(
      (data : any)=>{
        this.isSave = false;
        for (let t in data.datos) {
          this.todosPermisos.push(new PermisoGrupoBloque(data.datos[t] as PermisoGrupoBloque));
        }
        this.paramBusquedaGroup.patchValue({tipoGrupoUs:''});
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  changeleft(){
    this.itemObjectsLeft.forEach(t=>{
      if(t.grupo_bloque_id){
        console.log("existe relacion");
        this.deleteGrupoBloque(t);
        t.grupo_bloque_id = null;
      }
    })
  }
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.itemObjectsRight = this.todosPermisos.filter(t=> t.grupo_id==Number(val.tipoGrupoUs));
      this.itemObjectsLeft = this.todosBloques.filter(t=>{
        var ban:boolean= true;
        this.itemObjectsRight.forEach(l=>{if(l.bloque_id==t.bloque_id)ban=false;}) 
        return ban;
      });
    });
  }
  savePermisoGrupo(form){
    this.isSave = true;
    let ids = this.itemObjectsRight.filter(t=>(!t.grupo_bloque_id)).map(t=>{
      return t.bloque_id;
    }).join(",");
    this.generalService.useService(PermisoGrupoBloque.post(ids,form.tipoGrupoUs)).subscribe(
      (data : any)=>{
        this.isSave = false;
        this.getAllPermisos();
        console.log(data);
      },
      (err : HttpErrorResponse)=>{
        console.log(err.error);
      }
    );
  }
  addSelected(){
    if(this.paramBusquedaGroup.invalid){ 
      this.alertService.warn("Seleccione grupo.");
    }else{
      if(this.paraPasar.length==0){
        this.alertService.warn("No hay items seleccionados.");
      }else{
        Array.prototype.push.apply(this.itemObjectsRight,this.paraPasar);
        this.sortable2.writeValue(this.itemObjectsRight);
        this.paraPasar.forEach(x=>{
          this.itemObjectsLeft.forEach((t,idx)=>{
            if(x.bloque_id == t.bloque_id){
              x.isChecked = false;
              console.log(x.bloque_id,t.bloque_id)  
              this.itemObjectsLeft.splice(idx,1);
            }
          });
        });
        this.sortable1.writeValue(this.itemObjectsLeft);
        this.paraPasar = [];
      }
    }
  }
  changeBloque(i){
    if(i.isChecked){
      this.paraPasar.push(i);
    }else{
      this.paraPasar.forEach((t,idx)=>{
        if(t.bloque_id==i.bloque_id){
          this.paraPasar.splice(idx,1);
        }
      })
    }
  }
  deleteGrupoBloque(i:any){
    if(i.grupo_bloque_id){
      this.generalService.useService(PermisoGrupoBloque.delete(i.bloque_id, i.grupo_id)).subscribe(
        (data)=>{
          console.log(data);
        },
        (err)=>{
          console.log(err);
        }
      );
    }
    this.itemObjectsRight.forEach((t,idx)=>{
      if(i.bloque_id == t.bloque_id){
        this.itemObjectsRight.splice(idx,1);
      }
    });
    i.grupo_bloque_id = null;
    this.itemObjectsLeft.push(i);
    this.sortable2.writeValue(this.itemObjectsRight);
    this.sortable1.writeValue(this.itemObjectsLeft);
    this.paraPasar = [];
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-sm');
  }
  saveAdd(e){
    this.isLoading=true;
    this.generalService.useService(GrupoUsuario.post({descripcion:e.nombre,grupo_id:null})).subscribe(
      (data : any)=>{
        this.isLoading=false;
        this.getAllGrupoUsuario();
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  getAllGrupoUsuario() {
    this.generalService.useService(GrupoUsuario.getAll()).subscribe(
      (data : any)=>{
        if(data.datos){
          this.tipoGrupo = Object.values(data.datos).map((t:any)=>{
            return new GrupoUsuario(t);
          });
        }
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
}