import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
@Injectable()
export class MessageComponent {
  config = new MatSnackBarConfig();
  constructor(public _snackBar: MatSnackBar) {
    this.config.verticalPosition = 'top';
    this.config.duration = 5000;
  }
  messageSave(data:any){
    this.config.panelClass = 'snackbar-ok';
    if(data){
      let x = data.datos[0];
      this._snackBar.open(x.mensaje, "ERROR", this.config);
      if(x.status == "ERROR"){
        return false;
      }
    }else{
      this._snackBar.open("Error inesperado, consulte con su proveedor.", "ERROR", this.config);
      return false;
    }
    return true;
  }
  openSnackBar(data:any) {
    this.config.panelClass = 'snackbar-error';
    if(data){
      if(data.datos){
        if(data.datos[0]){
          let x = data.datos[0];
          if(x.status){
            this._snackBar.open(x.mensaje, x.status, this.config);
            return false;
          }
        }
      }else{

      }
    }else{
      this._snackBar.open("Error inesperado, consulte con su proveedor.", "ERROR", this.config);
      return false;
    }
    return true;
  }
  messageError(mensaje){
    this.config.panelClass = 'snackbar-error';
    this._snackBar.open(mensaje, "ERROR", this.config);
  }
}
