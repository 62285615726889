import { Component, OnInit, Output, EventEmitter, Input, Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Persona } from 'src/app/model/persona/persona.model';
@Component({
  selector: 'app-documentos-habilitantes-cliente',
  templateUrl: './documentos-habilitantes-cliente.component.html',
  styleUrls: ['./documentos-habilitantes-cliente.component.css']
})
@Pipe({
  name: 'safe'
})
export class DocumentosHabilitantesClienteComponent implements OnInit, PipeTransform  {
  doccontrato;
  docterminos;
  docdebito;
  _docInversionista;
  @Input() set docInversionista(docInversionista){
    this._docInversionista = this.transform(docInversionista);
  }
  administradora:string;
  paramGroup:FormGroup;
  @Input()
  set contratos(contratos: any) {
    this.doccontrato = this.transform(contratos.doccontrato);
    this.docterminos = this.transform(contratos.docterminos);
    this.docdebito = this.transform(contratos.docdebito);
  }
  hover:boolean = true;
  banText:boolean = false;
  banTextSB:boolean = false;
  @Input() banCheck:boolean;
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  constructor(private sanitizer: DomSanitizer) { 
  }
  ngOnInit() {
    this.administradora = atob(sessionStorage.getItem('inf7'));
  }
  changeStatusObs(e){
    this.obsCheck.emit(e);
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}