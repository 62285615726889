import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { Report4 } from 'src/app/model/reports/report4.model';
import { MatSort } from '@angular/material/sort';
import * as jsPDF  from 'jspdf';
import 'jspdf-autotable';
import { environment } from 'src/environments/environment';
import { ReportFuncionario } from 'src/app/model/reports/reportFuncionario.model';
import { ReportFuncionarioRetiro } from 'src/app/model/reports/reportFuncionarioRetiro.model';

@Component({
  selector: 'app-rep-produccion-retiros',
  templateUrl: './rep-produccion-retiros.component.html',
  styleUrls: ['./rep-produccion-retiros.component.css']
})
export class RepProduccionRetirosComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['nombrefondo','nombre','atendidos','noatendidos','monto_atendidos','monto_NoAtendidos'];
  @ViewChild(MatSort , {static: true}) sort: MatSort;
  listRendimiento: ReportFuncionarioRetiro[]=[];
  formFilter:FormGroup;
  isCollapsed = true;
  hidden = false;
  isLoading:boolean;
  minDateYear:Date = new Date(2020,1,1);
  maxDateYear:Date = new Date();
  minDate:Date = new Date(this.maxDateYear.getFullYear()-1,12,31);
  maxDate:Date = new Date(this.maxDateYear.getFullYear(),11,30);
  totalCreditos:number = 0;
  totalDebitos:number = 0;
  constructor(private generalService:GeneralService,private _builder: FormBuilder) {
    this.formFilter = this._builder.group({
      nombrefondo:['']
    });
  }
  ngOnInit() {
    this.getAllReport4();
    this.onChanges();
  }
  get tipoFondos(){
    return Data._fondosInversion;
  }
  getAllReport4(){
    this.isLoading = true;
    this.listRendimiento = [];
    this.dataSource = new MatTableDataSource<ReportFuncionarioRetiro>(this.listRendimiento);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.generalService.useService(ReportFuncionario.getAllRetiro()).subscribe(
      (data : any)=>{
        if(data.datos){
          this.listRendimiento = Object.values(data.datos as any).map((t:any)=>{
            t.nombrefondo = t.nombrefondo.trim();
            return t;
          });
        }
        this.totalCreditos = 0;
        this.totalDebitos = 0;
        // for (let t in data.datos) {
        //   this.totalCreditos = this.totalCreditos + Number(data.datos[t].CR);
        //   this.totalDebitos = this.totalDebitos + Number(data.datos[t].DB);
        //   this.listRendimiento.push(new ReportFuncionarioRetiro(data.datos[t]));
        // }
        this.dataSource = new MatTableDataSource<ReportFuncionarioRetiro>(this.listRendimiento);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = 
        (data: any, filtersJson: string) => {
          const matchFilter = [];
          const filters = JSON.parse(filtersJson);
          filters.forEach(filter => {
            const val = data[filter.id] === null ? '' : (typeof data[filter.id] == 'number'?data[filter.id].toString():data[filter.id]);
            matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
          });
          return matchFilter.every(Boolean);
        };
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    )
  }
  onChanges(): void {
    this.formFilter.valueChanges.subscribe(val => {
      this.find(val);
    });
  }
  find(form:any){
    console.log(form);
    const tableFilters = [];
    tableFilters.push({
      id: 'nombrefondo',
      value: form.nombrefondo
    });
    this.dataSource.filter = JSON.stringify(tableFilters);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  generarPDF(){
    const doc = new jsPDF('p', 'pt','a4')
    doc.setDrawColor(0);
    doc.setFillColor(37,43,71);
    doc.rect(0, 0, 595, 120, 'F');
    doc.addImage(environment.logoToBase64, 'PNG', 10, 10, 250, 80);
    doc.setFontSize(18);
    doc.setTextColor(255,255,255);
    doc.text(270,35,"")
    doc.setFontSize(18);
    doc.text(440,55,"REPORTE DE ")
    doc.setFontSize(18);
    doc.text(345,85,"FONDOS DE INVERSION")
    doc.autoTable({
      startY: 140,
      columnStyles: { 0: { fillColor: [37, 43, 71],textColor:[255,255,255] },2: { fillColor: [37, 43, 71],textColor:[255,255,255] } },
      cellWidth: 'wrap',
      body: [
        ['FECHA:', new Date().toLocaleString()]
      ],
    });
    doc.setFontSize(10);
    let data = [];
    this.dataSource.filteredData.forEach(obj => {
      let arr = [];
      this.displayedColumns.forEach(col => {
        if(col=="monto_atendidos" || col=="monto_NoAtendidos")
          arr.push(Number.parseFloat(obj[col] || 0).toFixed(2));
        else
          arr.push(obj[col]);
      });
      data.push(arr);
    });  
    let finalY = doc.previousAutoTable.finalY;    
    doc.autoTable({
      head: [['FONDO DE INVERSIÓN','FUNCIONARIO','ATENDIDOS','NO ATENDIDOS','MONTO DE ATENDIDOS', 'MONTO DE NO ATENDIDOS']],
      body: data,
      // foot: [
      //   [{ content: 'Total', colSpan: 2, styles: { halign: 'left' } },{ content: this.totalCredito.toFixed(2), styles: { halign: 'right' } },{ content: this.totalDebito.toFixed(2), styles: { halign: 'right' } }],
      // ],
      columnStyles: { 2: {halign: 'right'}, 3: {halign: 'right'}},
      headStyles:{fillColor: [37, 43, 71],textColor:[255,255,255]},
      footStyles:{fillColor: [37, 43, 71],textColor:[255,255,255]},
      startY: finalY
    });
    doc.setProperties({
      title: 'Reporte de inversionistas '+ new Date(),
      subject: 'Información de inversionistas, inversiones, rescates y su estado actual.',		
      author: 'Invierte',
      keywords: 'Reporte, inversiones',
      creator: 'Ing. Gonzalez Davids'
    });
    doc.save('Reporte de fondos de inversion - detalle '+(new Date()).getTime());
  }
}