import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { Report3 } from 'src/app/model/reports/report3.model';
import { environment } from 'src/environments/environment';
import * as jsPDF  from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-rep-rendimientos',
  templateUrl: './rep-rendimientos.component.html',
  styleUrls: ['./rep-rendimientos.component.css']
})
export class RepRendimientosComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['nombrefondo','CR','DB','semana','mes','anio'];
  listRendimiento: Report3[]=[];
  months = [ "ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", 
           "JULIO", "AGOSTO", "SEPTIRMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE" ];
  formFilter:FormGroup;
  isCollapsed = true;
  hidden = false;
  isLoading : boolean = false;
  isLoading2:boolean = true;
  minDateYear:Date = new Date(2020,1,1);
  maxDateYear:Date = new Date();
  minDate:Date = new Date(this.maxDateYear.getFullYear()-1,12,31);
  maxDate:Date = new Date(this.maxDateYear.getFullYear(),11,30);
  totalCreditos:number = 0;
  totalDebitos:number = 0;
  constructor(private generalService:GeneralService,private _builder: FormBuilder) {
    this.formFilter = this._builder.group({
      fondoinversion_id:[''],
      anio:[''],
      mes:['']
    });
  }
  ngOnInit() {
    this.getAllRendimientos(0,0);
    this.onChanges();
  }
  get tipoFondos(){
    return Data._fondosInversion;
  }
  getAllRendimientos(anio,mes){
    this.listRendimiento = [];
    this.dataSource = new MatTableDataSource<Report3>(this.listRendimiento);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(Report3.getAll(anio,mes)).subscribe(
      (data : any)=>{
        console.log(data.datos);
        this.totalCreditos = 0;
        this.totalDebitos = 0;
        for (let t in data.datos) {
          this.totalCreditos = this.totalCreditos + Number(data.datos[t].CR);
          this.totalDebitos = this.totalDebitos + Number(data.datos[t].DB);
          this.listRendimiento.push(new Report3(data.datos[t] as Report3));
        }
        this.dataSource = new MatTableDataSource<Report3>(this.listRendimiento);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data: any, filtersJson: string) => {
          const matchFilter = [];
          const filters = JSON.parse(filtersJson);
          console.log(filters);
          filters.forEach(filter => {
            let val = ''
            if(filter.id === "anio"){
              if(filter.value){
                filter.value = (new Date(filter.value)).getFullYear();
                if(filter.value == data[filter.id]){
                  matchFilter.push(true);    
                }else{
                  matchFilter.push(false);
                }
              }else{
                matchFilter.push(true);
              }
            }else if(filter.id === "mes"){
              if(filter.value != ""){
                if(Number(filter.value) == data[filter.id]){
                  matchFilter.push(true);    
                }else{
                  matchFilter.push(false);
                }
              }else{
                matchFilter.push(true);
              }
            }else{
              val = data[filter.id] === null ? '' : (typeof data[filter.id] == 'number'?data[filter.id].toString():data[filter.id]);
              matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
            }
          });
          return matchFilter.every(Boolean);
        };
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    )
  }
  find(form:any){
    const tableFilters = [];
    tableFilters.push({
      id: 'fondoinversion_id',
      value: form.fondoinversion_id
    },{
      id: 'anio',
      value: form.anio
    },{
      id: 'mes',
      value: form.mes
    });
    this.dataSource.filter = JSON.stringify(tableFilters);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onChanges(): void {
    this.formFilter.valueChanges.subscribe(val => {
      this.find(val);
    });
  }
  configTable(data){
    this.totalCreditos = 0;
    this.totalDebitos = 0;
    for(let x of data){
      this.totalCreditos = this.totalCreditos + Number(x.CR);
      this.totalDebitos = this.totalDebitos + Number(x.DB);
    }
    this.dataSource = new MatTableDataSource<Report3>(data);
    this.dataSource.paginator = this.paginator;
  }
  generarPDF(){
    const doc = new jsPDF('p', 'pt','a4')
    doc.setDrawColor(0);
    doc.setFillColor(37,43,71);
    doc.rect(0, 0, 595, 120, 'F');
    doc.addImage(environment.logoToBase64, 'PNG', 10, 10, 250, 80);
    doc.setFontSize(18);
    doc.setTextColor(255,255,255);
    doc.text(270,35,"")
    doc.setFontSize(18);
    doc.text(440,55,"REPORTE DE ")
    doc.setFontSize(18);
    doc.text(345,85,"FONDOS DE INVERSION")
    doc.autoTable({
      startY: 140,
      columnStyles: { 0: { fillColor: [37, 43, 71],textColor:[255,255,255] },2: { fillColor: [37, 43, 71],textColor:[255,255,255] } },
      cellWidth: 'wrap',
      body: [
        ['FECHA:', new Date().toLocaleString()]
      ],
    });
    doc.setFontSize(10);
    let data = [];
    this.dataSource.filteredData.forEach(obj => {
      let arr = [];
      this.displayedColumns.forEach(col => {
        if(col=="CR" || col=="DB")
          arr.push(Number.parseFloat(obj[col] || 0).toFixed(2));
        else if(col=='mes'){
          arr.push(this.months[obj[col]-1]);
        }else
          arr.push(obj[col]);
      });
      data.push(arr);
    });  
    let finalY = doc.previousAutoTable.finalY;    
    doc.autoTable({
      head: [['FONDO DE INVERSIÓN','INVERSIONES','RESCATES','SEMANA','MES', 'AÑO']],
      body: data,
      // foot: [
      //   [{ content: 'Total', colSpan: 2, styles: { halign: 'left' } },{ content: this.totalCredito.toFixed(2), styles: { halign: 'right' } },{ content: this.totalDebito.toFixed(2), styles: { halign: 'right' } }],
      // ],
      columnStyles: { 2: {halign: 'right'}, 3: {halign: 'right'}},
      headStyles:{fillColor: [37, 43, 71],textColor:[255,255,255]},
      footStyles:{fillColor: [37, 43, 71],textColor:[255,255,255]},
      startY: finalY
    });
    doc.setProperties({
      title: 'Reporte de inversionistas '+ new Date(),
      subject: 'Información de inversionistas, inversiones, rescates y su estado actual.',		
      author: 'Invierte',
      keywords: 'Reporte, inversiones',
      creator: 'Ing. Gonzalez Davids'
    });
    doc.save('Reporte de fondos de inversion - detalle '+(new Date()).getTime());
  }
}