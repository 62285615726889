import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitudInversion } from 'src/app/model/solicitudes/solicitudInversion.model';
import { Data } from 'src/app/model/z.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Persona } from 'src/app/model/persona/persona.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatosCuenta } from 'src/app/model/persona/datosCuenta.model';
import { Observacion } from 'src/app/model/observaciones.model';
import { GeneralService } from 'src/app/service/general.service';
import { ParticipacionDiaria } from 'src/app/model/participacionDiaria.model';
import { FondoAprobado } from 'src/app/model/fondoAprobado.model';
import { SolicitudRetiro } from 'src/app/model/solicitudes/solicitudRetiro.model';
import { Contrato } from 'src/app/model/contrato.model';

@Component({
  selector: 'app-ret-detalle',
  templateUrl: './ret-detalle.component.html',
  styleUrls: ['./ret-detalle.component.css']
})
export class retDetalleComponent implements OnInit {
  banCheck:boolean=false;
  isLoading2:boolean = false;
  isLoading: boolean = false;
  tipoEstados: DetalleCatalogo[] = [];
  code:number;
  checkDeclaratoria:boolean=true;
  solicitud:SolicitudRetiro = new SolicitudRetiro();
  persona:Persona = new Persona();
  cuentaDeb:DatosCuenta;
  tipoPersona:boolean = true;
  paramGroup:FormGroup;
  fondoInversion:FondoInversion = new FondoInversion();
  observaciones:Observacion[] = [];
  listObservacion:Observacion[] = [];
  blockedObsCuenta: boolean = false;
  listContrato: Contrato[] =[];
  constructor(private router : Router,private _builder: FormBuilder,private _snackBar: MatSnackBar,private route: ActivatedRoute, private generalService:GeneralService) {
    this.paramGroup = this._builder.group({
      estado:['',Validators.required]
    });
  }
  ngOnInit() {
    var any = Data._fondosInversion;
    this.tipoEstados = Data._fListCatalogo("ESTADO SOLICITUD");
    this.valDeclaratoria(false);
    for(let i = 2; i<=9 ; i++){
      let o = new Observacion();
      o.paso = i;
      this.observaciones.push(o);
    }
    
    this.route.paramMap.subscribe(params => {
      this.code = Number(params.get("id"));
      this.generalService.useService(SolicitudRetiro.getById(this.code)).subscribe(
        (data : any)=>{
          if(data.datos!=null){
            this.solicitud = new SolicitudRetiro(data.datos[0]);
            this.paramGroup.controls.estado.setValue(this.solicitud.estado_nombre=="PENDIENTE"?"":this.solicitud.estado_nombre);
            if(this.solicitud.estado_nombre == 'APROBADO' || this.solicitud.estado_nombre == 'CANCELADO'){
              this.paramGroup.disable();
              this.banCheck = true;
            }
            var lenF = any.filter(w => w.fondoinversion_id == this.solicitud.fondoinversion_id);
            if(lenF.length>0){
              this.fondoInversion = any.filter(w => w.fondoinversion_id == this.solicitud.fondoinversion_id)[0];
            }
            this.generalService.useService(Persona.getById(this.solicitud.persona_id)).subscribe(
              (data : any)=>{
                if(data!=null){
                  if(data.datos!=null){
                    this.persona = new Persona(data.datos as Persona);
                    this.getContrato(this.persona.datospersonales.identificacion);
                    this.getInversionistaDetalle(this.persona.datospersonales.identificacion);
                    for(let i=0; i<this.observaciones.length;i++){
                      this.observaciones[i].persona_id = this.persona.datospersonales.persona_id;
                    }
                    if(this.persona.datospersonales.tipo_persona_id == 12){
                      this.tipoPersona = false;
                    }
                    this.generalService.useService(Observacion.getByEstadoIsAtendido(this.persona.datospersonales.persona_id)).subscribe(
                      (data:any)=>{
                        for(let ob in data.datos){
                          this.listObservacion.push(new Observacion(data.datos[ob]));
                        }
                        console.log(this.listObservacion);
                      },(err:HttpErrorResponse)=>{
                        console.error(err);
                      }
                    );
                  }
                  this.isLoading2 = true;
                } 
              },
              (err : HttpErrorResponse)=>{
                this.persona = err.error.datos;
              }
            );
          }
        },
        (err : HttpErrorResponse)=>{
          
        }
      );
    });
  }
  valDeclaratoria(a){
    this.checkDeclaratoria=a;
  }
  getInversionistaDetalle(id){
    this.generalService.useService(FondoAprobado.getAllById(id)).subscribe(
      (data:any)=>{
        var count = 0;
        for (let t in data.datos) {
          count++;
        }
        if(count>0){
          this.blockedObsCuenta = true;
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  getContrato(id){
    this.generalService.useService(Contrato.getAll(id)).subscribe(
      (c:any)=>{
        for(let p in c.datos){
          if(Data._trim(c.datos[p].nombrefondo)==this.fondoInversion.nombrefondo && 
              (c.datos[p].tipo_doc!=3 && c.datos[p].tipo==1)){
            this.listContrato.push(new Contrato(c.datos[p]));
          }else if(Data._trim(c.datos[p].nombrefondo)==this.fondoInversion.nombrefondo && 
              (c.datos[p].tipo_doc==4 && c.datos[p].tipo==0)){
            let contr = new Contrato(c.datos[p]);
            let urlArr:string[] = contr.url.split('/');
            let urlArr2:string[] =urlArr[urlArr.length-1].split('_');
            if(Number(urlArr2[1]) == this.solicitud.solictudretirofondo_id){
              this.listContrato.push(new Contrato(c.datos[p]));
            }
          }
        }
      },(err:HttpErrorResponse)=>{
        
      }
    );
  }
  updateEstadoSolicitud(form){
    this.isLoading = !this.isLoading;
    var est = "P";
    if(form.estado == 'APROBADO'){
      est = "A";
    }else if(form.estado == 'CANCELADO'){
      est = "C";
    }else if(form.estado == 'OBSERVADO'){
      est = "O";
    }
    this.generalService.useService(SolicitudRetiro.put(this.solicitud.solictudretirofondo_id,est)).subscribe(
      (data : any)=>{
        this.router.navigate(['/home/solicitudes/retiro']);
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
  evaluarCheck(e){
    console.log(e);
  }
  setObservaciones(num,mensaje){
    this.observaciones[num].mensaje = mensaje;
    var hayMensaje:boolean = false;
    for(let o of this.observaciones){
      if(o.mensaje != ""){
        hayMensaje = true;
      }
    }
    if(hayMensaje == true){
      this.tipoEstados = Data._fListCatalogo("ESTADO SOLICITUD").filter(t=>t.descripcion!="APROBADO");
    }else{
      this.tipoEstados = Data._fListCatalogo("ESTADO SOLICITUD");
    }
  }
}