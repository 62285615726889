import { Data } from './z.model';

export class Observacion {
    id_observacion: number;
    persona_id: number;
    paso: number;
    mensaje:string = "";
    estado:number;
    estado_nombre:string;
    estado_color:string;
    constructor(p:Observacion = null){
        if(p != null){
            this.id_observacion = p.id_observacion;
            this.persona_id = p.persona_id;
            this.paso = p.paso;
            this.mensaje = p.mensaje;
            this.estado = p.estado;
            if(this.estado==0){
                this.estado_nombre = "EMITIDO";
                this.estado_color = "warning";
            }else if(this.estado==2){
                this.estado_nombre = "ATENDIDO";
                this.estado_color = "success";
            }
        }
    }
    public static post(f:Observacion){
        var tmp = Data._getBody("I",137,sessionStorage.getItem("token_fks"));
        if(f.id_observacion != null){
          tmp = Data._getBody("U",138,sessionStorage.getItem("token_fks"));
          tmp["data"]["id_observacion"] = f.id_observacion;
        }
        tmp["data"]["persona_id"] = f.persona_id;
        tmp["data"]["paso"] = f.paso;
        tmp["data"]["mensaje"] = f.mensaje;
        return tmp;
    }
    public static getByEstadoIsAtendido(idPersona:number){
        var tmp = Data._getBody("C4",136,sessionStorage.getItem("token_fks"));
        tmp["data"]["persona_id"] = idPersona;
        return tmp;
    }
}