import { Component, OnInit, TemplateRef } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { MatTableDataSource } from '@angular/material/table';
import { Data } from 'src/app/model/z.model';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralService } from 'src/app/service/general.service';
import { ReportBoni } from 'src/app/model/reports/reportBoni.model';
import { ReportBene } from 'src/app/model/reports/reportBene.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { DatePipe } from '@angular/common';
import { ReportFuncionario } from 'src/app/model/reports/reportFuncionario.model';
import { Nota } from 'src/app/model/nota.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  isLoading:boolean;
  listReportBoni:ReportBoni[] = [];
  listReportBene:ReportBene[] = [];
  listFondo:FondoInversion[] = [];
  listNota:Nota[] = [];
  formNota:FormGroup;
  modalRef: BsModalRef;
  totalUsuBene:number=0;
  totalUsuBoni:number=0;
  totalfondoBoni:number[]=[];
  totalfondoBene:number[]=[];
  radioModelFunci = 'anual';
  radioModelBene = 'a';
  radioModelBoni = 'a';
  cboBeneficios = Data._fListCatalogo("TIPO BENEFICIOS");
  poolSms:number = 879;
  poolEmail:number = 367;
  poolWhatsapp:number = 163;
  poolPush:number = 672;
  maxPool:number = 1000;
  
  
  constructor(private modalService: BsModalService,private datePipe: DatePipe,private _builder: FormBuilder,private generalService:GeneralService) {
    this.formNota = this._builder.group({
      nota_id:[null],
      nota: ['',Validators.required],
    });
  }

  ngOnInit(){
    this.listFondo = Data._fondosInversion;
    this.getAllReportBoni();
    this.getNotas();
  }
  get notificaciones(){
    return Data._notificaciones;
  }
  get numNoti(){
    return Data._numNotificaciones;
  }
  getNotas(){
    this.generalService.useService(Nota.getAll()).subscribe(
      (data:any)=>{
        this.listNota = [];
        for (let t in data.datos) {
          this.listNota.push(new Nota(data.datos[t]));
        }
      },
      (err:HttpErrorResponse)=>{
        console.error("Ocurrio error inesperado al obtener notas del usuario. Consulte con su proveedor");
      }
    );
  }
  addNota(form){
    this.isLoading=true;
    this.generalService.useService(Nota.post(form)).subscribe(
      (data:any)=>{
        this.getNotas();
        this.clear();
        this.isLoading = false;
      },
      (err:HttpErrorResponse)=>{
        console.log(err);
        console.error("Ocurrio un error inesperado al ingresar una nota. Consulte con su proveedor");
      }
    );
  }
  clear(){
    this.modalRef.hide();
    this.formNota.patchValue({nota_id:null, nota:''});
  }
  openModal(template: TemplateRef<any>){
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-sm');
  }
  deleteNota(id){
    this.generalService.useService(Nota.delete(id)).subscribe(
      (data:any)=>{
        this.getNotas();
      },
      (err:HttpErrorResponse)=>{
        console.error("Ocurrio un error inesperado al eliminar una nota. Consulte con su proveedor");
      }
    );
  }
  
  getAllReportBoni(){
    this.listFondo.forEach(t=>{
      t.anything = [];
    });
    this.generalService.useService(ReportBoni.getAll(2020)).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listReportBoni.push(new ReportBoni(data.datos[t] as ReportBoni));
        }
        this.getAllReportBene();
      },
      (err : HttpErrorResponse)=>{
        console.error("Ocurrio un error al obtener los rendimientos. Consulte con su proveedor.");
      }
    );
  }
  getAllReportBene(){
    this.generalService.useService(ReportBene.getAll(2020)).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listReportBene.push(new ReportBene(data.datos[t] as ReportBene));
        }
        for (let t of this.listReportBoni) {
          for(let y of this.listFondo){
            if(y.fondoinversion_id == t.fondoinversion_id){
              y.anything.push(t);
              break;
            }
          }
        }
        for (let t of this.listReportBene) {
          for(let y of this.listFondo){
            if(y.fondoinversion_id == t.fondoinversion_id){
              y.anything.push(t);
              break;
            }
          }
        }
        this.getPieBene();
        this.getPieBoni();
      },
      (err : HttpErrorResponse)=>{
        console.error("Ocurrio un error al consultar rendimientos. Consulte con su proveedor.");
      }
    );
  }
  getPieBoni(){
    var currentYear = (new Date()).getFullYear();
    var semana = this.datePipe.transform(new Date(),"w");
    var currentMount = (new Date()).getMonth();
    this.totalfondoBoni=[];
    this.pieChartLabelsBoni = [];
    this.pieChartDataBoni = [];
    this.totalUsuBoni = 0;
    for(let x of this.listFondo){
      var count = 0;
      var tot = 0;
      x.anything.forEach(t=>{
        if(t.bonificacion_id != undefined){
          if(this.radioModelBoni=='s'){
            if(t.s_bon == Number(semana)-1 && t.a_bon == currentYear){
              count = count + t.personas_asignadas;
              tot = tot + Number(t.monto);
              this.totalUsuBoni = this.totalUsuBoni + t.personas_asignadas;
            }
          }else if(this.radioModelBoni=='m'){
            if(t.m_bon == currentMount && t.a_bon == currentYear){
              count = count + t.personas_asignadas;
              tot = tot + Number(t.monto);
              this.totalUsuBoni = this.totalUsuBoni + t.personas_asignadas;
            }
          }else if(this.radioModelBoni=='a'){
            if(t.a_bon == currentYear){
              count = count + t.personas_asignadas;
              tot = tot + Number(t.monto);
              this.totalUsuBoni = this.totalUsuBoni + t.personas_asignadas;
            }
          }
        }
      });
      this.totalfondoBoni.push(tot);
      this.pieChartDataBoni.push(count);
      this.pieChartLabelsBoni.push(x.nombrefondo);
    }
  }
  getPieBene(){
    var currentYear = (new Date()).getFullYear();
    var semana = this.datePipe.transform(new Date(),"w");
    var currentMount = (new Date()).getMonth();
    this.pieChartLabelsBene = [];
    this.pieChartDataBene = [];
    this.totalfondoBene = [];
    this.totalUsuBene = 0;
    for(let x of this.listFondo){
      var count = 0;
      var tot = 0;
      x.anything.forEach(t=>{
        if(t.beneficio_id != undefined){
          if(this.radioModelBene=='s'){
            if(t.s_ben == Number(semana)-1 && t.a_ben == currentYear){
              tot = tot + Number(t.monto);
              this.totalUsuBene = this.totalUsuBene + t.personas_asignadas;
              count = count + t.personas_asignadas;  
            }
          }else if(this.radioModelBene=='m'){
            if(t.m_ben == currentMount && t.a_ben == currentYear){
              tot = tot + Number(t.monto);
              this.totalUsuBene = this.totalUsuBene + t.personas_asignadas;
              count = count + t.personas_asignadas;
            }
          }else if(this.radioModelBene=='a'){
            if(t.a_ben == currentYear){
              tot = tot + Number(t.monto);
              this.totalUsuBene = this.totalUsuBene + t.personas_asignadas;
              count = count + t.personas_asignadas;
            }
          }
        }  
      });
      this.totalfondoBene.push(tot);
      this.pieChartDataBene.push(count);
      this.pieChartLabelsBene.push(x.nombrefondo);
    }
  }
  
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'left',
      labels:{
        boxWidth:12
      }
    },
    plugins: {
      datalabels: {
        color: '#36A2EB',
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return "";
        },
      },
    }
  };
  public pieChartLabelsBene: Label[] = [['No existen datos']];
  public pieChartLabelsBoni: Label[] = [['No existen datos']];
  public pieChartDataBene: number[] = [0];
  public pieChartDataBoni: number[] = [0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgb(17, 36, 59)','rgb(54,63,118)','rgb(51,86,127)','rgb(104,112,162)','rgb(19, 80, 154)','rgb(76,125,183)','rgb(102,141,194)','rgb(138,163,204)','rgb(163,181,212)','rgb(185,198,221)','rgb(70,98,129)']
    },
  ];
  btn_bene(){
    if(this.radioModelBene=='s'){
      var currentYear = (new Date()).getFullYear();
      var semana = this.datePipe.transform(new Date(),"w");
      this.pieChartLabelsBene = [];
      this.pieChartDataBene = [];
      this.totalUsuBene = 0;
      for(let x of this.listFondo){
        var count = 0;
        x.anything.forEach(t=>{
          if(t.beneficio_id != undefined){
            if(t.s_ben == Number(semana)-1 && t.a_ben == currentYear){
              this.totalUsuBene = this.totalUsuBene + t.personas_asignadas;
              count = count + t.personas_asignadas;
            }
          }  
        });
        this.pieChartDataBene.push(count);
        this.pieChartLabelsBene.push(x.nombrefondo);
      }
    }else if (this.radioModelBene=='m'){
      var currentYear = (new Date()).getFullYear();
      var currentMount = (new Date()).getMonth();
      this.pieChartLabelsBene = [];
      this.pieChartDataBene = [];
      this.totalUsuBene = 0;
      for(let x of this.listFondo){
        var count = 0;
        x.anything.forEach(t=>{
          if(t.beneficio_id != undefined){
            if(t.m_ben == currentMount && t.a_ben == currentYear){
              this.totalUsuBene = this.totalUsuBene + t.personas_asignadas;
              count = count + t.personas_asignadas;
            }
          }  
        });
        this.pieChartDataBene.push(count);
        this.pieChartLabelsBene.push(x.nombrefondo);
      }
    }else if (this.radioModelBene=='a'){
      this.getPieBene();
    }
  }
  editNota(){

  }
}