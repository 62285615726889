import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatTableDataSource } from '@angular/material/table';
import { Administradora } from 'src/app/model/administradora.model';
import { Data } from 'src/app/model/z.model';
import { Pais } from 'src/app/model/pais.model';
import { Provincia } from 'src/app/model/provincia.model';
import { Ciudad } from 'src/app/model/ciudad.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralService } from 'src/app/service/general.service';
import { ImageSnippet } from 'src/app/model/imageSnippet.model';

@Component({
  selector: 'app-mant-administradora',
  templateUrl: './mant-administradora.component.html',
  styleUrls: ['./mant-administradora.component.css']
})
export class MantAdministradoraComponent implements OnInit {
  isInvierte:boolean = false;
  paises:Pais[] = [];
  listProvincias:Provincia[]=[];
  listCiudades:Ciudad[]=[];
  isLoading:boolean;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['ruc','razonSocial','nombreComercial','direccion','email','telefono','acciones'];
  listAdministradora: Administradora[]=[];
  isLoading2:boolean;
  isCollapsed:boolean = true;
  formAdministradora:FormGroup;
  modalRef: BsModalRef;
  administradoraSelected: Administradora;
  selectedFile: ImageSnippet = new ImageSnippet(null,null);
  constructor(private generalService:GeneralService,private _snackBar: MatSnackBar,private modalService: BsModalService,private _builder: FormBuilder) {
    this.formAdministradora = this._builder.group({
      administradora_fondo_id:[null],
      pais_id: ['',[Validators.required]],
      ruc:['',[Validators.required,Validators.maxLength(13),Validators.minLength(13)]],
      razon_social:['',[Validators.required]],
      nombre_comercial:['',[Validators.required]],
      direccion:['',[Validators.required]],
      email:['',[Validators.required]],
      telefonos:['',[Validators.required]],
      sitioweb:[''],
      url:['']
    });
  }
  clear(){
    this.isCollapsed = true;
    this.formAdministradora.reset({administradora_fondo_id:null,pais_id:'',ruc:'',razon_social:'',nombre_comercial:'',direccion:'',email:'',telefonos:'',sitioweb:'',url:''});
    this.selectedFile = new ImageSnippet(null,null);
    this.formAdministradora.enable();
  }
  ngOnInit() {
    this.getPaises();
    this.getAllAdministradora();
  }
  openModal(template: TemplateRef<any>,f:Administradora) {
    this.administradoraSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }
  getPaises(){
    this.paises =[];
    this.generalService.useService(Pais.getAll()).subscribe(
      (d:any)=>{
        for(let f in d.datos){
          this.paises.push(new Pais(d.datos[f]));
        }
      },(err:HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  save(form){
    this.isLoading = true;
    if(this.selectedFile.file != null){
      this.generalService.uploadImage(this.selectedFile.file,"adm").subscribe(
        (res:any) => {
          form.url = res.datos[0].url
          this.saveAdministradora(form);
        },
      (err) => {
        console.log(err);
      })
    }else{
      this.saveAdministradora(form);
    }
  }
  saveAdministradora(form){
    this.generalService.useService(Administradora.post(form)).subscribe(
      (data : any)=>{
        this.isLoading=false;
        this.changeAdm(this.isInvierte);
        this.clear();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  changeAdm(e){
    if(e.checked){
      this.isInvierte = true;
      this.getInvierte();
    }else{
      this.isInvierte = false;
      this.getAllAdministradora();
    }
  }
  viewAdministradora(f:Administradora,ban:boolean){
    this.isCollapsed = false;
    this.administradoraSelected = f;
    this.selectedFile.src = Data._trim(f.url);
    console.log(this.selectedFile);
    this.formAdministradora.patchValue(this.administradoraSelected);
    if(ban){
      this.formAdministradora.disable();
    }
    else{
      this.formAdministradora.enable();
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000
    });
  }
  delAdministradora(){
    this.isLoading=true;
    this.generalService.useService(Administradora.delete(this.administradoraSelected.administradora_fondo_id)).subscribe(
      (data : any)=>{
        console.log(data);
        this.getAllAdministradora();
        this.modalRef.hide();
        this.isLoading=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  getAllAdministradora(){
    this.isLoading2 = true;
    this.listAdministradora = [];
    this.dataSource = new MatTableDataSource<Administradora>(this.listAdministradora);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(Administradora.getAll()).subscribe(
      (data : any)=>{
        console.log(data);
        for (let t in data.datos) {
          this.listAdministradora.push(new Administradora(data.datos[t] as Administradora));
        }
        console.log(this.listAdministradora);
        this.dataSource = new MatTableDataSource<Administradora>(this.listAdministradora);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        let x = JSON.parse(Data._limpiarJson(err.error.text,"administradora{"))
        for (let t in x.datos) {
          this.listAdministradora.push(new Administradora(x.datos[t] as Administradora));
        }
        this.dataSource = new MatTableDataSource<Administradora>(this.listAdministradora);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      }
    );
  }
  getInvierte(){
    this.isLoading2 = true;
    this.listAdministradora = [];
    this.dataSource = new MatTableDataSource<Administradora>(this.listAdministradora);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(Administradora.getInvierte()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listAdministradora.push(new Administradora(data.datos[t] as Administradora));
        }
        console.log(this.listAdministradora);
        this.dataSource = new MatTableDataSource<Administradora>(this.listAdministradora);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  processFile(imageInput: any) {
    let file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.selectedFile.pending = true;
    });
    reader.readAsDataURL(file);
  }
}