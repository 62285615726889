import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter, SimpleChanges, IterableDiffers, IterableDiffer } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatosPersonales } from 'src/app/model/persona/datospersonales.model';
import { DatosCuenta } from 'src/app/model/persona/datosCuenta.model';
import { NivelInstruccion } from 'src/app/model/nivelInstruccion.model';
import { Data } from 'src/app/model/z.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatosConyugue } from 'src/app/model/datosConyugue.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { Imagen } from 'src/app/model/imagen.model';
import { Observacion } from 'src/app/model/observaciones.model';

@Component({
  selector: 'app-ret-datos-generales',
  templateUrl: './ret-datos-generales.component.html',
  styleUrls: ['./ret-datos-generales.component.css']
})
export class RetDatosGeneralesComponent implements OnInit, OnChanges {
  hiddenForm = false;
  hiddenDC = false;
  banText:boolean = false;
  @ViewChild('tab1', {static: true}) paginator: MatPaginator;
  @ViewChild('tab2', {static: true}) paginator2: MatPaginator;
  displayedColumns: string[] = ['entidad','tipo'];
  dataSource;
  @Input() dc: DatosConyugue = new DatosConyugue();
  @Input() d: DatosPersonales = new DatosPersonales();
  @Input() obsList:Observacion[]=[];
  @Input() dctas: DatosCuenta[]=[];
  @Input() i:Imagen[] = [];
  @Input() nInst: NivelInstruccion = new NivelInstruccion();
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  paramBusquedaGroup:FormGroup;
  cboTipoIdentificacion: DetalleCatalogo[] = [];
  cboNacionalidad: DetalleCatalogo[] = [];
  cboNivelEstudio: DetalleCatalogo[] = [];
  cboEstadoCivil: DetalleCatalogo[] = [];

  urlImg:Imagen = new Imagen();
  iterableDiffer: IterableDiffer<any>;
  constructor(private _builder: FormBuilder) {
    this.paramBusquedaGroup = this._builder.group({
      nombres: [''],
      apellidos:[''],
      tipoidentificacion_id:[''],
      identificacion:[''],
      nombresConyugue:[''],
      apellidosConyugue:[''],
      tipoIdentificacionConyugue:[''],
      identificacionConyugue:[''],
      fechaExpedicion:[''],
      codigo_dactilar:[''],
      nacionalidad:[''],
      fecha_nacimiento:[''],
      nivelEducativo:[''],
      estadocivil:['']
    });
    this.paramBusquedaGroup.disable();
  }
  ngOnChanges(changes: SimpleChanges){
    if(this.dc != null){
      if(this.dc.conyugue_id == null){
        this.hiddenDC = true;
      }else{
        this.hiddenDC = false;
      }
    }
    if(this.dctas.length == 0 && this.nInst.niveleducacion_id == null){
      this.hiddenForm = true;
    }else{
      this.hiddenForm = false;
    }
    this.dataSource = new MatTableDataSource<DatosCuenta>(this.dctas);
    this.dataSource.paginator = this.paginator;
    
  }
  ngOnInit() {
    this.cboTipoIdentificacion = Data._fListCatalogo("TIPO DE IDENTIFICACION PERSONA");
    this.cboNacionalidad = Data._fListCatalogo("NACIONALIDAD");
    this.cboNivelEstudio = Data._fListCatalogo("NIVEL DE EDUCACION");
    this.cboEstadoCivil = Data._fListCatalogo("ESTADO CIVIL");
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
  showImg(img:string){
    for(let x of this.i){
      if(Data._trim(x.nombre)==img){
        this.urlImg=x;
      }
    }
  }
}