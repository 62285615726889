import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { Data } from 'src/app/model/z.model';
import { MatPaginator } from '@angular/material/paginator';
import { Bonificacion } from 'src/app/model/bonificacion.model';
import { MatTableDataSource } from '@angular/material/table';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
defineLocale('es', esLocale);

@Component({
  selector: 'app-nueva-bonificacion',
  templateUrl: './nueva-bonificacion.component.html',
  styleUrls: ['./nueva-bonificacion.component.css']
})
export class NuevaBonificacionComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource;
  dataSourceFondos;
  displayedColumns: string[] = ['rangoInversion','montoBonificacion','rangoFechas','acciones'];
  displayedColumnsFondos: string[] = ['select','fondo'];
  listBonificacion: Bonificacion[]=[];
  isLoading:boolean;
  btnLoading:boolean;
  tipoFondos: FondoInversion[] = [];
  paramBusquedaGroup:FormGroup;
  tipoBonificacionForm:FormGroup;
  modalRef: BsModalRef;
  asignados:any[]=[];
  idBoniSelected;
  selection = new SelectionModel<FondoInversion>(true, []);
  constructor(private generalService: GeneralService,private modalService: BsModalService,private _builder: FormBuilder,private localeService: BsLocaleService) {
    this.localeService.use('es');
  }

  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.dataSourceFondos = new MatTableDataSource<FondoInversion>(this.tipoFondos);
    this.paramBusquedaGroup = this._builder.group({
      rangoFecha:[''],
      piso:[''],
      techo:[''],
      monto:['']
    });
    this.tipoBonificacionForm = this._builder.group({
      nombreBonificacion: ['']
    });
    this.getAllBonificacion();
  }
  openModal(template: TemplateRef<any>,asignados:any[],idBoni) {
    this.asignados = asignados;
    this.idBoniSelected = idBoni;
    for(let y of asignados){
     for(let x of this.tipoFondos){  
        if(x.fondoinversion_id == y.fondoinversion_id){
          this.selection.select(x);
        }
      }
    }
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }
  cerrarModal(){
    this.modalRef.hide();
    this.asignados=[];
    this.selection.clear();
    this.idBoniSelected="";
  }
  
  find(form){
    form.fecha_inicio = form.rangoFecha[0];
    form.fecha_fin = form.rangoFecha[1];
    this.btnLoading = true;
    this.generalService.useService(Bonificacion.post(form)).subscribe(
      (data : any)=>{
        this.paramBusquedaGroup.reset();
        this.btnLoading = false;
        this.getAllBonificacion();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  disasbleIf(row:FondoInversion){
    console.log(this.asignados);
    for(let y of this.asignados){
      console.log(row.fondoinversion_id+"::"+y.fondoinversion_id);
      if(y.fondoinversion_id==row.fondoinversion_id){
        console.log("entre")
        return true;
      }
    }
    return false;
  }
  saveAsignacion(){
    var num = this.selection.selected.length;
    this.selection.selected.forEach((t,idx)=>{
      if(!this.disasbleIf(t)){
        this.btnLoading = true;
        this.generalService.useService(Bonificacion.assign(t.fondoinversion_id,this.idBoniSelected)).subscribe(
          (data)=>{
            console.log(data);
            this.getAllBonificacion();
            if((idx+1)==num){
              this.modalRef.hide();
              this.asignados=[];
              this.idBoniSelected="";
              this.btnLoading = false;
            }
          },(err:HttpErrorResponse)=>{
            console.log(err); 
          }
        );     
      }
    })
  }
  getAllBonificacion(){
    this.listBonificacion=[];
    this.dataSource = new MatTableDataSource<Bonificacion>(this.listBonificacion);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.isLoading = true;
    this.generalService.useService(Bonificacion.getAll()).subscribe(
      (data : any)=>{
        console.log(data);
        for (let t in data.datos) {
          this.listBonificacion.push(new Bonificacion(data.datos[t] as Bonificacion));
        }
        this.dataSource = new MatTableDataSource<Bonificacion>(this.listBonificacion);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: FondoInversion): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
  }
}