import { Component, OnInit, Input, ViewChild, Output, EventEmitter, TemplateRef, IterableDiffers, IterableDiffer } from '@angular/core';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SolicitudInversion } from 'src/app/model/solicitudes/solicitudInversion.model';
import { DatosCuenta } from 'src/app/model/persona/datosCuenta.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Imagen } from 'src/app/model/imagen.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from 'src/app/service/general.service';
import { ParticipacionDiaria } from 'src/app/model/participacionDiaria.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Observacion } from 'src/app/model/observaciones.model';

@Component({
  selector: 'app-inventario-respuesta',
  templateUrl: './inventario-respuesta.component.html',
  styleUrls: ['./inventario-respuesta.component.css']
})
export class InventarioRespuestaComponent implements OnInit {
  @Input() f: FondoInversion = new FondoInversion();
  @Input() s: SolicitudInversion;
  @Input() dc: DatosCuenta;
  @Input() banCheck:boolean;
  @Input() i:Imagen = new Imagen();
  @Input() blockedObsCuenta:Boolean;
  @Input() vf:any;
  modalRef: BsModalRef;
  banText:boolean = false;
  alertType = "danger";
  paramBusquedaGroup:FormGroup;
  
  displayedColumns2: string[] = ['mensaje','estado'];
  @ViewChild('tab2', {static: true}) paginator2: MatPaginator;
  @Input() obsList:Observacion[]=[];
  dataSource2;
  iterableDiffer: IterableDiffer<any>;
  constructor(private iterableDiffers: IterableDiffers,private generalService:GeneralService,private _builder: FormBuilder,private modalService: BsModalService) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }
  dataSource;
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['entidad','tipo','numero','estadoCuenta'];
  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      cuentaBancaria: [{value: "", disabled:true}],
      cuentaBancariaInc:[{value:"", disabled:true}]
    });
    
  }
  ngOnChanges(){
    var dcs:DatosCuenta[] = [];
    if(this.dc!=null){
      dcs.push(this.dc);
    }
    this.dataSource = new MatTableDataSource<DatosCuenta>(dcs);
    this.dataSource.paginator = this.paginator;
  }
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.obsList);
    if (changes) {
      var obs = this.obsList.filter(t=>(t.paso==9));
      this.dataSource2 = new MatTableDataSource<Observacion>(obs);
    }
  }
  parseFloat(val){
    return Number(val);
  }
  changeStatusObs(e){
    this.banText=e.checked;
    this.obsCheck.emit(this.banText);
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('size-modal');
  }
}