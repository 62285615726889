import { Data } from '../z.model';

export class ReportBoni {
    personas_asignadas:number;
    fondoinversion_id: number;
    nombrefondo:string;
    bonificacion_id: number;
    a_bon: number;
    m_bon: number;
    s_bon: number;
    monto: number;
    constructor(b:ReportBoni = null){
        if(b != null){
            this.personas_asignadas=b.personas_asignadas;
            this.fondoinversion_id=b.fondoinversion_id;
            this.nombrefondo=b.nombrefondo;
            this.bonificacion_id=b.bonificacion_id;
            this.a_bon = b.a_bon;
            this.m_bon = b.m_bon;
            this.s_bon = b.s_bon;
            this.monto = b.monto;
        }
    }
    public static getAll(anio:number) {
        var tmp = Data._getBody("C8",168,sessionStorage.getItem("token_fks"));
        tmp["data"]["anio"] = anio;
        return tmp;
    }
}