import { Data } from '../z.model';

export class SolicitudDebito {
    solicitud_debito_id:number;
    solicitud_inversion_id:number;
    tipo_solicitud_id:number;
    fondoinversion_id:number;
    persona_id:number;
    nombre:string;
    identificacion:string;
    nombrefondo:string;
    transaccion_id: string;
    monto_debitar:string;
    concepto: string;
    estado:string;
    estado_nombre:string;
    estado_color:string;
    fecha_registro:Date;
    tipocuenta_obj:any;
    financiera_id:number;
    financiera_nombre:string;
    tipocuenta_id:number;
    tipocuenta_nombre:string;
    numero:string;
    email:string;
    telefono: string;
    tipoidentificacion_id:number;
    tipoidentificacion_nombre:string;
    tipoidentificacion_obj:any;
    referencia:string;
    constructor(sd:SolicitudDebito = null){
        if(sd != null){
            this.email = sd.email;
            this.telefono = sd.telefono;
            this.tipoidentificacion_id = sd.tipoidentificacion_id;
            this.tipoidentificacion_nombre = Data._fCatalogo(sd.tipoidentificacion_id,"TIPO DE IDENTIFICACION PERSONA");
            this.numero = Data._atob(sd.numero);
            this.financiera_id = sd.financiera_id;
            if(sd.financiera_id)
                this.financiera_nombre = Data._findFinanciera(sd.financiera_id).Nombre || '';
            this.tipocuenta_nombre = Data._fCatalogo(sd.tipocuenta_id,"TIPO DE CUENTA");
            this.tipocuenta_id = sd.tipocuenta_id;
            this.tipocuenta_obj = Data._fCatalogoObj(sd.tipocuenta_id,"TIPO DE CUENTA");
            this.tipoidentificacion_obj = Data._fCatalogoObj(sd.tipoidentificacion_id,"TIPO DE IDENTIFICACION PERSONA");
            this.referencia = sd.referencia;

            this.solicitud_debito_id = sd.solicitud_debito_id;
            this.solicitud_inversion_id = sd.solicitud_inversion_id;
            this.tipo_solicitud_id = sd.tipo_solicitud_id;
            this.fondoinversion_id = sd.fondoinversion_id;
            this.persona_id = sd.persona_id;
            this.transaccion_id = Data._trim(sd.transaccion_id)==""?"NINGUNO":Data._trim(sd.transaccion_id);
            this.monto_debitar = sd.monto_debitar;
            this.concepto = Data._trim(sd.concepto);
            this.estado = sd.estado;
            if(sd.estado == 'A'){
                this.estado_nombre = "APROBADO";
                this.estado_color = "success";
            }else if(sd.estado == 'P'){
                this.estado_nombre = "PENDIENTE";
                this.estado_color = "warning";
            }else if(sd.estado == 'C'){
                this.estado_nombre = "CANCELADO";
                this.estado_color = "danger";
            }else if(sd.estado == 'R'){
                this.estado_nombre = "REVISION ESPECIAL";
                this.estado_color = "primary";
            }else if(sd.estado == 'O'){
                this.estado_nombre = "OBSERVADO";
                this.estado_color = "secondary";
            }
            this.fecha_registro = new Date(sd.fecha_registro['date']);
            this.nombre = sd.nombre;
            this.nombrefondo = sd.nombrefondo.trim();
            this.identificacion = sd.identificacion
        }
    }
    public static getAll() {
        return Data._getBody("C",166,sessionStorage.getItem("token_fks"));
    }
    public static retornoDebito(obj:any){
        let tmp = Data._getBody("rdebito",103,sessionStorage.getItem("token_fks"));
        tmp["data"]["solicitud_debito_id"] = obj.solicitud_debito_id;
        tmp["data"]["estado"] = obj.estado;
        tmp["data"]["mensaje"] = obj.mensaje;
        tmp["data"]["comprobante"] = obj.comprobante;
        tmp["data"]["referencia"] = obj.referencia;
        return tmp;
    }
}