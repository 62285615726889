import { Data } from './z.model';

export class PermisoServicio {
    id_APP: string;
    identificador: number;
    nombre: string;
    descripcion_corta: string;
    tipo: number;
    estado: boolean;
    constructor(i:any = null){
        if(i != null){
            this.id_APP = i.id_APP;
            this.identificador = i.identificador;
            this.nombre = Data._trim(i.nombre);
            this.descripcion_corta = Data._trim(i.descripcion_corta);
            this.tipo = i.tipo;
            this.estado = i.estado==1?true:false;
        }
    }
    public static getAll(){
        return Data._getBody("C",140,sessionStorage.getItem("token_fks"));
    }
    public static getAllApp(){
        return Data._getBody("C1",140,sessionStorage.getItem("token_fks"));
    }
    public static getAllQrt(){
        return Data._getBody("C2",140,sessionStorage.getItem("token_fks"));
    }
    public static post(serv:any){
        var tmp = Data._getBody("I",141,sessionStorage.getItem("token_fks"));
        tmp["data"]["nombreapp"] = serv.nombreapp;
        return tmp;
    }
    public static postPermiso(serv:any){
        var tmp = Data._getBody("I3",141,sessionStorage.getItem("token_fks"));
        tmp["data"]["idapp"] = serv.idapp;
        tmp["data"]["id_qrt"] = serv.idqrt;
        return tmp;
    }
    public static put(id:number, estado:boolean){
        var tmp = Data._getBody("U",142,sessionStorage.getItem("token_fks"));
        tmp["data"]["id_APP_qrt"] = id;
        tmp["data"]["estado"] = estado?1:0;
        return tmp;
    }
    public static delete(id:string){
        var tmp = Data._getBody("D",143,sessionStorage.getItem("token_fks"));
        tmp["data"]["id_APP"] = id;
        return tmp;
    }
}