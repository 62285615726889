import { Data } from './z.model';

export class Nota {
    nota_id:number;
    persona_id:number;
    nota:string;
    constructor(ps:Nota=null){
        if(ps!=null){
            this.persona_id = ps.persona_id;
            this.nota = ps.nota.trim();
            this.nota_id = ps.nota_id;
        }
    }
    public static getAll(){
        var tmp = Data._getBody("C",179,sessionStorage.getItem("token_fks"));
        tmp["data"]["persona_id"] = 0;
        return tmp;
    }
    public static post(f:Nota){
        var tmp = Data._getBody("I",180,sessionStorage.getItem("token_fks"));
        if(f.nota_id){
            tmp = Data._getBody("U",178,sessionStorage.getItem("token_fks"));
            tmp["data"]["nota_id"] = f.nota_id;
        }
        tmp["data"]["persona_id"] = 0;
        tmp["data"]["nota"] = f.nota;
        return tmp;
    }
    public static delete(id:number){
        var tmp = Data._getBody("D",181,sessionStorage.getItem("token_fks"));
        tmp["data"]["nota_id"] = id;
        return tmp;
    }
}