import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { Data } from 'src/app/model/z.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
defineLocale('es', esLocale);
@Component({
  selector: 'app-nueva-notificacion',
  templateUrl: './nueva-notificacion.component.html',
  styleUrls: ['./nueva-notificacion.component.css']
})
export class NuevaNotificacionComponent implements OnInit {
  @ViewChild('labelImport',{static: true}) labelImport: ElementRef;
  fileToUpload: File = null;
  
  paramBusquedaGroup:FormGroup;
  tipoFondos: FondoInversion[] = Data._fondosInversion;
  constructor(private _builder: FormBuilder,private localeService: BsLocaleService) {
    this.localeService.use('es');
  }

  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      tipoBeneficio: [''],
      FondoInversion:[''],
      fechaD:[''],
      fechaH:[''],
      horaInicial:[''],
      horaFinal:[''],
      montoInicial:[''],
      montoFinal:[''],
      asunto:[''],
      archivo:[''],
      texto:[''],
      mensajeTexto:[''],
      email:[''],
      whatsapp:[''],
      push:['']
    });
  }

  find(form){
    console.log(form);
  }

  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }
}
