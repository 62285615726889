import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatosLaborables } from 'src/app/model/datosLaborables.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { LugarTrabajo } from 'src/app/model/lugarTrabajo.model';
import { DataService } from 'src/app/service/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';

@Component({
  selector: 'app-lugar-trabajo',
  templateUrl: './lugar-trabajo.component.html',
  styleUrls: ['./lugar-trabajo.component.css']
})
export class LugarTrabajoComponent implements OnInit {
  @Input() d: DatosLaborables;
  @Input() lt: LugarTrabajo;
  @Input() tipopers: boolean;
  banText:boolean = false;
  pais = "Ninguno";
  prov = "Ninguno";
  ciud = "Ninguno";
  paramBusquedaGroup:FormGroup;
  cboActividadEconomica: DetalleCatalogo[] = [];
  cboRelacionLaboral: DetalleCatalogo[] = [];
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  constructor(private _builder: FormBuilder,private dataService:DataService) {
    this.paramBusquedaGroup = this._builder.group({
      relacionlaboral:  [''],
      tiponegocio:      [''],
      empresatrabajo:   [''],
      area_trabajo:     [''],
      cargo:            [''],
      fecha_inicioactividades:[''],
      ingresomensual:   [''],
      pais:             [''],
      provincia:        [''],
      ciudad:           [''],
      direccion:        [''],
      piso:             [''],
      sector:           [''],
      telefono:         ['']
    });
    this.paramBusquedaGroup.disable();
  }
  ngOnChanges(){
    if(this.lt != null){
      this.pais = Data._findPais(this.lt.pais_id);
      this.dataService.getDivisionPais(this.lt.pais_id).subscribe(
        (data : any)=>{
          var pro = data.datos;
          var ciu = null;
          for (let i in pro) {
            if(Number(i) == this.lt.provincia_id){
              ciu = pro[i];
              this.prov = ciu.descripcion;
              for (let j in ciu.ciudades) {
                if(Number(j) == this.lt.ciudad_id){
                  this.ciud = ciu.ciudades[j].descripcion;
                }
              }
            }
          }
        },
        (err : HttpErrorResponse)=>{
        }
      );
    }
  }
  ngOnInit() {
    this.cboActividadEconomica = Data._fListCatalogo("TIPO NEGOCIO");
    this.cboRelacionLaboral = Data._fListCatalogo("RELACION LABORAL");
  }
  changeStatusObs(e){
    this.banText=e.checked;
    this.obsCheck.emit(this.banText);
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
}