import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Data } from '../model/z.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Financiera } from '../model/financiera.model';

@Injectable({
  providedIn: 'root'
})
export class InstitucionFinancieraService {
  url2=environment.apiHost2;
  object;
  constructor(private http:HttpClient) { }
  async getAllInstitucionFinanciera(): Promise<any>{
    var tmp = Data._getBody("C",43,sessionStorage.getItem("token_fks"));
    var reqHeader = new HttpHeaders();
    try {
      let res = await this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader }).toPromise();
      this.object = res;
      Data._financieras = [];
      for(let f in this.object.datos){
        Data._financieras.push(new Financiera(this.object.datos[f]));
      }
      return Data._financieras;
    } catch(err) {
      console.log(err);
    }
  }
  postInstitucionFinanciera(f:Financiera){
    var tmp = Data._getBody("I",43,sessionStorage.getItem("token_fks"));
    if(f.financiera_id != null){
      tmp = Data._getBody("U",43,sessionStorage.getItem("token_fks"));
      tmp["data"]["financiera_id"] = f.financiera_id;
    }
    tmp["data"]["tipofinanciera_id"] = f.tipofinanciera_id;
    tmp["data"]["pais_id"] = f.pais_id;
    tmp["data"]["codigoentidad"] = f.codigoentidad;
    tmp["data"]["ruc"] = "0999999999";
    tmp["data"]["Nombre"] = f.Nombre;
    tmp["data"]["direccionmatriz"] = f.direccionmatriz;
    tmp["data"]["telefono"] = f.telefono;
    tmp["data"]["estado"] = "A";
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
  deleteInstitucionFinanciera(id:Number){
    var tmp = Data._getBody("D",43,sessionStorage.getItem("token_fks"));
    tmp["data"]["financiera_id"] = id;
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
}