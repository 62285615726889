import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { PreguntaSecreta } from 'src/app/model/preguntaSecreta.model';
import { GeneralService } from 'src/app/service/general.service';
import { AlertService } from 'src/app/util/alerts/alert.service';
import { Data } from 'src/app/model/z.model';

@Component({
  selector: 'app-mant-preguntas',
  templateUrl: './mant-preguntas.component.html',
  styleUrls: ['./mant-preguntas.component.css']
})
export class MantPreguntasComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['descripcion','acciones'];
  preguntaSelected: PreguntaSecreta;
  listPregunta: PreguntaSecreta[]=[];
  paramBusquedaGroup:FormGroup;
  modalRef: BsModalRef;
  isCollapsed = true;
  isLoading2:boolean = true;
  isLoading:boolean=false;
  fech:Date= new Date();
  isDeleting: boolean;
  @ViewChild('modalDelete', { static: false }) modalDelete: ModalDirective;
  constructor(private generalService:GeneralService,private alertService: AlertService ,private _builder: FormBuilder) {
    this.paramBusquedaGroup = this._builder.group({
      id_pregunta: [null],
      descripcion: ['',Validators.required]
    });
  }
  clear(){
    this.isCollapsed = true;
    this.preguntaSelected = null;
    this.paramBusquedaGroup.reset({
      id_pregunta: null,
      descripcion: ''
    });
  }
  viewCosto(f:PreguntaSecreta,ban:boolean){
    this.isCollapsed = false;
    this.paramBusquedaGroup.patchValue(f);
  }
  ngOnInit() {
    this.getAllPreguntaSecreta()
  }
  save(form){
    this.isLoading = true;
    this.generalService.useService(PreguntaSecreta.post(form)).subscribe(
      (data : any)=>{
        if(Data.isOk(data)){
          this.alertService.success("Operación realizada.",{autoClose:true});
          this.getAllPreguntaSecreta();
          this.clear();
          this.isLoading = false;
        }else{
          this.alertService.responseService(data);
        }
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  getAllPreguntaSecreta(){
    this.listPregunta = [];
    this.generalService.useService(PreguntaSecreta.getAll()).subscribe(
      (data : any)=>{
        this.fillPregunta(data.datos);
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  fillPregunta(datos){
    for (let t in datos) {
      this.listPregunta.push(new PreguntaSecreta(datos[t] as PreguntaSecreta));
    }
    this.dataSource = new MatTableDataSource<PreguntaSecreta>(this.listPregunta);
    this.dataSource.paginator = this.paginator;
  }
  deletePregunta(){
    this.isDeleting=true;
    this.generalService.useService(PreguntaSecreta.delete(this.preguntaSelected.id_pregunta)).subscribe(
      (data : any)=>{
        console.log(data);
        this.getAllPreguntaSecreta();
        this.modalDelete.hide();
        this.preguntaSelected = null;
        this.isDeleting=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}
