import { Component, OnInit, Input, ViewChild, Output, EventEmitter, IterableDiffers, IterableDiffer } from '@angular/core';
import { SituacionFinanciera } from 'src/app/model/situacionFinanciera.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InfoEconomicaFinanciera } from 'src/app/model/infoEconomicaFinanciera.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { Data } from 'src/app/model/z.model';
import { EstadoFinanciero } from 'src/app/model/estadoFinanciero.model';
import { Observacion } from 'src/app/model/observaciones.model';

@Component({
  selector: 'app-situacion-financiera',
  templateUrl: './situacion-financiera.component.html',
  styleUrls: ['./situacion-financiera.component.css']
})
export class SituacionFinancieraComponent implements OnInit {
  @Input() sfa: SituacionFinanciera[];
  @Input() sfp: SituacionFinanciera[];
  @Input() sfi: SituacionFinanciera[];
  @Input() sfe: SituacionFinanciera[];
  @Input() ief: InfoEconomicaFinanciera;
  @Input() ef: EstadoFinanciero;
  @Input() p: number = 0;
  @Input() f: number = 0;
  @Input() banCheck:boolean;
  banText:boolean = false;
  banTextEstado:boolean = false;
  displayedColumns: string[] = ['tipo','descripcion','valor'];
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  dataSource;
  @ViewChild('paginator1', {static: true}) paginator1: MatPaginator;
  dataSource1;
  @ViewChild('paginator2', {static: true}) paginator2: MatPaginator;
  dataSource2;
  @ViewChild('paginator3', {static: true}) paginator3: MatPaginator;
  dataSource3;
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsChangeEstado:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  @Output() obsCheckEstado:EventEmitter<boolean> = new EventEmitter();
  paramBusquedaGroup:FormGroup;
  paramBusquedaGroup2:FormGroup;
  cboActividad:DetalleCatalogo[] = [];

  displayedColumns4: string[] = ['mensaje','estado'];
  @Input() obsList:Observacion[]=[];
  dataSource4;
  dataSource5;
  iterableDiffer: IterableDiffer<any>;
  constructor(private iterableDiffers: IterableDiffers,private _builder: FormBuilder) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
    this.paramBusquedaGroup = this._builder.group({
      actividadeconomica_id: [''],
      ingresos_actividadeconomica:[''],
      otros_ingresos:[''],
      egresos_mensuales:[''],
      patrimonio:[''],
      origen_recursos:[]
    });
    this.paramBusquedaGroup.disable();
    this.paramBusquedaGroup2 = this._builder.group({
      totalactivos: [''],
      totalpasivos:[''],
      patrimonio:['']
    });
    this.paramBusquedaGroup2.disable();
  }
  ngOnChanges(){
      this.dataSource = new MatTableDataSource<SituacionFinanciera>(this.sfa);
      this.dataSource.paginator = this.paginator;
      this.dataSource1 = new MatTableDataSource<SituacionFinanciera>(this.sfp);
      this.dataSource1.paginator = this.paginator1;
      this.dataSource2 = new MatTableDataSource<SituacionFinanciera>(this.sfi);
      this.dataSource2.paginator = this.paginator2;
      this.dataSource3 = new MatTableDataSource<SituacionFinanciera>(this.sfe);
      this.dataSource3.paginator = this.paginator3;
  }
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.obsList);
    if (changes) {
      var obs = this.obsList.filter(t=>(t.paso==5));
      this.dataSource4 = new MatTableDataSource<Observacion>(obs);
      var obs = this.obsList.filter(t=>(t.paso==6));
      this.dataSource5 = new MatTableDataSource<Observacion>(obs);
    }
  }
  ngOnInit() {
    this.cboActividad = Data._fListCatalogo("OCUPACION");
  }
  changeStatusObs(e){
    this.banText=e.checked;
    this.obsCheck.emit(this.banText);
  }
  changeStatusObsEstado(e){
    this.banTextEstado=e.checked;
    this.obsCheckEstado.emit(this.banTextEstado);
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
  obtValorEstado(v){
    this.obsChangeEstado.emit(v);
  }
}
