import { Data } from './z.model';

export class Rendimiento {
    persona_id:number;
    administradorafondos_id: number;
    fondoinversion_id:number;
    identificacion:string;
    nombres:string;
    nombrefondo: string;
    referencia: string;
    personafondoinversion_id: number;
    CR: string;
    DB: string;
    semana: number;
    anio: number;
    constructor(r:Rendimiento=null){
        if(r!=null){
            this.persona_id = r.persona_id;
            this.administradorafondos_id = r.administradorafondos_id;
            this.fondoinversion_id = r.fondoinversion_id;
            var nombres = r.nombres.split(" ");
            this.nombres = atob(nombres[0])+' '+atob(nombres[1])
            this.identificacion = atob(r.identificacion);
            this.nombrefondo = r.nombrefondo;
            this.referencia = r.referencia;
            this.personafondoinversion_id = r.personafondoinversion_id;
            this.CR = r.CR;
            this.DB = r.DB;
            this.semana = r.semana;
            this.anio = r.anio;
        }
    }
    public static getAll(){
        return Data._getBody("C5",156,sessionStorage.getItem("token_fks"));
    }
}