import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Data } from 'src/app/model/z.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GeneralService } from 'src/app/service/general.service';
import { NotificacionUsuario } from 'src/app/model/notificacionUsuario.model';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
declare var $: any;
@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.css']
})
export class TopnavbarComponent implements OnInit {
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService, private generalService:GeneralService,private router: Router) { }
  ngOnInit() {
  }
  logout() {
    sessionStorage.clear();
    this.modalRef.hide();
    this.router.navigate(['/login']);
  }
  get notificaciones(){
    return Data._notificaciones;
  }
  get numNoti(){
    return Data._numNotificaciones;
  }
  minimalize(){
    $("body").toggleClass("mini-navbar");
      if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
          $('#side-menu').hide();
          setTimeout(
              function () {
                  $('#side-menu').fadeIn(400);
              }, 200);
      } else if ($('body').hasClass('fixed-sidebar')){
          $('#side-menu').hide();
          setTimeout(
              function () {
                  $('#side-menu').fadeIn(400);
              }, 100);
      } else {
          $('#side-menu').removeAttr('style');
    }
  }
  marcarLeidoNotificacion(id:number){
    this.generalService.useServiceWithBackend(NotificacionUsuario.updateToLeida(id)).subscribe(
      (data:any)=>{
        if(!Data.isOk(data)){
        }
      }
    );
    Data._notificaciones.forEach((t:any,idx)=>{
      if(t.notificacionusuario_id == id && t.leida == "0"){
        t.leida = "1";
        Data._numNotificaciones--;
      }
    })
  }
  getNotificaciones(){
    const calls = [];
    calls.push(this.generalService.useService(NotificacionUsuario.getNoLeidas()));
    calls.push(this.generalService.useService(NotificacionUsuario.getAll()));
    forkJoin(calls).subscribe((responses:any) => {
      Data._numNotificaciones = responses[0].datos[0].n_notificaciones;
      Data._notificaciones = responses[1].datos.map(t=>{
        return new NotificacionUsuario(t);
      });
    })
  }
  eliminarNotificacion(id){
    this.generalService.useServiceWithBackend(NotificacionUsuario.delete(id)).subscribe(
      (data:any)=>{
      }
    );
    Data._notificaciones.forEach((t:any,idx)=>{
      if(t.notificacionusuario_id == id){
        Data._notificaciones.splice(idx,1);
        if(t.leida == "0"){
          Data._numNotificaciones--;
        }
      }
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-sm');
  }
}
