import { Data } from './z.model';

export class Imagen {
    id_tipo: number;
    url:string;
    nombre:string = "";
    persona_id:number;
    estado:string;
    constructor(i:Imagen = null){
        if(i != null){
            this.id_tipo = i.id_tipo;
            this.url = Data._trim(i.url);
            this.nombre = i.nombre;
            this.persona_id = i.persona_id;
            this.estado = i.estado;
        }
    }
}