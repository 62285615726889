import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AlertService } from '../util/alerts/alert.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const CSV_TYPE = 'text/csv;charset=UTF-8;';
const TXT_TYPE = 'text/plain;charset=UTF-8;';

@Injectable({
  providedIn: 'root'
})
export class ExportServicesService {

  constructor(private alertService: AlertService) { }
  public exportAsExcel(json: any[], excelFileName: string): void {
    if(this.banData(json)){
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.downloadFile(excelBuffer, excelFileName, ".xlsx",EXCEL_TYPE);
    }
  }
  public exportAsExcelNoHeader(json: any[], excelFileName: string): void {
    if(this.banData(json)){
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json,{skipHeader: true});
      console.log(worksheet);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.downloadFile(excelBuffer, excelFileName, ".xlsx",EXCEL_TYPE);
    }
  }
  public exportAsTxt(json: any[], excelFileName: string): void {
    if(this.banData(json)){
      const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
      const header = Object.keys(json[0]);
      const csv = json.map((row) =>
        header.map((fieldName) => row[fieldName], replacer)
              .join('\t')
      );
      const csvArray = csv.join('\r\n');
      this.downloadFile(csvArray,excelFileName,".txt", TXT_TYPE);
    }
  }
  exportAsCSV(data: any[], excelFileName: string) {
    if(this.banData(data)){
      const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      const csv = data.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
      );
      csv.unshift(header.join(','));
      const csvArray = csv.join('\r\n');
      this.downloadFile(csvArray,excelFileName,".csv", CSV_TYPE);
    }
  }
  
  private downloadFile(buffer: any, fileName: string, extension:string, type:string): void {
    const data: Blob = new Blob([buffer], {type: type});
    FileSaver.saveAs(data, fileName + extension);
  }
  banData(data){
    if(data.length > 0){
      return true;
    }else{
      this.alertService.warn("No existen datos.");
      return false;
    }
  }
}