import { Data } from './z.model';

export class ParticipacionDiaria {
    participaciondiaria_id:number;
    fondoinversion_id:number;
    unidades:number;
    valorporunidad:number;
    fechahora:Date;
    constructor(b:ParticipacionDiaria=null){
        if(b!=null){
            this.participaciondiaria_id = b.participaciondiaria_id;
            this.fondoinversion_id = b.fondoinversion_id;
            this.unidades = b.unidades;
            this.valorporunidad = b.valorporunidad;
            this.fechahora = new Date(b.fechahora['date']);
        }
    }
    public static getAll(id:number){
        var tmp = Data._getBody("C",152,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = id;
        return tmp;
    }
    public static getDiary(id:number){
        var tmp = Data._getBody("C2",152,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = id;
        return tmp;
    }
    public static post(f:ParticipacionDiaria){
        var tmp = Data._getBody("I",153,sessionStorage.getItem("token_fks"));
        if(f.participaciondiaria_id != null){
          tmp = Data._getBody("U",154,sessionStorage.getItem("token_fks"));
          tmp["data"]["participaciondiaria_id"] = f.participaciondiaria_id;  
        }
        tmp["data"]["fondoinversion_id"] = f.fondoinversion_id;
        tmp["data"]["unidades"] = f.unidades;
        tmp["data"]["valorporunidad"] = f.valorporunidad;
        return tmp;
    }
    public static delete(id:Number){
        var tmp = Data._getBody("D",155,sessionStorage.getItem("token_fks"));
        tmp["data"]["participaciondiaria_id"] = id;
        return tmp;
    }
}