import { Data } from './z.model';

export class RelacionTipoGrupoUsuario {
    tipousuario_grupo_id:number;
    grupo_id:number;
    grupo: string;
    tipousuario_id  :number;
    tipousuario:string;
    constructor(ps:RelacionTipoGrupoUsuario=null){
        if(ps!=null){
            this.tipousuario_grupo_id = ps.tipousuario_grupo_id;
            this.grupo_id = ps.grupo_id;
            this.grupo = ps.grupo;
            this.tipousuario_id = ps.tipousuario_id;
            this.tipousuario = ps.tipousuario;
        }
    }
    public static getAll(){
        return Data._getBody("C",144,sessionStorage.getItem("token_fks"));
    }
    public static post(f:RelacionTipoGrupoUsuario){
        var tmp = Data._getBody("I",145,sessionStorage.getItem("token_fks"));
        if(f.tipousuario_grupo_id != null){
          tmp = Data._getBody("U",146,sessionStorage.getItem("token_fks"));
          tmp["data"]["tipousuario_grupo_id"] = f.tipousuario_grupo_id;
        }
        tmp["data"]["tipousuario_id"] = f.tipousuario_id;
        tmp["data"]["grupo_id"] = f.grupo_id;
        return tmp;
    }
    public static delete(id:number){
        var tmp = Data._getBody("D",147,sessionStorage.getItem("token_fks"));
        tmp["data"]["tipousuario_grupo_id"] = id;
        return tmp;
    }
}