import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { Data } from 'src/app/model/z.model';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FondoInversionService } from 'src/app/service/fondo-inversion.service';
import { MatPaginator } from '@angular/material/paginator';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { NormaFondo } from 'src/app/model/normaFondo.model';
import { Administradora } from 'src/app/model/administradora.model';
import { ImageSnippet } from 'src/app/model/imageSnippet.model';
import { AlertService } from 'src/app/util/alerts/alert.service';

@Component({
  selector: 'app-mant-fondo-inversion',
  templateUrl: './mant-fondo-inversion.component.html',
  styleUrls: ['./mant-fondo-inversion.component.css']
})
export class MantFondoInversionComponent implements OnInit {
  isCollapsed = true;
  isLoading2:boolean;
  isLoading:boolean;
  //COMBOS
  get cbo_TipoInversionista(){ return Data._fListCatalogo("TIPO DE INVERSIONISTA")}
  get cbo_TipoFondo(){return Data._fListCatalogo("TIPO DE FONDO")}
  get cbo_TipoPeriodicidad(){return Data._fListCatalogo("TIPO DE PERIODICIDAD")}
  @ViewChild(MatHorizontalStepper, {static: true}) stepper: MatHorizontalStepper;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<FondoInversion>() ;
  isLinear = true;
  displayedColumns: string[] = ['tipoFondo','nombre','acciones'];
  listFondo: FondoInversion[]=[];
  fondoSelected: FondoInversion;
  normaselected:NormaFondo;
  paramBusquedaGroup:FormGroup;
  documentoForm:FormGroup;
  formNormas:FormGroup;
  modalRef: BsModalRef;
  idAdministradora:number;
  imgAdm:string="https://f5app2ec.invierte.ec/DOCUMENTOS/img/icono_rounded.png";
  
  selectedFile: ImageSnippet = new ImageSnippet(null,null);
  cboAdministradora: any[];
  constructor(private alertService:AlertService,private generalService:GeneralService,private _snackBar: MatSnackBar,private modalService: BsModalService, private _builder: FormBuilder, private fondoService: FondoInversionService) {
    this.paramBusquedaGroup = this._builder.group({
      fondoinversion_id: [null],
      codigo_ext:['',Validators.required],
      tipofondo_id:['',Validators.required],
      nombrefondo:['',Validators.required],
      eslogan:['',Validators.required],
      tasa_activa:['',Validators.required],
      calificacion:['',Validators.required],
      id_periodicidad:['',Validators.required],
      permanencia:['',Validators.required],
      aporte_inicial:['',Validators.required],
      incrementoobligatorio:[false,Validators.required],
      incremento:['',Validators.required],
      rendimiento_anual:['',Validators.required],
      rendimiento_promedio:['',Validators.required],
      politica:['',Validators.required],
      horario:['',Validators.required],
      pdffondo:['',Validators.required],
      url_img:['',Validators.required],
      administradorafondos_id:['',Validators.required]
    });
    this.formNormas = this._builder.group({
      normas_id:[null],
      fondoinversion_id: [null],
      permanencia:['',Validators.required],
      incrementoobligatorio:[false,Validators.required],
      incrementomanual:[false,Validators.required],
      min_io:['',Validators.required],
      max_io:['',Validators.required],
      min_im:['',Validators.required],
      max_im:['',Validators.required],
      permite_retiro_parcial:[false,Validators.required],
      permite_capital_rendimiento:[false,Validators.required],
      perm_rend_retiro:['',Validators.required],
      perm_capital_retiro:['',Validators.required],
      porc_rend_retiro:['',Validators.required],
      porc_capital_retiro:['',Validators.required],
      aplica_penalidad:[false,Validators.required],
      porc_sobre_rend:['',Validators.required],
      porc_sobre_capital_retiro:['',Validators.required],
      aplica_autorenovacion:[false,Validators.required],
      dias_autorenovacion:['',Validators.required],
      aplica_nuevo_contrato:[false,Validators.required],
      dias_antes_rescate:['',Validators.required],
      notificar_cliente:[false,Validators.required]
    });
    this.documentoForm = this._builder.group({
      archivo: ['',Validators.required],
      tipoDocumento:['',Validators.required],
    });
  }
  ngOnInit() {
    this.getAllAdministradora();
  }
  buscarAdm(val){
    this.idAdministradora = val;
    this.getAllFondo(val);
  }
  nuevo(){
    this.clear();
    this.isLinear = false;
    this.isCollapsed = false;
  }
  resetFormFondo(){
    this.paramBusquedaGroup.enable();
    this.paramBusquedaGroup.reset({
      fondoinversion_id: null,
      tipofondo_id:'',
      nombrefondo:'',
      eslogan:'',
      tasa_activa:'',
      calificacion:'',
      id_periodicidad:'',
      permanencia:'',
      aporte_inicial:'',
      incrementoobligatorio:false,
      incremento:'',
      rendimiento_anual:'',
      rendimiento_promedio:'',
      politica:'',
      horario:'',
      pdffondo:'',
      url_img:'',
      administradorafondos_id:''
    });
  }
  resetFormNormas(idFondo){
    this.formNormas.enable();
    this.formNormas.reset({
      normas_id:null,
      fondoinversion_id: idFondo,
      permanencia:'',
      incrementoobligatorio:false,
      incrementomanual:false,
      min_io:'',
      max_io:'',
      min_im:'',
      max_im:'',
      permite_retiro_parcial:false,
      permite_capital_rendimiento:false,
      perm_rend_retiro:'',
      perm_capital_retiro:'',
      porc_rend_retiro:'',
      porc_capital_retiro:'',
      aplica_penalidad:false,
      porc_sobre_rend:'',
      porc_sobre_capital_retiro:'',
      aplica_autorenovacion:false,
      dias_autorenovacion:'',
      aplica_nuevo_contrato:false,
      dias_antes_rescate:'',
      notificar_cliente:false
    });
  }
  clear(){
    this.stepper.selectedIndex = 0;
    this.isCollapsed = true;
    this.selectedFile = new ImageSnippet(null,null);
    this.resetFormFondo();
    this.resetFormNormas(null);
  }
  delFondo(){
    this.isLoading = true;
    this.fondoService.deleteFondoInversion(this.fondoSelected.fondoinversion_id).subscribe(
      (data : any)=>{
        this.isLoading = false;
        this.modalRef.hide();
        this.getAllFondo(this.idAdministradora);
      },
      (err : HttpErrorResponse)=>{
        console.error("Ocurrio un error al eliminar fondo de inversión. Consulte con su proveedor.");
      }
    );
  }
  viewFondo(f:FondoInversion,ban:boolean){
    this.isLinear = false;
    this.isCollapsed = false;
    this.fondoSelected = f;
    this.getNormaById(f.fondoinversion_id);
    this.selectedFile.src = Data._trim(f.url_img);
    this.paramBusquedaGroup.patchValue(this.fondoSelected);
    if(ban){
      this.paramBusquedaGroup.disable();
      this.formNormas.disable();
    }
    else{
      this.paramBusquedaGroup.enable();
      this.formNormas.enable();
    }
  }
  getAllAdministradora(){
    this.cboAdministradora = [];
    this.generalService.useService(Administradora.getAll()).subscribe(
      (data : any)=>{
        console.log(data);
        for (let t in data.datos) {
          this.cboAdministradora.push(new Administradora(data.datos[t] as Administradora));
        }
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.selectedFile.pending = true;
      this.paramBusquedaGroup.controls['url_img'].setValue('exist');
    });

    reader.readAsDataURL(file);
  }
  getNormaById(id:number){
    this.resetFormNormas(id);
    this.normaselected = null;
    this.generalService.useService(NormaFondo.getAll(id)).subscribe(
      (doc:any) =>{
        if(doc.datos){
          this.formNormas.patchValue(doc.datos[0]);
        }
        console.log(doc);
      }
    );    
  }

  getAllFondo(val){
    this.isLoading2 = true;
    this.listFondo = [];
    this.dataSource = new MatTableDataSource<FondoInversion>(this.listFondo) ;
    this.dataSource.paginator = this.paginator;
    this.fondoService.getAllFondoInversionByIdAdministradora(val).then(
      (doc) =>{
        console.log(doc);
        this.listFondo = doc;
        this.dataSource = new MatTableDataSource<FondoInversion>(this.listFondo);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      }
    );    
  }
  save(form){
    this.isLoading = true;
    if(this.selectedFile.file != null){
      this.generalService.uploadImage(this.selectedFile.file,"fondo").subscribe(
        (res:any) => {
          form.url_img = res.datos[0].url;
          this.saveFondo(form);
        },
        (err) => {
          console.log(err);
        })
      }else{
        this.saveFondo(form);
      }
  }
  saveFondo(form){
    this.generalService.useService(FondoInversion.post(form)).subscribe(
      (data : any)=>{
        if(Data.isOk(data)){
          this.paramBusquedaGroup.disable();
          if(data.datos[0].identificador){
            this.formNormas.controls['fondoinversion_id'].setValue(data.datos[0].identificador);
          }
          this.stepper.next();
        }
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  saveNorma(form){
    this.isLoading = true;
    this.generalService.useService(NormaFondo.post(form)).subscribe(
      (data : any)=>{
        if(!Data.isOk(data)){
          this.alertService.warn("Error al guardar Normas del fondo de inversión");
        }else{
          this.getAllFondo(this.idAdministradora);
          this.clear();
        }
        this.isLoading=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }

  
  openModal(template: TemplateRef<any>,f:FondoInversion) {
    this.fondoSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
}
