import { Data } from './z.model';

export class SubMenu {
    bloques_id:number;
    bloques_items_id:number;
    item:string;
    url:string;
    orden:string;
    constructor(b:SubMenu=null){
        if(b!=null){
            this.bloques_items_id = b.bloques_items_id;
            this.bloques_id = b.bloques_id;
            this.item = Data._trim(b.item);
            this.url = Data._trim(b.url);
            this.orden = b.orden;
        }
    }
    public static getAll(){
        return Data._getBody("C",112,sessionStorage.getItem("token_fks"));
    }
    public static getAllGroupByBloque(){
        return Data._getBody("C6",148,sessionStorage.getItem("token_fks"));
    }
    public static getAllByBloque(id:number){
        var tmp = Data._getBody("C2",112,sessionStorage.getItem("token_fks"));
        tmp["data"]["bloques_id"] = id;
        return tmp;
    }
    public static post(f:SubMenu){
        var tmp = Data._getBody("I",113,sessionStorage.getItem("token_fks"));
        if(f.bloques_items_id != null){
          tmp = Data._getBody("U",114,sessionStorage.getItem("token_fks"));
          tmp["data"]["bloques_items_id"] = f.bloques_items_id;
        }
        tmp["data"]["bloques_id"] = f.bloques_id;
        tmp["data"]["url"] = f.url;
        tmp["data"]["item"] = f.item;
        tmp["data"]["orden"] = f.orden;
        return tmp;
    }
    public static delete(id:Number){
        var tmp = Data._getBody("D",115,sessionStorage.getItem("token_fks"));
        tmp["data"]["bloques_items_id"] = id;
        return tmp;
    }
}