export class LugarTrabajo {
    id_lugar_trabajo:number;
    persona_id: number;
    pais_id:number;
    provincia_id:number;
    ciudad_id:number;
    direccion:string  = "Ninguno";
    departamento:string = "Ninguno";
    piso:string = "Ninguno";
    sector:string = "Ninguno";
    telefono:string = "Ninguno";
    tiponegocio:string = "Ninguno";
    constructor(lt:LugarTrabajo = null){
        if(lt!=null){
            this.id_lugar_trabajo = lt.id_lugar_trabajo;
            this.persona_id = lt.persona_id;
            this.pais_id = lt.pais_id;
            this.provincia_id = lt.provincia_id;
            this.ciudad_id = lt.ciudad_id;
            this.direccion = atob(lt.direccion);
            this.departamento = atob(lt.departamento);
            this.piso = lt.piso;
            this.sector = atob(lt.sector);
            this.telefono = atob(lt.telefono);
            this.tiponegocio = lt.tiponegocio;
        }
    }
}