export class DatosLaborables {
    personaactividadec_id:number;
    persona_id: number;
    relacionlaboral_id:number;
    tiponegocio_id:number;
    area_trabajo:string = "Ninguno";
    cargo:string = "Ninguno";
    empresatrabajo:string = "Ninguno";
    Ingresomensual:string = "Ninguno";
    fecha_inicioactividades:Date = new Date;
    actividadEconomica:string = "Ninguno";
    constructor(dl:DatosLaborables = null){
        if(dl!=null){
            this.personaactividadec_id = dl.personaactividadec_id;
            this.persona_id = dl.persona_id;
            this.relacionlaboral_id = dl.relacionlaboral_id;
            this.tiponegocio_id = dl.tiponegocio_id;
            this.area_trabajo = atob(dl.area_trabajo);
            this.cargo = atob(dl.cargo);
            this.empresatrabajo = atob(dl.empresatrabajo);
            this.Ingresomensual = dl.Ingresomensual;
            this.fecha_inicioactividades = new Date(dl.fecha_inicioactividades['date']);
            this.actividadEconomica = dl.actividadEconomica;
        }
    }
}