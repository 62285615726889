import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Data } from 'src/app/model/z.model';
import { GeneralService } from 'src/app/service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FondoAprobado } from 'src/app/model/fondoAprobado.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-fondos-aprobados',
  templateUrl: './fondos-aprobados.component.html',
  styleUrls: ['./fondos-aprobados.component.css']
})
export class FondosAprobadosComponent implements OnInit {
  isLoading = true;
  bpor:string = "persona";
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ['solicitud_inversion_id','identificacion','apellidos','nombrefondo','fecha_registro','estado','acciones'];
  listSolicitudInversion: FondoAprobado[]=[];
  tipoFondos: FondoInversion[] = [];
  dataSource;
  paramBusquedaGroup:FormGroup;
  constructor(private _builder: FormBuilder, private generalService:GeneralService) {}
  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.generalService.useService(FondoAprobado.getAll()).subscribe(
      (data : any)=>{
        this.fillSolicitud(data.datos);
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
    this.paramBusquedaGroup = this._builder.group({
      Documento: [''],
      FondoInversion:[''],
      rangoFecha:['']
    });
    this.onChanges();
  }
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.find(this.paramBusquedaGroup.value);
    });
  }
  fillSolicitud(datos:any){
    for (let t in datos) {
      this.listSolicitudInversion.push(new FondoAprobado(datos[t] as FondoAprobado));
    }
    this.configTable(this.listSolicitudInversion);
    this.isLoading = false;
  }
  find(form:any){
    var temp = this.listSolicitudInversion;
    if(form.Documento!=""){
      if(this.bpor == "persona"){
        temp = temp;
          //return el.identificacion.indexOf(form.Documento) > -1});
      }else{
        temp = temp.filter(function(el) {
          return el.solicitud_inversion_id.toString().indexOf(form.Documento) > -1});
      }
    }
    if(form.FondoInversion!="")
      temp=temp.filter(d => (d.fondoinversion_id == form.FondoInversion));
    if(form.rangoFecha != null){
      if((form.rangoFecha[0] != null && form.rangoFecha[1] != null))
        temp=temp.filter(d => (d.fecha_registro.getTime() <= form.rangoFecha[1].getTime() && d.fecha_registro.getTime() >= form.rangoFecha[0].getTime()));
    }
    this.configTable(temp);
  }
  configTable(data){
    this.dataSource = new MatTableDataSource<FondoAprobado>(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
