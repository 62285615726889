import { Data } from '../z.model';
//Transacciones fallidas, numero de intentos
export class Report4 {
    concepto:string;
    fecha:Date;
    nombre:string;
    mensaje:string;
    monto:number;
    respuesta:number;
    fecha_hora
    constructor(b:any = null){
        if(b != null){
            this.concepto = b.concepto.trim();
            this.fecha = new Date(b.fecha.date);
            this.fecha_hora = new Date(b.fecha_hora.date);
            this.nombre=b.nombre;
            this.mensaje=b.mensaje.trim();
            this.respuesta=Number(b.respuesta);
            this.monto=b.monto;
        }
    }
    //TODO Aun no me lo han dado a conocer.
    public static getAll() {
        return Data._getBody("C1",156,sessionStorage.getItem("token_fks"));
    }
} 