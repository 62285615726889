import { Pais } from '../pais.model';
import { Data } from '../z.model';

export class Domicilio {
    pais_id:number;
    provincia_id:Number;
    ciudad_id:Number;
    persona_id:string;
    id_persona_domicilio:Number;
    sector:string;
    direccion:string;
    meses_residencia:Number;
    anios_residencia:Number;
    correspondencia_id:Number;
    vivienda_id:Number;

    pais:Pais = new Pais();
    provincia:any;   
    constructor(d:Domicilio = null){
        if(d!=null){
            this.pais_id = d.pais_id;
            this.provincia_id =d.provincia_id;
            this.ciudad_id = d.ciudad_id;
            this.persona_id = d.persona_id;
            this.id_persona_domicilio = d.id_persona_domicilio;
            this.sector = atob(d.sector);
            this.direccion = Data._atob(d.direccion);
            this.meses_residencia = d.meses_residencia;
            this.anios_residencia = d.anios_residencia;
            this.correspondencia_id = d.correspondencia_id;
            this.vivienda_id = d.vivienda_id;
        }
    }
}
