import { Data } from './z.model';

export class Dispositivo {
    compilacion:string;
    equipos_registrados_id:number;
    id_equipo_persona:number;
    mac:string;
    nombre_equipo:string;
    activo:boolean;
    constructor(p:any = null){
        if(p != null){
            this.compilacion = p.compilacion;
            this.equipos_registrados_id = p.equipos_registrados_id;
            this.id_equipo_persona = p.id_equipo_persona;
            this.mac = p.mac;
            this.nombre_equipo = p.nombre_equipo;
            if(p.nombre_equipo.trim() == Data._equipo && p.compilacion.trim() == Data._idKernel && Data._idEquipo == p.mac.trim()){
                this.activo = true;
            }else{
                this.activo = false;
            }
        }
    }
    public static getAll(){
        var tmp = Data._getBody("C",342,sessionStorage.getItem("token_fks"));
        return tmp;
    }
    public static delete(id:number){
        var tmp = Data._getBody("D",343,sessionStorage.getItem("token_fks"));
        tmp["data"]["id_equipo_persona"] = id;
        return tmp;
    }
}