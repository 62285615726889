import { Rendimiento } from './rendimiento.model';
import { Data } from './z.model';

export class InversionistaReport {
    CR:number = 0;
    DB:number = 0;
    identificacion:string; 
    persona_id:string;
    nombres:string;
    referencia:string;
    fecha_trx:Date;
    fondoinversion_id:number;
    nombrefondo:string;
    constructor(b:any=null){
        if(b!=null){
            this.fondoinversion_id = b.fondoinversion_id;
            this.persona_id = b.persona_id;
            this.identificacion = atob(b.identificacion);
            var nombres = b.nombres.split(" ");
            this.nombres = atob(nombres[0])+' '+atob(nombres[1]);
            this.referencia = b.referencia;
            this.fecha_trx = b.fecha_trx['date'];
            this.nombrefondo = Data._trim(b.nombrefondo);
            this.CR = b.CR;
            this.DB = b.DB;
        }
    }
}