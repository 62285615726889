import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Data } from 'src/app/model/z.model';
import { MatPaginator } from '@angular/material/paginator';
import { SolicitudRetiro } from 'src/app/model/solicitudes/solicitudRetiro.model';
import { MatTableDataSource } from '@angular/material/table';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralService } from 'src/app/service/general.service';
import { SelectionModel } from '@angular/cdk/collections';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-retiro',
  templateUrl: './retiro.component.html',
  styleUrls: ['./retiro.component.css']
})
export class RetiroComponent implements OnInit {
  isLoading = true;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['transaccion_id','identificacion','nombre','nombrefondo','monto','fecha_registro','estado_nombre','acciones'];
  listSolicitudRetiro: SolicitudRetiro[];
  paramBusquedaGroup:FormGroup;
  formSelections:FormGroup;
  tipoFondos: FondoInversion[] = [];
  tipoEstados: DetalleCatalogo[] = [];
  solicitudSelected:SolicitudRetiro;
  selection = new SelectionModel<SolicitudRetiro>(true, []);
  modalRef: BsModalRef;
  smsModal:string;
  estadosol:string;
  constructor(private modalService: BsModalService,private _builder: FormBuilder,private generalService:GeneralService) {
  }
  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.tipoEstados = Data._fListCatalogo("ESTADO SOLICITUD");
    this.formSelections = this._builder.group({
      estado:['',[Validators.required]]
    });
    this.paramBusquedaGroup = this._builder.group({
      Documento: [''],
      FondoInversion:[''],
      rangoFecha:[''],
      Estado:[''],
      monto:['']
    });
    this.getAllolicitud();
    this.onChanges();
  }
  getAllolicitud(){
    this.paramBusquedaGroup.disable();
    this.listSolicitudRetiro = [];
    this.dataSource = new MatTableDataSource<SolicitudRetiro>(this.listSolicitudRetiro) ;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.generalService.useService(SolicitudRetiro.getAll()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listSolicitudRetiro.push(new SolicitudRetiro(data.datos[t] as SolicitudRetiro));
        }
        this.dataSource = new MatTableDataSource<SolicitudRetiro>(this.listSolicitudRetiro);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.paramBusquedaGroup.enable();
      }, 
      (err : HttpErrorResponse)=>{
        
      }
    );
  }
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.find(this.paramBusquedaGroup.value);
    });
  }
  find(form){
    var temp = this.listSolicitudRetiro;
    if(form.Documento!=""){
      temp = temp.filter(function(el) {
        return el.identificacion.indexOf(form.Documento) > -1});
    }
    if(form.FondoInversion!="")
      temp=temp.filter(d => (d.fondoinversion_id == form.FondoInversion));
    if(form.rangoFecha != null){
    if((form.rangoFecha[0] != null && form.rangoFecha[1] != null)){
      temp=temp.filter(d => (d.fecha_registro.getTime() <= form.rangoFecha[1].getTime() && d.fecha_registro.getTime() >= form.rangoFecha[0].getTime()));
    }}
    if(form.Estado!=""){
      temp=temp.filter(d => (d.estado_nombre == form.Estado));
    }
    if(form.monto!=""){
      var monto:number = 0;
      var temp2 = temp.filter(d => (d.estado_nombre != "APROBADO"));
      temp = [];
      for(let a of temp2){
        monto = monto + Number(a.monto);
        if(monto <= Number(form.monto))
          temp.push(a);
      }
    }
    this.dataSource = new MatTableDataSource<SolicitudRetiro>(temp);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: SolicitudRetiro): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    //return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  openModal(sms:string,template: TemplateRef<any>,el:SolicitudRetiro) {
    this.solicitudSelected=el;
    this.estadosol = sms;
    if(sms == "A"){
      this.smsModal = "Esta seguro de APROBAR esta solicitud";
    }else{
      this.smsModal = "Esta seguro de CANCELAR esta solicitud";
    }
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }
  aprobarsolicitud(val,est){
    this.isLoading=true;
    this.generalService.useService(SolicitudRetiro.put(val,est)).subscribe(
      (data) => {
        for(let p of this.dataSource.data){
          if(p.solictudretirofondo_id == val){
            p.isLoading = false;
            if(est == 'A'){
              p.estado = "A";
              p.estado_nombre = "APROBADO";
              p.estado_color = "primary";
          }else if(est == 'C'){
              p.estado_nombre = "CANCELADO";
              p.estado_color = "accent";
              p.estado = "C";
          }
          }
        }
        this.solicitudSelected = null;
        this.estadosol = "";
        this.modalRef.hide();
        this.isLoading=false;
      },
      (err:HttpErrorResponse)=>console.log(err)
    );
  }
  solSeleccionadas(value){
    this.selection.selected.filter((t)=>(t.estado=="P")).forEach((t)=>{
      var temp;
      for(let p of this.dataSource.data){
        if(p.solictudretirofondo_id == t.solictudretirofondo_id){
          temp = p;
          p.isLoading = true;
        }
      }
      this.aprobarsolicitud(t.solictudretirofondo_id,value.estado);
    })
    
  }
}
