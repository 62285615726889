import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router : Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {
      if (sessionStorage.getItem('token_fks') != null && sessionStorage.getItem('inf1') && sessionStorage.getItem('inf2'))
        return true;
      this.router.navigate(['/login']);
        return false;
  }
}