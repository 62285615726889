import { DatosPersonales } from './persona/datospersonales.model';
import { DatosContacto } from './datoscontacto.model';
import { Data } from './z.model';

export class Usuario {
    datosdecontacto:DatosContacto = new DatosContacto();
    credenciales:any = {};
    persona_id:number;
    email:string;
    password:string;
    tipo:number;
    tipo_nombre:string;
    estado:number;
    usuario_id:number;
    invadministradora_fondo_id:number;
    datospersonales:DatosPersonales  = new DatosPersonales();

    constructor(p:Usuario = null){
        if(p != null){
            this.usuario_id = p.usuario_id;
            this.datospersonales = new DatosPersonales(p.datospersonales);
            this.datosdecontacto = new DatosContacto(p.datosdecontacto);
            this.persona_id = p.persona_id;
            this.email = p.email;
            this.password = p.password;
            this.tipo = p.tipo;
            this.tipo_nombre = p.tipo_nombre;
            this.estado = p.estado;
            this.invadministradora_fondo_id = p.invadministradora_fondo_id;
        }
    }
    public static login(userName:string,password:string){
        var tmp = Data._getBody("C",3,"");
        tmp["data"]["user"] = btoa(userName);
        tmp["data"]["pass"] = btoa(password);
        tmp["data"]["tpo"] = 1;
        return tmp;
    }
    public static post(u:any,idAdm:Number = 0){
        var tmp = Data._getBody("I3",3,sessionStorage.getItem("token_fks"));
        tmp["data"]["user"] = btoa(u.email);
        tmp["data"]["pass"] = btoa(u.contrasenia);
        tmp["data"]["tpo"] = u.tpo;
        tmp["data"]["financiera"] = idAdm;
        return tmp;
    }
    public static extendLogin(){
        var tmp = Data._getBody("C4",120,sessionStorage.getItem("token_fks"));  
        return tmp;
    }
    public static changePass(data:any){
        var tmp = Data._getBody("U",130,sessionStorage.getItem("token_fks"));
        tmp["data"]["id"] = data.id;
        tmp["data"]["password"] = btoa(data.password);
        tmp["data"]["tipo"] = data.tipo;
        tmp["data"]["estado"] = data.estado;
        return tmp;
    }
    public static changePassUs(act:any,nue:any){
        var tmp = Data._getBody("U",41,sessionStorage.getItem("token_fks"));
        tmp["data"]["claveactual"] = btoa(act);
        tmp["data"]["clavenueva"] = btoa(nue);
        return tmp;
    }
    public static getAll(adm:number=0){
        var tmp = Data._getBody("C",128,sessionStorage.getItem("token_fks"));
        tmp["data"]["adm"] = adm;
        return tmp;
    }
    public static getAllByTipoUsuario(idTipoUsuario:number,adm:number=0){
        var tmp = Data._getBody("C7",148,sessionStorage.getItem("token_fks"));
        tmp["data"]["tipousuario_id"] = idTipoUsuario;
        tmp["data"]["adm"] = adm;
        console.log(JSON.stringify(tmp));
        return tmp;
    }
    public static delete(id:number){
        var tmp = Data._getBody("D",131,sessionStorage.getItem("token_fks"));
        tmp["data"]["id"] = id;
        return tmp;
    }
    //Aun falta de implementar
    public static updateTipo(obj){
        var tmp = Data._getBody("U4",131,sessionStorage.getItem("token_fks"));
        tmp["data"]["tipo_nuevo"] = obj;
        tmp["data"]["usuario_id"] = obj;
        return tmp;
    }
}