import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Data } from '../model/z.model';
import { Pais } from '../model/pais.model';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { GeneralService } from './general.service';
import { FondoInversion } from '../model/fondoInversion.model';
import { Catalogo } from '../model/catalogo.model';
import { forkJoin } from 'rxjs';
@Injectable()
export class AppLoadService {
  url2=environment.apiHost2;
  constructor(private generalService:GeneralService,private httpClient: HttpClient,handler: HttpBackend,private localeService: BsLocaleService,private injector: Injector) { 
    this.httpClient = new HttpClient(handler);
    this.localeService.use('es');
  }
  async getDataPriory(): Promise<any> {
      var reqHeader = new HttpHeaders();
      try {
        const res:any = await this.httpClient.get(`${environment.apiServices}services/ip.php`, { headers: reqHeader }).toPromise();
        Data._idEquipo = res.IP;
        return this.getFondos();
      } catch(err) {
        console.error("Error al cargar datos prioritarios...");
        return {op:"ERR",mensaje:"Error al cargar datos prioritarios..."};
      } 
  }

  async getFondos(): Promise<any> {
    let calls:any[] = [];
    calls.push(this.generalService.useService(FondoInversion.getAll()));
    calls.push(this.generalService.useServiceWithBackend(Catalogo.getAllInit()));
    const promise = await forkJoin(calls)
      .toPromise()
      .then((d:any[]) => {
        if(d[0].datos){
          Data._fondosInversion = Object.values(d[0].datos).map((t:any)=>{
            return new FondoInversion(t);
          });
        }
        for(let i in d[1].datos){
          var el = d[1].datos[i];
          Data._data[i.trim()] = el;
        }
        return {op:"OK",mensaje:null};
      },error => {
        Data._fondosInversion = [];
        console.error("Error al cargar fondos y catalogo...");
        return {op:"OK",mensaje:"Error al cargar fondos y catalogo..."};
    });
    return promise;
  }
}