import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Data } from '../model/z.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  url2=environment.apiHost2;
  constructor(private http:HttpClient) { }

  getDivisionPais(id:number) {
    var tmp = Data._getBody("division",11,sessionStorage.getItem("token_fks"));
    tmp["data"]["id_pais"]=id;
    var reqHeader = new HttpHeaders();
    return this.http.post(`${this.url2}JBoxService.php`, JSON.stringify(tmp), { headers: reqHeader });
  }
}
