import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SubMenu } from 'src/app/model/subMenu';
import { GeneralService } from 'src/app/service/general.service';
import { Data } from 'src/app/model/z.model';

@Component({
  selector: 'app-mant-sub-menu',
  templateUrl: './mant-sub-menu.component.html',
  styleUrls: ['./mant-sub-menu.component.css']
})
export class MantSubMenuComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['submenu','orden','acciones'];
  listSubMenu:SubMenu[]=[];
  _lcat:number;
  isLoadingBtn:boolean = false;
  isLoading:boolean = false;
  isDeleting:boolean;
  @Input() set lcat(lcat:number){
    this.findAllSubMenu(lcat);
    this._lcat = lcat;
  }
  @Input() set banMenu(ban:boolean){
    if(ban)
      this.findAllSubMenu(this._lcat);
  }
  modalRef: BsModalRef;
  subMenuForm:FormGroup;
  hiddenButton:boolean;
  subMenuSelected:SubMenu;
  constructor(private _builder: FormBuilder,private modalService: BsModalService,private generalService:GeneralService) { }

  ngOnInit() {
    this.subMenuForm = this._builder.group({
      bloques_items_id:[''],
      bloques_id:[''],
      item: ['',Validators.required],
      url:[''],
      orden:['']
    });
  }
  clear(){
    this.modalRef.hide();
    this.subMenuForm.reset({
      bloques_items_id:'',
      bloques_id:'',
      item: '',
      url:'',
      orden:''
    });
  }
  openModal(template: TemplateRef<any>,hiddenButton:boolean) {
    this.hiddenButton = hiddenButton;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-lg');
  }
  updateSubMenu(form){
    this.isLoadingBtn = true;
    this.generalService.useService(SubMenu.post(form)).subscribe(
      (data : any)=>{
        console.log(this._lcat);
        this.findAllSubMenu(this._lcat);
        this.isLoadingBtn = false;
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        
      }
    );
  }
  delSubMenu(){
    this.isDeleting = true;
    this.generalService.useService(SubMenu.delete(this.subMenuSelected.bloques_items_id)).subscribe(
      (data : any)=>{
        if(Data.isOk(data)){
          this.findAllSubMenu(this._lcat);
          this.modalRef.hide();
        }
        this.isDeleting = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  findAllSubMenu(idMenu){
    this.isLoading = true;
    this.listSubMenu = [];
    this.dataSource = new MatTableDataSource<SubMenu>(this.listSubMenu);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(SubMenu.getAllByBloque(idMenu)).subscribe(
      (data : any)=>{
        for (let j in data.datos) {
          let tempDet:SubMenu = new SubMenu(data.datos[j] as SubMenu);
          tempDet.bloques_items_id = Number(j);
          this.listSubMenu.push(tempDet);
        }
        this.dataSource = new MatTableDataSource<SubMenu>(this.listSubMenu);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
}