import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hom-uno',
  templateUrl: './hom-uno.component.html',
  styleUrls: ['./hom-uno.component.css']
})
export class HomUnoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
