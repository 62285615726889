import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';

@Component({
  selector: 'app-fi-doc',
  templateUrl: './fi-documentos.component.html',
  styleUrls: ['./fi-documentos.component.css']
})
export class FiDocumentosComponent implements OnInit {
  tipoDocumentos: DetalleCatalogo[] = [];
  paramBusquedaGroup:FormGroup;
  constructor(private _builder: FormBuilder) { }

  ngOnInit() {
    this.fillComboBox();
    this.paramBusquedaGroup = this._builder.group({
      tipoDocumento: [''],
      archivo:['']
    });
  }
  find(form){
    console.log(form);
  }
  fillComboBox(){
    this.tipoDocumentos = Data._fListCatalogo("TIPO DE DOCUMENTO");
  }
}
