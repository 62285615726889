export class InfoEconomicaFinanciera {
    actividadeconomica_id:number = 0;
    persona_id:number;
    ingresos_actividadeconomica:string;
    otros_ingresos:string;
    egresos_mensuales:string;
    patrimonio:string;
    origen_recursos:string;
    constructor(ie:InfoEconomicaFinanciera=null){
        if(ie != null){
            this.actividadeconomica_id = ie.actividadeconomica_id;
            this.persona_id = ie.persona_id;
            if(ie.ingresos_actividadeconomica == null)
                this.ingresos_actividadeconomica = "";
            else
                this.ingresos_actividadeconomica = ie.ingresos_actividadeconomica;
            this.otros_ingresos = ie.otros_ingresos;
            this.egresos_mensuales = ie.egresos_mensuales;
            this.patrimonio = ie.patrimonio;
            this.origen_recursos = ie.origen_recursos;
        }
    }
}