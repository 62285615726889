import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Data } from 'src/app/model/z.model';
import { ParticipacionDiaria } from 'src/app/model/participacionDiaria.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { GeneralService } from 'src/app/service/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-detalle-fondo',
  templateUrl: './detalle-fondo.component.html',
  styleUrls: ['./detalle-fondo.component.css']
})
export class DetalleFondoComponent implements OnInit {
  @Input() f:FondoInversion;
  dataSource;
  modalRef: BsModalRef;
  displayedColumns: string[] = ['unidades','valor','fecha','acciones'];
  formUnidades:FormGroup;
  isLoading:boolean=false;
  isLoading2:boolean=false;
  participacionSelected: ParticipacionDiaria;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  listParticipacion: ParticipacionDiaria[] = [];
  constructor(private _builder: FormBuilder,private modalService: BsModalService,private _snackBar: MatSnackBar, private route: ActivatedRoute, private generalService: GeneralService) { 
    this.formUnidades = this._builder.group({
      unidades: ['', [Validators.required]],
      valorporunidad:['', [Validators.required]],
      participaciondiaria_id:[null]
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.f = Data._fondosInversion.find(t => t.fondoinversion_id == Number(params.get("id")));
      this.getAllParticipacion(this.f.fondoinversion_id);
    });
  }
  save(form){
    this.isLoading= true;
    form.fondoinversion_id = this.f.fondoinversion_id;
    this.generalService.useService(ParticipacionDiaria.post(form)).subscribe(
      (doc:any) =>  {
        this.getAllParticipacion(form.fondoinversion_id);
        this.clearData();
        this.isLoading=false;
       }
    );
  }
  delParticipacion(){
    this.isLoading=true;
    this.generalService.useService(ParticipacionDiaria.delete(this.participacionSelected.participaciondiaria_id)).subscribe(
      (data : any)=>{
        this.resultRequest(data.datos)
        this.getAllParticipacion(this.f.fondoinversion_id);
        this.modalRef.hide();
        this.isLoading=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  resultRequest(datos){
    if(datos == null){
      this.openSnackBar("Operacion no realizada","ERROR");
    }else{
      if(datos[0].status == "100"){
        this.openSnackBar("Operacion realizada con éxito","INFORMACIÓN");
        // this.clearData();
      }
    }
  }
  getAllParticipacion(id){
    this.isLoading2=true;
    this.listParticipacion = [];
    this.dataSource = new MatTableDataSource<ParticipacionDiaria>(this.listParticipacion);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(ParticipacionDiaria.getAll(id)).subscribe(
      (doc:any) =>  {
        if(doc.datos!=null){
          console.log(doc);
          for(let a in doc.datos){
            this.listParticipacion.push(new ParticipacionDiaria(doc.datos[a] as ParticipacionDiaria))
          }
          this.dataSource = new MatTableDataSource<ParticipacionDiaria>(this.listParticipacion);
          this.dataSource.paginator = this.paginator;
        }
        this.isLoading2 = false;
       }
    );    
  }
  openModal(template: TemplateRef<any>,f:ParticipacionDiaria) {
    this.participacionSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
  viewParticipacion(f:ParticipacionDiaria,ban:boolean){
    this.participacionSelected = f;
    this.formUnidades.patchValue(this.participacionSelected);
    if(ban)
      this.formUnidades.disable();
    else{
      this.formUnidades.enable();
    }
  }
  clearData(){
    this.participacionSelected = new ParticipacionDiaria();
    this.formUnidades.reset({participaciondiaria_id: null, unidades: '', valor:''});
  }
}