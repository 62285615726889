import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Pais } from 'src/app/model/pais.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Provincia } from 'src/app/model/provincia.model';
import { DataService } from 'src/app/service/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Ciudad } from 'src/app/model/ciudad.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-mant-geolocalizacion',
  templateUrl: './mant-geolocalizacion.component.html',
  styleUrls: ['./mant-geolocalizacion.component.css']
})
export class MantGeolocalizacionComponent implements OnInit {
  isCollapsed = true;
  isLoadingPais = true;
  modalRef: BsModalRef;
  tabIndex:number=0;
  listPais:Pais[]=[];
  listProvincias:Provincia[]=[];
  listCiudades:Ciudad[]=[];
  paisSelected:Pais= new Pais();
  provinciaSelected:Provincia= new Provincia();
  lenghtItemsSelectedPais:number=0;
  lenghtItemsSelectedProvincia:number=0;
  paisForm:FormGroup;
  provinciaForm:FormGroup;
  dataSource;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['nombre','acciones'];
  typesOfShoes2: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins'];
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins'];
  constructor(private generalService:GeneralService,private dataService:DataService,private _builder: FormBuilder,private modalService: BsModalService,private _snackBar: MatSnackBar) {
    this.paisForm = this._builder.group({
      nombre:['']
    });
    this.provinciaForm = this._builder.group({
      nombre:['']
    });
  }

  ngOnInit() {
    this.getAllPais();
  }
  addp(){
    this.listPais.push(new Pais())
  }
  addprovincia(){
    this.listProvincias.push(new Provincia())
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-lg');
  }
  onSelectionPais(e){
    this.paisSelected = e;
    this.listProvincias = [];
    this.dataService.getDivisionPais(this.paisSelected.id_pais).subscribe(
      (data : any)=>{
        for(let f in data.datos){
          this.listProvincias.push(new Provincia(data.datos[f]));
        }
        this.tabIndex = 1;
      },
      (err : HttpErrorResponse)=>{
        this.openSnackBar("No se pudo cardar Provincias", "Error");      
      }
    );
  }
  onSelectionProvincia(e){
    this.lenghtItemsSelectedProvincia = e.option.selectionList.selectedOptions.selected.length;
    if(this.lenghtItemsSelectedProvincia == 1){
      if(e.option.selectionList.selectedOptions.selected[0].value.descripcion != this.provinciaSelected.descripcion ||
         (e.option.selectionList.selectedOptions.selected[0].value.descripcion == this.provinciaSelected.descripcion && this.listCiudades.length == 0)){
        this.provinciaSelected = e.option.selectionList.selectedOptions.selected[0].value;
        if(this.provinciaSelected.ciudades !=null)
          this.listCiudades = this.provinciaSelected.ciudades;
        else
          this.openSnackBar("No se pudo cardar Ciudades", "Error");
      }
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  changeTabs(e){
    this.tabIndex=e;
      if(this.lenghtItemsSelectedProvincia != 1 && e == 2){
        this.listCiudades = [];
        this.openSnackBar("Asegurese de escoger una sola Ciudad", "ADVERTENCIA");
      }
  }
  getAllPais(){
    this.isLoadingPais = true;
    this.listPais =[];
    this.dataSource = new MatTableDataSource<Pais>(this.listPais);
    this.dataSource.paginator = this.paginator;
    this.generalService.useService(Pais.getAll()).subscribe(
      (d:any)=>{
        for(let f in d.datos){
          this.listPais.push(new Pais(d.datos[f]));
        }
        this.dataSource = new MatTableDataSource<Pais>(this.listPais);
        this.dataSource.paginator = this.paginator;
        this.isLoadingPais = false;
      },(err:HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000
    });
  }
}