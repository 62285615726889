import { SubMenu } from './subMenu';
import { Data } from './z.model';

export class BloqueMenu {
    bloque_id:number;
    nombre_bloque:string;
    url:string;
    orden:number;
    icon:string;
    banNuevo:boolean=false;
    active: boolean=false;
    items:SubMenu[]=[];
    constructor(b:BloqueMenu=null){
        if(b!=null){
            this.icon = Data._trim(b.icon);
            this.bloque_id = b.bloque_id;
            this.orden = b.orden;
            this.nombre_bloque = Data._trim(b.nombre_bloque);
            this.url = Data._trim(b.url);
        }
    }
    public setBloqueUsuario(obj:any){
        this.nombre_bloque = Data._trim(obj.nombre);
        this.icon = Data._trim(obj.icon);
        if(Data._trim(obj.url)=='#'){
            this.url = null
        }else{
            this.url = Data._trim(obj.url);
        }
        if(obj.items){
            this.items = Object.values(obj.items).map((t:any)=>{
                return new SubMenu(t);
            });
        }
    }
    public static getAll(){
        return Data._getBody("C",108,sessionStorage.getItem("token_fks"));
    }
    public static post(f:BloqueMenu){
        var tmp = Data._getBody("I",109,sessionStorage.getItem("token_fks"));
        if(f.bloque_id != null){
          tmp = Data._getBody("U",110,sessionStorage.getItem("token_fks"));
          tmp["data"]["sistema_bloques_id"] = f.bloque_id;  
        }
        tmp["data"]["nombre_bloque"] = f.nombre_bloque;
        tmp["data"]["url"] = f.url;
        tmp["data"]["icon"] = f.icon;
        tmp["data"]["orden"] = f.orden;
        return tmp;
    }
    public static delete(id:Number){
        var tmp = Data._getBody("D",111,sessionStorage.getItem("token_fks"));
        tmp["data"]["sistema_bloques_id"] = id;
        return tmp;
    }
    public static getMenu(){
        var tmp = Data._getBody("C1",148,sessionStorage.getItem("token_fks"));
        return tmp;
    }
    public static getMenuByTipoUsuario(tipousuario_id:number){
        var tmp = Data._getBody("C8",148,sessionStorage.getItem("token_fks"));
        tmp["data"]["tipousuario_id"] = tipousuario_id;
        return tmp;
    }
    public static getMenuOfUsuario(){
        return Data._getBody("C",148,sessionStorage.getItem("token_fks"));
    }
}