import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-fi-firma-electronica',
  templateUrl: './fi-firma-electronica.component.html',
  styleUrls: ['./fi-firma-electronica.component.css']
})
export class FiFirmaElectronicaComponent implements OnInit {
  paramBusquedaGroup:FormGroup;
  constructor(private _builder: FormBuilder) { }

  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      tipoFirma: [''],
      archivo: ['']
    });
  }
  find(form){
    console.log(form);
  }
}
