export class AccionistaEmpresa {
    fondoinversion_id: number;
    persona_id:number;
    identificacion_empresa:string;
    nombre_empresa:string;
    porcentaje:string;
    estado:string;
    constructor(ae:AccionistaEmpresa = null){
        if(ae != null){
            this.fondoinversion_id=ae.fondoinversion_id;
            this.persona_id=ae.persona_id;
            this.identificacion_empresa=ae.identificacion_empresa;
            this.nombre_empresa=ae.nombre_empresa;
            this.porcentaje=ae.porcentaje;
            this.estado=ae.estado;
        }
    }
}