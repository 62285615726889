import { Component, OnInit } from '@angular/core';

export interface element {
  tipoMensaje: string;
  unidad: Number;
  costo: Number;
}
export interface element2 {
  tipoNotificacion: string;
  unidad: Number;
  costo: Number;
}
const ELEMENT_DATA: element[] = [
  {tipoMensaje: 'E-mail', unidad: 1000, costo: 0.001},
  {tipoMensaje: 'SMS', unidad: 4000, costo: 0.001},
  {tipoMensaje: 'Whatsapp', unidad: 6000, costo: 0.001},
  {tipoMensaje: 'Push', unidad: 9000, costo: 0.001}
];
const ELEMENT_DATA2: element2[] = [
  {tipoNotificacion: 'Informativa', unidad: 1000, costo: 0.001},
  {tipoNotificacion: 'Eventos', unidad: 4000, costo: 0.001},
  {tipoNotificacion: 'Acciones', unidad: 6000, costo: 0.001},
  {tipoNotificacion: 'Control Seguridad', unidad: 9000, costo: 0.001},
  {tipoNotificacion: 'Noticioso', unidad: 9000, costo: 0.001},
  {tipoNotificacion: 'Promocional', unidad: 9000, costo: 0.001}
];

@Component({
  selector: 'app-mant-pool',
  templateUrl: './mant-pool.component.html',
  styleUrls: ['./mant-pool.component.css']
})
export class MantPoolComponent implements OnInit {
  displayedColumns: string[] = ['tipoMensaje','unidad','costo'];
  
  dataSource = ELEMENT_DATA;
  dataSource2 = ELEMENT_DATA2;
  constructor() { }

  ngOnInit() {
  }

}
