import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observacion } from 'src/app/model/observaciones.model';

@Component({
  selector: 'app-inv-observaciones',
  templateUrl: './inv-observaciones.component.html',
  styleUrls: ['./inv-observaciones.component.css']
})
export class InvObservacionesComponent implements OnInit{
  paramBusquedaGroup:FormGroup;
  constructor(private _builder: FormBuilder) { }
  @Input() obs: Observacion[];

  ngOnInit() {
    this.paramBusquedaGroup = this._builder.group({
      enDatosPersonales: [{value: ''}],
      enDomicilio:[{value: ''}],
      enLugarTrabajo:[{value: ''}],
      enDatosContacto:[{value: ''}],
      enBeneficiario:[{value: ''}],
      enDocumentos:[{value: ''}],
      enInversion:[{value: ''}],
      enReferencias:[{value: ''}],
      enSituacionFinanciera:[{value: ''}],
      enAccionistaEmpresa:[{value: ''}],
      enPeps:[{value: ''}],
      enEstadoFinanciera:[{value: ''}]
    });
    this.paramBusquedaGroup.disable();
  }
}