import { Data } from './z.model';
import { formatDate } from '@angular/common';
export class Bonificacion {
    bonificacion_id:number;
    monto:string;
    piso:string;
    techo:string;
    fecha_inicio:Date;
    fecha_fin:Date;
    estado:string;
    asignados:any[]=[];
    constructor(b:Bonificacion = null){
        if(b!=null){
            for(let i in b.asignados){
                this.asignados.push(b.asignados[i]);
            }
            this.bonificacion_id = b.bonificacion_id;
            this.monto = b.monto;
            this.piso = b.piso;
            this.techo = b.techo;
            this.fecha_inicio = new Date(b.fecha_inicio['date']);
            this.fecha_fin = new Date(b.fecha_fin['date']);
            this.estado = b.estado;
        }
    }
    public static getAll() {
        var tmp = Data._getBody("C6",168,sessionStorage.getItem("token_fks"));
        return tmp;
    }
    public static assign(idFondo,idBonif){
        var tmp = Data._getBody("I4",173,sessionStorage.getItem("token_fks"));
        tmp["data"]["fondoinversion_id"] = idFondo;
        tmp["data"]["bonificacion_id"] = idBonif;
        return tmp;
    }
    public static post(b:Bonificacion) {
        var finicio = formatDate(b.fecha_inicio, 'yyyy-MM-dd', 'en');
        var ffin = formatDate(b.fecha_fin, 'yyyy-MM-dd', 'en');
        var tmp = Data._getBody("I2",173,sessionStorage.getItem("token_fks"));
        tmp["data"]["monto"] = b.monto;
        tmp["data"]["piso"] = b.piso;
        tmp["data"]["techo"] = b.techo;
        tmp["data"]["fecha_inicio"] = finicio;
        tmp["data"]["fecha_fin"] = ffin;
        tmp["data"]["estado"] = "A";
        return tmp;
    }

}