import { Data } from '../z.model';

export class Report3 {
    administradorafondos_id:number;
    fondoinversion_id:number;
    nombrefondo:string;
    CR:number;
    DB:number;
    semana: number;
    mes:number;
    anio:number;
    constructor(b:Report3 = null){
        if(b != null){
            this.administradorafondos_id=b.administradorafondos_id;
            this.fondoinversion_id=b.fondoinversion_id;
            this.nombrefondo=Data._trim(b.nombrefondo);
            this.CR=b.CR;
            this.DB=b.DB;
            this.semana = b.semana;
            this.mes=b.mes;
            this.anio=b.anio;
        }
    }
    public static getAll(anio:number,mes:number) {
        var tmp = Data._getBody("C10",156,sessionStorage.getItem("token_fks"));
        tmp["data"]["anio"] = anio;
        tmp["data"]["mes"] = mes;
        return tmp;
    }
}