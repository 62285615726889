import { Data } from './z.model';
import { DetalleCatalogo } from './detalleCatalogo.model';

export class Catalogo {
    catalogo_id:number;
    nombre:string;
    descripcion:string;
    listDetCatalogo:DetalleCatalogo[] = [];
    estado:string;
    banNuevo = false;
    constructor(c:Catalogo){
        if(c!=null){
            this.catalogo_id = c.catalogo_id;
            this.nombre = Data._trim(c.nombre);
            this.descripcion = Data._trim(c.descripcion);
            this.estado = c.estado;
        }
    }
    public static getAll(){
        var tmp = Data._getBody("C",86,sessionStorage.getItem("token_fks"));
        return tmp;
    }
    public static getAllInit(){
        var tmp = Data._getBody("catalogo",11,"");
        return tmp;
    }
    public static validarDispositivo(id:string) {
        let stId = id.split(" ").join("+");
        var tmp = Data._getBody("equipo",11,"");
        tmp["data"]["id_equipo_persona"] = stId;
        return tmp;
    }
    public static post(f:Catalogo){
        var tmp = Data._getBody("I",87,sessionStorage.getItem("token_fks"));
        if(f.catalogo_id != null){
          tmp = Data._getBody("U",88,sessionStorage.getItem("token_fks"));
          tmp["data"]["catalogo_id"] = f.catalogo_id;  
        }
        tmp["data"]["nombre"] = f.nombre;
        tmp["data"]["descripcion"] = f.descripcion;
        tmp["data"]["estado"] = "A";
        return tmp;
    }
    public static delete(id:Number){
        var tmp = Data._getBody("D",89,sessionStorage.getItem("token_fks"));
        tmp["data"]["catalogo_id"] = id;
        return tmp;
    }
}