import { Component, OnInit, Input, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { Pep } from 'src/app/model/persona/pep.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-peps',
  templateUrl: './peps.component.html',
  styleUrls: ['./peps.component.css']
})
export class PepsComponent implements OnInit, OnChanges {
  isLoading:boolean=true;
  banText:boolean = false;
  @Input() p:Pep[];
  @Output() obsChange:EventEmitter<string> = new EventEmitter();
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['nombre','funcion','cargo','jerarquia','relacion'];
  dataSource;
  constructor() {}
  ngOnChanges(){
    if(this.p.length>0){
      this.isLoading=false;
    }
    this.dataSource = new MatTableDataSource<Pep>(this.p);
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit() {
  }
  changeStatusObs(e){
    this.banText=e.checked;
    this.obsCheck.emit(this.banText);
  }
  obtValor(v){
    this.obsChange.emit(v);
  }
}
