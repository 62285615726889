export class CostoFinanciero {
    costosfinancieros_id:number;
    pais_id:number;
    nombre:string;
    aplica_porcentaje:boolean;
    valor:number;
    monto_desde:number;
    monto_hasta:number;
    fecha_inicio:Date;
    fecha_fin:Date;
    rangoFecha:Date[] = [];
    estado:string;
    constructor(c:CostoFinanciero=null){
        if(c!=null){
            this.costosfinancieros_id = c.costosfinancieros_id;
            this.pais_id = c.pais_id;
            this.nombre = c.nombre;
            this.aplica_porcentaje = c.aplica_porcentaje;
            this.valor = c.valor;
            this.monto_desde = c.monto_desde;
            this.monto_hasta = c.monto_hasta;
            this.fecha_inicio = new Date(c.fecha_inicio['date']);
            this.fecha_fin = new Date(c.fecha_fin['date']);
            this.rangoFecha.push(this.fecha_inicio);
            this.rangoFecha.push(this.fecha_fin);
            this.estado = c.estado;
        }
    }
}