import { Component, OnInit, Output, EventEmitter, Input, Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-documentos-habilitantes',
  templateUrl: './documentos-habilitantes.component.html',
  styleUrls: ['./documentos-habilitantes.component.css']
})
@Pipe({
  name: 'safe'
})
export class DocumentosHabilitantesComponent implements OnInit, PipeTransform  {
  doccontrato;
  docterminos;
  docdebito;
  administradora:string;
  @Input()
  set contratos(contratos: any) {
    this.doccontrato = this.transform(contratos.doccontrato);
    this.docterminos = this.transform(contratos.docterminos);
    this.docdebito = this.transform(contratos.docdebito);
  }
  hover:boolean = true;
  banText:boolean = false;
  banTextSB:boolean = false;
  @Input() banCheck:boolean;
  @Output() obsCheck:EventEmitter<boolean> = new EventEmitter();
  constructor(private sanitizer: DomSanitizer) { 
  }
  ngOnInit() {
    this.administradora = atob(sessionStorage.getItem('inf7'));
  }
  changeStatusObs(e){
    this.obsCheck.emit(e);
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
