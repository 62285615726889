import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatTableDataSource } from '@angular/material/table';
import { CostoAdministrativo } from 'src/app/model/costoAdministrativo.model';
import { CostoAdministrativoService } from 'src/app/service/costo-administrativo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-mant-costo-administrativo',
  templateUrl: './mant-costo-administrativo.component.html',
  styleUrls: ['./mant-costo-administrativo.component.css']
})
export class MantCostoAdministrativoComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['nombre','aplica','valores','monto','fechas','acciones'];
  costoSelected: CostoAdministrativo;
  listCostoAdministrativo: CostoAdministrativo[]=[];
  paramBusquedaGroup:FormGroup;
  modalRef: BsModalRef;
  isCollapsed = true;
  isLoading:boolean=false;
  isLoading2:boolean = true;
  fech:Date= new Date();
  addonText:boolean = true;
  
  constructor(private costoAdministrativoService:CostoAdministrativoService,private _snackBar: MatSnackBar,private modalService: BsModalService,private _builder: FormBuilder) {
    this.paramBusquedaGroup = this._builder.group({
      costosadministrativos_id: [null],
      nombre: ['',Validators.required],
      valor:['',Validators.required],
      aplica_porcentaje:[false],
      monto_desde:['',Validators.required],
      monto_hasta:['',Validators.required],
      rangoFecha:['',Validators.required]
    });
  }

  changeAddon(e){
      this.addonText = !(e.checked);
  }

  delCosto(){
    this.isLoading=true;
    this.costoAdministrativoService.deleteCostoAdministrativo(this.costoSelected.costosadministrativos_id).subscribe(
      (data : any)=>{
        this.getAllCostoAdministrativo();
        this.isLoading=false;
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  viewCosto(f:CostoAdministrativo,ban:boolean){
    this.isCollapsed = false;
    this.costoSelected = f;
    this.paramBusquedaGroup.patchValue(this.costoSelected);
    if(ban)
      this.paramBusquedaGroup.disable();
    else
      this.paramBusquedaGroup.enable();
  }
  ngOnInit() {
    this.getAllCostoAdministrativo();
  }
  openModal(template: TemplateRef<any>,f:CostoAdministrativo) {
    this.costoSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-md');
  }

  save(form){
    this.isLoading=true;
    form.fecha_inicio = form.rangoFecha[0];
    form.fecha_fin = form.rangoFecha[1];
    this.costoAdministrativoService.postCostoAdministrativo(form).subscribe(
      (data : any)=>{
        this.getAllCostoAdministrativo();
        this.isCollapsed = !this.isCollapsed;
        this.isLoading=false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
  
  getAllCostoAdministrativo(){
    this.isLoading2=true;
    this.listCostoAdministrativo = [];
    this.dataSource = new MatTableDataSource<CostoAdministrativo>(this.listCostoAdministrativo);
    this.dataSource.paginator = this.paginator;
    this.costoAdministrativoService.getAllCostoAdministrativo().subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listCostoAdministrativo.push(new CostoAdministrativo(data.datos[t] as CostoAdministrativo));
        }
        this.dataSource = new MatTableDataSource<CostoAdministrativo>(this.listCostoAdministrativo);
        this.dataSource.paginator = this.paginator;
        this.isLoading2 = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
}
