import { Data } from '../z.model';

export class DatosCuenta {
    datoscuenta_id:number;
    persona_id:number;
    financiera_id:number;
    financiera_nombre:string;
    tipocuenta_id:number;
    tipocuenta_nombre:string;
    numero:string;
    estado:string;
    constructor(dc:DatosCuenta = null){
        if(dc !=null){
            this.datoscuenta_id=dc.datoscuenta_id;
            this.persona_id = dc.persona_id;
            this.financiera_id = dc.financiera_id;
            this.financiera_nombre = Data._findFinanciera(dc.financiera_id).Nombre;
            this.tipocuenta_id = dc.tipocuenta_id;
            this.tipocuenta_nombre = Data._fCatalogo(dc.tipocuenta_id,"TIPO DE CUENTA");
            this.numero = dc.numero;
            this.estado = dc.estado;
        }
    }
}