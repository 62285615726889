import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";  
import { AppLoadService } from '../service/app-load.service';
import { Data } from '../model/z.model';
import { GeneralService } from '../service/general.service';

@Injectable()
export class PrioryResolve implements Resolve<any>{
  constructor(private postService: AppLoadService,private gService: GeneralService) {}  
  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    if(Data._idEquipo == "")
      return this.postService.getDataPriory();
    else if(sessionStorage.getItem("token_fks"))
      return this.postService.getFondos();
  }
}
@Injectable()
export class IpResolve implements Resolve<any>{
  constructor(private postService: GeneralService) {}
  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.postService.useServiceIp().toPromise();
  }
}