import { Data } from '../z.model';

export class Report2 {
    fondoinversion_id:number;
    nombrefondo:string;
    solicitantes:number;
    inversionistas:number;
    indice_inversionistas:number;
    constructor(b:Report2 = null){
        if(b != null){
            this.fondoinversion_id = b.fondoinversion_id;
            this.nombrefondo=Data._trim(b.nombrefondo);
            this.solicitantes=b.solicitantes;
            this.inversionistas=b.inversionistas;
            this.indice_inversionistas=b.indice_inversionistas;
        }
    }
    //TODO Aun no me lo han dado a conocer.
    public static getAll() {
        return Data._getBody("C7",156,sessionStorage.getItem("token_fks"));
    }
} 