import { Data } from './z.model';

export class DetalleCatalogo {
    detallecatalogo_id: number;
    catalogo_id: number;
    descripcion: string;
    etiqueta:string;
    valor: string;
    orden: number;
    constructor(dc:DetalleCatalogo = null){
        if(dc!=null){
            this.detallecatalogo_id = dc.detallecatalogo_id;
            this.catalogo_id = dc.catalogo_id;
            this.descripcion = Data._trim(dc.descripcion);
            this.etiqueta = Data._trim(dc.etiqueta);
            this.valor = Data._trim(dc.valor);
            this.orden = dc.orden;
        }
    }
    public static getAll(idCabecera:number){
        var tmp = Data._getBody("C",104,sessionStorage.getItem("token_fks"));
        tmp["data"]["catalogo_id"] = idCabecera;
        return tmp;
    }
      
    public static post(f:DetalleCatalogo){
        var tmp = Data._getBody("I",105,sessionStorage.getItem("token_fks"));
        if(f.detallecatalogo_id != null){
          tmp = Data._getBody("U",106,sessionStorage.getItem("token_fks"));
          tmp["data"]["detallecatalogo_id"] = f.detallecatalogo_id;
        }
        tmp["data"]["catalogo_id"] = f.catalogo_id;
        tmp["data"]["etiqueta"] = f.etiqueta;
        tmp["data"]["valor"] = f.valor;
        tmp["data"]["orden"] = f.orden;
        tmp["data"]["descripcion"] = f.descripcion;
        tmp["data"]["estado"] = "A";
        return tmp;
    }
      
    public static delete(id:Number){
        var tmp = Data._getBody("D",107,sessionStorage.getItem("token_fks"));
        tmp["data"]["detallecatalogo_id"] = id;
        return tmp;
    }
}