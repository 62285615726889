import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { MatPaginator } from '@angular/material/paginator';
import { Impuesto } from 'src/app/model/impuesto.model';
import { MatTableDataSource } from '@angular/material/table';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';

@Component({
  selector: 'app-mant-impuesto',
  templateUrl: './mant-impuesto.component.html',
  styleUrls: ['./mant-impuesto.component.css']
})
export class MantImpuestoComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource;
  displayedColumns: string[] = ['nombre','aplica','valores','monto','fechas','acciones'];
  listTipoImpuesto: Impuesto[]=[];
  fech:Date= new Date();

  paramBusquedaGroup:FormGroup;
  tipoImpuestoForm:FormGroup;
  modalRef: BsModalRef;
  tipoImpuestos: DetalleCatalogo[] = [];
  constructor(private modalService: BsModalService,private _builder: FormBuilder) { }

  ngOnInit() {
    this.tipoImpuestos  = Data._fListCatalogo("TIPO IMPUESTO");
    this.paramBusquedaGroup = this._builder.group({
      nombre: [''],
      valores:[''],
      aplica:[''],
      montoInicial:[''],
      montoFinal:[''],
      fechaH:[''],
      fechaD:['']
    });
    for (var _i = 0; _i < 30; _i++) {
      this.listTipoImpuesto.push(new Impuesto("Tipo "+_i,12.2));
    }
    this.dataSource = new MatTableDataSource<Impuesto>(this.listTipoImpuesto) ;
    this.dataSource.paginator = this.paginator;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-lg');
  }

  find(form){
    console.log(form);
  }
}