import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Data } from 'src/app/model/z.model';
import { SolicitudInversion } from 'src/app/model/solicitudes/solicitudInversion.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { MatSort } from '@angular/material/sort';
import { GeneralService } from 'src/app/service/general.service';
import { Router } from '@angular/router';
import { ParticipacionDiaria } from 'src/app/model/participacionDiaria.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-inversion',
  templateUrl: './inversion.component.html',
  styleUrls: ['./inversion.component.css']
})
export class InversionComponent implements OnInit {
  isLoading;
  bpor:string = "persona";
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ['solicitud_inversion_id','identificacion','apellidos','nombrefondo','fecha_registro','estado','acciones'];
  listSolicitudInversion: SolicitudInversion[];
  tipoEstados: DetalleCatalogo[] = [];
  tipoFondos: FondoInversion[] = [];
  dataSource;
  paramBusquedaGroup:FormGroup;
  modalRef: BsModalRef;
  idFondo:number;
  constructor(private modalService: BsModalService,private router : Router,private _builder: FormBuilder, private generalService:GeneralService) {}
  ngOnInit() {
    this.tipoFondos = Data._fondosInversion;
    this.tipoEstados = Data._fListCatalogo("ESTADO SOLICITUD");
    this.paramBusquedaGroup = this._builder.group({
      Documento: [''],
      FondoInversion:[''],
      rangoFecha:[''],
      Estado:['']
    });
    this.getAllSolicitud();
    this.onChanges();
  }
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.find(this.paramBusquedaGroup.value);
    });
  }
  getAllSolicitud(){
    this.isLoading = true;
    this.paramBusquedaGroup.disable();
    this.listSolicitudInversion = [];
    this.generalService.useService(SolicitudInversion.getAllByAceptadas()).subscribe(
      (data : any)=>{
        for (let t in data.datos) {
          this.listSolicitudInversion.push(new SolicitudInversion(data.datos[t] as SolicitudInversion));
        }
        this.configTable(this.listSolicitudInversion);
        this.paramBusquedaGroup.enable();
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
        console.log("Ocurrio un error al listar Solicitudes de inversion.Consulte con su proveedor.");
      }
    );
  }
  find(form:any){
    var temp = this.listSolicitudInversion;
    if(form.Documento!=""){
      if(this.bpor == "persona"){
        temp = temp.filter(function(el) {
          return el.identificacion.indexOf(form.Documento) > -1});
      }else{
        temp = temp.filter(function(el) {
          return el.solicitud_inversion_id.toString().indexOf(form.Documento) > -1});
      }
    }
    if(form.FondoInversion!="")
      temp=temp.filter(d => (d.fondoinversion_id == form.FondoInversion));
    if(form.rangoFecha != null){
      if((form.rangoFecha[0] != null && form.rangoFecha[1] != null))
        temp=temp.filter(d => (d.fecha_registro.getTime() <= form.rangoFecha[1].getTime() && d.fecha_registro.getTime() >= form.rangoFecha[0].getTime()));
    }
    if(form.Estado!="")
      temp=temp.filter(d => (d.estado_nombre == form.Estado));
    this.configTable(temp);
  }
  configTable(data){
    this.dataSource = new MatTableDataSource<SolicitudInversion>(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  redirectValor(){
    this.modalRef.hide();
    this.router.navigate(['/home/configuraciones/fondoinversion',this.idFondo]);
  }
  getValorUnidades(id,idFondo,template: TemplateRef<any>){
    this.idFondo = idFondo;
    this.generalService.useService(ParticipacionDiaria.getDiary(idFondo)).subscribe(
      (data:any) => {
        if(data.datos[0].unidades_diarias_cargadas == 0){
          this.modalRef = this.modalService.show(template);
          this.modalRef.setClass('modal-md');
        }else{
          this.router.navigate(['/home/solicitudes/inversion',id]);
        }
      },
      (err : HttpErrorResponse)=>{
        console.log("Ocurrio un error al tratar de consultar Participación diario. Consulte con su proveedor.");
      }
    );
  }
}