import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatPaginator } from '@angular/material/paginator';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { Data } from 'src/app/model/z.model';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { Usuario } from 'src/app/model/usuario.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { GeneralService } from 'src/app/service/general.service';
import { Persona } from 'src/app/model/persona/persona.model';
import { TipoUsuario } from 'src/app/model/tipoUsuario.model';

@Component({
  selector: 'app-usuario-administradora',
  templateUrl: './usuario-administradora.component.html',
  styleUrls: ['./usuario-administradora.component.css']
})
export class UsuarioAdministradoraComponent implements OnInit {
  hidden = false;
  isLoading : boolean = false;
  @ViewChild(MatHorizontalStepper, {static: true}) stepper: MatHorizontalStepper;
  @ViewChild('fileImportInput', {static: true}) fileImportInput: any;
  @ViewChild("pag1", {static: true}) paginator: MatPaginator;
  @ViewChild("pag2", {static: true}) paginator2: MatPaginator;
  dataSource;
  dataSource2;
  displayedColumns: string[] = ['identificacion','nombre','usuario','cargo','acciones'];
  csvRecords:Usuario[]=[];
  listUsuario:Usuario[]=[];
  isLinear = true;
  isCvs = false;
  isCollapsed = true;
  isCollapsedTable = true;
  usuarioSelected:Usuario = new Usuario();
  datosPersonalesForm:FormGroup;
  credencialesForm:FormGroup;
  formFilter:FormGroup;
  changePassForm:FormGroup;
  tipoIdentificacion: DetalleCatalogo[] = [];
  nacionalidad: DetalleCatalogo[] = [];
  genero: DetalleCatalogo[] = [];
  estadoCivil: DetalleCatalogo[] = [];
  tipoGrupo: DetalleCatalogo[] = [];
  modalRef: BsModalRef;
  id_usuario: number;
  cboTpoUsuario: any[];
  constructor(private modalService: BsModalService,private _builder: FormBuilder,private localeService: BsLocaleService,
              private _snackBar: MatSnackBar, private generalService:GeneralService) {
    this.localeService.use('es');
    this.datosPersonalesForm = this._builder.group({
      persona_id:[null],
      nombres: ['',Validators.required],
      apellidos:['',Validators.required],
      tipoidentificacion_id:['',Validators.required],
      identificacion:['',Validators.required],
      fecha_expedicion:['',Validators.required],
      codigo_dactilar:['',Validators.required],
      nacionalidad_id:['',Validators.required],
      fecha_nacimiento:['',Validators.required],
      genero:['',Validators.required],
      estadocivil_id:['',Validators.required]
    });
    this.credencialesForm = this._builder.group({
      tpo:['',[Validators.required]],
      email:['administradora002@yopmail.com',[Validators.required,Validators.email]]
    });
    this.changePassForm = this._builder.group({
      password:['',[Validators.required]]
    });
    this.formFilter = this._builder.group({
      tipo:[''],
      identificacion:[''],
      administradora:['']
    });
  }
  ngOnInit() {
    this.tipoIdentificacion = Data._fListCatalogo("TIPO DE IDENTIFICACION PERSONA");
    this.nacionalidad = Data._fListCatalogo("NACIONALIDAD");
    this.genero = Data._fListCatalogo("SEXO");
    this.estadoCivil = Data._fListCatalogo("ESTADO CIVIL");
    this.tipoGrupo = Data._fListCatalogo("TIPO GRUPO DE TRABAJO");
    this.getAllTipoUsuario();
    this.onChanges(); 
  }
  onChanges(): void {
    this.formFilter.valueChanges.subscribe(val => {
      const tableFilters = [];
      tableFilters.push({
        id: 'identificacion',
        value: val.identificacion
      },{
        id:'tipo',
        value: val.tipo
      });
      console.log(tableFilters);
      this.dataSource.filter = JSON.stringify(tableFilters);
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    });
  }
  getAllTipoUsuario(){
    this.isLoading = true;
    this.cboTpoUsuario = [];
    this.generalService.useService(TipoUsuario.getAll()).subscribe(
      (data : any)=>{
        for(let t in data.datos){
          // if(t!='8')
            this.cboTpoUsuario.push(new TipoUsuario({tipousuario_id:Number(t),tipousuario:data.datos[t].tipousuario}));
        }
        this.isLoading = false;
        this.getAllUsuario();
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  getPersonaByCedula(val:string){
    this.generalService.useService(Persona.getByCedula(val)).subscribe(
      (data:any)=>{
        if(data.datos[0].status!="ERROR PELIGROSO"){
          var d = data.datos[0].datospersonales.fecha_nacimiento.split("-");
          data.datos[0].datospersonales.fecha_nacimiento = new Date(Number(d[0]),Number(d[1])-1,Number(d[2]));
          data.datos[0].datospersonales.identificacion = val;
          this.datosPersonalesForm.patchValue(data.datos[0].datospersonales);
        }
      },(err:HttpErrorResponse)=>{
        console.error(err);
      }
    );
  }
  getAllUsuario(){
    this.isLoading = true;
    this.listUsuario = [];
    this.generalService.useService(Usuario.getAll()).subscribe(
      (data : any)=>{
        this.listUsuario = Object.values(data.datos).map((t:Usuario)=>{
          t.tipo_nombre = this.cboTpoUsuario.find(element => element.tipousuario_id == t.tipo).tipousuario;
          return new Usuario(t);
        });
        this.dataSource = new MatTableDataSource<Usuario>(this.listUsuario);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = 
        (data: any, filtersJson: string) => {
          console.log(data, filtersJson);
          const matchFilter = [];
          const filters = JSON.parse(filtersJson);
          filters.forEach(filter => {
            let val;
            if(filter.id === 'identificacion'){
              val = data['datospersonales']['identificacion'];  
            }else{
              val = data[filter.id] === null ? '' : (typeof data[filter.id] == 'number'?data[filter.id].toString():data[filter.id]);
            }
            matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
          });
          return matchFilter.every(Boolean);
        };
        this.isLoading = false;
      },
      (err : HttpErrorResponse)=>{
        console.log(err.error);
      }
    );
  }
  cambiarPass(form){
    form.id = this.usuarioSelected.usuario_id;
    form.estado = this.usuarioSelected.estado;
    form.tipo = this.usuarioSelected.tipo;
    this.generalService.useService(Usuario.changePass(form)).subscribe(
      (data : any)=>{
        console.log(data);
        this.fillUsuario(data.datos);
        this.usuarioSelected = new Usuario();
        this.modalRef.hide();
      },
      (err : HttpErrorResponse)=>{
        console.log(err.error);
      }
    );
  }
  fillUsuario(datos){
    for (let t in datos) {
      let temp:Usuario = new Usuario(datos[t] as Usuario);
      this.listUsuario.push(temp);
    }
    this.dataSource = new MatTableDataSource<Usuario>(this.listUsuario);
    this.dataSource.paginator = this.paginator;
    this.isLoading = false;
  }
  saveDatosPersonales(id_usuario){
    let form = this.datosPersonalesForm.value;
    this.isLoading = true;
    this.generalService.useService(Persona.post(form,id_usuario)).subscribe(
      (data : any)=>{
        console.log(data);
        if(Data.isOk(data)){
          this.clear();
          this.getAllUsuario();
        }
        this.isLoading = !this.isLoading;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  buscarCedula(val){
    if(this.datosPersonalesForm.controls['tipoidentificacion_id'].value=='14' && val.length==10)
        this.getPersonaByCedula(val);
  }
  clear(){
    this.isCollapsed = true;
    this.datosPersonalesForm.reset({
      persona_id:null,
      nombres: '',
      apellidos:'',
      tipoidentificacion_id:'',
      identificacion:'',
      fecha_expedicion:'',
      codigo_dactilar:'',
      nacionalidad_id:'',
      fecha_nacimiento:'',
      genero:'',
      estadocivil_id:''
    });
    this.credencialesForm.reset({
      tpo:'',
      email:'',
      financiera:''
    });
    this.changePassForm.reset({
      password:''
    });
    this.credencialesForm.enable();
  }
  makeid(length:number):string{
    var result='';
    var characters='ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  saveCredenciales(){
    let form = this.credencialesForm.value;
    this.isLoading = true;
    form.contrasenia = this.makeid(5);
    this.generalService.useService(Usuario.post(form)).subscribe(
      (data : any)=>{
        console.log(data);
        if(Data.isOk(data)){
          this.saveDatosPersonales(Number(data.datos[0].codigo));
        }
        this.isLoading = !this.isLoading;
      },
      (err : HttpErrorResponse)=>{
        console.log(err);
      }
    );
  }
  resultRequest(datos):boolean{
    if(datos[0] != undefined){
      this.openSnackBar(datos[0].mensaje,datos[0].status);
      if(datos[0].status != "ERROR"){
        this.id_usuario = Number(datos[0].codigo);
        return true;
      }
    }
    return false;
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }
  openModal(template: TemplateRef<any>,f:Usuario,size:string) {
    this.usuarioSelected = f;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-'+size);
  }
  clearData(){
    if(this.isCvs){
      this.isCollapsed = true;
      this.isCollapsedTable = false;
    }else{
      this.hidden = false;
      this.isCollapsed = true;
      this.isCollapsedTable = true;
    }
  }
  viewUsuario(f:Usuario,ban:boolean,cvs:boolean){
    if(cvs){
      this.isCollapsed = false;
      this.isCollapsedTable = true;
    }else{
      this.isCollapsed = false;
    }
    this.usuarioSelected = f;
    this.datosPersonalesForm.patchValue(this.usuarioSelected.datospersonales);
    this.credencialesForm.patchValue(this.usuarioSelected.credenciales);
    if(ban)
      this.datosPersonalesForm.disable();
    else
      this.datosPersonalesForm.enable();
    this.credencialesForm.disable();
  }
  delUsuario(){
    if(this.isCvs){
      var index = this.csvRecords.findIndex(item => item.datospersonales.persona_id === this.usuarioSelected.datospersonales.persona_id)
      this.csvRecords.splice(index, 1);
      this.dataSource2 = new MatTableDataSource<any>(this.csvRecords);
      this.dataSource2.paginator = this.paginator2;
      this.clearData();
    }else{
      this.generalService.useService(Usuario.delete(this.usuarioSelected.usuario_id)).subscribe(
        (data : any)=>{
          console.log(data);
          if(this.resultRequest(data.datos)){
            this.isLoading = !this.isLoading;
            this.getAllUsuario();
            this.usuarioSelected = null;
          }
        },
        (err : HttpErrorResponse)=>{
          console.log(err);
        }
      );
    }
    this.modalRef.hide();
  }
  //PARA ARCHIVO CVS
  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = csvRecordsArr[0].split(",");
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    var dataArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let data = csvRecordsArray[i].split(",");
      if (data.length == headerLength) {
        var d:Usuario = new Usuario();
        d.datospersonales.persona_id = i;
        d.datospersonales.nombres = data[0];
        d.datospersonales.apellidos = data[1];
        d.datospersonales.identificacion = data[2];
        d.datospersonales.tipoidentificacion_id = data[3];
        d.datospersonales.fecha_expedicion = new Date(data[4]);
        d.credenciales.usuario = data[5];
        d.credenciales.grupo = data[6];
        d.credenciales.cargo = data[7];
        dataArr.push(d);
      }
    }
    return dataArr;
  }
  fileChangeListener($event: any): void {
    var files = $event.srcElement.files;
    if (this.isCSVFile(files[0])) {
      var input = $event.target;
      var reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = (data) => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray,headersRow.length);
        this.isCvs = true;
        this.dataSource2 = new MatTableDataSource<any>(this.csvRecords);
        this.dataSource2.paginator = this.paginator2;
        this.hidden = true;
        this.isCollapsedTable = !this.isCollapsedTable;
        this.fileReset();
      }
      reader.onerror = function() {
          alert('Unable to read ' + input.files[0]);
      };
    } else {
      alert('Please import valid .csv file.');
      this.fileReset();
    }
  }
  fileReset() {
    this.fileImportInput.nativeElement.value = "";
  }
  clearCVS(){
    this.csvRecords = [];
    this.isCvs = false;
    this.hidden = false;
    this.isCollapsedTable = true;
  }
  saveCVS(){
    for(let c of this.csvRecords){
      this.listUsuario.push(c); 
    }
    this.dataSource = new MatTableDataSource<any>(this.listUsuario);
    this.dataSource.paginator = this.paginator;
    this.clearCVS();
  }
  deleteCVS(el){
  }
}