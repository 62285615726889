import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Data } from 'src/app/model/z.model';

import { MatPaginator } from '@angular/material/paginator';
import { SolicitudTransferencia } from 'src/app/model/solicitudes/solicitudTransferencia.model';
import { MatTableDataSource } from '@angular/material/table';
import { DetalleCatalogo } from 'src/app/model/detalleCatalogo.model';
import { FondoInversion } from 'src/app/model/fondoInversion.model';
import { GeneralService } from 'src/app/service/general.service';
import { MatSort } from '@angular/material/sort';
import { HttpErrorResponse } from '@angular/common/http';
import { SimuladorBanco } from 'src/app/model/simuladorBanco.model';
import { ExportServicesService } from 'src/app/service/export-services.service';
import * as jsPDF  from 'jspdf';
import 'jspdf-autotable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ModalsComponent } from '../../modals/modals.component';
import { AlertService } from 'src/app/util/alerts/alert.service';

@Component({
  selector: 'app-proc-transferencia',
  templateUrl: './proc-transferencia.component.html',
  styleUrls: ['./proc-transferencia.component.css']
})
export class ProcTransferenciaComponent implements OnInit {
  isLoading:boolean;
  isLoading2:boolean;
  isLoadingCSV:boolean;

  @ViewChild('childModal',{static:true}) childModal :ModalsComponent;
  @ViewChild('fileImportInput', {static: true}) fileImportInput: any;

  @ViewChild("paginatorPendientes", {static: true}) paginatorPendientes: MatPaginator;
  @ViewChild("sortPendientes", {static: true}) sortPendientes: MatSort;
  dataSourcePendientes = new MatTableDataSource<SolicitudTransferencia>();
  
  @ViewChild("paginatorAprobados", {static: true}) paginatorAprobados: MatPaginator;
  @ViewChild("sortAprobados", {static: true}) sortAprobados: MatSort;
  dataSourceAprobados  = new MatTableDataSource<SolicitudTransferencia>();
  
  @ViewChild("paginatorRetorno", {static: true}) paginatorRetorno: MatPaginator;
  @ViewChild("sortRetorno", {static: true}) sortRetorno: MatSort;
  dataSourceRetorno = new MatTableDataSource<SolicitudTransferencia>();
  
  listSolicitudPendientes: SolicitudTransferencia[]=[];
  listSolicitudAprobados: SolicitudTransferencia[]=[];
  
  displayedColumns: string[] = ['transaccion_id','identificacion','nombre','nombrefondo','estado_nombre','monto','fecha_registro'];
  displayedColumnsRetorno: string[] = ['referencia','comprobante','mensaje'];
  paramBusquedaGroup:FormGroup;
  tipoFondos: FondoInversion[] = [];
  tipoEstados: DetalleCatalogo[] = [];
  listRetornoBanco:any[];
  
  constructor(private generalService: GeneralService,private _builder: FormBuilder, private excelService:ExportServicesService,
              private alertService:AlertService) {}

  ngOnInit() {
    this.tipoEstados = Data._fListCatalogo("ESTADO SOLICITUD");
    this.tipoFondos = Data._fondosInversion;
    this.paramBusquedaGroup = this._builder.group({
      Documento: [''],
      FondoInversion:[''],
      rangoFecha:[''],
      Estado:['']
    });
    this.getAllSolRetiro();
    this.onChanges();
  }
  run(){
    this.isLoading2 = true;
    this.generalService.useService(SimuladorBanco.startSimulador()).subscribe(
      (data)=>{
        this.getAllSolRetiro();
        this.isLoading2 = false;
      },
      (err)=>{console.error("Ocurrio un error en el simulador")}
    );
  }
  getAllSolRetiro(){
    this.isLoading = true;
    this.paramBusquedaGroup.disable();
    this.listSolicitudPendientes = [];
    this.dataSourcePendientes = new MatTableDataSource<SolicitudTransferencia>(this.listSolicitudPendientes);
    this.dataSourcePendientes.sort = this.sortPendientes;
    this.dataSourcePendientes.paginator = this.paginatorPendientes;
    this.listSolicitudAprobados = []
    this.dataSourceAprobados = new MatTableDataSource<SolicitudTransferencia>(this.listSolicitudAprobados);
    this.dataSourceAprobados.sort = this.sortAprobados;
    this.dataSourceAprobados.paginator = this.paginatorAprobados;
    this.generalService.useService(SolicitudTransferencia.getAll()).subscribe(
      (data : any)=>{
        if(data.datos){
          let arr = Object.values(data.datos).map((t:any)=>{
            return new SolicitudTransferencia(t);
          });
          this.listSolicitudAprobados = arr.filter(t =>(t.estado=="A"));
          let now = new Date();
          now.setHours(23);
          this.listSolicitudPendientes = arr.filter(t =>(t.estado=="P" && t.fecha_registro<=now));
          this.dataSourceAprobados = new MatTableDataSource<SolicitudTransferencia>(this.listSolicitudAprobados);
          this.dataSourceAprobados.sort = this.sortAprobados;
          this.dataSourceAprobados.paginator = this.paginatorAprobados;
          this.dataSourcePendientes = new MatTableDataSource<SolicitudTransferencia>(this.listSolicitudPendientes);
          this.dataSourcePendientes.sort = this.sortPendientes;
          this.dataSourcePendientes.paginator = this.paginatorPendientes;
        }
        this.isLoading = false;
        this.paramBusquedaGroup.enable();
      },
      (err : HttpErrorResponse)=>{
      }
    );
  }
  onChanges(): void {
    this.paramBusquedaGroup.valueChanges.subscribe(val => {
      this.find(this.paramBusquedaGroup.value);
    });
  }
  find(form:any){
    var temp = this.listSolicitudPendientes;
    if(form.Documento!=""){
      temp = temp.filter(function(el) {
        return el.identificacion.indexOf(form.Documento) > -1});
    }
    if(form.FondoInversion!="")
      temp=temp.filter(d => (d.fondoinversion_id == form.FondoInversion));
    if(form.rangoFecha != null){
      if((form.rangoFecha[0] != null && form.rangoFecha[1] != null)){
        form.rangoFecha[0].setHours(0, 0, 0);
        form.rangoFecha[1].setHours(23, 59, 59);
        temp=temp.filter(d => (d.fecha_registro.getTime() <= form.rangoFecha[1].getTime() && d.fecha_registro.getTime() >= form.rangoFecha[0].getTime()));
      }
    }
    if(form.Estado!="")
      temp=temp.filter(d => (d.estado_nombre == form.Estado));
    this.configTable(temp);
  }
  configTable(data){
    this.dataSourcePendientes = new MatTableDataSource<SolicitudTransferencia>(data);
    this.dataSourcePendientes.sort = this.sortPendientes;
    this.dataSourcePendientes.paginator = this.paginatorPendientes;
  }
  find2(form:any){
    var temp = this.listSolicitudAprobados;
    if(form.Documento!=""){
      temp = temp.filter(function(el) {
        return el.identificacion.indexOf(form.Documento) > -1});
    }
    if(form.FondoInversion!="")
      temp=temp.filter(d => (d.fondoinversion_id == form.FondoInversion));
    if(form.rangoFecha != null){
      if((form.rangoFecha[0] != null && form.rangoFecha[1] != null))
        temp=temp.filter(d => (d.fecha_registro.getTime() <= form.rangoFecha[1].getTime() && d.fecha_registro.getTime() >= form.rangoFecha[0].getTime()));
    }
    if(form.Estado!="")
      temp=temp.filter(d => (d.estado_nombre == form.Estado));
    this.configTable2(temp);
  }
  configTable2(data){
    this.dataSourceAprobados = new MatTableDataSource<SolicitudTransferencia>(data);
    this.dataSourceAprobados.sort = this.sortAprobados;
    this.dataSourceAprobados.paginator = this.paginatorAprobados;
  }
  dataForExport(){
    return this.dataSourcePendientes.data.map(t=>{
      return {
        "Codigo de Institución Bancaria": t.financiera_id,
        "Tipo de cuenta": t.tipocuenta_nombre,
        "Numero de cuenta": t.numero,
        "Valor":t.monto,
        "Tipo de identificación": t.tipoidentificacion_nombre,
        "Identificación": t.identificacion,
        "Nombres completos": t.apellidos + ' ' + t.nombres,
        "Email":t.email,
        "Teléfono": t.telefono,
        "Referencia": t.referencia,
        "Concepto": t.concepto
      }
    });
  }
  exportAsPDF(){
    let data = this.dataForExport();
    if(data.length>0){
      const doc = new jsPDF('l', 'pt','a4')
      doc.autoTable({
        margin: { right: 25, left:15 },
        startY: 15,
        theme:'grid',
        head: [["Codigo de Institución Bancaria","Tipo de cuenta","Numero de cuenta",
          "Valor","Tipo de identificación","Identificación","Nombres completos",
          "Email","Teléfono","Referencia","Concepto"]],
        body:data.map(t=>{return Object.values(t)}),
        headStyles:{lineColor: [255, 255, 255],lineWidth: 0.25,valign: 'middle', fontStyle:'normal',halign:'center',fillColor: [37, 43, 71],textColor:[255,255,255]},
        bodyStyles:{fontSize:7}
      });
      doc.setProperties({
        title: 'Ordenes de cobro '+ new Date(),
        subject: 'Ordees de cobro.',
        author: 'Invierte',
        creator: 'Invierte'
      });
      doc.save('Ordenes de cobro '+ new Date());
    }else{
      this.alertService.warn("No existen datos.");
    }
  }
  exportAsCSV() {
    let data = this.dataForExport();
    this.excelService.exportAsCSV(data,'Ordenes de cobro '+ (new Date()).getTime());
  }
  
  exportAsXLSX():void {
    let data = this.dataForExport();
    if(data.length>0){
      this.excelService.exportAsExcel(data, 'Ordenes de cobro '+ new Date());
    }else{
      this.alertService.warn("No existen datos.");
    }
  }
  saveRetorno(){
    this.isLoadingCSV = true;
    let calls:any[] = [];
    this.listRetornoBanco.forEach(t=>{
      calls.push(this.generalService.useService(SolicitudTransferencia.retornoTransferencia(t)));
    })
    forkJoin(calls).subscribe(
      (resp:any[])=>{
        this.childModal.hide();
        this.isLoadingCSV = false;
        this.getAllSolRetiro();
      }
    );
  }
  fileChangeListener($event: any): void {
    this.isLoadingCSV = true;
    var files = $event.srcElement.files;
    if (this.isCSVFile(files[0])) {
      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = (data) => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        this.listRetornoBanco = [];
        for (let i = 1; i < csvRecordsArray.length; i++) {
          if(csvRecordsArray[i].trim() != ""){
            let x = csvRecordsArray[i].split(",");
            if(x.length <= 1){
              x = csvRecordsArray[i].split(";");
            }
            let obj:any = {};
            headersRow.forEach((t,idx)=>{
              obj[t] = x[idx];
            });
            if(obj.referencia && obj.comprobante && obj.mensaje){
              let referencia = obj.referencia.split("-");
              obj.solicitud_transferencia_id = Number(referencia[3].substring(1));
              obj.solicitud_retiro_id = Number(referencia[4].substring(1));
              this.listRetornoBanco.push(obj);
            }else{
              this.alertService.warn("Error en la linea "+i);
            }
          }
        }
        this.dataSourceRetorno = new MatTableDataSource<SolicitudTransferencia>(this.listRetornoBanco);
        this.dataSourceRetorno.sort = this.sortRetorno;
        this.dataSourceRetorno.paginator = this.paginatorRetorno;
        this.childModal.show();
        this.fileReset();
      }
      reader.onerror = function() {
          alert('Unable to read ' + input.files[0]);
      };
    } else {
      this.alertService.warn("Asegurese de que el archivo sea de extensión .csv");
      this.fileReset();
    }
    this.isLoadingCSV = false;
  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = csvRecordsArr[0].split(",");
    if(headers.length <= 1){
      headers = csvRecordsArr[0].split(";");
    }
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }
  fileReset() {
    this.fileImportInput.nativeElement.value = "";
  }
  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }
}
