import { Component, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval } from 'rxjs';

@Component({
  selector: 'app-verificado',
  templateUrl: './verificado.component.html',
  styleUrls: ['./verificado.component.css']
})
export class VerificadoComponent implements OnInit {
  public player:any;

  constructor(private _builder: FormBuilder) { }
  ngOnInit() {
    this.init();
    const otherCont = interval(1000);
    otherCont.subscribe(() =>{
      this.changeSize();
    });
  }
  init(){
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }
  startVideo(){
    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    if(Number(width)<Number(height)){
      width = 1670*height/960;
    }else{
      height = 960*width/1670;
    }
    this.player = new window['YT'].Player('player', {
      videoId: 'HyYpY69Zk6Y',
      width: width,
      height: height,
      playerVars : {
        'modestbranding':1,
        'rel' : 0,
        'iv_load_policy':3,
        'showinfo' : 0,
        'showsearch' : 0, 
        'controls' : 0,
        'fs':0
      },
      events: {
        'onReady': this.onPlayerReady,
        'onStateChange': this.onStateChange
      }
    });
  }
  changeSize(){
    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    var porcW = width*100/1670;
    var porcH = height*100/960;
    if(porcW<porcH){
      width = 1670*height/960;
    }else{
      height = 960*width/1670;
    }
    this.player.setSize(width,height);
  }
  onPlayerReady(event){
    event.target.mute();
    event.target.playVideo();
  }
  onStateChange(event){
    if (event.data === 1) {
      document.getElementById("contenedorvideo").style.opacity = "1";
    }
    if (event.data === window['YT'].PlayerState.ENDED) {
      document.getElementById("contenedorvideo").style.opacity = "0";
      event.target.playVideo(); 
    }
  }
}
